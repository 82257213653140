/* eslint-disable */
/* tslint:disable */
import { disableFragmentWarnings } from '@apollo/client';
disableFragmentWarnings();
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '../apollo';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
};

/**
 *   An Accommodation is a representation of a location which can contain one or more RentalUnits.
 *  For example: a hotel would be represented by an Accommodation with each room type represented by a separate RentalUnit.
 */
export type Accommodation = {
    __typename?: 'Accommodation';
    /**   The address where this Accommodation is located. */
    address: Address;
    /**   The attributes assigned to this Accommodation, organized into categories. */
    attributeCategories: Array<AttributeCategory>;
    /**   Indicates the Travelbase brand this Accommodation belongs to (used on aggregate sites). */
    brand: Scalars['String'];
    /**
     *   The time at which check-in closes.
     *  Formatted as HH:MM.
     */
    checkInEndTime: Scalars['String'];
    /**
     *   The time at which check-in opens.
     *  Formatted as HH:MM.
     */
    checkInStartTime: Scalars['String'];
    /**
     *   The time before which guests have to check out.
     *  Formatted as HH:MM.
     */
    checkOutTime: Scalars['String'];
    collapseInSearch: Scalars['Boolean'];
    /**   The GPS coordinates of this Accommodation. */
    coordinates: LatLon;
    /**
     *   A general description of the Accommodation.
     *  This value is language dependent.
     */
    descriptionGeneral?: Maybe<Scalars['String']>;
    /**
     *   A description of the location this Accommodation is in.
     *  This value is language dependent.
     */
    descriptionLocation: Scalars['String'];
    /**   Indicates whether this Accommodation should have it's own page on the website, otherwise only the pages of the underlying RentalUnits exist. */
    hasPublicPage: Scalars['Boolean'];
    /**   Unique ID representing this Accommodation. */
    id: Scalars['ID'];
    /**   Image gallery for the Accommodation. */
    images: Array<AccommodationImage>;
    /**   The main (hero) image for the Accommodation. */
    mainImage?: Maybe<GenericImage>;
    /**   The highest max occupancy of all the rental units within the accommodation */
    maxOccupancy?: Maybe<Scalars['Int']>;
    /**   The name of the Accommodation. */
    name: Scalars['String'];
    /**   An avatar image for the Accommodation's owner. */
    ownerImage?: Maybe<GenericImage>;
    /**
     *   A short descriptive text about the Accommodation's owner.
     *  This value is language dependent.
     */
    ownerIntroduction?: Maybe<Scalars['String']>;
    /**   The name of the Accommodation's owner. */
    ownerName?: Maybe<Scalars['String']>;
    /**
     *   A text containing tips for visitors from the Accommodation's owner.
     *  This value is language dependent.
     */
    ownerTips?: Maybe<Scalars['String']>;
    /**   The name of the Place this Accommodation is located in. */
    place: Scalars['String'];
    /**   Distances to POI's. */
    pointsOfInterest: Array<PointOfInterest>;
    /**   Public email address for reservations. */
    publicReservationsEmail?: Maybe<Scalars['String']>;
    /**   Public phone number for reservations. */
    publicReservationsPhoneNumber?: Maybe<Scalars['String']>;
    /**   Public URL for reservations. */
    publicReservationsWebsiteUrl?: Maybe<Scalars['String']>;
    /**   List the RentalUnits within this Accommodation. */
    rentalUnits: Array<RentalUnit>;
    /**   Search the RentalUnits within this Accommodation. */
    searchRentalUnits: UnitSearchResult;
    /**   Unique slug to represent this Accommodation, most commonly in URLs. */
    slug: Scalars['String'];
    /**   The type of this Accommodation. */
    type: AccommodationTypeEnum;
};

/**
 *   An Accommodation is a representation of a location which can contain one or more RentalUnits.
 *  For example: a hotel would be represented by an Accommodation with each room type represented by a separate RentalUnit.
 */
export type AccommodationSearchRentalUnitsArgs = {
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    params?: Maybe<UnitSearchParamsInput>;
};

/**   An image file available through a CDN URL. */
export type AccommodationImage = Image & {
    __typename?: 'AccommodationImage';
    /**   The category assigned to this Image. */
    category: AccommodationImageCategoryEnum;
    /**
     *   A version of the original image transformed to specific parameters.
     *  Note that the available transform options are dependent on the type of image.
     */
    transform?: Maybe<ImageTransform>;
};

/**   An image file available through a CDN URL. */
export type AccommodationImageTransformArgs = {
    config: ImageTransformConfigEnum;
    format: ImageTransformFormatEnum;
};

/**   Categories for AccommodationImages, describing what the image displays. */
export enum AccommodationImageCategoryEnum {
    Bathroom = 'BATHROOM',
    Bedroom = 'BEDROOM',
    Entrance = 'ENTRANCE',
    Facilities = 'FACILITIES',
    FloorPlan = 'FLOOR_PLAN',
    Front = 'FRONT',
    Kitchen = 'KITCHEN',
    LivingRoom = 'LIVING_ROOM',
    Other = 'OTHER',
    Outdoor = 'OUTDOOR',
    Surroundings = 'SURROUNDINGS',
    View = 'VIEW',
}

/**   Types of Accommodation. */
export enum AccommodationTypeEnum {
    ApartmentBlock = 'APARTMENT_BLOCK',
    BedAndBreakfast = 'BED_AND_BREAKFAST',
    BungalowPark = 'BUNGALOW_PARK',
    Camping = 'CAMPING',
    Hotel = 'HOTEL',
    Location = 'LOCATION',
}

export type Activity = {
    __typename?: 'Activity';
    bookingType: ActivityBookingTypesEnum;
    company?: Maybe<Company>;
    coordinates?: Maybe<LatLon>;
    /**   This value is language dependent. */
    description?: Maybe<Scalars['String']>;
    /**   This value is language dependent. */
    descriptionLocation?: Maybe<Scalars['String']>;
    /**   This value is language dependent. */
    descriptionParticulars?: Maybe<Scalars['String']>;
    externalBookingUrl?: Maybe<Scalars['String']>;
    externalInfoUrl?: Maybe<Scalars['String']>;
    highlighted: Scalars['Boolean'];
    id: Scalars['ID'];
    images: Array<ActivityImage>;
    listImage?: Maybe<GenericImage>;
    /**   The address where this Activity is located. */
    locationAddress?: Maybe<Address>;
    mainImage?: Maybe<GenericImage>;
    maxCapacity?: Maybe<Scalars['Int']>;
    minCapacity?: Maybe<Scalars['Int']>;
    /**   This value is language dependent. */
    name: Scalars['String'];
    place?: Maybe<Scalars['String']>;
    slug: Scalars['String'];
    targetAudiences: Array<TargetAudienceEnum>;
    themes: Array<ActivityTheme>;
    /** @deprecated Now handled by bookingType of Agreement */
    ticketSalesEnabled: Scalars['Boolean'];
    timeslots: Array<Timeslot>;
    videoUrl?: Maybe<Scalars['String']>;
};

export type ActivityTimeslotsArgs = {
    endDate: Scalars['String'];
    startDate: Scalars['String'];
};

/**   Activity booking types */
export enum ActivityBookingTypesEnum {
    /**  Activity is directly bookable */
    Direct = 'DIRECT',
    /**  Activity is externally bookable */
    External = 'EXTERNAL',
    /**  activity is not bookable */
    NotBookable = 'NOT_BOOKABLE',
}

/**   An image file available through a CDN URL. */
export type ActivityImage = Image & {
    __typename?: 'ActivityImage';
    /**
     *   A version of the original image transformed to specific parameters.
     *  Note that the available transform options are dependent on the type of image.
     */
    transform?: Maybe<ImageTransform>;
};

/**   An image file available through a CDN URL. */
export type ActivityImageTransformArgs = {
    config: ImageTransformConfigEnum;
    format: ImageTransformFormatEnum;
};

export type ActivityRateGroup = {
    __typename?: 'ActivityRateGroup';
    /** @deprecated Now handled by bookingType of Agreement */
    canBuyTickets: Scalars['Boolean'];
    rates: Array<Rate>;
    releaseHours?: Maybe<Scalars['Int']>;
};

/**   Activity search result ordering options. */
export enum ActivitySearchOrderEnum {
    /**
     *  Randomize order.
     * # The random seed used is the current date, so the order will be identical between pageloads and between users, but change daily.
     */
    Random = 'RANDOM',
    /**  Order by first available Timeslot. */
    TimeslotsAsc = 'TIMESLOTS_ASC',
}

export type ActivitySearchParamsInput = {
    companyId?: Maybe<Scalars['ID']>;
    /**   Formatted as yyyy-mm-dd, inclusive */
    endDate?: Maybe<Scalars['String']>;
    highlighted?: Maybe<Scalars['Boolean']>;
    placeId?: Maybe<Scalars['ID']>;
    /**   Formatted as yyyy-mm-dd */
    startDate?: Maybe<Scalars['String']>;
    targetAudiences?: Maybe<Array<TargetAudienceEnum>>;
    themeSlugs?: Maybe<Array<Scalars['String']>>;
};

export type ActivitySearchResult = {
    __typename?: 'ActivitySearchResult';
    hits: Array<Activity>;
    placeCounts: Array<GenericAggregationCount>;
    targetAudienceCount: Array<TargetAudienceAggregationCount>;
    themeCounts: Array<GenericAggregationCount>;
    totalHits: Scalars['Int'];
};

export type ActivityTheme = {
    __typename?: 'ActivityTheme';
    id: Scalars['ID'];
    /**   This value is language dependent. */
    name?: Maybe<Scalars['String']>;
    position: Scalars['Int'];
    slug: Scalars['String'];
};

export type AddFavoriteActivityInput = {
    activityId: Scalars['ID'];
};

export type AddFavoriteActivityPayload = {
    __typename?: 'AddFavoriteActivityPayload';
    favorite?: Maybe<FavoriteActivity>;
};

export type AddFavoriteCompanyInput = {
    companyId: Scalars['ID'];
};

export type AddFavoriteCompanyPayload = {
    __typename?: 'AddFavoriteCompanyPayload';
    favorite?: Maybe<FavoriteCompany>;
};

export type AddFavoriteRentalUnitInput = {
    rentalUnitId: Scalars['ID'];
};

export type AddFavoriteRentalUnitPayload = {
    __typename?: 'AddFavoriteRentalUnitPayload';
    favorite?: Maybe<FavoriteRentalUnit>;
};

export type Address = {
    __typename?: 'Address';
    city?: Maybe<Scalars['String']>;
    countryCode?: Maybe<Scalars['String']>;
    number?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
};

export type AddressInput = {
    city?: Maybe<Scalars['String']>;
    countryCode?: Maybe<Scalars['String']>;
    number?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
};

export type Affiliate = {
    __typename?: 'Affiliate';
    handle: Scalars['String'];
    name: Scalars['String'];
};

export type AffiliateInfoInput = {
    handle: Scalars['String'];
    reference?: Maybe<Scalars['String']>;
};

export type AlterBookingInput = {
    bookingId: Scalars['ID'];
    /**
     *   Submitting `null` will leave the surcharges unchanged, submitting an empty array indicates none of the available optional surcharges are desired.
     *  Submitting something other than `null` will result in an error if `Booking.isSurchargesAlterable` is `false`.
     */
    optionalSurcharges?: Maybe<Array<BookingCartItemSurchargeInput>>;
    /**   Submitting something other than `null` will result in an error if `Booking.isTravelGroupAlterable` is `false`. */
    travelGroup?: Maybe<TravelGroupInput>;
};

export type AlterBookingPayload = {
    __typename?: 'AlterBookingPayload';
    booking?: Maybe<Booking>;
};

/**   An attribute which is assigned to either an Accommodation or a RentalUnit. */
export type Attribute = {
    __typename?: 'Attribute';
    /**   A URL-friendly handle unique to this Attribute, this should never change even if the description changes. */
    handle: Scalars['String'];
    /**   URL of an SVG icon representing this Attribute. */
    iconUrl?: Maybe<Scalars['String']>;
    /**   Unique ID representing this Attribute. */
    id: Scalars['ID'];
    /**
     *   The name of this Attribute.
     *  This value is language dependent.
     */
    name: Scalars['String'];
};

/**   A category for Attributes. */
export type AttributeCategory = {
    __typename?: 'AttributeCategory';
    /**   The Attributes in this category. */
    attributes: Array<Attribute>;
    /**   A URL-friendly handle unique to this AttributeCategory, this should never change even if the description changes. */
    handle: Scalars['String'];
    /**   Unique ID representing this AttributeCategory. */
    id: Scalars['ID'];
    /**
     *   The name of this AttributeCategory.
     *  This value is language dependent.
     */
    name: Scalars['String'];
};

export type Booking = OrderItem & {
    __typename?: 'Booking';
    amountAdults: Scalars['Int'];
    amountBabies: Scalars['Int'];
    amountChildren: Scalars['Int'];
    amountPets: Scalars['Int'];
    amountYouths: Scalars['Int'];
    /**   Formatted as yyyy-mm-dd */
    arrivalDate: Scalars['String'];
    customerComment?: Maybe<Scalars['String']>;
    /**   Formatted as yyyy-mm-dd */
    departureDate: Scalars['String'];
    deposit: Scalars['Float'];
    downloadVoucherUrl?: Maybe<Scalars['String']>;
    errors: Array<Scalars['String']>;
    /**   Formatted as yyyy-mm-dd HH:mm */
    flexCancellationUntil?: Maybe<Scalars['String']>;
    handleDepositPayment: Scalars['Boolean'];
    hasCancellationInsurance: Scalars['Boolean'];
    hasContentsInsurance: Scalars['Boolean'];
    id?: Maybe<Scalars['ID']>;
    /**   If true, some part of the Booking can be altered by the Customer (via the `alterBooking` Mutation). Use the `is...Alterable` fields to determine which parts specifically can be altered. */
    isAlterable: Scalars['Boolean'];
    isCancellable: Scalars['Boolean'];
    /**   If true, the optional surcharges for this Booking can be altered by the Customer (via the `alterBooking` Mutation). */
    isSurchargesAlterable: Scalars['Boolean'];
    /**   If true, the travel group for this Booking can be altered by the Customer (via the `alterBooking` Mutation). */
    isTravelGroupAlterable: Scalars['Boolean'];
    optionalSurcharges: Array<BookingCartItemSurcharge>;
    partnerComment?: Maybe<Scalars['String']>;
    paymentPrice: Scalars['Float'];
    priceLines: Array<PriceLine>;
    rentalUnit?: Maybe<RentalUnit>;
    requiresApproval: Scalars['Boolean'];
    review?: Maybe<Review>;
    special?: Maybe<Special>;
    status: BookingStatus;
    totalPrice: Scalars['Float'];
};

export type BookingCartItemInput = {
    amountAdults: Scalars['Int'];
    amountBabies: Scalars['Int'];
    amountChildren: Scalars['Int'];
    amountPets: Scalars['Int'];
    amountYouths: Scalars['Int'];
    /**   Formatted as yyyy-mm-dd */
    arrivalDate: Scalars['String'];
    cancellationInsurance: Scalars['Boolean'];
    contentsInsurance: Scalars['Boolean'];
    /**   Formatted as yyyy-mm-dd */
    departureDate: Scalars['String'];
    optionalSurcharges: Array<BookingCartItemSurchargeInput>;
    rentalUnitId: Scalars['ID'];
    specialId?: Maybe<Scalars['ID']>;
};

export type BookingCartItemSurcharge = {
    __typename?: 'BookingCartItemSurcharge';
    amount: Scalars['Int'];
    rentalUnitSurchargeId: Scalars['ID'];
};

export type BookingCartItemSurchargeInput = {
    amount: Scalars['Int'];
    rentalUnitSurchargeId: Scalars['ID'];
};

export type BookingChangePreview = {
    __typename?: 'BookingChangePreview';
    bookingPreview: Booking;
    newOrderAmountDue: Scalars['Float'];
};

export enum BookingStatus {
    Accepted = 'ACCEPTED',
    CancelledExternal = 'CANCELLED_EXTERNAL',
    CancelledGuest = 'CANCELLED_GUEST',
    CancelledPartner = 'CANCELLED_PARTNER',
    Declined = 'DECLINED',
    Init = 'INIT',
    NoShow = 'NO_SHOW',
    Pending = 'PENDING',
    Waived = 'WAIVED',
}

/**   A bounding box of GPS coordinates. */
export type BoundingBoxInput = {
    bottom: Scalars['Float'];
    left: Scalars['Float'];
    right: Scalars['Float'];
    top: Scalars['Float'];
};

export type CancelBookingInput = {
    bookingId: Scalars['ID'];
};

export type CancelBookingPayload = {
    __typename?: 'CancelBookingPayload';
    booking?: Maybe<Booking>;
};

export type Cart = {
    __typename?: 'Cart';
    availableUpsellOffers: Array<UpsellOffer>;
    id: Scalars['ID'];
    order: Order;
    status: CartStatusEnum;
};

export enum CartStatusEnum {
    Closed = 'CLOSED',
    Open = 'OPEN',
    PaymentFailed = 'PAYMENT_FAILED',
    PaymentPending = 'PAYMENT_PENDING',
}

export type CheckoutCartInput = {
    affiliateInfo?: Maybe<AffiliateInfoInput>;
    agreedToTerms: Scalars['Boolean'];
    bookings?: Maybe<Array<BookingCartItemInput>>;
    cartId: Scalars['ID'];
    customerInfo?: Maybe<CustomerInfoInput>;
    paymentOptions: PaymentOptionsInput;
    tickets?: Maybe<Array<TicketCartItemInput>>;
    upsellPurchases?: Maybe<Array<UpsellPurchaseCartItemInput>>;
};

export type CheckoutCartPayload = {
    __typename?: 'CheckoutCartPayload';
    cart?: Maybe<Cart>;
    redirectUrl?: Maybe<Scalars['String']>;
    success?: Maybe<Scalars['Boolean']>;
};

export type Company = {
    __typename?: 'Company';
    activities: Array<Activity>;
    address: Address;
    categories: Array<CompanyCategory>;
    companyRateGroups: Array<CompanyRateGroup>;
    coordinates?: Maybe<LatLon>;
    /**   This value is language dependent. */
    description?: Maybe<Scalars['String']>;
    /**   This value is language dependent. */
    descriptionParticulars?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    fullCircleImageUrl?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    images: Array<CompanyImage>;
    listImage?: Maybe<GenericImage>;
    logoImage?: Maybe<GenericImage>;
    mainImage?: Maybe<GenericImage>;
    name: Scalars['String'];
    normalOperatingHours?: Maybe<NormalOperatingHours>;
    phoneNumber?: Maybe<Scalars['String']>;
    place: Scalars['String'];
    showInCalendar: Scalars['Boolean'];
    slug: Scalars['String'];
    socialsFacebookUrl?: Maybe<Scalars['String']>;
    socialsInstagramUrl?: Maybe<Scalars['String']>;
    socialsTwitterUrl?: Maybe<Scalars['String']>;
    specialOperatingHours: Array<SpecialOperatingHours>;
    targetAudiences: Array<TargetAudienceEnum>;
    themes: Array<ActivityTheme>;
    videoUrl?: Maybe<Scalars['String']>;
    websiteUrl?: Maybe<Scalars['String']>;
};

export type CompanyCategory = {
    __typename?: 'CompanyCategory';
    children: Array<CompanyCategory>;
    id: Scalars['ID'];
    isRoot: Scalars['Boolean'];
    /**   This value is language dependent. */
    name?: Maybe<Scalars['String']>;
    position: Scalars['Int'];
    slug: Scalars['String'];
};

/**   An image file available through a CDN URL. */
export type CompanyImage = Image & {
    __typename?: 'CompanyImage';
    /**
     *   A version of the original image transformed to specific parameters.
     *  Note that the available transform options are dependent on the type of image.
     */
    transform?: Maybe<ImageTransform>;
};

/**   An image file available through a CDN URL. */
export type CompanyImageTransformArgs = {
    config: ImageTransformConfigEnum;
    format: ImageTransformFormatEnum;
};

export type CompanyRateGroup = {
    __typename?: 'CompanyRateGroup';
    canBuyTickets: Scalars['Boolean'];
    endDate?: Maybe<Scalars['String']>;
    rates: Array<Rate>;
    startDate?: Maybe<Scalars['String']>;
};

export type CompanySearchParamsInput = {
    categorySlugs?: Maybe<Array<Scalars['String']>>;
    /**   Formatted as yyyy-mm-dd, inclusive */
    endDate?: Maybe<Scalars['String']>;
    mainCategorySlugs?: Maybe<Array<Scalars['String']>>;
    placeId?: Maybe<Scalars['ID']>;
    showInCalendar?: Maybe<Scalars['Boolean']>;
    /**   Formatted as yyyy-mm-dd */
    startDate?: Maybe<Scalars['String']>;
    targetAudiences?: Maybe<Array<TargetAudienceEnum>>;
    themeSlugs?: Maybe<Array<Scalars['String']>>;
};

export type CompanySearchResult = {
    __typename?: 'CompanySearchResult';
    categoryCounts: Array<GenericAggregationCount>;
    hits: Array<Company>;
    mainCategoryCounts: Array<GenericAggregationCount>;
    placeCounts: Array<GenericAggregationCount>;
    targetAudienceCount: Array<TargetAudienceAggregationCount>;
    themeCounts: Array<GenericAggregationCount>;
    totalHits: Scalars['Int'];
};

export type ContactInfo = {
    __typename?: 'ContactInfo';
    contactName?: Maybe<Scalars['String']>;
    emails: Array<Scalars['String']>;
    phoneNumbers: Array<Scalars['String']>;
};

export type Customer = {
    __typename?: 'Customer';
    address?: Maybe<Address>;
    agreedToTermsAt: Scalars['String'];
    birthdate?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    favorites: Array<Favorite>;
    firstName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    lastName?: Maybe<Scalars['String']>;
    locale: Scalars['String'];
    orders: Array<Order>;
    phoneNumber?: Maybe<Scalars['String']>;
};

export type CustomerInfoInput = {
    address?: Maybe<AddressInput>;
    birthdate?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    newsletterOptin: Scalars['Boolean'];
    phoneNumber?: Maybe<Scalars['String']>;
};

export type DateCount = {
    __typename?: 'DateCount';
    /**   Formatted as yyyy-mm-dd */
    date: Scalars['String'];
    hits: Scalars['Int'];
};

export type DateHitCount = {
    __typename?: 'DateHitCount';
    /**   Formatted as yyyy-mm-dd */
    date: Scalars['String'];
    hits: Scalars['Int'];
};

export type Favorite = {
    id: Scalars['ID'];
    targetId: Scalars['ID'];
};

export type FavoriteActivity = Favorite & {
    __typename?: 'FavoriteActivity';
    activity?: Maybe<Activity>;
    id: Scalars['ID'];
    targetId: Scalars['ID'];
};

export type FavoriteCompany = Favorite & {
    __typename?: 'FavoriteCompany';
    company?: Maybe<Company>;
    id: Scalars['ID'];
    targetId: Scalars['ID'];
};

export type FavoriteRentalUnit = Favorite & {
    __typename?: 'FavoriteRentalUnit';
    id: Scalars['ID'];
    rentalUnit?: Maybe<RentalUnit>;
    targetId: Scalars['ID'];
};

export type GenericAggregationCount = {
    __typename?: 'GenericAggregationCount';
    hits: Scalars['Int'];
    value: Scalars['String'];
};

/**   An image file available through a CDN URL. */
export type GenericImage = Image & {
    __typename?: 'GenericImage';
    /**
     *   A version of the original image transformed to specific parameters.
     *  Note that the available transform options are dependent on the type of image.
     */
    transform?: Maybe<ImageTransform>;
};

/**   An image file available through a CDN URL. */
export type GenericImageTransformArgs = {
    config: ImageTransformConfigEnum;
    format: ImageTransformFormatEnum;
};

/**   An image file available through a CDN URL. */
export type Image = {
    /**
     *   A version of the original image transformed to specific parameters.
     *  Note that the available transform options are dependent on the type of image.
     */
    transform?: Maybe<ImageTransform>;
};

/**   An image file available through a CDN URL. */
export type ImageTransformArgs = {
    config: ImageTransformConfigEnum;
    format: ImageTransformFormatEnum;
};

/**   A version of an image transformed according to the requested parameters. */
export type ImageTransform = {
    __typename?: 'ImageTransform';
    /**
     *   This is an extremely downscaled version of the original image which can be used as a placeholder while loading the full image.
     *  This is formatted as a string of base64 encoded WebP data, and is therefore only available on transforms with the `WEBP` format.
     */
    placeholder?: Maybe<Scalars['String']>;
    /**   The aspect ratio of this image. */
    ratio: Scalars['Float'];
    /**   The URL for the largest version of this transform. */
    src: Scalars['String'];
    /**   The different available sizes of this transform, formatted for use in an HTML `srcSet` attribute. */
    srcSet: Scalars['String'];
};

/**   Available image transform configurations. This determines the scaling method and the generated sizes. */
export enum ImageTransformConfigEnum {
    /**
     *  Used for displaying avatar images, generally inside a circular frame. Images are transformed and cropped to fill a 1:1 frame.
     * # Available on avatar image fields.
     */
    Avatar = 'AVATAR',
    /**
     *  Used for displaying images of varying aspect ratio in a gallery. Images are transformed to fit inside a 4:3 frame without cropping.
     * # Available on fields containing multiple images.
     */
    Gallery = 'GALLERY',
    /**
     *  Used for displaying large landscape images in a hero element. Images are transformed and cropped to fill a 4:3 frame.
     * # Available on fields called `mainImage`.
     */
    Hero = 'HERO',
    /**
     *  Used for displaying logos. Images are transformed to fit inside a 1:1 frame and extended with empty space to always produce a 1:1 image.
     * # Available on logo image fields.
     */
    Logo = 'LOGO',
    /**
     *  Used for displaying landscape images in a listing. Images are transformed and cropped to fill a 4:3 frame.
     * # Available on fields called `listImage`.
     */
    Tile = 'TILE',
}

/**
 *   Available image formats for transforms. Use WebP whenever possible for lowest image size.
 *  Note that not all formats are available for every transform.
 */
export enum ImageTransformFormatEnum {
    /**  JPEG is not available for transforms with the `LOGO` config. */
    Jpeg = 'JPEG',
    /**  PNG is only available for transforms with the `LOGO` config. */
    Png = 'PNG',
    /**  WebP is available for all transforms. */
    Webp = 'WEBP',
}

/**   A set of GPS coordinates. */
export type LatLon = {
    __typename?: 'LatLon';
    /**   The latitude. */
    lat: Scalars['Float'];
    /**   The longitude. */
    lon: Scalars['Float'];
};

/**   A set of GPS coordinates. */
export type LatLonInput = {
    /**   The latitude. */
    lat: Scalars['Float'];
    /**   The longitude. */
    lon: Scalars['Float'];
};

export type LeaveReviewInput = {
    bookingId: Scalars['ID'];
    comment: Scalars['String'];
    ratingHospitality: Scalars['Int'];
    ratingLayout: Scalars['Int'];
    ratingLocation: Scalars['Int'];
    ratingMaintenance: Scalars['Int'];
    ratingValueForMoney: Scalars['Int'];
    tips?: Maybe<Scalars['String']>;
    title: Scalars['String'];
    travelGroupType: TravelGroupType;
};

export type LeaveReviewPayload = {
    __typename?: 'LeaveReviewPayload';
    review?: Maybe<Review>;
};

export type LeaveUnauthenticatedReviewInput = {
    bookingHash: Scalars['ID'];
    comment: Scalars['String'];
    ratingHospitality: Scalars['Int'];
    ratingLayout: Scalars['Int'];
    ratingLocation: Scalars['Int'];
    ratingMaintenance: Scalars['Int'];
    ratingValueForMoney: Scalars['Int'];
    tips?: Maybe<Scalars['String']>;
    title: Scalars['String'];
    travelGroupType: TravelGroupType;
};

export type Mutation = {
    __typename?: 'Mutation';
    /**
     *   Add an Activity to a Customer's favorites.
     *  Requires an active login session.
     */
    addFavoriteActivity: AddFavoriteActivityPayload;
    /**
     *   Add a Company to a Customer's favorites.
     *  Requires an active login session.
     */
    addFavoriteCompany: AddFavoriteCompanyPayload;
    /**
     *   Add a RentalUnit to a Customer's favorites.
     *  Requires an active login session.
     */
    addFavoriteRentalUnit: AddFavoriteRentalUnitPayload;
    /**
     *   Alter an existing Booking.
     *  The Booking must belong to the currently logged in Customer and Booking.isAlterable must be TRUE.
     */
    alterBooking: AlterBookingPayload;
    /**
     *   Cancel an existing Booking.
     *  The Booking must belong to the currently logged in Customer and Booking.isCancellable must be TRUE.
     */
    cancelBooking: CancelBookingPayload;
    /**   Initiate final checkout for an existing Cart. */
    checkoutCart: CheckoutCartPayload;
    /**
     *   Leave a review for a Booking.
     *  The Booking must be accepted, in the past, linked to the currently logged in Customer, and not already have a Review.
     */
    leaveReview: LeaveReviewPayload;
    /**   Leave a review without an active login session by supplying the hash from a review request email. */
    leaveUnauthenticatedReview: LeaveReviewPayload;
    /**
     *   Initiate payment for an existing Order.
     *  The Order must belong to the currently logged in Customer.
     */
    payOrder: PayOrderPayload;
    /**   Register a new Customer. */
    register: RegisterPayload;
    /**
     *   Remove an item for a Customer's favorites.
     *  The Favorite must belong to the currently logged in Customer.
     */
    removeFavorite: RemoveFavoritePayload;
    /**
     *   Request a login email for a Customer.
     *  The email contains a link which supplies an `authCode` for the oAuth token endpoint.
     */
    sendAuthenticationEmail: SendAuthenticationEmailPayload;
    /**   Create or update a Cart with contents and/or details. */
    updateCart: UpdateCartPayload;
    /**
     *   Update the details of an existing Customer.
     *  This can only be performed on the currently logged in Customer.
     */
    updateCustomer: UpdateCustomerPayload;
};

export type MutationAddFavoriteActivityArgs = {
    input: AddFavoriteActivityInput;
};

export type MutationAddFavoriteCompanyArgs = {
    input: AddFavoriteCompanyInput;
};

export type MutationAddFavoriteRentalUnitArgs = {
    input: AddFavoriteRentalUnitInput;
};

export type MutationAlterBookingArgs = {
    input: AlterBookingInput;
};

export type MutationCancelBookingArgs = {
    input: CancelBookingInput;
};

export type MutationCheckoutCartArgs = {
    input: CheckoutCartInput;
};

export type MutationLeaveReviewArgs = {
    input: LeaveReviewInput;
};

export type MutationLeaveUnauthenticatedReviewArgs = {
    input: LeaveUnauthenticatedReviewInput;
};

export type MutationPayOrderArgs = {
    input: PayOrderInput;
};

export type MutationRegisterArgs = {
    input: RegisterInput;
};

export type MutationRemoveFavoriteArgs = {
    input: RemoveFavoriteInput;
};

export type MutationSendAuthenticationEmailArgs = {
    input: SendAuthenticationEmailInput;
};

export type MutationUpdateCartArgs = {
    input: UpdateCartInput;
};

export type MutationUpdateCustomerArgs = {
    input: UpdateCustomerInput;
};

export type NormalOperatingHours = {
    __typename?: 'NormalOperatingHours';
    friday: Array<TimeOpenClosed>;
    monday: Array<TimeOpenClosed>;
    saturday: Array<TimeOpenClosed>;
    sunday: Array<TimeOpenClosed>;
    thursday: Array<TimeOpenClosed>;
    tuesday: Array<TimeOpenClosed>;
    wednesday: Array<TimeOpenClosed>;
};

export type Order = {
    __typename?: 'Order';
    affiliate?: Maybe<Affiliate>;
    affiliateReference?: Maybe<Scalars['String']>;
    agreedToTerms?: Maybe<Scalars['Boolean']>;
    createdDate?: Maybe<Scalars['String']>;
    customer?: Maybe<Customer>;
    downloadOverviewUrl?: Maybe<Scalars['String']>;
    errors: Array<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    invoiceAddress?: Maybe<Address>;
    isDeskOrder: Scalars['Boolean'];
    newsletterOptin?: Maybe<Scalars['Boolean']>;
    number?: Maybe<Scalars['String']>;
    orderItems: Array<OrderItem>;
    paymentOptions?: Maybe<PaymentOptions>;
    payments: Array<Payment>;
    totalPrice: Scalars['Float'];
};

export type OrderItem = {
    errors: Array<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    paymentPrice: Scalars['Float'];
    priceLines: Array<PriceLine>;
    totalPrice: Scalars['Float'];
};

export type PayOrderInput = {
    orderId: Scalars['ID'];
    paymentOptions: PaymentOptionsInput;
};

export type PayOrderPayload = {
    __typename?: 'PayOrderPayload';
    order?: Maybe<Order>;
    redirectUrl?: Maybe<Scalars['String']>;
};

export type Payment = {
    __typename?: 'Payment';
    amount: Scalars['Float'];
    date: Scalars['String'];
    method: PaymentMethodEnum;
    status: PaymentStatusEnum;
};

export enum PaymentMethodEnum {
    ApplePay = 'APPLE_PAY',
    Bancontact = 'BANCONTACT',
    BankAccount = 'BANK_ACCOUNT',
    Cash = 'CASH',
    CreditCard = 'CREDIT_CARD',
    CreditNote = 'CREDIT_NOTE',
    DeskBooking = 'DESK_BOOKING',
    GiftCard = 'GIFT_CARD',
    Giropay = 'GIROPAY',
    Ideal = 'IDEAL',
    IngHomepay = 'ING_HOMEPAY',
    None = 'NONE',
    Other = 'OTHER',
    Paypal = 'PAYPAL',
    PointOfSale = 'POINT_OF_SALE',
    SepaDirectDebit = 'SEPA_DIRECT_DEBIT',
    SepaTransfer = 'SEPA_TRANSFER',
    Sofort = 'SOFORT',
    Trustly = 'TRUSTLY',
}

export type PaymentOptions = {
    __typename?: 'PaymentOptions';
    /**   The methods available for this payment. */
    availableMethods: Array<PaymentMethodEnum>;
    /**   The date before which the totalAmountDue must be paid. Formatted as: yyyy-mm-dd. */
    fullPaymentDueDate?: Maybe<Scalars['String']>;
    /**   The minimum amount that needs to be paid at this point. */
    partialPaymentAmount: Scalars['Float'];
    /**   The total remaining amount due to be paid through Travelbase. */
    totalAmountDue: Scalars['Float'];
};

export type PaymentOptionsInput = {
    /**   Method "SEPA_DIRECT_DEBIT" only, this must be supplied */
    accountHolderName?: Maybe<Scalars['String']>;
    /**   Method "SEPA_DIRECT_DEBIT" only, this must be supplied and must be a valid IBAN */
    iban?: Maybe<Scalars['String']>;
    landingUrl: Scalars['String'];
    method: PaymentMethodEnum;
    payPartialAmount: Scalars['Boolean'];
    /**   Method "POINT_OF_SALE" only, this must be supplied and be stored in the PaymentTerminal table */
    sellDeviceId?: Maybe<Scalars['String']>;
};

export enum PaymentStatusEnum {
    Completed = 'COMPLETED',
    Failed = 'FAILED',
    Initializing = 'INITIALIZING',
    Pending = 'PENDING',
    Provisional = 'PROVISIONAL',
}

export type Place = {
    __typename?: 'Place';
    id: Scalars['ID'];
    name: Scalars['String'];
    position: Scalars['Int'];
};

/**   Description of a POI and the distance to it from the parent object. */
export type PointOfInterest = {
    __typename?: 'PointOfInterest';
    /**   The distance in meters from the parent object to the POI (as the crow flies). */
    distance: Scalars['Int'];
    /**
     *   Name of the POI.
     *  This value is language dependent.
     */
    name: Scalars['String'];
};

export type PriceLine = {
    __typename?: 'PriceLine';
    category?: Maybe<PriceLineCategoryEnum>;
    label: Scalars['String'];
    quantity?: Maybe<Scalars['String']>;
    totalPrice: Scalars['Float'];
    unitPrice?: Maybe<Scalars['Float']>;
};

export enum PriceLineCategoryEnum {
    Accommodation = 'ACCOMMODATION',
    AdminCosts = 'ADMIN_COSTS',
    Charges = 'CHARGES',
    Insurance = 'INSURANCE',
    NotInTotal = 'NOT_IN_TOTAL',
    Special = 'SPECIAL',
    Surcharges = 'SURCHARGES',
}

export type PropertyFilterCount = {
    __typename?: 'PropertyFilterCount';
    hits: Scalars['Int'];
    property: Scalars['String'];
};

/**   Properties to filter by in a search query. */
export type PropertyFilterInput = {
    /**
     *   The handles of the properties to filter by.
     *  Multiples handles are combined as "OR".
     *  Be aware that when treating filters as "OR", the corresponding FilterBuckets in the result are essentially useless as possible values will be excluded based on already active filters.
     */
    properties: Array<Scalars['String']>;
};

export type Query = {
    __typename?: 'Query';
    /**   Retrieve details for a specific Accommodation. */
    accommodation?: Maybe<Accommodation>;
    /**   Retrieve details for a specific set of Accommodations. */
    accommodationsBySlug: Array<Accommodation>;
    /**   Search within all available Activities. */
    activities: ActivitySearchResult;
    /**   Retrieve details for a specific set of Activities. */
    activitiesBySlug: Array<Activity>;
    /**   Retrieve details for a specific Activity. */
    activity?: Maybe<Activity>;
    /**   Retrieve details for all available ActivityThemes. */
    activityThemes: Array<ActivityTheme>;
    /**
     *   Preview the effects of altering a specific Booking.
     *  The booking must belong to the currently logged in Customer.
     */
    bookingAlterationPreview?: Maybe<BookingChangePreview>;
    /**
     *   Preview the effects of cancelling a specific Booking.
     *  The booking must belong to the currently logged in Customer.
     */
    bookingCancellationPreview?: Maybe<BookingChangePreview>;
    /**   Retrieve details for a specific Cart. */
    cart?: Maybe<Cart>;
    /**   Search within all available Companies. */
    companies: CompanySearchResult;
    /**   Retrieve details for a specific set of Companies. */
    companiesBySlug: Array<Company>;
    /**   Retrieve details for a specific Company. */
    company?: Maybe<Company>;
    /**   Retrieve details for all available CompanyCategories. */
    companyCategories: Array<CompanyCategory>;
    /**   Retrieve details for a specific Order. */
    order?: Maybe<Order>;
    /**   Retrieve details for all available Places. */
    places: Array<Place>;
    /**   Retrieve details for a specific RentalUnit. */
    rentalUnit?: Maybe<RentalUnit>;
    /**   Retrieve details for a specific set of RentalUnits. */
    rentalUnits: Array<RentalUnit>;
    /**   Search within all available RentalUnits. */
    searchRentalUnits: SearchRentalUnitsResult;
    /**
     *   Search for available Trips.
     *  Note that this will only ever return and/or count 1 Trip per RentalUnit, even if multiple Trips for the same RentalUnit match the search parameters.
     */
    searchTrips: SearchTripsResult;
    /**   Retrieve details for a specific Special. */
    special?: Maybe<Special>;
    /**   Retrieve all possible properties by which RentalUnits can be filtered. */
    unitFilterProperties: Array<UnitFilterPropertyCategory>;
    /** @deprecated Use searchRentalUnits or searchTrips instead */
    unitSearch: UnitSearchResult;
    /** @deprecated Use searchTrips.stats.dateCounts instead */
    unitSearchAlternativeDates: Array<DateHitCount>;
    /**   Returns the currently logged in Customer, if any. */
    viewer?: Maybe<Customer>;
};

export type QueryAccommodationArgs = {
    slug: Scalars['String'];
};

export type QueryAccommodationsBySlugArgs = {
    slugs: Array<Scalars['String']>;
};

export type QueryActivitiesArgs = {
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order?: Maybe<ActivitySearchOrderEnum>;
    params: ActivitySearchParamsInput;
};

export type QueryActivitiesBySlugArgs = {
    slugs: Array<Scalars['String']>;
};

export type QueryActivityArgs = {
    slug: Scalars['String'];
};

export type QueryBookingAlterationPreviewArgs = {
    input: AlterBookingInput;
};

export type QueryBookingCancellationPreviewArgs = {
    input: CancelBookingInput;
};

export type QueryCartArgs = {
    id: Scalars['ID'];
};

export type QueryCompaniesArgs = {
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    params: CompanySearchParamsInput;
    sortByDistanceTo?: Maybe<LatLonInput>;
};

export type QueryCompaniesBySlugArgs = {
    slugs: Array<Scalars['String']>;
};

export type QueryCompanyArgs = {
    slug: Scalars['String'];
};

export type QueryOrderArgs = {
    id: Scalars['ID'];
};

export type QueryRentalUnitArgs = {
    slug: Scalars['String'];
};

export type QueryRentalUnitsArgs = {
    slugs: Array<Scalars['String']>;
};

export type QuerySearchRentalUnitsArgs = {
    babies?: Maybe<Scalars['Int']>;
    filters?: Maybe<Array<PropertyFilterInput>>;
    limit?: Maybe<Scalars['Int']>;
    mapArea?: Maybe<BoundingBoxInput>;
    offset?: Maybe<Scalars['Int']>;
    order?: Maybe<SearchRentalUnitsOrderEnum>;
    persons?: Maybe<Scalars['Int']>;
    pets?: Maybe<Scalars['Int']>;
};

export type QuerySearchTripsArgs = {
    babies: Scalars['Int'];
    date: Array<Scalars['String']>;
    duration: Array<Scalars['Int']>;
    filters?: Maybe<Array<PropertyFilterInput>>;
    mapArea?: Maybe<BoundingBoxInput>;
    persons: Scalars['Int'];
    pets: Scalars['Int'];
    price?: Maybe<RangeFilterInput>;
    specialId?: Maybe<Array<Scalars['ID']>>;
    tripType?: Maybe<Array<TripTypeEnum>>;
};

export type QuerySpecialArgs = {
    id: Scalars['ID'];
};

export type QueryUnitSearchArgs = {
    limit?: Maybe<Scalars['Int']>;
    mapArea?: Maybe<BoundingBoxInput>;
    offset?: Maybe<Scalars['Int']>;
    order?: Maybe<UnitSearchOrderEnum>;
    params: UnitSearchParamsInput;
};

export type QueryUnitSearchAlternativeDatesArgs = {
    params: UnitAndTripSearchParamsInput;
};

export type RangeFilterInput = {
    max?: Maybe<Scalars['Float']>;
    min?: Maybe<Scalars['Float']>;
};

export type Rate = {
    __typename?: 'Rate';
    id: Scalars['ID'];
    /**   This value is language dependent. */
    name: Scalars['String'];
    position: Scalars['Int'];
    price: Scalars['Float'];
};

export type RegisterInput = {
    agreedToTerms: Scalars['Boolean'];
    email: Scalars['String'];
    landingUrl: Scalars['String'];
    newsletterOptIn: Scalars['Boolean'];
};

export type RegisterPayload = {
    __typename?: 'RegisterPayload';
    customer?: Maybe<Customer>;
};

export type RemoveFavoriteInput = {
    favoriteId: Scalars['ID'];
};

export type RemoveFavoritePayload = {
    __typename?: 'RemoveFavoritePayload';
    success: Scalars['Boolean'];
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnit = {
    __typename?: 'RentalUnit';
    /**   The Accommodation this RentalUnit is a part of. */
    accommodation: Accommodation;
    /**   Search for Trips in other RentalUnits within the same Accommodation. */
    alternativesInAccommodation?: Maybe<UnitSearchResult>;
    /**   The amount of bathrooms present in this RentalUnit. */
    amountBathrooms?: Maybe<Scalars['Int']>;
    /**   The amount of bedrooms present in this RentalUnit. */
    amountBedrooms?: Maybe<Scalars['Int']>;
    /**   The amount of beds present in this RentalUnit. */
    amountBeds?: Maybe<Scalars['Int']>;
    /**   The amount of toilets present in this RentalUnit. */
    amountToilets?: Maybe<Scalars['Int']>;
    /**   The attributes assigned to this RentalUnit, organized into categories. */
    attributeCategories: Array<AttributeCategory>;
    /**   Indicates the Travelbase brand this RentalUnit belongs to (used on aggregate sites). */
    brand: Scalars['String'];
    /**   Whether or not cancellation insurance can be purchased during checkout for this RentalUnit. */
    cancellationInsuranceAvailable: Scalars['Boolean'];
    /**   Unique code representing this RentalUnit to facilitate communication with partners and customers. */
    code: Scalars['String'];
    /**   Whether or not contents insurance can be purchased during checkout for this RentalUnit. */
    contentsInsuranceAvailable: Scalars['Boolean'];
    /**
     *   An optional description of features unique to this RentalUnit.
     *  This value is language dependent.
     */
    descriptionExtras?: Maybe<Scalars['String']>;
    /**
     *   A general description of the RentalUnit, describing its layout.
     *  This value is language dependent.
     */
    descriptionLayout: Scalars['String'];
    /**
     *   A text containing important information for any (prospective) guests.
     *  This information is also displayed on the booking voucher.
     *  This value is language dependent.
     */
    descriptionParticulars?: Maybe<Scalars['String']>;
    /**
     *   The first date for which a Trip is available, if any.
     *  Formatted as yyyy-mm-dd.
     */
    firstTripDate?: Maybe<Scalars['String']>;
    /**   If set, bookings for this RentalUnit can be cancelled with full refund up to this many days before arrival. */
    flexCancellationDays?: Maybe<Scalars['Int']>;
    /**   A URL for an embeddable 3D view of the RentalUnit. */
    fullCircleImageUrl?: Maybe<Scalars['String']>;
    /**   Way to check if this RentalUnit matches a specific filter property. */
    hasFilterProperty: Scalars['Boolean'];
    /**   Unique ID representing this RentalUnit. */
    id: Scalars['ID'];
    /**   A set of images intended to be displayed as some form of photo gallery. */
    images: Array<AccommodationImage>;
    /**   An image (photo) intended for use in a listing/as a thumbnail. */
    listImage: GenericImage;
    /**   The living area (in square meters) of the RentalUnit. */
    livingArea?: Maybe<Scalars['Int']>;
    /**   An image (photo) intended for use in a large (hero) display. */
    mainImage?: Maybe<GenericImage>;
    /**   The maximum amount of babies that may be brought along without counting against the occupancy and without being charged as an occupant. */
    maxExtraBabyOccupancy: Scalars['Int'];
    /**   The maximum travel party size for which this RentalUnit can be booked. */
    maxOccupancy: Scalars['Int'];
    /**   The minimum travel party size for which this RentalUnit can be booked. */
    minOccupancy: Scalars['Int'];
    /**   The municipal registration number for this RentalUnit. */
    municipalRegistration?: Maybe<Scalars['String']>;
    /**   The name of this RentalUnit. */
    name: Scalars['String'];
    /**   The outdoor area (in square meters) private to the RentalUnit. */
    outdoorArea?: Maybe<Scalars['Int']>;
    /**   The amount of pets allowed in this RentalUnit. */
    petsAllowed: Scalars['Int'];
    /**
     *   Search for alternative RentalUnits given the specified search parameters.
     *  Alternatives will always have the same `type` and `maxOccupancy`, and will prioritize similar `livingArea` and geographic location.
     */
    relevantAlternatives?: Maybe<UnitSearchResult>;
    /**   A summary of the reviews for this RentalUnit. */
    reviewStats?: Maybe<ReviewStats>;
    /**   The reviews submitted by past guests for this RentalUnit. */
    reviews: Array<Review>;
    /**   Unique slug to represent this RentalUnit, most commonly in URLs. */
    slug: Scalars['String'];
    /**   The surcharges valid on the specified arrival date, or today if no date is submitted. */
    surcharges: Array<RentalUnitSurcharge>;
    /**
     *   This is a short "marketing-oriented" description used as a display title.
     *  This value is language dependent.
     */
    tagline: Scalars['String'];
    /**   Search within available Trips for this RentalUnit in a specific time period. */
    trips: Array<Trip>;
    /**   The type of this RentalUnit. */
    type: RentalUnitTypeEnum;
    /**   A URL for an embeddable video presenting the RentalUnit. */
    videoUrl?: Maybe<Scalars['String']>;
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitAlternativesInAccommodationArgs = {
    params?: Maybe<UnitSearchParamsInput>;
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitHasFilterPropertyArgs = {
    handle: Scalars['String'];
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitRelevantAlternativesArgs = {
    params?: Maybe<UnitSearchParamsInput>;
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitReviewsArgs = {
    limit: Scalars['Int'];
    offset?: Maybe<Scalars['Int']>;
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitSurchargesArgs = {
    arrivalDate?: Maybe<Scalars['String']>;
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitTripsArgs = {
    babies: Scalars['Int'];
    endDate: Scalars['String'];
    persons: Scalars['Int'];
    pets: Scalars['Int'];
    startDate: Scalars['String'];
};

export type RentalUnitSurcharge = {
    __typename?: 'RentalUnitSurcharge';
    calculation: SurchargeCalculationEnum;
    /**   This value is language dependent. */
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    maxAmount: Scalars['Int'];
    minAmount: Scalars['Int'];
    /**   This value is language dependent. */
    name: Scalars['String'];
    unitPrice: Scalars['Float'];
};

/**   Types of RentalUnit. */
export enum RentalUnitTypeEnum {
    Bed = 'BED',
    Boat = 'BOAT',
    CamperSpot = 'CAMPER_SPOT',
    CampingPitch = 'CAMPING_PITCH',
    Home = 'HOME',
    Room = 'ROOM',
    Tent = 'TENT',
}

/**   A review of a RentalUnit submitted by a Customer. */
export type Review = {
    __typename?: 'Review';
    /** @deprecated No longer supported */
    anonymous?: Maybe<Scalars['Boolean']>;
    /**   The average of the specific ratings given. */
    average: Scalars['Float'];
    /**   The city the Customer is from. */
    city?: Maybe<Scalars['String']>;
    /**   The main text submitted by the Customer. */
    comment: Scalars['String'];
    /**
     *   The date this Review was submitted.
     *  Formatted as yyyy-mm-dd.
     */
    date: Scalars['String'];
    /** @deprecated No longer supported */
    displayName?: Maybe<Scalars['String']>;
    /**   Unique ID representing this Review. */
    id: Scalars['ID'];
    /**
     *   The language in which this Review was written.
     *  This is a 2-letter language code.
     */
    language?: Maybe<Scalars['String']>;
    /**   Rating (1-10) of the hospitality provided. */
    ratingHospitality?: Maybe<Scalars['Int']>;
    /**   Rating (1-10) of the RentalUnit's layout. */
    ratingLayout?: Maybe<Scalars['Int']>;
    /**   Rating (1-10) of the location of the Accommodation. */
    ratingLocation?: Maybe<Scalars['Int']>;
    /**   Rating (1-10) of the level of maintenance. */
    ratingMaintenance?: Maybe<Scalars['Int']>;
    /**   Rating (1-10) of value for money. */
    ratingValueForMoney?: Maybe<Scalars['Int']>;
    /**   The reply to this review submitted by the Partner, if any. */
    reply?: Maybe<Scalars['String']>;
    /**
     *   Tips the Customer submitted for the Partner, if any.
     *  This field is not publicly visible, only logged in Customers can see this for the Reviews they submitted themselves.
     */
    tips?: Maybe<Scalars['String']>;
    /**   The title/short summary the Customer submitted. */
    title: Scalars['String'];
    /**
     *   The type of travel group this Customer visited the RentalUnit with.
     *  This field is not publicly visible, only logged in Customers can see this for the Reviews they submitted themselves.
     */
    travelGroupType?: Maybe<TravelGroupType>;
};

/**   Summary of the Reviews submitted. */
export type ReviewStats = {
    __typename?: 'ReviewStats';
    /**   The average of all ratings across all Reviews. */
    average: Scalars['Float'];
    /**   Amount of Reviews available. */
    count: Scalars['Int'];
    /**   The average of the `hospitality` ratings across all Reviews. */
    hospitality: Scalars['Float'];
    /**   The average of the `layout` ratings across all Reviews. */
    layout: Scalars['Float'];
    /**   The average of the `location` ratings across all Reviews. */
    location: Scalars['Float'];
    /**   The average of the `maintenance` ratings across all Reviews. */
    maintenance: Scalars['Float'];
    /**   The average of the `valueForMoney` ratings across all Reviews. */
    valueForMoney: Scalars['Float'];
};

/**   RentalUnit search result ordering options. */
export enum SearchRentalUnitsOrderEnum {
    /**
     *  Randomize order.
     * # The random seed used is the current date, so the order will be identical between pageloads and between users, but change daily.
     */
    RandomBoosted = 'RANDOM_BOOSTED',
    /**  Sort by amount of recent bookings. */
    RecentBookingsBoosted = 'RECENT_BOOKINGS_BOOSTED',
    /**  Sort by average RentalUnit review rating, descending. */
    ReviewRatingDesc = 'REVIEW_RATING_DESC',
}

/**   Results of a RentalUnit search query. */
export type SearchRentalUnitsResult = {
    __typename?: 'SearchRentalUnitsResult';
    /**   The search results. */
    hits: Array<RentalUnit>;
    /**   Available filter properties and corresponding result counts within the result set. */
    propertyFilterCounts: Array<PropertyFilterCount>;
    /**   Total amount of matching RentalUnits. */
    totalHits: Scalars['Int'];
};

/**   The results of a search within Trips. */
export type SearchTripsResult = {
    __typename?: 'SearchTripsResult';
    /**   Fetch Trips matching the specified parameters. */
    hits: Array<Trip>;
    /**   Statistical information about the Trips matching the specified parameters. */
    stats: SearchTripsStats;
};

/**   The results of a search within Trips. */
export type SearchTripsResultHitsArgs = {
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order?: Maybe<UnitSearchOrderEnum>;
};

/**   Statistics for a specific search whitin Trips. */
export type SearchTripsStats = {
    __typename?: 'SearchTripsStats';
    /**
     *   List of dates and the respective amount of Trips found for that date.
     *  Only useful in case the search parameters contain multiple dates. Any date with 0 Trips found will not appear in this result.
     */
    dateCounts: Array<DateCount>;
    /**   The highest price among the Trips found. */
    maxPrice?: Maybe<Scalars['Float']>;
    /**   The lowest price among the Trips found. */
    minPrice?: Maybe<Scalars['Float']>;
    /**   List of filter properties and the respective amount of Trips found. */
    propertyFilterCounts: Array<PropertyFilterCount>;
    /**   List of Specials and the respective amount of Trips found for that Special. */
    specialCounts: Array<SpecialCount>;
    /**   The total amount of Trips found. */
    totalHits: Scalars['Int'];
    /**   List of TripTypes and the respective amount of Trips found for that TripType. */
    tripTypeCounts: Array<TripTypeCount>;
};

export type SendAuthenticationEmailInput = {
    email: Scalars['String'];
    landingUrl: Scalars['String'];
};

export type SendAuthenticationEmailPayload = {
    __typename?: 'SendAuthenticationEmailPayload';
    success?: Maybe<Scalars['Boolean']>;
};

export type Special = {
    __typename?: 'Special';
    /**   This value is language dependent. */
    description: Scalars['String'];
    id: Scalars['ID'];
    landingPageUrl?: Maybe<Scalars['String']>;
    /**   This value is language dependent. */
    name: Scalars['String'];
};

export type SpecialCount = {
    __typename?: 'SpecialCount';
    hits: Scalars['Int'];
    special: Special;
};

export type SpecialOperatingHours = {
    __typename?: 'SpecialOperatingHours';
    endDate: Scalars['String'];
    friday?: Maybe<Array<TimeOpenClosed>>;
    monday?: Maybe<Array<TimeOpenClosed>>;
    saturday?: Maybe<Array<TimeOpenClosed>>;
    startDate: Scalars['String'];
    sunday?: Maybe<Array<TimeOpenClosed>>;
    thursday?: Maybe<Array<TimeOpenClosed>>;
    tuesday?: Maybe<Array<TimeOpenClosed>>;
    wednesday?: Maybe<Array<TimeOpenClosed>>;
};

export enum SurchargeCalculationEnum {
    PerPerson = 'PER_PERSON',
    PerPersonPerNight = 'PER_PERSON_PER_NIGHT',
    PerPet = 'PER_PET',
    PerPetPerNight = 'PER_PET_PER_NIGHT',
    PerPiece = 'PER_PIECE',
    PerPiecePerNight = 'PER_PIECE_PER_NIGHT',
    Subsequent = 'SUBSEQUENT',
}

export type TargetAudienceAggregationCount = {
    __typename?: 'TargetAudienceAggregationCount';
    hits: Scalars['Int'];
    value: TargetAudienceEnum;
};

/**   Available target audience options. */
export enum TargetAudienceEnum {
    Adults = 'ADULTS',
    AllAges = 'ALL_AGES',
    Children = 'CHILDREN',
    Pets = 'PETS',
    WheelchairAccessible = 'WHEELCHAIR_ACCESSIBLE',
}

export type Ticket = OrderItem & {
    __typename?: 'Ticket';
    activity?: Maybe<Activity>;
    downloadVoucherUrl?: Maybe<Scalars['String']>;
    endDateTime: Scalars['String'];
    errors: Array<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    paymentPrice: Scalars['Float'];
    priceLines: Array<PriceLine>;
    rateLines: Array<TicketRateLine>;
    startDateTime: Scalars['String'];
    status: TicketStatus;
    timeslot?: Maybe<Timeslot>;
    totalPrice: Scalars['Float'];
};

export type TicketCartItemInput = {
    rateLines: Array<TicketRateLineInput>;
    timeslotId: Scalars['ID'];
};

export type TicketRateLine = {
    __typename?: 'TicketRateLine';
    amount: Scalars['Int'];
    rateId?: Maybe<Scalars['ID']>;
    totalPrice: Scalars['Float'];
    unitPrice: Scalars['Float'];
};

export type TicketRateLineInput = {
    amount: Scalars['Int'];
    rateId: Scalars['ID'];
};

export enum TicketStatus {
    Accepted = 'ACCEPTED',
    Init = 'INIT',
    Waived = 'WAIVED',
}

export type TimeOpenClosed = {
    __typename?: 'TimeOpenClosed';
    timeClosed: Scalars['String'];
    timeOpen: Scalars['String'];
};

export type Timeslot = {
    __typename?: 'Timeslot';
    /**   If `null`, unlimited tickets are available */
    allotment?: Maybe<Scalars['Int']>;
    /**   Formatted as yyyy-mm-dd HH:MM */
    endDateTime: Scalars['String'];
    id: Scalars['ID'];
    label?: Maybe<Scalars['String']>;
    rateGroup?: Maybe<ActivityRateGroup>;
    /**   Formatted as yyyy-mm-dd HH:MM */
    startDateTime: Scalars['String'];
};

export type TravelGroupInput = {
    amountAdults: Scalars['Int'];
    amountBabies: Scalars['Int'];
    amountChildren: Scalars['Int'];
    amountPets: Scalars['Int'];
    amountYouths: Scalars['Int'];
};

export enum TravelGroupType {
    Alone = 'ALONE',
    BusinessTrip = 'BUSINESS_TRIP',
    FamilyWithOlderChildren = 'FAMILY_WITH_OLDER_CHILDREN',
    FamilyWithYoungChildren = 'FAMILY_WITH_YOUNG_CHILDREN',
    GroupOfFriends = 'GROUP_OF_FRIENDS',
    GroupTrip = 'GROUP_TRIP',
    OlderCouple = 'OLDER_COUPLE',
    YoungCouple = 'YOUNG_COUPLE',
}

export type Trip = {
    __typename?: 'Trip';
    date: Scalars['String'];
    duration: Scalars['Int'];
    originalPrice?: Maybe<Scalars['Float']>;
    price: Scalars['Float'];
    rentalUnit: RentalUnit;
    special?: Maybe<Special>;
    specialId?: Maybe<Scalars['ID']>;
    type: TripTypeEnum;
};

export type TripTypeCount = {
    __typename?: 'TripTypeCount';
    hits: Scalars['Int'];
    tripType: TripTypeEnum;
};

/**   Available types of Trip. */
export enum TripTypeEnum {
    /**  Rate including an early booking discount. */
    EarlyBooking = 'EARLY_BOOKING',
    /**  Rate including a last minute discount. */
    LastMinute = 'LAST_MINUTE',
    /**  The regular rate. */
    Regular = 'REGULAR',
    /**  Rate including a Special. */
    Special = 'SPECIAL',
}

export type UnitAndTripSearchParamsInput = {
    babies: Scalars['Int'];
    date: Scalars['String'];
    duration: Scalars['Int'];
    filters?: Maybe<Array<PropertyFilterInput>>;
    persons: Scalars['Int'];
    pets: Scalars['Int'];
    price?: Maybe<RangeFilterInput>;
    specialId?: Maybe<Scalars['ID']>;
    tripType?: Maybe<Array<TripTypeEnum>>;
};

/**   A property of a RentalUnit or Accommodation which can be used to filter by. */
export type UnitFilterProperty = {
    __typename?: 'UnitFilterProperty';
    /**   A URL-friendly handle unique to this property, this should never change even if the name changes. */
    handle: Scalars['String'];
    /**
     *   Name of this property.
     *  This value is language dependent.
     */
    name: Scalars['String'];
};

/**   A category of FilterProperties. */
export type UnitFilterPropertyCategory = {
    __typename?: 'UnitFilterPropertyCategory';
    /**   The properties in this category. */
    filterProperties: Array<UnitFilterProperty>;
    /**   A URL-friendly handle unique to this category, this should never change even if the name changes. */
    handle: Scalars['String'];
    /**
     *   Name of this category.
     *  This value is language dependent.
     */
    name: Scalars['String'];
};

export type UnitSearchHit = {
    __typename?: 'UnitSearchHit';
    rentalUnit: RentalUnit;
    trips: Array<Trip>;
};

/**   RentalUnit/Trip search result ordering options. */
export enum UnitSearchOrderEnum {
    /**  Sort by Trip price, ascending. */
    PriceAsc = 'PRICE_ASC',
    /**  Sort by Trip price, descending. */
    PriceDesc = 'PRICE_DESC',
    /**
     *  Randomize order.
     * # The random seed used is the current date, so the order will be identical between pageloads and between users, but change daily.
     */
    RandomBoosted = 'RANDOM_BOOSTED',
    /**  Sort by amount of recent bookings. */
    RecentBookingsBoosted = 'RECENT_BOOKINGS_BOOSTED',
    /**  Sort by average RentalUnit review rating, descending. */
    ReviewRatingDesc = 'REVIEW_RATING_DESC',
}

/**   Parameters for searching within RentalUnits (optionally filtered by specific Trip availability). */
export type UnitSearchParamsInput = {
    /**   The amount of babies in the travel party. */
    babies?: Maybe<Scalars['Int']>;
    /**
     *   Filter by this arrival date.
     *  Formatted as yyyy-mm-dd.
     */
    date?: Maybe<Scalars['String']>;
    /**   Filter by this Trip duration. */
    duration?: Maybe<Scalars['Int']>;
    /**
     *   Filter by properties the RentalUnit/Accommodation must posses.
     *  Multiple filters are combined as "AND".
     */
    filters?: Maybe<Array<PropertyFilterInput>>;
    /**   The amount of non-baby people in the travel party. */
    persons?: Maybe<Scalars['Int']>;
    /**   The amount of pets in the travel party. */
    pets?: Maybe<Scalars['Int']>;
    /**   Filter by a price range. */
    price?: Maybe<RangeFilterInput>;
    /**   Filter by a specific Special (by ID). */
    specialId?: Maybe<Scalars['ID']>;
    /**   Filter by this/these TripType(s). */
    tripType?: Maybe<Array<TripTypeEnum>>;
};

/**   Results of a RentalUnit/Trip search query. */
export type UnitSearchResult = {
    __typename?: 'UnitSearchResult';
    /**   The search results. */
    hits: Array<UnitSearchHit>;
    /**   The maximum Trip price within the result set. */
    maxPrice?: Maybe<Scalars['Float']>;
    /**   The minimum Trip price within the result set. */
    minPrice?: Maybe<Scalars['Float']>;
    /**   Available filter properties and corresponding result counts within the result set. */
    propertyFilterCounts: Array<PropertyFilterCount>;
    /**   Available Specials and corresponding counts within the result set. */
    specialCounts: Array<SpecialCount>;
    /**   Total amount of matching RentalUnits. */
    totalHits: Scalars['Int'];
    /**   Available TripTypes and corresponding counts within the result set. */
    tripTypeCounts: Array<TripTypeCount>;
};

export type UpdateCartInput = {
    affiliateInfo?: Maybe<AffiliateInfoInput>;
    agreedToTerms?: Maybe<Scalars['Boolean']>;
    bookings?: Maybe<Array<BookingCartItemInput>>;
    cartId?: Maybe<Scalars['ID']>;
    customerInfo?: Maybe<CustomerInfoInput>;
    paymentOptions?: Maybe<UpdateCartPaymentOptionsInput>;
    tickets?: Maybe<Array<TicketCartItemInput>>;
    upsellPurchases?: Maybe<Array<UpsellPurchaseCartItemInput>>;
};

export type UpdateCartPayload = {
    __typename?: 'UpdateCartPayload';
    cart?: Maybe<Cart>;
    /**   Indicates whether the current user (if any) is allowed to perform the checkout action for the submitted customer email address. */
    checkoutAllowed?: Maybe<Scalars['Boolean']>;
};

export type UpdateCartPaymentOptionsInput = {
    /**   Method "POINT_OF_SALE" only, this must be supplied and be stored in the PaymentTerminal table */
    sellDeviceId?: Maybe<Scalars['String']>;
};

export type UpdateCustomerInput = {
    address?: Maybe<AddressInput>;
    /**   Formatted as yyyy-mm-dd */
    birthdate?: Maybe<Scalars['String']>;
    customerId: Scalars['ID'];
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    phoneNumber?: Maybe<Scalars['String']>;
};

export type UpdateCustomerPayload = {
    __typename?: 'UpdateCustomerPayload';
    customer?: Maybe<Customer>;
};

export type UpsellOffer = {
    __typename?: 'UpsellOffer';
    /**   This value is language dependent. */
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    image?: Maybe<GenericImage>;
    maxAmount: Scalars['Int'];
    /**   This value is language dependent. */
    name: Scalars['String'];
    unitPrice: Scalars['Float'];
};

export type UpsellPurchase = OrderItem & {
    __typename?: 'UpsellPurchase';
    amount: Scalars['Int'];
    errors: Array<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    offer?: Maybe<UpsellOffer>;
    paymentPrice: Scalars['Float'];
    priceLines: Array<PriceLine>;
    status: UpsellPurchaseStatus;
    totalPrice: Scalars['Float'];
};

export type UpsellPurchaseCartItemInput = {
    amount: Scalars['Int'];
    upsellOfferId: Scalars['ID'];
};

export enum UpsellPurchaseStatus {
    Accepted = 'ACCEPTED',
    Init = 'INIT',
    Waived = 'WAIVED',
}

export type ActivityAtlternativesQueryVariables = Exact<{
    params: ActivitySearchParamsInput;
    limit?: Maybe<Scalars['Int']>;
    startDate: Scalars['String'];
    endDate: Scalars['String'];
}>;

export type ActivityAtlternativesQuery = {
    __typename?: 'Query';
    activities: {
        __typename?: 'ActivitySearchResult';
        hits: Array<{
            __typename?: 'Activity';
            id: string;
            name: string;
            slug: string;
            highlighted: boolean;
            place?: Maybe<string>;
            videoUrl?: Maybe<string>;
            externalBookingUrl?: Maybe<string>;
            bookingType: ActivityBookingTypesEnum;
            minCapacity?: Maybe<number>;
            maxCapacity?: Maybe<number>;
            themes: Array<{ __typename?: 'ActivityTheme'; slug: string; name?: Maybe<string> }>;
            timeslots: Array<{
                __typename?: 'Timeslot';
                id: string;
                allotment?: Maybe<number>;
                startDateTime: string;
                endDateTime: string;
                label?: Maybe<string>;
                rateGroup?: Maybe<{
                    __typename?: 'ActivityRateGroup';
                    releaseHours?: Maybe<number>;
                    rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                }>;
            }>;
            mainImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            webpMainImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            webpListImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            listImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
        }>;
    };
};

export type ActivityCheckoutModalTimeslotFragment = {
    __typename?: 'Timeslot';
    id: string;
    startDateTime: string;
    endDateTime: string;
    allotment?: Maybe<number>;
    rateGroup?: Maybe<{
        __typename?: 'ActivityRateGroup';
        rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
    }>;
};

export type ActivityPlannerQueryVariables = Exact<{
    slug: Scalars['String'];
    startDate: Scalars['String'];
    endDate: Scalars['String'];
}>;

export type ActivityPlannerQuery = {
    __typename?: 'Query';
    activity?: Maybe<{
        __typename?: 'Activity';
        id: string;
        externalBookingUrl?: Maybe<string>;
        bookingType: ActivityBookingTypesEnum;
        timeslots: Array<{
            __typename?: 'Timeslot';
            id: string;
            allotment?: Maybe<number>;
            startDateTime: string;
            endDateTime: string;
            label?: Maybe<string>;
            rateGroup?: Maybe<{
                __typename?: 'ActivityRateGroup';
                releaseHours?: Maybe<number>;
                rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
            }>;
        }>;
    }>;
};

export type ActivityPlannerTimeslotFragment = {
    __typename?: 'Timeslot';
    id: string;
    allotment?: Maybe<number>;
    startDateTime: string;
    endDateTime: string;
    label?: Maybe<string>;
    rateGroup?: Maybe<{
        __typename?: 'ActivityRateGroup';
        releaseHours?: Maybe<number>;
        rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
    }>;
};

export type ActivityPlannerActivityFragment = {
    __typename?: 'Activity';
    name: string;
    bookingType: ActivityBookingTypesEnum;
    externalBookingUrl?: Maybe<string>;
    company?: Maybe<{
        __typename?: 'Company';
        normalOperatingHours?: Maybe<{
            __typename?: 'NormalOperatingHours';
            monday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
            tuesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
            wednesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
            thursday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
            friday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
            saturday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
            sunday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
        }>;
        specialOperatingHours: Array<{
            __typename?: 'SpecialOperatingHours';
            startDate: string;
            endDate: string;
            monday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
            tuesday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
            wednesday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
            thursday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
            friday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
            saturday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
            sunday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
        }>;
    }>;
};

export type CheckoutPaymentsBookingFragment = {
    __typename?: 'Booking';
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        name: string;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            checkInEndTime: string;
            checkInStartTime: string;
            checkOutTime: string;
        };
    }>;
};

export type SearchSurchargesQueryVariables = Exact<{
    slug: Scalars['String'];
    arrivalDate?: Maybe<Scalars['String']>;
}>;

export type SearchSurchargesQuery = {
    __typename?: 'Query';
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        surcharges: Array<{
            __typename?: 'RentalUnitSurcharge';
            id: string;
            name: string;
            calculation: SurchargeCalculationEnum;
            description?: Maybe<string>;
            maxAmount: number;
            minAmount: number;
            unitPrice: number;
        }>;
    }>;
};

export type CheckoutExtrasBookingFragment = {
    __typename?: 'Booking';
    hasCancellationInsurance: boolean;
    hasContentsInsurance: boolean;
    special?: Maybe<{ __typename?: 'Special'; id: string; name: string; description: string }>;
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        slug: string;
        name: string;
        type: RentalUnitTypeEnum;
        amountBedrooms?: Maybe<number>;
        maxOccupancy: number;
        minOccupancy: number;
        flexCancellationDays?: Maybe<number>;
        petsAllowed: number;
        descriptionParticulars?: Maybe<string>;
        contentsInsuranceAvailable: boolean;
        cancellationInsuranceAvailable: boolean;
        accommodation: {
            __typename?: 'Accommodation';
            hasPublicPage: boolean;
            name: string;
            id: string;
            checkInStartTime: string;
            checkInEndTime: string;
            checkOutTime: string;
        };
    }>;
    optionalSurcharges: Array<{
        __typename?: 'BookingCartItemSurcharge';
        rentalUnitSurchargeId: string;
        amount: number;
    }>;
};

export type CheckoutExtrasBookingRentalUnitFragment = {
    __typename?: 'RentalUnit';
    id: string;
    slug: string;
    name: string;
    type: RentalUnitTypeEnum;
    amountBedrooms?: Maybe<number>;
    maxOccupancy: number;
    minOccupancy: number;
    flexCancellationDays?: Maybe<number>;
    petsAllowed: number;
    descriptionParticulars?: Maybe<string>;
    contentsInsuranceAvailable: boolean;
    cancellationInsuranceAvailable: boolean;
    accommodation: {
        __typename?: 'Accommodation';
        hasPublicPage: boolean;
        name: string;
        id: string;
        checkInStartTime: string;
        checkInEndTime: string;
        checkOutTime: string;
    };
};

export type CheckoutExtrasTicketFragment = {
    __typename?: 'Ticket';
    startDateTime: string;
    endDateTime: string;
    activity?: Maybe<{
        __typename?: 'Activity';
        id: string;
        name: string;
        slug: string;
        descriptionParticulars?: Maybe<string>;
        bookingType: ActivityBookingTypesEnum;
        company?: Maybe<{
            __typename?: 'Company';
            id: string;
            normalOperatingHours?: Maybe<{
                __typename?: 'NormalOperatingHours';
                monday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                tuesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                wednesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                thursday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                friday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                saturday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                sunday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
            }>;
            specialOperatingHours: Array<{
                __typename?: 'SpecialOperatingHours';
                startDate: string;
                endDate: string;
                monday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                tuesday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                wednesday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                thursday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                friday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                saturday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                sunday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
            }>;
        }>;
    }>;
    timeslot?: Maybe<{
        __typename?: 'Timeslot';
        id: string;
        startDateTime: string;
        endDateTime: string;
        allotment?: Maybe<number>;
        rateGroup?: Maybe<{
            __typename?: 'ActivityRateGroup';
            rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
        }>;
    }>;
    rateLines: Array<{ __typename?: 'TicketRateLine'; amount: number }>;
};

export type CheckoutOrderPageQueryVariables = Exact<{
    orderId: Scalars['ID'];
}>;

export type CheckoutOrderPageQuery = {
    __typename?: 'Query';
    order?: Maybe<{
        __typename?: 'Order';
        id?: Maybe<string>;
        number?: Maybe<string>;
        errors: Array<string>;
        totalPrice: number;
        payments: Array<{ __typename?: 'Payment'; amount: number; date: string; status: PaymentStatusEnum }>;
        orderItems: Array<
            | {
                  __typename?: 'Booking';
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  status: BookingStatus;
                  amountAdults: number;
                  amountPets: number;
                  amountChildren: number;
                  amountBabies: number;
                  amountYouths: number;
                  arrivalDate: string;
                  departureDate: string;
                  hasCancellationInsurance: boolean;
                  hasContentsInsurance: boolean;
                  handleDepositPayment: boolean;
                  deposit: number;
                  requiresApproval: boolean;
                  rentalUnit?: Maybe<{
                      __typename?: 'RentalUnit';
                      id: string;
                      code: string;
                      name: string;
                      slug: string;
                      maxOccupancy: number;
                      minOccupancy: number;
                      petsAllowed: number;
                      type: RentalUnitTypeEnum;
                      accommodation: {
                          __typename?: 'Accommodation';
                          id: string;
                          hasPublicPage: boolean;
                          name: string;
                          checkInEndTime: string;
                          checkInStartTime: string;
                          checkOutTime: string;
                          type: AccommodationTypeEnum;
                      };
                      jpegThumbnail: {
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      };
                      webpThumbnail: {
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      };
                  }>;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  special?: Maybe<{ __typename?: 'Special'; id: string }>;
                  optionalSurcharges: Array<{
                      __typename?: 'BookingCartItemSurcharge';
                      rentalUnitSurchargeId: string;
                      amount: number;
                  }>;
              }
            | {
                  __typename?: 'Ticket';
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  startDateTime: string;
                  ticketStatus: TicketStatus;
                  activity?: Maybe<{
                      __typename?: 'Activity';
                      id: string;
                      slug: string;
                      name: string;
                      place?: Maybe<string>;
                      jpegThumbnail?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                      webpThumbnail?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                  }>;
                  timeslot?: Maybe<{
                      __typename?: 'Timeslot';
                      id: string;
                      startDateTime: string;
                      endDateTime: string;
                      allotment?: Maybe<number>;
                      label?: Maybe<string>;
                      rateGroup?: Maybe<{
                          __typename?: 'ActivityRateGroup';
                          releaseHours?: Maybe<number>;
                          rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                      }>;
                  }>;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  rateLines: Array<{
                      __typename?: 'TicketRateLine';
                      amount: number;
                      rateId?: Maybe<string>;
                      totalPrice: number;
                      unitPrice: number;
                  }>;
              }
            | {
                  __typename?: 'UpsellPurchase';
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  amount: number;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
              }
        >;
        paymentOptions?: Maybe<{ __typename?: 'PaymentOptions'; partialPaymentAmount: number; totalAmountDue: number }>;
    }>;
};

export type PaymentFragment = { __typename?: 'Payment'; amount: number; date: string; status: PaymentStatusEnum };

export type CheckoutPageQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type CheckoutPageQuery = {
    __typename?: 'Query';
    cart?: Maybe<{
        __typename?: 'Cart';
        id: string;
        status: CartStatusEnum;
        order: {
            __typename?: 'Order';
            number?: Maybe<string>;
            errors: Array<string>;
            totalPrice: number;
            orderItems: Array<
                | {
                      __typename?: 'Booking';
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      status: BookingStatus;
                      amountAdults: number;
                      amountPets: number;
                      amountChildren: number;
                      amountBabies: number;
                      amountYouths: number;
                      arrivalDate: string;
                      departureDate: string;
                      hasCancellationInsurance: boolean;
                      hasContentsInsurance: boolean;
                      handleDepositPayment: boolean;
                      deposit: number;
                      requiresApproval: boolean;
                      rentalUnit?: Maybe<{
                          __typename?: 'RentalUnit';
                          id: string;
                          code: string;
                          name: string;
                          slug: string;
                          maxOccupancy: number;
                          minOccupancy: number;
                          petsAllowed: number;
                          type: RentalUnitTypeEnum;
                          accommodation: {
                              __typename?: 'Accommodation';
                              id: string;
                              hasPublicPage: boolean;
                              name: string;
                              checkInEndTime: string;
                              checkInStartTime: string;
                              checkOutTime: string;
                              type: AccommodationTypeEnum;
                          };
                          jpegThumbnail: {
                              __typename?: 'GenericImage';
                              transform?: Maybe<{
                                  __typename?: 'ImageTransform';
                                  placeholder?: Maybe<string>;
                                  srcSet: string;
                                  src: string;
                                  ratio: number;
                              }>;
                          };
                          webpThumbnail: {
                              __typename?: 'GenericImage';
                              transform?: Maybe<{
                                  __typename?: 'ImageTransform';
                                  placeholder?: Maybe<string>;
                                  srcSet: string;
                                  src: string;
                                  ratio: number;
                              }>;
                          };
                      }>;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      special?: Maybe<{ __typename?: 'Special'; id: string }>;
                      optionalSurcharges: Array<{
                          __typename?: 'BookingCartItemSurcharge';
                          rentalUnitSurchargeId: string;
                          amount: number;
                      }>;
                  }
                | {
                      __typename?: 'Ticket';
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      startDateTime: string;
                      ticketStatus: TicketStatus;
                      activity?: Maybe<{
                          __typename?: 'Activity';
                          id: string;
                          slug: string;
                          name: string;
                          place?: Maybe<string>;
                          jpegThumbnail?: Maybe<{
                              __typename?: 'GenericImage';
                              transform?: Maybe<{
                                  __typename?: 'ImageTransform';
                                  placeholder?: Maybe<string>;
                                  srcSet: string;
                                  src: string;
                                  ratio: number;
                              }>;
                          }>;
                          webpThumbnail?: Maybe<{
                              __typename?: 'GenericImage';
                              transform?: Maybe<{
                                  __typename?: 'ImageTransform';
                                  placeholder?: Maybe<string>;
                                  srcSet: string;
                                  src: string;
                                  ratio: number;
                              }>;
                          }>;
                      }>;
                      timeslot?: Maybe<{
                          __typename?: 'Timeslot';
                          id: string;
                          startDateTime: string;
                          endDateTime: string;
                          allotment?: Maybe<number>;
                          label?: Maybe<string>;
                          rateGroup?: Maybe<{
                              __typename?: 'ActivityRateGroup';
                              releaseHours?: Maybe<number>;
                              rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                          }>;
                      }>;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      rateLines: Array<{
                          __typename?: 'TicketRateLine';
                          amount: number;
                          rateId?: Maybe<string>;
                          totalPrice: number;
                          unitPrice: number;
                      }>;
                  }
                | {
                      __typename?: 'UpsellPurchase';
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      amount: number;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
                  }
            >;
            paymentOptions?: Maybe<{
                __typename?: 'PaymentOptions';
                partialPaymentAmount: number;
                totalAmountDue: number;
            }>;
        };
        availableUpsellOffers: Array<{
            __typename?: 'UpsellOffer';
            id: string;
            name: string;
            unitPrice: number;
            maxAmount: number;
            description?: Maybe<string>;
        }>;
    }>;
};

export type CheckoutPaymentTicketFragment = {
    __typename?: 'Ticket';
    timeslot?: Maybe<{
        __typename?: 'Timeslot';
        id: string;
        startDateTime: string;
        endDateTime: string;
        rateGroup?: Maybe<{
            __typename?: 'ActivityRateGroup';
            rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
        }>;
    }>;
    activity?: Maybe<{ __typename?: 'Activity'; id: string; name: string }>;
};

export type CompanyOperatingHoursFragment = {
    __typename?: 'Company';
    normalOperatingHours?: Maybe<{
        __typename?: 'NormalOperatingHours';
        monday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
        tuesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
        wednesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
        thursday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
        friday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
        saturday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
        sunday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
    }>;
    specialOperatingHours: Array<{
        __typename?: 'SpecialOperatingHours';
        startDate: string;
        endDate: string;
        monday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
        tuesday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
        wednesday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
        thursday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
        friday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
        saturday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
        sunday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
    }>;
};

export type TimeOpenClosedFragment = { __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string };

export type DeskModeQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DeskModeQuery = {
    __typename?: 'Query';
    cart?: Maybe<{ __typename?: 'Cart'; id: string; order: { __typename?: 'Order'; isDeskOrder: boolean } }>;
};

export type CheckoutDetailsCustomerInfoFragment = {
    __typename?: 'Order';
    agreedToTerms?: Maybe<boolean>;
    newsletterOptin?: Maybe<boolean>;
    customer?: Maybe<{
        __typename?: 'Customer';
        phoneNumber?: Maybe<string>;
        firstName?: Maybe<string>;
        lastName?: Maybe<string>;
        email?: Maybe<string>;
        birthdate?: Maybe<string>;
        address?: Maybe<{
            __typename?: 'Address';
            city?: Maybe<string>;
            countryCode?: Maybe<string>;
            number?: Maybe<string>;
            postalCode?: Maybe<string>;
            street?: Maybe<string>;
        }>;
    }>;
};

export type CustomerDetailsFragment = {
    __typename?: 'Customer';
    phoneNumber?: Maybe<string>;
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
    email?: Maybe<string>;
    birthdate?: Maybe<string>;
    address?: Maybe<{
        __typename?: 'Address';
        city?: Maybe<string>;
        countryCode?: Maybe<string>;
        number?: Maybe<string>;
        postalCode?: Maybe<string>;
        street?: Maybe<string>;
    }>;
};

export type UpdateCustomerMutationVariables = Exact<{
    input: UpdateCustomerInput;
}>;

export type UpdateCustomerMutation = {
    __typename?: 'Mutation';
    updateCustomer: {
        __typename?: 'UpdateCustomerPayload';
        customer?: Maybe<{
            __typename?: 'Customer';
            id?: Maybe<string>;
            phoneNumber?: Maybe<string>;
            firstName?: Maybe<string>;
            lastName?: Maybe<string>;
            email?: Maybe<string>;
            birthdate?: Maybe<string>;
            address?: Maybe<{
                __typename?: 'Address';
                city?: Maybe<string>;
                countryCode?: Maybe<string>;
                number?: Maybe<string>;
                postalCode?: Maybe<string>;
                street?: Maybe<string>;
            }>;
        }>;
    };
};

export type ExtrasFragment = {
    __typename?: 'AttributeCategory';
    name: string;
    handle: string;
    attributes: Array<{ __typename?: 'Attribute'; id: string; name: string; iconUrl?: Maybe<string>; handle: string }>;
};

export type FavoriteButtonQueryVariables = Exact<{ [key: string]: never }>;

export type FavoriteButtonQuery = {
    __typename?: 'Query';
    viewer?: Maybe<{
        __typename?: 'Customer';
        favorites: Array<
            | { __typename: 'FavoriteActivity'; id: string; targetId: string }
            | { __typename: 'FavoriteCompany'; id: string; targetId: string }
            | { __typename: 'FavoriteRentalUnit'; id: string; targetId: string }
        >;
    }>;
};

export type AddFavoriteActivityMutationVariables = Exact<{
    input: AddFavoriteActivityInput;
}>;

export type AddFavoriteActivityMutation = {
    __typename?: 'Mutation';
    result: {
        __typename?: 'AddFavoriteActivityPayload';
        favorite?: Maybe<{
            __typename?: 'FavoriteActivity';
            id: string;
            activity?: Maybe<{
                __typename?: 'Activity';
                slug: string;
                name: string;
                id: string;
                jpegThumbnail?: Maybe<{
                    __typename?: 'GenericImage';
                    transform?: Maybe<{
                        __typename?: 'ImageTransform';
                        placeholder?: Maybe<string>;
                        srcSet: string;
                        src: string;
                        ratio: number;
                    }>;
                }>;
                webpThumbnail?: Maybe<{
                    __typename?: 'GenericImage';
                    transform?: Maybe<{
                        __typename?: 'ImageTransform';
                        placeholder?: Maybe<string>;
                        srcSet: string;
                        src: string;
                        ratio: number;
                    }>;
                }>;
            }>;
        }>;
    };
};

export type AddFavoriteCompanyMutationVariables = Exact<{
    input: AddFavoriteCompanyInput;
}>;

export type AddFavoriteCompanyMutation = {
    __typename?: 'Mutation';
    result: {
        __typename?: 'AddFavoriteCompanyPayload';
        favorite?: Maybe<{
            __typename?: 'FavoriteCompany';
            id: string;
            company?: Maybe<{
                __typename?: 'Company';
                slug: string;
                name: string;
                id: string;
                jpegThumbnail?: Maybe<{
                    __typename?: 'GenericImage';
                    transform?: Maybe<{
                        __typename?: 'ImageTransform';
                        placeholder?: Maybe<string>;
                        srcSet: string;
                        src: string;
                        ratio: number;
                    }>;
                }>;
                webpThumbnail?: Maybe<{
                    __typename?: 'GenericImage';
                    transform?: Maybe<{
                        __typename?: 'ImageTransform';
                        placeholder?: Maybe<string>;
                        srcSet: string;
                        src: string;
                        ratio: number;
                    }>;
                }>;
            }>;
        }>;
    };
};

export type AddFavoriteRentalUnitMutationVariables = Exact<{
    input: AddFavoriteRentalUnitInput;
}>;

export type AddFavoriteRentalUnitMutation = {
    __typename?: 'Mutation';
    result: {
        __typename?: 'AddFavoriteRentalUnitPayload';
        favorite?: Maybe<{
            __typename?: 'FavoriteRentalUnit';
            id: string;
            rentalUnit?: Maybe<{
                __typename?: 'RentalUnit';
                slug: string;
                name: string;
                id: string;
                jpegThumbnail: {
                    __typename?: 'GenericImage';
                    transform?: Maybe<{
                        __typename?: 'ImageTransform';
                        placeholder?: Maybe<string>;
                        srcSet: string;
                        src: string;
                        ratio: number;
                    }>;
                };
                webpThumbnail: {
                    __typename?: 'GenericImage';
                    transform?: Maybe<{
                        __typename?: 'ImageTransform';
                        placeholder?: Maybe<string>;
                        srcSet: string;
                        src: string;
                        ratio: number;
                    }>;
                };
            }>;
        }>;
    };
};

export type RemoveFavoriteMutationVariables = Exact<{
    input: RemoveFavoriteInput;
}>;

export type RemoveFavoriteMutation = {
    __typename?: 'Mutation';
    removeFavorite: { __typename?: 'RemoveFavoritePayload'; success: boolean };
};

export type LocationFragment = {
    __typename?: 'Accommodation';
    descriptionLocation: string;
    pointsOfInterest: Array<{ __typename?: 'PointOfInterest'; name: string; distance: number }>;
    coordinates: { __typename?: 'LatLon'; lat: number; lon: number };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: 'Query'; viewer?: Maybe<{ __typename?: 'Customer'; id?: Maybe<string> }> };

export type SendAuthenticationEmailMutationVariables = Exact<{
    input: SendAuthenticationEmailInput;
}>;

export type SendAuthenticationEmailMutation = {
    __typename?: 'Mutation';
    sendAuthenticationEmail: { __typename?: 'SendAuthenticationEmailPayload'; success?: Maybe<boolean> };
};

export type MyOrderFragment = {
    __typename?: 'Order';
    id?: Maybe<string>;
    number?: Maybe<string>;
    createdDate?: Maybe<string>;
    paymentOptions?: Maybe<{
        __typename?: 'PaymentOptions';
        fullPaymentDueDate?: Maybe<string>;
        totalAmountDue: number;
    }>;
    orderItems: Array<
        | {
              __typename?: 'Booking';
              id?: Maybe<string>;
              downloadVoucherUrl?: Maybe<string>;
              isCancellable: boolean;
              isAlterable: boolean;
              customerComment?: Maybe<string>;
              totalPrice: number;
              paymentPrice: number;
              errors: Array<string>;
              status: BookingStatus;
              amountAdults: number;
              amountPets: number;
              amountChildren: number;
              amountBabies: number;
              amountYouths: number;
              arrivalDate: string;
              departureDate: string;
              hasCancellationInsurance: boolean;
              hasContentsInsurance: boolean;
              handleDepositPayment: boolean;
              deposit: number;
              requiresApproval: boolean;
              review?: Maybe<{
                  __typename?: 'Review';
                  id: string;
                  average: number;
                  comment: string;
                  date: string;
                  ratingHospitality?: Maybe<number>;
                  ratingLayout?: Maybe<number>;
                  ratingLocation?: Maybe<number>;
                  ratingMaintenance?: Maybe<number>;
                  ratingValueForMoney?: Maybe<number>;
                  tips?: Maybe<string>;
                  title: string;
                  travelGroupType?: Maybe<TravelGroupType>;
                  reply?: Maybe<string>;
              }>;
              rentalUnit?: Maybe<{
                  __typename?: 'RentalUnit';
                  id: string;
                  flexCancellationDays?: Maybe<number>;
                  code: string;
                  name: string;
                  slug: string;
                  maxOccupancy: number;
                  minOccupancy: number;
                  petsAllowed: number;
                  type: RentalUnitTypeEnum;
                  reviewStats?: Maybe<{
                      __typename?: 'ReviewStats';
                      count: number;
                      average: number;
                      location: number;
                      hospitality: number;
                      layout: number;
                      maintenance: number;
                      valueForMoney: number;
                  }>;
                  accommodation: {
                      __typename?: 'Accommodation';
                      id: string;
                      ownerName?: Maybe<string>;
                      hasPublicPage: boolean;
                      name: string;
                      checkInEndTime: string;
                      checkInStartTime: string;
                      checkOutTime: string;
                      type: AccommodationTypeEnum;
                      jpegOwner?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                      webpOwner?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                  };
                  jpegThumbnail: {
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  };
                  webpThumbnail: {
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  };
              }>;
              priceLines: Array<{
                  __typename?: 'PriceLine';
                  label: string;
                  quantity?: Maybe<string>;
                  totalPrice: number;
                  unitPrice?: Maybe<number>;
                  category?: Maybe<PriceLineCategoryEnum>;
              }>;
              special?: Maybe<{ __typename?: 'Special'; id: string }>;
              optionalSurcharges: Array<{
                  __typename?: 'BookingCartItemSurcharge';
                  rentalUnitSurchargeId: string;
                  amount: number;
              }>;
          }
        | {
              __typename?: 'Ticket';
              id?: Maybe<string>;
              downloadVoucherUrl?: Maybe<string>;
              totalPrice: number;
              paymentPrice: number;
              errors: Array<string>;
              startDateTime: string;
              ticketStatus: TicketStatus;
              activity?: Maybe<{
                  __typename?: 'Activity';
                  id: string;
                  slug: string;
                  name: string;
                  place?: Maybe<string>;
                  jpegThumbnail?: Maybe<{
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  }>;
                  webpThumbnail?: Maybe<{
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  }>;
              }>;
              timeslot?: Maybe<{
                  __typename?: 'Timeslot';
                  id: string;
                  startDateTime: string;
                  endDateTime: string;
                  allotment?: Maybe<number>;
                  label?: Maybe<string>;
                  rateGroup?: Maybe<{
                      __typename?: 'ActivityRateGroup';
                      releaseHours?: Maybe<number>;
                      rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                  }>;
              }>;
              priceLines: Array<{
                  __typename?: 'PriceLine';
                  label: string;
                  quantity?: Maybe<string>;
                  totalPrice: number;
                  unitPrice?: Maybe<number>;
                  category?: Maybe<PriceLineCategoryEnum>;
              }>;
              rateLines: Array<{
                  __typename?: 'TicketRateLine';
                  amount: number;
                  rateId?: Maybe<string>;
                  totalPrice: number;
                  unitPrice: number;
              }>;
          }
        | {
              __typename?: 'UpsellPurchase';
              id?: Maybe<string>;
              totalPrice: number;
              paymentPrice: number;
              errors: Array<string>;
              amount: number;
              priceLines: Array<{
                  __typename?: 'PriceLine';
                  label: string;
                  quantity?: Maybe<string>;
                  totalPrice: number;
                  unitPrice?: Maybe<number>;
                  category?: Maybe<PriceLineCategoryEnum>;
              }>;
              offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
          }
    >;
    payments: Array<{ __typename?: 'Payment'; status: PaymentStatusEnum }>;
};

export type OrderItem_Booking_Fragment = {
    __typename?: 'Booking';
    id?: Maybe<string>;
    downloadVoucherUrl?: Maybe<string>;
    isCancellable: boolean;
    isAlterable: boolean;
    customerComment?: Maybe<string>;
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    status: BookingStatus;
    amountAdults: number;
    amountPets: number;
    amountChildren: number;
    amountBabies: number;
    amountYouths: number;
    arrivalDate: string;
    departureDate: string;
    hasCancellationInsurance: boolean;
    hasContentsInsurance: boolean;
    handleDepositPayment: boolean;
    deposit: number;
    requiresApproval: boolean;
    review?: Maybe<{
        __typename?: 'Review';
        id: string;
        average: number;
        comment: string;
        date: string;
        ratingHospitality?: Maybe<number>;
        ratingLayout?: Maybe<number>;
        ratingLocation?: Maybe<number>;
        ratingMaintenance?: Maybe<number>;
        ratingValueForMoney?: Maybe<number>;
        tips?: Maybe<string>;
        title: string;
        travelGroupType?: Maybe<TravelGroupType>;
        reply?: Maybe<string>;
    }>;
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        flexCancellationDays?: Maybe<number>;
        code: string;
        name: string;
        slug: string;
        maxOccupancy: number;
        minOccupancy: number;
        petsAllowed: number;
        type: RentalUnitTypeEnum;
        reviewStats?: Maybe<{
            __typename?: 'ReviewStats';
            count: number;
            average: number;
            location: number;
            hospitality: number;
            layout: number;
            maintenance: number;
            valueForMoney: number;
        }>;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            ownerName?: Maybe<string>;
            hasPublicPage: boolean;
            name: string;
            checkInEndTime: string;
            checkInStartTime: string;
            checkOutTime: string;
            type: AccommodationTypeEnum;
            jpegOwner?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            webpOwner?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
        };
        jpegThumbnail: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
        webpThumbnail: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
    }>;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    special?: Maybe<{ __typename?: 'Special'; id: string }>;
    optionalSurcharges: Array<{
        __typename?: 'BookingCartItemSurcharge';
        rentalUnitSurchargeId: string;
        amount: number;
    }>;
};

export type OrderItem_Ticket_Fragment = {
    __typename?: 'Ticket';
    id?: Maybe<string>;
    downloadVoucherUrl?: Maybe<string>;
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    startDateTime: string;
    ticketStatus: TicketStatus;
    activity?: Maybe<{
        __typename?: 'Activity';
        id: string;
        slug: string;
        name: string;
        place?: Maybe<string>;
        jpegThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        webpThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
    }>;
    timeslot?: Maybe<{
        __typename?: 'Timeslot';
        id: string;
        startDateTime: string;
        endDateTime: string;
        allotment?: Maybe<number>;
        label?: Maybe<string>;
        rateGroup?: Maybe<{
            __typename?: 'ActivityRateGroup';
            releaseHours?: Maybe<number>;
            rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
        }>;
    }>;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    rateLines: Array<{
        __typename?: 'TicketRateLine';
        amount: number;
        rateId?: Maybe<string>;
        totalPrice: number;
        unitPrice: number;
    }>;
};

export type OrderItem_UpsellPurchase_Fragment = {
    __typename?: 'UpsellPurchase';
    id?: Maybe<string>;
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    amount: number;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
};

export type OrderItemFragment =
    | OrderItem_Booking_Fragment
    | OrderItem_Ticket_Fragment
    | OrderItem_UpsellPurchase_Fragment;

export type OrderBookingFragment = {
    __typename?: 'Booking';
    id?: Maybe<string>;
    downloadVoucherUrl?: Maybe<string>;
    isCancellable: boolean;
    isAlterable: boolean;
    customerComment?: Maybe<string>;
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    status: BookingStatus;
    amountAdults: number;
    amountPets: number;
    amountChildren: number;
    amountBabies: number;
    amountYouths: number;
    arrivalDate: string;
    departureDate: string;
    hasCancellationInsurance: boolean;
    hasContentsInsurance: boolean;
    handleDepositPayment: boolean;
    deposit: number;
    requiresApproval: boolean;
    review?: Maybe<{
        __typename?: 'Review';
        id: string;
        average: number;
        comment: string;
        date: string;
        ratingHospitality?: Maybe<number>;
        ratingLayout?: Maybe<number>;
        ratingLocation?: Maybe<number>;
        ratingMaintenance?: Maybe<number>;
        ratingValueForMoney?: Maybe<number>;
        tips?: Maybe<string>;
        title: string;
        travelGroupType?: Maybe<TravelGroupType>;
        reply?: Maybe<string>;
    }>;
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        flexCancellationDays?: Maybe<number>;
        code: string;
        name: string;
        slug: string;
        maxOccupancy: number;
        minOccupancy: number;
        petsAllowed: number;
        type: RentalUnitTypeEnum;
        reviewStats?: Maybe<{
            __typename?: 'ReviewStats';
            count: number;
            average: number;
            location: number;
            hospitality: number;
            layout: number;
            maintenance: number;
            valueForMoney: number;
        }>;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            ownerName?: Maybe<string>;
            hasPublicPage: boolean;
            name: string;
            checkInEndTime: string;
            checkInStartTime: string;
            checkOutTime: string;
            type: AccommodationTypeEnum;
            jpegOwner?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            webpOwner?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
        };
        jpegThumbnail: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
        webpThumbnail: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
    }>;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    special?: Maybe<{ __typename?: 'Special'; id: string }>;
    optionalSurcharges: Array<{
        __typename?: 'BookingCartItemSurcharge';
        rentalUnitSurchargeId: string;
        amount: number;
    }>;
};

export type OrderTicketFragment = {
    __typename?: 'Ticket';
    id?: Maybe<string>;
    downloadVoucherUrl?: Maybe<string>;
    ticketStatus: TicketStatus;
};

export type SpecialCountFragment = {
    __typename?: 'UnitSearchResult';
    specialCounts: Array<{
        __typename?: 'SpecialCount';
        hits: number;
        special: {
            __typename?: 'Special';
            description: string;
            id: string;
            landingPageUrl?: Maybe<string>;
            name: string;
        };
    }>;
};

export type TripTypeCountFragment = {
    __typename?: 'UnitSearchResult';
    tripTypeCounts: Array<{ __typename?: 'TripTypeCount'; hits: number; tripType: TripTypeEnum }>;
};

export type RegisterMutationVariables = Exact<{
    input: RegisterInput;
}>;

export type RegisterMutation = {
    __typename?: 'Mutation';
    register: { __typename?: 'RegisterPayload'; customer?: Maybe<{ __typename?: 'Customer'; id?: Maybe<string> }> };
};

export type ReviewModal_Booking_Fragment = {
    __typename?: 'Booking';
    id?: Maybe<string>;
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    status: BookingStatus;
    amountAdults: number;
    amountPets: number;
    amountChildren: number;
    amountBabies: number;
    amountYouths: number;
    arrivalDate: string;
    departureDate: string;
    hasCancellationInsurance: boolean;
    hasContentsInsurance: boolean;
    handleDepositPayment: boolean;
    deposit: number;
    requiresApproval: boolean;
    review?: Maybe<{
        __typename?: 'Review';
        id: string;
        average: number;
        comment: string;
        date: string;
        ratingHospitality?: Maybe<number>;
        ratingLayout?: Maybe<number>;
        ratingLocation?: Maybe<number>;
        ratingMaintenance?: Maybe<number>;
        ratingValueForMoney?: Maybe<number>;
        tips?: Maybe<string>;
        title: string;
        travelGroupType?: Maybe<TravelGroupType>;
        reply?: Maybe<string>;
    }>;
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        flexCancellationDays?: Maybe<number>;
        code: string;
        name: string;
        slug: string;
        maxOccupancy: number;
        minOccupancy: number;
        petsAllowed: number;
        type: RentalUnitTypeEnum;
        reviewStats?: Maybe<{
            __typename?: 'ReviewStats';
            count: number;
            average: number;
            location: number;
            hospitality: number;
            layout: number;
            maintenance: number;
            valueForMoney: number;
        }>;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            ownerName?: Maybe<string>;
            hasPublicPage: boolean;
            name: string;
            checkInEndTime: string;
            checkInStartTime: string;
            checkOutTime: string;
            type: AccommodationTypeEnum;
            jpegOwner?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            webpOwner?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
        };
        jpegThumbnail: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
        webpThumbnail: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
    }>;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    special?: Maybe<{ __typename?: 'Special'; id: string }>;
    optionalSurcharges: Array<{
        __typename?: 'BookingCartItemSurcharge';
        rentalUnitSurchargeId: string;
        amount: number;
    }>;
};

export type ReviewModal_Ticket_Fragment = {
    __typename?: 'Ticket';
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    startDateTime: string;
    ticketStatus: TicketStatus;
    activity?: Maybe<{
        __typename?: 'Activity';
        id: string;
        slug: string;
        name: string;
        place?: Maybe<string>;
        jpegThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        webpThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
    }>;
    timeslot?: Maybe<{
        __typename?: 'Timeslot';
        id: string;
        startDateTime: string;
        endDateTime: string;
        allotment?: Maybe<number>;
        label?: Maybe<string>;
        rateGroup?: Maybe<{
            __typename?: 'ActivityRateGroup';
            releaseHours?: Maybe<number>;
            rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
        }>;
    }>;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    rateLines: Array<{
        __typename?: 'TicketRateLine';
        amount: number;
        rateId?: Maybe<string>;
        totalPrice: number;
        unitPrice: number;
    }>;
};

export type ReviewModal_UpsellPurchase_Fragment = {
    __typename?: 'UpsellPurchase';
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    amount: number;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
};

export type ReviewModalFragment =
    | ReviewModal_Booking_Fragment
    | ReviewModal_Ticket_Fragment
    | ReviewModal_UpsellPurchase_Fragment;

export type LeaveReviewMutationVariables = Exact<{
    input: LeaveReviewInput;
}>;

export type LeaveReviewMutation = {
    __typename?: 'Mutation';
    leaveReview: {
        __typename?: 'LeaveReviewPayload';
        review?: Maybe<{
            __typename?: 'Review';
            id: string;
            average: number;
            comment: string;
            date: string;
            ratingHospitality?: Maybe<number>;
            ratingLayout?: Maybe<number>;
            ratingLocation?: Maybe<number>;
            ratingMaintenance?: Maybe<number>;
            ratingValueForMoney?: Maybe<number>;
            tips?: Maybe<string>;
            title: string;
            travelGroupType?: Maybe<TravelGroupType>;
            reply?: Maybe<string>;
        }>;
    };
};

export type ReviewFragment = {
    __typename?: 'Review';
    id: string;
    average: number;
    comment: string;
    date: string;
    ratingHospitality?: Maybe<number>;
    ratingLayout?: Maybe<number>;
    ratingLocation?: Maybe<number>;
    ratingMaintenance?: Maybe<number>;
    ratingValueForMoney?: Maybe<number>;
    tips?: Maybe<string>;
    title: string;
    travelGroupType?: Maybe<TravelGroupType>;
    reply?: Maybe<string>;
};

export type ReviewStatsFragment = {
    __typename?: 'ReviewStats';
    count: number;
    average: number;
    location: number;
    hospitality: number;
    layout: number;
    maintenance: number;
    valueForMoney: number;
};

export type UnitSearchAlternativeDatesQueryVariables = Exact<{
    params: UnitAndTripSearchParamsInput;
}>;

export type UnitSearchAlternativeDatesQuery = {
    __typename?: 'Query';
    unitSearchAlternativeDates: Array<{ __typename?: 'DateHitCount'; hits: number; date: string }>;
};

export type SearchHitRentalUnitFragment = {
    __typename?: 'RentalUnit';
    id: string;
    name: string;
    slug: string;
    type: RentalUnitTypeEnum;
    code: string;
    brand: string;
    amountBedrooms?: Maybe<number>;
    maxOccupancy: number;
    flexCancellationDays?: Maybe<number>;
    tagline: string;
    fullCircleImageUrl?: Maybe<string>;
    videoUrl?: Maybe<string>;
    hasBadge: boolean;
    hasbadge2: boolean;
    reviewStats?: Maybe<{ __typename?: 'ReviewStats'; count: number; average: number }>;
    mainImage?: Maybe<{
        __typename?: 'GenericImage';
        transform?: Maybe<{ __typename?: 'ImageTransform'; src: string }>;
    }>;
    webpListImage: {
        __typename?: 'GenericImage';
        transform?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    };
    listImage: {
        __typename?: 'GenericImage';
        transform?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    };
    accommodation: {
        __typename?: 'Accommodation';
        id: string;
        place: string;
        name: string;
        hasPublicPage: boolean;
        collapseInSearch: boolean;
        address: { __typename?: 'Address'; city?: Maybe<string> };
        coordinates: { __typename?: 'LatLon'; lat: number; lon: number };
    };
};

export type SearchHitTripFragment = {
    __typename?: 'Trip';
    originalPrice?: Maybe<number>;
    date: string;
    duration: number;
    price: number;
    type: TripTypeEnum;
    special?: Maybe<{
        __typename?: 'Special';
        id: string;
        name: string;
        description: string;
        landingPageUrl?: Maybe<string>;
    }>;
};

export type SearchHitFragment = {
    __typename?: 'UnitSearchHit';
    rentalUnit: {
        __typename?: 'RentalUnit';
        id: string;
        name: string;
        slug: string;
        type: RentalUnitTypeEnum;
        code: string;
        brand: string;
        amountBedrooms?: Maybe<number>;
        maxOccupancy: number;
        flexCancellationDays?: Maybe<number>;
        tagline: string;
        fullCircleImageUrl?: Maybe<string>;
        videoUrl?: Maybe<string>;
        hasBadge: boolean;
        hasbadge2: boolean;
        reviewStats?: Maybe<{ __typename?: 'ReviewStats'; count: number; average: number }>;
        mainImage?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{ __typename?: 'ImageTransform'; src: string }>;
        }>;
        webpListImage: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
        listImage: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            place: string;
            name: string;
            hasPublicPage: boolean;
            collapseInSearch: boolean;
            address: { __typename?: 'Address'; city?: Maybe<string> };
            coordinates: { __typename?: 'LatLon'; lat: number; lon: number };
        };
    };
    trips: Array<{
        __typename?: 'Trip';
        originalPrice?: Maybe<number>;
        date: string;
        duration: number;
        price: number;
        type: TripTypeEnum;
        special?: Maybe<{
            __typename?: 'Special';
            id: string;
            name: string;
            description: string;
            landingPageUrl?: Maybe<string>;
        }>;
    }>;
};

export type ListImageFragment = {
    __typename?: 'ImageTransform';
    placeholder?: Maybe<string>;
    srcSet: string;
    src: string;
    ratio: number;
};

export type SearchRentalUnitsQueryVariables = Exact<{
    slug: Scalars['String'];
    params?: Maybe<UnitSearchParamsInput>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    badgeHandle: Scalars['String'];
    badge2Handle: Scalars['String'];
}>;

export type SearchRentalUnitsQuery = {
    __typename?: 'Query';
    accommodation?: Maybe<{
        __typename?: 'Accommodation';
        id: string;
        searchRentalUnits: {
            __typename?: 'UnitSearchResult';
            totalHits: number;
            hits: Array<{
                __typename?: 'UnitSearchHit';
                rentalUnit: {
                    __typename?: 'RentalUnit';
                    id: string;
                    name: string;
                    slug: string;
                    type: RentalUnitTypeEnum;
                    code: string;
                    brand: string;
                    amountBedrooms?: Maybe<number>;
                    maxOccupancy: number;
                    flexCancellationDays?: Maybe<number>;
                    tagline: string;
                    fullCircleImageUrl?: Maybe<string>;
                    videoUrl?: Maybe<string>;
                    hasBadge: boolean;
                    hasbadge2: boolean;
                    reviewStats?: Maybe<{ __typename?: 'ReviewStats'; count: number; average: number }>;
                    mainImage?: Maybe<{
                        __typename?: 'GenericImage';
                        transform?: Maybe<{ __typename?: 'ImageTransform'; src: string }>;
                    }>;
                    webpListImage: {
                        __typename?: 'GenericImage';
                        transform?: Maybe<{
                            __typename?: 'ImageTransform';
                            placeholder?: Maybe<string>;
                            srcSet: string;
                            src: string;
                            ratio: number;
                        }>;
                    };
                    listImage: {
                        __typename?: 'GenericImage';
                        transform?: Maybe<{
                            __typename?: 'ImageTransform';
                            placeholder?: Maybe<string>;
                            srcSet: string;
                            src: string;
                            ratio: number;
                        }>;
                    };
                    accommodation: {
                        __typename?: 'Accommodation';
                        id: string;
                        place: string;
                        name: string;
                        hasPublicPage: boolean;
                        collapseInSearch: boolean;
                        address: { __typename?: 'Address'; city?: Maybe<string> };
                        coordinates: { __typename?: 'LatLon'; lat: number; lon: number };
                    };
                };
                trips: Array<{
                    __typename?: 'Trip';
                    originalPrice?: Maybe<number>;
                    date: string;
                    duration: number;
                    price: number;
                    type: TripTypeEnum;
                    special?: Maybe<{
                        __typename?: 'Special';
                        id: string;
                        name: string;
                        description: string;
                        landingPageUrl?: Maybe<string>;
                    }>;
                }>;
            }>;
        };
    }>;
};

export type SearchRentalUnitsFragment = {
    __typename?: 'UnitSearchResult';
    totalHits: number;
    hits: Array<{
        __typename?: 'UnitSearchHit';
        rentalUnit: {
            __typename?: 'RentalUnit';
            id: string;
            name: string;
            slug: string;
            type: RentalUnitTypeEnum;
            code: string;
            brand: string;
            amountBedrooms?: Maybe<number>;
            maxOccupancy: number;
            flexCancellationDays?: Maybe<number>;
            tagline: string;
            fullCircleImageUrl?: Maybe<string>;
            videoUrl?: Maybe<string>;
            hasBadge: boolean;
            hasbadge2: boolean;
            reviewStats?: Maybe<{ __typename?: 'ReviewStats'; count: number; average: number }>;
            mainImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{ __typename?: 'ImageTransform'; src: string }>;
            }>;
            webpListImage: {
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            };
            listImage: {
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            };
            accommodation: {
                __typename?: 'Accommodation';
                id: string;
                place: string;
                name: string;
                hasPublicPage: boolean;
                collapseInSearch: boolean;
                address: { __typename?: 'Address'; city?: Maybe<string> };
                coordinates: { __typename?: 'LatLon'; lat: number; lon: number };
            };
        };
        trips: Array<{
            __typename?: 'Trip';
            originalPrice?: Maybe<number>;
            date: string;
            duration: number;
            price: number;
            type: TripTypeEnum;
            special?: Maybe<{
                __typename?: 'Special';
                id: string;
                name: string;
                description: string;
                landingPageUrl?: Maybe<string>;
            }>;
        }>;
    }>;
};

export type UnitFilterPropertyCategoryFragment = {
    __typename?: 'UnitFilterPropertyCategory';
    name: string;
    handle: string;
    filterProperties: Array<{ __typename?: 'UnitFilterProperty'; name: string; handle: string }>;
};

export type SpecialQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type SpecialQuery = {
    __typename?: 'Query';
    special?: Maybe<{
        __typename?: 'Special';
        id: string;
        description: string;
        landingPageUrl?: Maybe<string>;
        name: string;
    }>;
};

export type OrderSummaryFragment = {
    __typename?: 'Order';
    number?: Maybe<string>;
    errors: Array<string>;
    totalPrice: number;
    orderItems: Array<
        | {
              __typename?: 'Booking';
              totalPrice: number;
              paymentPrice: number;
              errors: Array<string>;
              status: BookingStatus;
              amountAdults: number;
              amountPets: number;
              amountChildren: number;
              amountBabies: number;
              amountYouths: number;
              arrivalDate: string;
              departureDate: string;
              hasCancellationInsurance: boolean;
              hasContentsInsurance: boolean;
              handleDepositPayment: boolean;
              deposit: number;
              requiresApproval: boolean;
              rentalUnit?: Maybe<{
                  __typename?: 'RentalUnit';
                  id: string;
                  code: string;
                  name: string;
                  slug: string;
                  maxOccupancy: number;
                  minOccupancy: number;
                  petsAllowed: number;
                  type: RentalUnitTypeEnum;
                  accommodation: {
                      __typename?: 'Accommodation';
                      id: string;
                      hasPublicPage: boolean;
                      name: string;
                      checkInEndTime: string;
                      checkInStartTime: string;
                      checkOutTime: string;
                      type: AccommodationTypeEnum;
                  };
                  jpegThumbnail: {
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  };
                  webpThumbnail: {
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  };
              }>;
              priceLines: Array<{
                  __typename?: 'PriceLine';
                  label: string;
                  quantity?: Maybe<string>;
                  totalPrice: number;
                  unitPrice?: Maybe<number>;
                  category?: Maybe<PriceLineCategoryEnum>;
              }>;
              special?: Maybe<{ __typename?: 'Special'; id: string }>;
              optionalSurcharges: Array<{
                  __typename?: 'BookingCartItemSurcharge';
                  rentalUnitSurchargeId: string;
                  amount: number;
              }>;
          }
        | {
              __typename?: 'Ticket';
              totalPrice: number;
              paymentPrice: number;
              errors: Array<string>;
              startDateTime: string;
              ticketStatus: TicketStatus;
              activity?: Maybe<{
                  __typename?: 'Activity';
                  id: string;
                  slug: string;
                  name: string;
                  place?: Maybe<string>;
                  jpegThumbnail?: Maybe<{
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  }>;
                  webpThumbnail?: Maybe<{
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  }>;
              }>;
              timeslot?: Maybe<{
                  __typename?: 'Timeslot';
                  id: string;
                  startDateTime: string;
                  endDateTime: string;
                  allotment?: Maybe<number>;
                  label?: Maybe<string>;
                  rateGroup?: Maybe<{
                      __typename?: 'ActivityRateGroup';
                      releaseHours?: Maybe<number>;
                      rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                  }>;
              }>;
              priceLines: Array<{
                  __typename?: 'PriceLine';
                  label: string;
                  quantity?: Maybe<string>;
                  totalPrice: number;
                  unitPrice?: Maybe<number>;
                  category?: Maybe<PriceLineCategoryEnum>;
              }>;
              rateLines: Array<{
                  __typename?: 'TicketRateLine';
                  amount: number;
                  rateId?: Maybe<string>;
                  totalPrice: number;
                  unitPrice: number;
              }>;
          }
        | {
              __typename?: 'UpsellPurchase';
              totalPrice: number;
              paymentPrice: number;
              errors: Array<string>;
              amount: number;
              priceLines: Array<{
                  __typename?: 'PriceLine';
                  label: string;
                  quantity?: Maybe<string>;
                  totalPrice: number;
                  unitPrice?: Maybe<number>;
                  category?: Maybe<PriceLineCategoryEnum>;
              }>;
              offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
          }
    >;
    paymentOptions?: Maybe<{ __typename?: 'PaymentOptions'; partialPaymentAmount: number; totalAmountDue: number }>;
};

export type OrderSummaryItem_Booking_Fragment = {
    __typename?: 'Booking';
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    status: BookingStatus;
    amountAdults: number;
    amountPets: number;
    amountChildren: number;
    amountBabies: number;
    amountYouths: number;
    arrivalDate: string;
    departureDate: string;
    hasCancellationInsurance: boolean;
    hasContentsInsurance: boolean;
    handleDepositPayment: boolean;
    deposit: number;
    requiresApproval: boolean;
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        code: string;
        name: string;
        slug: string;
        maxOccupancy: number;
        minOccupancy: number;
        petsAllowed: number;
        type: RentalUnitTypeEnum;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            hasPublicPage: boolean;
            name: string;
            checkInEndTime: string;
            checkInStartTime: string;
            checkOutTime: string;
            type: AccommodationTypeEnum;
        };
        jpegThumbnail: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
        webpThumbnail: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
    }>;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    special?: Maybe<{ __typename?: 'Special'; id: string }>;
    optionalSurcharges: Array<{
        __typename?: 'BookingCartItemSurcharge';
        rentalUnitSurchargeId: string;
        amount: number;
    }>;
};

export type OrderSummaryItem_Ticket_Fragment = {
    __typename?: 'Ticket';
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    startDateTime: string;
    ticketStatus: TicketStatus;
    activity?: Maybe<{
        __typename?: 'Activity';
        id: string;
        slug: string;
        name: string;
        place?: Maybe<string>;
        jpegThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        webpThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
    }>;
    timeslot?: Maybe<{
        __typename?: 'Timeslot';
        id: string;
        startDateTime: string;
        endDateTime: string;
        allotment?: Maybe<number>;
        label?: Maybe<string>;
        rateGroup?: Maybe<{
            __typename?: 'ActivityRateGroup';
            releaseHours?: Maybe<number>;
            rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
        }>;
    }>;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    rateLines: Array<{
        __typename?: 'TicketRateLine';
        amount: number;
        rateId?: Maybe<string>;
        totalPrice: number;
        unitPrice: number;
    }>;
};

export type OrderSummaryItem_UpsellPurchase_Fragment = {
    __typename?: 'UpsellPurchase';
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    amount: number;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
};

export type OrderSummaryItemFragment =
    | OrderSummaryItem_Booking_Fragment
    | OrderSummaryItem_Ticket_Fragment
    | OrderSummaryItem_UpsellPurchase_Fragment;

export type OrderSummaryItemBookingFragment = {
    __typename?: 'Booking';
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        code: string;
        name: string;
        slug: string;
        maxOccupancy: number;
        minOccupancy: number;
        petsAllowed: number;
        type: RentalUnitTypeEnum;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            hasPublicPage: boolean;
            name: string;
            checkInEndTime: string;
            checkInStartTime: string;
            checkOutTime: string;
            type: AccommodationTypeEnum;
        };
        jpegThumbnail: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
        webpThumbnail: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
    }>;
};

export type OrderSummaryItemTicketFragment = {
    __typename?: 'Ticket';
    activity?: Maybe<{
        __typename?: 'Activity';
        id: string;
        slug: string;
        name: string;
        place?: Maybe<string>;
        jpegThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        webpThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
    }>;
    timeslot?: Maybe<{
        __typename?: 'Timeslot';
        id: string;
        startDateTime: string;
        endDateTime: string;
        rateGroup?: Maybe<{
            __typename?: 'ActivityRateGroup';
            rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
        }>;
    }>;
};

export type OrderSummaryItemUpsellPurchaseFragment = {
    __typename?: 'UpsellPurchase';
    totalPrice: number;
    paymentPrice: number;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
};

export type PriceLineFragment = {
    __typename?: 'PriceLine';
    label: string;
    quantity?: Maybe<string>;
    totalPrice: number;
    unitPrice?: Maybe<number>;
    category?: Maybe<PriceLineCategoryEnum>;
};

export type UnitAccommodationFragment = {
    __typename?: 'Accommodation';
    slug: string;
    descriptionGeneral?: Maybe<string>;
    name: string;
    jpegMain?: Maybe<{
        __typename?: 'GenericImage';
        transform?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    }>;
    webpMain?: Maybe<{
        __typename?: 'GenericImage';
        transform?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    }>;
};

export type UnitFeaturesFragment = {
    __typename?: 'RentalUnit';
    livingArea?: Maybe<number>;
    outdoorArea?: Maybe<number>;
    maxOccupancy: number;
    amountBedrooms?: Maybe<number>;
    petsAllowed: number;
    amountBathrooms?: Maybe<number>;
    amountToilets?: Maybe<number>;
    code: string;
    type: RentalUnitTypeEnum;
    accommodation: { __typename?: 'Accommodation'; place: string };
};

export type UnitOwnerFragment = {
    __typename?: 'Accommodation';
    ownerIntroduction?: Maybe<string>;
    ownerName?: Maybe<string>;
    ownerTips?: Maybe<string>;
    jpegOwner?: Maybe<{
        __typename?: 'GenericImage';
        transform?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    }>;
    webpOwner?: Maybe<{
        __typename?: 'GenericImage';
        transform?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    }>;
};

export type ImagesFragment = {
    __typename?: 'AccommodationImage';
    category: AccommodationImageCategoryEnum;
    jpegTile?: Maybe<{
        __typename?: 'ImageTransform';
        placeholder?: Maybe<string>;
        srcSet: string;
        src: string;
        ratio: number;
    }>;
    webpTile?: Maybe<{
        __typename?: 'ImageTransform';
        placeholder?: Maybe<string>;
        srcSet: string;
        src: string;
        ratio: number;
    }>;
    jpegGallery?: Maybe<{
        __typename?: 'ImageTransform';
        placeholder?: Maybe<string>;
        srcSet: string;
        src: string;
        ratio: number;
    }>;
    webpGallery?: Maybe<{
        __typename?: 'ImageTransform';
        placeholder?: Maybe<string>;
        srcSet: string;
        src: string;
        ratio: number;
    }>;
};

export type TransformFragment = {
    __typename?: 'ImageTransform';
    placeholder?: Maybe<string>;
    srcSet: string;
    src: string;
    ratio: number;
};

export type UnitPhotosFragment = {
    __typename?: 'RentalUnit';
    images: Array<{
        __typename?: 'AccommodationImage';
        category: AccommodationImageCategoryEnum;
        jpegTile?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
        webpTile?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
        jpegGallery?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
        webpGallery?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    }>;
};

export type AccommodationPhotosFragment = {
    __typename?: 'Accommodation';
    images: Array<{
        __typename?: 'AccommodationImage';
        category: AccommodationImageCategoryEnum;
        jpegTile?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
        webpTile?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
        jpegGallery?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
        webpGallery?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    }>;
};

export type UnitRelevantAlternativesQueryVariables = Exact<{
    slug: Scalars['String'];
    params?: Maybe<UnitSearchParamsInput>;
    badgeHandle: Scalars['String'];
    badge2Handle: Scalars['String'];
}>;

export type UnitRelevantAlternativesQuery = {
    __typename?: 'Query';
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        relevantAlternatives?: Maybe<{
            __typename?: 'UnitSearchResult';
            hits: Array<{
                __typename?: 'UnitSearchHit';
                rentalUnit: {
                    __typename?: 'RentalUnit';
                    id: string;
                    name: string;
                    slug: string;
                    type: RentalUnitTypeEnum;
                    code: string;
                    brand: string;
                    amountBedrooms?: Maybe<number>;
                    maxOccupancy: number;
                    flexCancellationDays?: Maybe<number>;
                    tagline: string;
                    fullCircleImageUrl?: Maybe<string>;
                    videoUrl?: Maybe<string>;
                    hasBadge: boolean;
                    hasbadge2: boolean;
                    reviewStats?: Maybe<{ __typename?: 'ReviewStats'; count: number; average: number }>;
                    mainImage?: Maybe<{
                        __typename?: 'GenericImage';
                        transform?: Maybe<{ __typename?: 'ImageTransform'; src: string }>;
                    }>;
                    webpListImage: {
                        __typename?: 'GenericImage';
                        transform?: Maybe<{
                            __typename?: 'ImageTransform';
                            placeholder?: Maybe<string>;
                            srcSet: string;
                            src: string;
                            ratio: number;
                        }>;
                    };
                    listImage: {
                        __typename?: 'GenericImage';
                        transform?: Maybe<{
                            __typename?: 'ImageTransform';
                            placeholder?: Maybe<string>;
                            srcSet: string;
                            src: string;
                            ratio: number;
                        }>;
                    };
                    accommodation: {
                        __typename?: 'Accommodation';
                        id: string;
                        place: string;
                        name: string;
                        hasPublicPage: boolean;
                        collapseInSearch: boolean;
                        address: { __typename?: 'Address'; city?: Maybe<string> };
                        coordinates: { __typename?: 'LatLon'; lat: number; lon: number };
                    };
                };
                trips: Array<{
                    __typename?: 'Trip';
                    originalPrice?: Maybe<number>;
                    date: string;
                    duration: number;
                    price: number;
                    type: TripTypeEnum;
                    special?: Maybe<{
                        __typename?: 'Special';
                        id: string;
                        name: string;
                        description: string;
                        landingPageUrl?: Maybe<string>;
                    }>;
                }>;
            }>;
        }>;
    }>;
};

export type UnitRelevantAlternativesFragment = {
    __typename?: 'UnitSearchResult';
    hits: Array<{
        __typename?: 'UnitSearchHit';
        rentalUnit: {
            __typename?: 'RentalUnit';
            id: string;
            name: string;
            slug: string;
            type: RentalUnitTypeEnum;
            code: string;
            brand: string;
            amountBedrooms?: Maybe<number>;
            maxOccupancy: number;
            flexCancellationDays?: Maybe<number>;
            tagline: string;
            fullCircleImageUrl?: Maybe<string>;
            videoUrl?: Maybe<string>;
            hasBadge: boolean;
            hasbadge2: boolean;
            reviewStats?: Maybe<{ __typename?: 'ReviewStats'; count: number; average: number }>;
            mainImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{ __typename?: 'ImageTransform'; src: string }>;
            }>;
            webpListImage: {
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            };
            listImage: {
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            };
            accommodation: {
                __typename?: 'Accommodation';
                id: string;
                place: string;
                name: string;
                hasPublicPage: boolean;
                collapseInSearch: boolean;
                address: { __typename?: 'Address'; city?: Maybe<string> };
                coordinates: { __typename?: 'LatLon'; lat: number; lon: number };
            };
        };
        trips: Array<{
            __typename?: 'Trip';
            originalPrice?: Maybe<number>;
            date: string;
            duration: number;
            price: number;
            type: TripTypeEnum;
            special?: Maybe<{
                __typename?: 'Special';
                id: string;
                name: string;
                description: string;
                landingPageUrl?: Maybe<string>;
            }>;
        }>;
    }>;
};

export type ReviewsQueryVariables = Exact<{
    slug: Scalars['String'];
    limit: Scalars['Int'];
    offset?: Maybe<Scalars['Int']>;
}>;

export type ReviewsQuery = {
    __typename?: 'Query';
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        reviews: Array<{
            __typename?: 'Review';
            id: string;
            title: string;
            average: number;
            city?: Maybe<string>;
            date: string;
            comment: string;
            reply?: Maybe<string>;
        }>;
    }>;
};

export type UnitReviewsFragment = {
    __typename?: 'Review';
    id: string;
    title: string;
    average: number;
    city?: Maybe<string>;
    date: string;
    comment: string;
    reply?: Maybe<string>;
};

export type AccommodationDetailsQueryVariables = Exact<{
    slug: Scalars['String'];
}>;

export type AccommodationDetailsQuery = {
    __typename?: 'Query';
    accommodation?: Maybe<{
        __typename?: 'Accommodation';
        id: string;
        slug: string;
        type: AccommodationTypeEnum;
        name: string;
        hasPublicPage: boolean;
        place: string;
        descriptionGeneral?: Maybe<string>;
        descriptionLocation: string;
        jpegHero?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        webpHero?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        images: Array<{
            __typename?: 'AccommodationImage';
            category: AccommodationImageCategoryEnum;
            jpegTile?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
            webpTile?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
            jpegGallery?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
            webpGallery?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        attributeCategories: Array<{
            __typename?: 'AttributeCategory';
            name: string;
            handle: string;
            attributes: Array<{
                __typename?: 'Attribute';
                id: string;
                name: string;
                iconUrl?: Maybe<string>;
                handle: string;
            }>;
        }>;
        pointsOfInterest: Array<{ __typename?: 'PointOfInterest'; name: string; distance: number }>;
        coordinates: { __typename?: 'LatLon'; lat: number; lon: number };
    }>;
};

export type ActivityDetailQueryVariables = Exact<{
    slug: Scalars['String'];
}>;

export type ActivityDetailQuery = {
    __typename?: 'Query';
    activity?: Maybe<{
        __typename?: 'Activity';
        id: string;
        slug: string;
        externalBookingUrl?: Maybe<string>;
        externalInfoUrl?: Maybe<string>;
        bookingType: ActivityBookingTypesEnum;
        videoUrl?: Maybe<string>;
        place?: Maybe<string>;
        name: string;
        minCapacity?: Maybe<number>;
        maxCapacity?: Maybe<number>;
        targetAudiences: Array<TargetAudienceEnum>;
        description?: Maybe<string>;
        descriptionLocation?: Maybe<string>;
        descriptionParticulars?: Maybe<string>;
        jpegHero?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        webpHero?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        jpegThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        webpThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        themes: Array<{ __typename?: 'ActivityTheme'; id: string; name?: Maybe<string> }>;
        coordinates?: Maybe<{ __typename?: 'LatLon'; lat: number; lon: number }>;
        images: Array<{
            __typename?: 'ActivityImage';
            jpegTile?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
            webpTile?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
            jpegGallery?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
            webpGallery?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        company?: Maybe<{
            __typename?: 'Company';
            id: string;
            name: string;
            description?: Maybe<string>;
            slug: string;
            webpMain?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            jpegMain?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            normalOperatingHours?: Maybe<{
                __typename?: 'NormalOperatingHours';
                monday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                tuesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                wednesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                thursday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                friday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                saturday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                sunday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
            }>;
            specialOperatingHours: Array<{
                __typename?: 'SpecialOperatingHours';
                startDate: string;
                endDate: string;
                monday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                tuesday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                wednesday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                thursday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                friday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                saturday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                sunday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
            }>;
        }>;
    }>;
};

export type ActivityIframePlannerQueryVariables = Exact<{
    slug: Scalars['String'];
}>;

export type ActivityIframePlannerQuery = {
    __typename?: 'Query';
    activity?: Maybe<{
        __typename?: 'Activity';
        id: string;
        bookingType: ActivityBookingTypesEnum;
        externalBookingUrl?: Maybe<string>;
        name: string;
        descriptionParticulars?: Maybe<string>;
        jpegThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        webpThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
    }>;
};

export type ActivitySearchQueryVariables = Exact<{
    params: ActivitySearchParamsInput;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    startDate: Scalars['String'];
    endDate: Scalars['String'];
}>;

export type ActivitySearchQuery = {
    __typename?: 'Query';
    activities: {
        __typename?: 'ActivitySearchResult';
        totalHits: number;
        hits: Array<{
            __typename?: 'Activity';
            id: string;
            name: string;
            slug: string;
            highlighted: boolean;
            place?: Maybe<string>;
            videoUrl?: Maybe<string>;
            externalBookingUrl?: Maybe<string>;
            bookingType: ActivityBookingTypesEnum;
            minCapacity?: Maybe<number>;
            maxCapacity?: Maybe<number>;
            themes: Array<{ __typename?: 'ActivityTheme'; slug: string; name?: Maybe<string> }>;
            timeslots: Array<{
                __typename?: 'Timeslot';
                id: string;
                allotment?: Maybe<number>;
                startDateTime: string;
                endDateTime: string;
                label?: Maybe<string>;
                rateGroup?: Maybe<{
                    __typename?: 'ActivityRateGroup';
                    releaseHours?: Maybe<number>;
                    rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                }>;
            }>;
            mainImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            webpMainImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            webpListImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            listImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
        }>;
        placeCounts: Array<{ __typename?: 'GenericAggregationCount'; value: string; hits: number }>;
        themeCounts: Array<{ __typename?: 'GenericAggregationCount'; value: string; hits: number }>;
        targetAudienceCount: Array<{
            __typename?: 'TargetAudienceAggregationCount';
            value: TargetAudienceEnum;
            hits: number;
        }>;
    };
};

export type ActivityHitFragment = {
    __typename?: 'Activity';
    id: string;
    name: string;
    slug: string;
    highlighted: boolean;
    place?: Maybe<string>;
    videoUrl?: Maybe<string>;
    externalBookingUrl?: Maybe<string>;
    bookingType: ActivityBookingTypesEnum;
    minCapacity?: Maybe<number>;
    maxCapacity?: Maybe<number>;
    themes: Array<{ __typename?: 'ActivityTheme'; slug: string; name?: Maybe<string> }>;
    timeslots: Array<{
        __typename?: 'Timeslot';
        id: string;
        allotment?: Maybe<number>;
        startDateTime: string;
        endDateTime: string;
        label?: Maybe<string>;
        rateGroup?: Maybe<{
            __typename?: 'ActivityRateGroup';
            releaseHours?: Maybe<number>;
            rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
        }>;
    }>;
    mainImage?: Maybe<{
        __typename?: 'GenericImage';
        transform?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    }>;
    webpMainImage?: Maybe<{
        __typename?: 'GenericImage';
        transform?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    }>;
    webpListImage?: Maybe<{
        __typename?: 'GenericImage';
        transform?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    }>;
    listImage?: Maybe<{
        __typename?: 'GenericImage';
        transform?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    }>;
};

export type AgendaFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type AgendaFiltersQuery = {
    __typename?: 'Query';
    activityThemes: Array<{ __typename?: 'ActivityTheme'; slug: string; name?: Maybe<string>; position: number }>;
    places: Array<{ __typename?: 'Place'; id: string; position: number; name: string }>;
};

export type BookingAlterationPreviewQueryVariables = Exact<{
    input: AlterBookingInput;
}>;

export type BookingAlterationPreviewQuery = {
    __typename?: 'Query';
    bookingAlterationPreview?: Maybe<{
        __typename?: 'BookingChangePreview';
        newOrderAmountDue: number;
        bookingPreview: {
            __typename?: 'Booking';
            id?: Maybe<string>;
            downloadVoucherUrl?: Maybe<string>;
            isCancellable: boolean;
            isAlterable: boolean;
            customerComment?: Maybe<string>;
            totalPrice: number;
            paymentPrice: number;
            errors: Array<string>;
            status: BookingStatus;
            amountAdults: number;
            amountPets: number;
            amountChildren: number;
            amountBabies: number;
            amountYouths: number;
            arrivalDate: string;
            departureDate: string;
            hasCancellationInsurance: boolean;
            hasContentsInsurance: boolean;
            handleDepositPayment: boolean;
            deposit: number;
            requiresApproval: boolean;
            review?: Maybe<{
                __typename?: 'Review';
                id: string;
                average: number;
                comment: string;
                date: string;
                ratingHospitality?: Maybe<number>;
                ratingLayout?: Maybe<number>;
                ratingLocation?: Maybe<number>;
                ratingMaintenance?: Maybe<number>;
                ratingValueForMoney?: Maybe<number>;
                tips?: Maybe<string>;
                title: string;
                travelGroupType?: Maybe<TravelGroupType>;
                reply?: Maybe<string>;
            }>;
            rentalUnit?: Maybe<{
                __typename?: 'RentalUnit';
                id: string;
                flexCancellationDays?: Maybe<number>;
                code: string;
                name: string;
                slug: string;
                maxOccupancy: number;
                minOccupancy: number;
                petsAllowed: number;
                type: RentalUnitTypeEnum;
                reviewStats?: Maybe<{
                    __typename?: 'ReviewStats';
                    count: number;
                    average: number;
                    location: number;
                    hospitality: number;
                    layout: number;
                    maintenance: number;
                    valueForMoney: number;
                }>;
                accommodation: {
                    __typename?: 'Accommodation';
                    id: string;
                    ownerName?: Maybe<string>;
                    hasPublicPage: boolean;
                    name: string;
                    checkInEndTime: string;
                    checkInStartTime: string;
                    checkOutTime: string;
                    type: AccommodationTypeEnum;
                    jpegOwner?: Maybe<{
                        __typename?: 'GenericImage';
                        transform?: Maybe<{
                            __typename?: 'ImageTransform';
                            placeholder?: Maybe<string>;
                            srcSet: string;
                            src: string;
                            ratio: number;
                        }>;
                    }>;
                    webpOwner?: Maybe<{
                        __typename?: 'GenericImage';
                        transform?: Maybe<{
                            __typename?: 'ImageTransform';
                            placeholder?: Maybe<string>;
                            srcSet: string;
                            src: string;
                            ratio: number;
                        }>;
                    }>;
                };
                jpegThumbnail: {
                    __typename?: 'GenericImage';
                    transform?: Maybe<{
                        __typename?: 'ImageTransform';
                        placeholder?: Maybe<string>;
                        srcSet: string;
                        src: string;
                        ratio: number;
                    }>;
                };
                webpThumbnail: {
                    __typename?: 'GenericImage';
                    transform?: Maybe<{
                        __typename?: 'ImageTransform';
                        placeholder?: Maybe<string>;
                        srcSet: string;
                        src: string;
                        ratio: number;
                    }>;
                };
            }>;
            priceLines: Array<{
                __typename?: 'PriceLine';
                label: string;
                quantity?: Maybe<string>;
                totalPrice: number;
                unitPrice?: Maybe<number>;
                category?: Maybe<PriceLineCategoryEnum>;
            }>;
            special?: Maybe<{ __typename?: 'Special'; id: string }>;
            optionalSurcharges: Array<{
                __typename?: 'BookingCartItemSurcharge';
                rentalUnitSurchargeId: string;
                amount: number;
            }>;
        };
    }>;
};

export type AlterBookingMutationVariables = Exact<{
    input: AlterBookingInput;
}>;

export type AlterBookingMutation = {
    __typename?: 'Mutation';
    alterBooking: {
        __typename?: 'AlterBookingPayload';
        booking?: Maybe<{
            __typename?: 'Booking';
            id?: Maybe<string>;
            downloadVoucherUrl?: Maybe<string>;
            isCancellable: boolean;
            isAlterable: boolean;
            customerComment?: Maybe<string>;
            totalPrice: number;
            paymentPrice: number;
            errors: Array<string>;
            status: BookingStatus;
            amountAdults: number;
            amountPets: number;
            amountChildren: number;
            amountBabies: number;
            amountYouths: number;
            arrivalDate: string;
            departureDate: string;
            hasCancellationInsurance: boolean;
            hasContentsInsurance: boolean;
            handleDepositPayment: boolean;
            deposit: number;
            requiresApproval: boolean;
            review?: Maybe<{
                __typename?: 'Review';
                id: string;
                average: number;
                comment: string;
                date: string;
                ratingHospitality?: Maybe<number>;
                ratingLayout?: Maybe<number>;
                ratingLocation?: Maybe<number>;
                ratingMaintenance?: Maybe<number>;
                ratingValueForMoney?: Maybe<number>;
                tips?: Maybe<string>;
                title: string;
                travelGroupType?: Maybe<TravelGroupType>;
                reply?: Maybe<string>;
            }>;
            rentalUnit?: Maybe<{
                __typename?: 'RentalUnit';
                id: string;
                flexCancellationDays?: Maybe<number>;
                code: string;
                name: string;
                slug: string;
                maxOccupancy: number;
                minOccupancy: number;
                petsAllowed: number;
                type: RentalUnitTypeEnum;
                reviewStats?: Maybe<{
                    __typename?: 'ReviewStats';
                    count: number;
                    average: number;
                    location: number;
                    hospitality: number;
                    layout: number;
                    maintenance: number;
                    valueForMoney: number;
                }>;
                accommodation: {
                    __typename?: 'Accommodation';
                    id: string;
                    ownerName?: Maybe<string>;
                    hasPublicPage: boolean;
                    name: string;
                    checkInEndTime: string;
                    checkInStartTime: string;
                    checkOutTime: string;
                    type: AccommodationTypeEnum;
                    jpegOwner?: Maybe<{
                        __typename?: 'GenericImage';
                        transform?: Maybe<{
                            __typename?: 'ImageTransform';
                            placeholder?: Maybe<string>;
                            srcSet: string;
                            src: string;
                            ratio: number;
                        }>;
                    }>;
                    webpOwner?: Maybe<{
                        __typename?: 'GenericImage';
                        transform?: Maybe<{
                            __typename?: 'ImageTransform';
                            placeholder?: Maybe<string>;
                            srcSet: string;
                            src: string;
                            ratio: number;
                        }>;
                    }>;
                };
                jpegThumbnail: {
                    __typename?: 'GenericImage';
                    transform?: Maybe<{
                        __typename?: 'ImageTransform';
                        placeholder?: Maybe<string>;
                        srcSet: string;
                        src: string;
                        ratio: number;
                    }>;
                };
                webpThumbnail: {
                    __typename?: 'GenericImage';
                    transform?: Maybe<{
                        __typename?: 'ImageTransform';
                        placeholder?: Maybe<string>;
                        srcSet: string;
                        src: string;
                        ratio: number;
                    }>;
                };
            }>;
            priceLines: Array<{
                __typename?: 'PriceLine';
                label: string;
                quantity?: Maybe<string>;
                totalPrice: number;
                unitPrice?: Maybe<number>;
                category?: Maybe<PriceLineCategoryEnum>;
            }>;
            special?: Maybe<{ __typename?: 'Special'; id: string }>;
            optionalSurcharges: Array<{
                __typename?: 'BookingCartItemSurcharge';
                rentalUnitSurchargeId: string;
                amount: number;
            }>;
        }>;
    };
};

export type BookingCancellationPreviewQueryVariables = Exact<{
    input: CancelBookingInput;
}>;

export type BookingCancellationPreviewQuery = {
    __typename?: 'Query';
    bookingCancellationPreview?: Maybe<{
        __typename?: 'BookingChangePreview';
        newOrderAmountDue: number;
        bookingPreview: {
            __typename?: 'Booking';
            id?: Maybe<string>;
            downloadVoucherUrl?: Maybe<string>;
            isCancellable: boolean;
            isAlterable: boolean;
            customerComment?: Maybe<string>;
            totalPrice: number;
            paymentPrice: number;
            errors: Array<string>;
            status: BookingStatus;
            amountAdults: number;
            amountPets: number;
            amountChildren: number;
            amountBabies: number;
            amountYouths: number;
            arrivalDate: string;
            departureDate: string;
            hasCancellationInsurance: boolean;
            hasContentsInsurance: boolean;
            handleDepositPayment: boolean;
            deposit: number;
            requiresApproval: boolean;
            review?: Maybe<{
                __typename?: 'Review';
                id: string;
                average: number;
                comment: string;
                date: string;
                ratingHospitality?: Maybe<number>;
                ratingLayout?: Maybe<number>;
                ratingLocation?: Maybe<number>;
                ratingMaintenance?: Maybe<number>;
                ratingValueForMoney?: Maybe<number>;
                tips?: Maybe<string>;
                title: string;
                travelGroupType?: Maybe<TravelGroupType>;
                reply?: Maybe<string>;
            }>;
            rentalUnit?: Maybe<{
                __typename?: 'RentalUnit';
                id: string;
                flexCancellationDays?: Maybe<number>;
                code: string;
                name: string;
                slug: string;
                maxOccupancy: number;
                minOccupancy: number;
                petsAllowed: number;
                type: RentalUnitTypeEnum;
                reviewStats?: Maybe<{
                    __typename?: 'ReviewStats';
                    count: number;
                    average: number;
                    location: number;
                    hospitality: number;
                    layout: number;
                    maintenance: number;
                    valueForMoney: number;
                }>;
                accommodation: {
                    __typename?: 'Accommodation';
                    id: string;
                    ownerName?: Maybe<string>;
                    hasPublicPage: boolean;
                    name: string;
                    checkInEndTime: string;
                    checkInStartTime: string;
                    checkOutTime: string;
                    type: AccommodationTypeEnum;
                    jpegOwner?: Maybe<{
                        __typename?: 'GenericImage';
                        transform?: Maybe<{
                            __typename?: 'ImageTransform';
                            placeholder?: Maybe<string>;
                            srcSet: string;
                            src: string;
                            ratio: number;
                        }>;
                    }>;
                    webpOwner?: Maybe<{
                        __typename?: 'GenericImage';
                        transform?: Maybe<{
                            __typename?: 'ImageTransform';
                            placeholder?: Maybe<string>;
                            srcSet: string;
                            src: string;
                            ratio: number;
                        }>;
                    }>;
                };
                jpegThumbnail: {
                    __typename?: 'GenericImage';
                    transform?: Maybe<{
                        __typename?: 'ImageTransform';
                        placeholder?: Maybe<string>;
                        srcSet: string;
                        src: string;
                        ratio: number;
                    }>;
                };
                webpThumbnail: {
                    __typename?: 'GenericImage';
                    transform?: Maybe<{
                        __typename?: 'ImageTransform';
                        placeholder?: Maybe<string>;
                        srcSet: string;
                        src: string;
                        ratio: number;
                    }>;
                };
            }>;
            priceLines: Array<{
                __typename?: 'PriceLine';
                label: string;
                quantity?: Maybe<string>;
                totalPrice: number;
                unitPrice?: Maybe<number>;
                category?: Maybe<PriceLineCategoryEnum>;
            }>;
            special?: Maybe<{ __typename?: 'Special'; id: string }>;
            optionalSurcharges: Array<{
                __typename?: 'BookingCartItemSurcharge';
                rentalUnitSurchargeId: string;
                amount: number;
            }>;
        };
    }>;
};

export type CancelBookingMutationVariables = Exact<{
    input: CancelBookingInput;
}>;

export type CancelBookingMutation = {
    __typename?: 'Mutation';
    cancelBooking: {
        __typename?: 'CancelBookingPayload';
        booking?: Maybe<{
            __typename?: 'Booking';
            id?: Maybe<string>;
            downloadVoucherUrl?: Maybe<string>;
            isCancellable: boolean;
            isAlterable: boolean;
            customerComment?: Maybe<string>;
            totalPrice: number;
            paymentPrice: number;
            errors: Array<string>;
            status: BookingStatus;
            amountAdults: number;
            amountPets: number;
            amountChildren: number;
            amountBabies: number;
            amountYouths: number;
            arrivalDate: string;
            departureDate: string;
            hasCancellationInsurance: boolean;
            hasContentsInsurance: boolean;
            handleDepositPayment: boolean;
            deposit: number;
            requiresApproval: boolean;
            review?: Maybe<{
                __typename?: 'Review';
                id: string;
                average: number;
                comment: string;
                date: string;
                ratingHospitality?: Maybe<number>;
                ratingLayout?: Maybe<number>;
                ratingLocation?: Maybe<number>;
                ratingMaintenance?: Maybe<number>;
                ratingValueForMoney?: Maybe<number>;
                tips?: Maybe<string>;
                title: string;
                travelGroupType?: Maybe<TravelGroupType>;
                reply?: Maybe<string>;
            }>;
            rentalUnit?: Maybe<{
                __typename?: 'RentalUnit';
                id: string;
                flexCancellationDays?: Maybe<number>;
                code: string;
                name: string;
                slug: string;
                maxOccupancy: number;
                minOccupancy: number;
                petsAllowed: number;
                type: RentalUnitTypeEnum;
                reviewStats?: Maybe<{
                    __typename?: 'ReviewStats';
                    count: number;
                    average: number;
                    location: number;
                    hospitality: number;
                    layout: number;
                    maintenance: number;
                    valueForMoney: number;
                }>;
                accommodation: {
                    __typename?: 'Accommodation';
                    id: string;
                    ownerName?: Maybe<string>;
                    hasPublicPage: boolean;
                    name: string;
                    checkInEndTime: string;
                    checkInStartTime: string;
                    checkOutTime: string;
                    type: AccommodationTypeEnum;
                    jpegOwner?: Maybe<{
                        __typename?: 'GenericImage';
                        transform?: Maybe<{
                            __typename?: 'ImageTransform';
                            placeholder?: Maybe<string>;
                            srcSet: string;
                            src: string;
                            ratio: number;
                        }>;
                    }>;
                    webpOwner?: Maybe<{
                        __typename?: 'GenericImage';
                        transform?: Maybe<{
                            __typename?: 'ImageTransform';
                            placeholder?: Maybe<string>;
                            srcSet: string;
                            src: string;
                            ratio: number;
                        }>;
                    }>;
                };
                jpegThumbnail: {
                    __typename?: 'GenericImage';
                    transform?: Maybe<{
                        __typename?: 'ImageTransform';
                        placeholder?: Maybe<string>;
                        srcSet: string;
                        src: string;
                        ratio: number;
                    }>;
                };
                webpThumbnail: {
                    __typename?: 'GenericImage';
                    transform?: Maybe<{
                        __typename?: 'ImageTransform';
                        placeholder?: Maybe<string>;
                        srcSet: string;
                        src: string;
                        ratio: number;
                    }>;
                };
            }>;
            priceLines: Array<{
                __typename?: 'PriceLine';
                label: string;
                quantity?: Maybe<string>;
                totalPrice: number;
                unitPrice?: Maybe<number>;
                category?: Maybe<PriceLineCategoryEnum>;
            }>;
            special?: Maybe<{ __typename?: 'Special'; id: string }>;
            optionalSurcharges: Array<{
                __typename?: 'BookingCartItemSurcharge';
                rentalUnitSurchargeId: string;
                amount: number;
            }>;
        }>;
    };
};

export type CheckoutConfirmQueryVariables = Exact<{
    cartId: Scalars['ID'];
}>;

export type CheckoutConfirmQuery = {
    __typename?: 'Query';
    cart?: Maybe<{
        __typename?: 'Cart';
        id: string;
        status: CartStatusEnum;
        order: {
            __typename?: 'Order';
            number?: Maybe<string>;
            errors: Array<string>;
            totalPrice: number;
            customer?: Maybe<{
                __typename?: 'Customer';
                email?: Maybe<string>;
                phoneNumber?: Maybe<string>;
                firstName?: Maybe<string>;
                lastName?: Maybe<string>;
                address?: Maybe<{
                    __typename?: 'Address';
                    city?: Maybe<string>;
                    countryCode?: Maybe<string>;
                    number?: Maybe<string>;
                    postalCode?: Maybe<string>;
                    street?: Maybe<string>;
                }>;
            }>;
            orderItems: Array<
                | {
                      __typename?: 'Booking';
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      status: BookingStatus;
                      amountAdults: number;
                      amountPets: number;
                      amountChildren: number;
                      amountBabies: number;
                      amountYouths: number;
                      arrivalDate: string;
                      departureDate: string;
                      hasCancellationInsurance: boolean;
                      hasContentsInsurance: boolean;
                      handleDepositPayment: boolean;
                      deposit: number;
                      requiresApproval: boolean;
                      rentalUnit?: Maybe<{
                          __typename?: 'RentalUnit';
                          id: string;
                          code: string;
                          type: RentalUnitTypeEnum;
                          name: string;
                          accommodation: {
                              __typename?: 'Accommodation';
                              id: string;
                              checkInStartTime: string;
                              checkInEndTime: string;
                          };
                      }>;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      special?: Maybe<{ __typename?: 'Special'; id: string }>;
                      optionalSurcharges: Array<{
                          __typename?: 'BookingCartItemSurcharge';
                          rentalUnitSurchargeId: string;
                          amount: number;
                      }>;
                  }
                | {
                      __typename?: 'Ticket';
                      startDateTime: string;
                      endDateTime: string;
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      ticketStatus: TicketStatus;
                      activity?: Maybe<{ __typename?: 'Activity'; id: string; name: string; slug: string }>;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      timeslot?: Maybe<{
                          __typename?: 'Timeslot';
                          id: string;
                          allotment?: Maybe<number>;
                          startDateTime: string;
                          endDateTime: string;
                          label?: Maybe<string>;
                          rateGroup?: Maybe<{
                              __typename?: 'ActivityRateGroup';
                              releaseHours?: Maybe<number>;
                              rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                          }>;
                      }>;
                      rateLines: Array<{
                          __typename?: 'TicketRateLine';
                          amount: number;
                          rateId?: Maybe<string>;
                          totalPrice: number;
                          unitPrice: number;
                      }>;
                  }
                | {
                      __typename?: 'UpsellPurchase';
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      amount: number;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
                  }
            >;
            paymentOptions?: Maybe<{
                __typename?: 'PaymentOptions';
                totalAmountDue: number;
                partialPaymentAmount: number;
            }>;
        };
    }>;
};

export type OrderCustomerInfoFragment = {
    __typename?: 'Customer';
    email?: Maybe<string>;
    phoneNumber?: Maybe<string>;
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
    address?: Maybe<{
        __typename?: 'Address';
        city?: Maybe<string>;
        countryCode?: Maybe<string>;
        number?: Maybe<string>;
        postalCode?: Maybe<string>;
        street?: Maybe<string>;
    }>;
};

export type CheckoutConfirmOrderItem_Booking_Fragment = {
    __typename?: 'Booking';
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    status: BookingStatus;
    amountAdults: number;
    amountPets: number;
    amountChildren: number;
    amountBabies: number;
    amountYouths: number;
    arrivalDate: string;
    departureDate: string;
    hasCancellationInsurance: boolean;
    hasContentsInsurance: boolean;
    handleDepositPayment: boolean;
    deposit: number;
    requiresApproval: boolean;
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        code: string;
        type: RentalUnitTypeEnum;
        name: string;
        accommodation: { __typename?: 'Accommodation'; id: string; checkInStartTime: string; checkInEndTime: string };
    }>;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    special?: Maybe<{ __typename?: 'Special'; id: string }>;
    optionalSurcharges: Array<{
        __typename?: 'BookingCartItemSurcharge';
        rentalUnitSurchargeId: string;
        amount: number;
    }>;
};

export type CheckoutConfirmOrderItem_Ticket_Fragment = {
    __typename?: 'Ticket';
    startDateTime: string;
    endDateTime: string;
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    ticketStatus: TicketStatus;
    activity?: Maybe<{ __typename?: 'Activity'; id: string; name: string; slug: string }>;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    timeslot?: Maybe<{
        __typename?: 'Timeslot';
        id: string;
        allotment?: Maybe<number>;
        startDateTime: string;
        endDateTime: string;
        label?: Maybe<string>;
        rateGroup?: Maybe<{
            __typename?: 'ActivityRateGroup';
            releaseHours?: Maybe<number>;
            rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
        }>;
    }>;
    rateLines: Array<{
        __typename?: 'TicketRateLine';
        amount: number;
        rateId?: Maybe<string>;
        totalPrice: number;
        unitPrice: number;
    }>;
};

export type CheckoutConfirmOrderItem_UpsellPurchase_Fragment = {
    __typename?: 'UpsellPurchase';
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    amount: number;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
};

export type CheckoutConfirmOrderItemFragment =
    | CheckoutConfirmOrderItem_Booking_Fragment
    | CheckoutConfirmOrderItem_Ticket_Fragment
    | CheckoutConfirmOrderItem_UpsellPurchase_Fragment;

export type CheckoutConfirmTicketFragment = {
    __typename?: 'Ticket';
    startDateTime: string;
    endDateTime: string;
    activity?: Maybe<{ __typename?: 'Activity'; id: string; name: string }>;
};

export type CheckoutConfirmBookingFragment = {
    __typename?: 'Booking';
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        code: string;
        type: RentalUnitTypeEnum;
        name: string;
        accommodation: { __typename?: 'Accommodation'; id: string; checkInStartTime: string; checkInEndTime: string };
    }>;
};

export type CheckoutConfirmOrderQueryVariables = Exact<{
    orderId: Scalars['ID'];
}>;

export type CheckoutConfirmOrderQuery = {
    __typename?: 'Query';
    order?: Maybe<{
        __typename?: 'Order';
        id?: Maybe<string>;
        number?: Maybe<string>;
        errors: Array<string>;
        totalPrice: number;
        customer?: Maybe<{
            __typename?: 'Customer';
            email?: Maybe<string>;
            phoneNumber?: Maybe<string>;
            firstName?: Maybe<string>;
            lastName?: Maybe<string>;
            address?: Maybe<{
                __typename?: 'Address';
                city?: Maybe<string>;
                countryCode?: Maybe<string>;
                number?: Maybe<string>;
                postalCode?: Maybe<string>;
                street?: Maybe<string>;
            }>;
        }>;
        payments: Array<{ __typename?: 'Payment'; amount: number; date: string; status: PaymentStatusEnum }>;
        orderItems: Array<
            | {
                  __typename?: 'Booking';
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  status: BookingStatus;
                  amountAdults: number;
                  amountPets: number;
                  amountChildren: number;
                  amountBabies: number;
                  amountYouths: number;
                  arrivalDate: string;
                  departureDate: string;
                  hasCancellationInsurance: boolean;
                  hasContentsInsurance: boolean;
                  handleDepositPayment: boolean;
                  deposit: number;
                  requiresApproval: boolean;
                  rentalUnit?: Maybe<{
                      __typename?: 'RentalUnit';
                      id: string;
                      code: string;
                      name: string;
                      slug: string;
                      maxOccupancy: number;
                      minOccupancy: number;
                      petsAllowed: number;
                      type: RentalUnitTypeEnum;
                      accommodation: {
                          __typename?: 'Accommodation';
                          id: string;
                          hasPublicPage: boolean;
                          name: string;
                          checkInEndTime: string;
                          checkInStartTime: string;
                          checkOutTime: string;
                          type: AccommodationTypeEnum;
                      };
                      jpegThumbnail: {
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      };
                      webpThumbnail: {
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      };
                  }>;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  special?: Maybe<{ __typename?: 'Special'; id: string }>;
                  optionalSurcharges: Array<{
                      __typename?: 'BookingCartItemSurcharge';
                      rentalUnitSurchargeId: string;
                      amount: number;
                  }>;
              }
            | {
                  __typename?: 'Ticket';
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  startDateTime: string;
                  ticketStatus: TicketStatus;
                  activity?: Maybe<{
                      __typename?: 'Activity';
                      id: string;
                      slug: string;
                      name: string;
                      place?: Maybe<string>;
                      jpegThumbnail?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                      webpThumbnail?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                  }>;
                  timeslot?: Maybe<{
                      __typename?: 'Timeslot';
                      id: string;
                      startDateTime: string;
                      endDateTime: string;
                      allotment?: Maybe<number>;
                      label?: Maybe<string>;
                      rateGroup?: Maybe<{
                          __typename?: 'ActivityRateGroup';
                          releaseHours?: Maybe<number>;
                          rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                      }>;
                  }>;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  rateLines: Array<{
                      __typename?: 'TicketRateLine';
                      amount: number;
                      rateId?: Maybe<string>;
                      totalPrice: number;
                      unitPrice: number;
                  }>;
              }
            | {
                  __typename?: 'UpsellPurchase';
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  amount: number;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
              }
        >;
        paymentOptions?: Maybe<{ __typename?: 'PaymentOptions'; partialPaymentAmount: number; totalAmountDue: number }>;
    }>;
};

export type CheckoutDetailsQueryVariables = Exact<{
    cartId: Scalars['ID'];
}>;

export type CheckoutDetailsQuery = {
    __typename?: 'Query';
    cart?: Maybe<{
        __typename?: 'Cart';
        id: string;
        status: CartStatusEnum;
        order: {
            __typename?: 'Order';
            isDeskOrder: boolean;
            agreedToTerms?: Maybe<boolean>;
            newsletterOptin?: Maybe<boolean>;
            number?: Maybe<string>;
            errors: Array<string>;
            totalPrice: number;
            orderItems: Array<
                | {
                      __typename?: 'Booking';
                      hasCancellationInsurance: boolean;
                      hasContentsInsurance: boolean;
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      status: BookingStatus;
                      amountAdults: number;
                      amountPets: number;
                      amountChildren: number;
                      amountBabies: number;
                      amountYouths: number;
                      arrivalDate: string;
                      departureDate: string;
                      handleDepositPayment: boolean;
                      deposit: number;
                      requiresApproval: boolean;
                      special?: Maybe<{ __typename?: 'Special'; id: string; name: string; description: string }>;
                      rentalUnit?: Maybe<{
                          __typename?: 'RentalUnit';
                          id: string;
                          slug: string;
                          name: string;
                          type: RentalUnitTypeEnum;
                          amountBedrooms?: Maybe<number>;
                          maxOccupancy: number;
                          minOccupancy: number;
                          flexCancellationDays?: Maybe<number>;
                          petsAllowed: number;
                          descriptionParticulars?: Maybe<string>;
                          contentsInsuranceAvailable: boolean;
                          cancellationInsuranceAvailable: boolean;
                          accommodation: {
                              __typename?: 'Accommodation';
                              hasPublicPage: boolean;
                              name: string;
                              id: string;
                              checkInStartTime: string;
                              checkInEndTime: string;
                              checkOutTime: string;
                          };
                      }>;
                      optionalSurcharges: Array<{
                          __typename?: 'BookingCartItemSurcharge';
                          rentalUnitSurchargeId: string;
                          amount: number;
                      }>;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                  }
                | {
                      __typename?: 'Ticket';
                      startDateTime: string;
                      endDateTime: string;
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      ticketStatus: TicketStatus;
                      activity?: Maybe<{
                          __typename?: 'Activity';
                          id: string;
                          name: string;
                          slug: string;
                          descriptionParticulars?: Maybe<string>;
                          bookingType: ActivityBookingTypesEnum;
                          company?: Maybe<{
                              __typename?: 'Company';
                              id: string;
                              normalOperatingHours?: Maybe<{
                                  __typename?: 'NormalOperatingHours';
                                  monday: Array<{
                                      __typename?: 'TimeOpenClosed';
                                      timeOpen: string;
                                      timeClosed: string;
                                  }>;
                                  tuesday: Array<{
                                      __typename?: 'TimeOpenClosed';
                                      timeOpen: string;
                                      timeClosed: string;
                                  }>;
                                  wednesday: Array<{
                                      __typename?: 'TimeOpenClosed';
                                      timeOpen: string;
                                      timeClosed: string;
                                  }>;
                                  thursday: Array<{
                                      __typename?: 'TimeOpenClosed';
                                      timeOpen: string;
                                      timeClosed: string;
                                  }>;
                                  friday: Array<{
                                      __typename?: 'TimeOpenClosed';
                                      timeOpen: string;
                                      timeClosed: string;
                                  }>;
                                  saturday: Array<{
                                      __typename?: 'TimeOpenClosed';
                                      timeOpen: string;
                                      timeClosed: string;
                                  }>;
                                  sunday: Array<{
                                      __typename?: 'TimeOpenClosed';
                                      timeOpen: string;
                                      timeClosed: string;
                                  }>;
                              }>;
                              specialOperatingHours: Array<{
                                  __typename?: 'SpecialOperatingHours';
                                  startDate: string;
                                  endDate: string;
                                  monday?: Maybe<
                                      Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                  >;
                                  tuesday?: Maybe<
                                      Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                  >;
                                  wednesday?: Maybe<
                                      Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                  >;
                                  thursday?: Maybe<
                                      Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                  >;
                                  friday?: Maybe<
                                      Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                  >;
                                  saturday?: Maybe<
                                      Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                  >;
                                  sunday?: Maybe<
                                      Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                  >;
                              }>;
                          }>;
                      }>;
                      timeslot?: Maybe<{
                          __typename?: 'Timeslot';
                          id: string;
                          startDateTime: string;
                          endDateTime: string;
                          allotment?: Maybe<number>;
                          label?: Maybe<string>;
                          rateGroup?: Maybe<{
                              __typename?: 'ActivityRateGroup';
                              releaseHours?: Maybe<number>;
                              rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                          }>;
                      }>;
                      rateLines: Array<{
                          __typename?: 'TicketRateLine';
                          amount: number;
                          rateId?: Maybe<string>;
                          totalPrice: number;
                          unitPrice: number;
                      }>;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                  }
                | {
                      __typename?: 'UpsellPurchase';
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      amount: number;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
                  }
            >;
            customer?: Maybe<{
                __typename?: 'Customer';
                phoneNumber?: Maybe<string>;
                firstName?: Maybe<string>;
                lastName?: Maybe<string>;
                email?: Maybe<string>;
                birthdate?: Maybe<string>;
                address?: Maybe<{
                    __typename?: 'Address';
                    city?: Maybe<string>;
                    countryCode?: Maybe<string>;
                    number?: Maybe<string>;
                    postalCode?: Maybe<string>;
                    street?: Maybe<string>;
                }>;
            }>;
            paymentOptions?: Maybe<{
                __typename?: 'PaymentOptions';
                totalAmountDue: number;
                partialPaymentAmount: number;
            }>;
        };
    }>;
};

export type CheckoutDetailsMeQueryVariables = Exact<{ [key: string]: never }>;

export type CheckoutDetailsMeQuery = {
    __typename?: 'Query';
    viewer?: Maybe<{
        __typename?: 'Customer';
        id?: Maybe<string>;
        phoneNumber?: Maybe<string>;
        firstName?: Maybe<string>;
        lastName?: Maybe<string>;
        email?: Maybe<string>;
        birthdate?: Maybe<string>;
        address?: Maybe<{
            __typename?: 'Address';
            city?: Maybe<string>;
            countryCode?: Maybe<string>;
            number?: Maybe<string>;
            postalCode?: Maybe<string>;
            street?: Maybe<string>;
        }>;
    }>;
};

export type UpdateCartFromDetailsMutationVariables = Exact<{
    input: UpdateCartInput;
}>;

export type UpdateCartFromDetailsMutation = {
    __typename?: 'Mutation';
    updateCart: {
        __typename?: 'UpdateCartPayload';
        checkoutAllowed?: Maybe<boolean>;
        cart?: Maybe<{
            __typename?: 'Cart';
            id: string;
            status: CartStatusEnum;
            order: {
                __typename?: 'Order';
                isDeskOrder: boolean;
                agreedToTerms?: Maybe<boolean>;
                newsletterOptin?: Maybe<boolean>;
                number?: Maybe<string>;
                errors: Array<string>;
                totalPrice: number;
                orderItems: Array<
                    | {
                          __typename?: 'Booking';
                          hasCancellationInsurance: boolean;
                          hasContentsInsurance: boolean;
                          totalPrice: number;
                          paymentPrice: number;
                          errors: Array<string>;
                          status: BookingStatus;
                          amountAdults: number;
                          amountPets: number;
                          amountChildren: number;
                          amountBabies: number;
                          amountYouths: number;
                          arrivalDate: string;
                          departureDate: string;
                          handleDepositPayment: boolean;
                          deposit: number;
                          requiresApproval: boolean;
                          special?: Maybe<{ __typename?: 'Special'; id: string; name: string; description: string }>;
                          rentalUnit?: Maybe<{
                              __typename?: 'RentalUnit';
                              id: string;
                              slug: string;
                              name: string;
                              type: RentalUnitTypeEnum;
                              amountBedrooms?: Maybe<number>;
                              maxOccupancy: number;
                              minOccupancy: number;
                              flexCancellationDays?: Maybe<number>;
                              petsAllowed: number;
                              descriptionParticulars?: Maybe<string>;
                              contentsInsuranceAvailable: boolean;
                              cancellationInsuranceAvailable: boolean;
                              accommodation: {
                                  __typename?: 'Accommodation';
                                  hasPublicPage: boolean;
                                  name: string;
                                  id: string;
                                  checkInStartTime: string;
                                  checkInEndTime: string;
                                  checkOutTime: string;
                              };
                          }>;
                          optionalSurcharges: Array<{
                              __typename?: 'BookingCartItemSurcharge';
                              rentalUnitSurchargeId: string;
                              amount: number;
                          }>;
                          priceLines: Array<{
                              __typename?: 'PriceLine';
                              label: string;
                              quantity?: Maybe<string>;
                              totalPrice: number;
                              unitPrice?: Maybe<number>;
                              category?: Maybe<PriceLineCategoryEnum>;
                          }>;
                      }
                    | {
                          __typename?: 'Ticket';
                          startDateTime: string;
                          endDateTime: string;
                          totalPrice: number;
                          paymentPrice: number;
                          errors: Array<string>;
                          ticketStatus: TicketStatus;
                          activity?: Maybe<{
                              __typename?: 'Activity';
                              id: string;
                              name: string;
                              slug: string;
                              descriptionParticulars?: Maybe<string>;
                              bookingType: ActivityBookingTypesEnum;
                              company?: Maybe<{
                                  __typename?: 'Company';
                                  id: string;
                                  normalOperatingHours?: Maybe<{
                                      __typename?: 'NormalOperatingHours';
                                      monday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      tuesday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      wednesday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      thursday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      friday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      saturday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      sunday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                  }>;
                                  specialOperatingHours: Array<{
                                      __typename?: 'SpecialOperatingHours';
                                      startDate: string;
                                      endDate: string;
                                      monday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      tuesday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      wednesday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      thursday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      friday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      saturday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      sunday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                  }>;
                              }>;
                          }>;
                          timeslot?: Maybe<{
                              __typename?: 'Timeslot';
                              id: string;
                              startDateTime: string;
                              endDateTime: string;
                              allotment?: Maybe<number>;
                              label?: Maybe<string>;
                              rateGroup?: Maybe<{
                                  __typename?: 'ActivityRateGroup';
                                  releaseHours?: Maybe<number>;
                                  rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                              }>;
                          }>;
                          rateLines: Array<{
                              __typename?: 'TicketRateLine';
                              amount: number;
                              rateId?: Maybe<string>;
                              totalPrice: number;
                              unitPrice: number;
                          }>;
                          priceLines: Array<{
                              __typename?: 'PriceLine';
                              label: string;
                              quantity?: Maybe<string>;
                              totalPrice: number;
                              unitPrice?: Maybe<number>;
                              category?: Maybe<PriceLineCategoryEnum>;
                          }>;
                      }
                    | {
                          __typename?: 'UpsellPurchase';
                          totalPrice: number;
                          paymentPrice: number;
                          errors: Array<string>;
                          amount: number;
                          priceLines: Array<{
                              __typename?: 'PriceLine';
                              label: string;
                              quantity?: Maybe<string>;
                              totalPrice: number;
                              unitPrice?: Maybe<number>;
                              category?: Maybe<PriceLineCategoryEnum>;
                          }>;
                          offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
                      }
                >;
                customer?: Maybe<{
                    __typename?: 'Customer';
                    phoneNumber?: Maybe<string>;
                    firstName?: Maybe<string>;
                    lastName?: Maybe<string>;
                    email?: Maybe<string>;
                    birthdate?: Maybe<string>;
                    address?: Maybe<{
                        __typename?: 'Address';
                        city?: Maybe<string>;
                        countryCode?: Maybe<string>;
                        number?: Maybe<string>;
                        postalCode?: Maybe<string>;
                        street?: Maybe<string>;
                    }>;
                }>;
                paymentOptions?: Maybe<{
                    __typename?: 'PaymentOptions';
                    totalAmountDue: number;
                    partialPaymentAmount: number;
                }>;
            };
        }>;
    };
};

export type CheckoutDetailsFragment = {
    __typename?: 'Cart';
    id: string;
    status: CartStatusEnum;
    order: {
        __typename?: 'Order';
        isDeskOrder: boolean;
        agreedToTerms?: Maybe<boolean>;
        newsletterOptin?: Maybe<boolean>;
        number?: Maybe<string>;
        errors: Array<string>;
        totalPrice: number;
        orderItems: Array<
            | {
                  __typename?: 'Booking';
                  hasCancellationInsurance: boolean;
                  hasContentsInsurance: boolean;
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  status: BookingStatus;
                  amountAdults: number;
                  amountPets: number;
                  amountChildren: number;
                  amountBabies: number;
                  amountYouths: number;
                  arrivalDate: string;
                  departureDate: string;
                  handleDepositPayment: boolean;
                  deposit: number;
                  requiresApproval: boolean;
                  special?: Maybe<{ __typename?: 'Special'; id: string; name: string; description: string }>;
                  rentalUnit?: Maybe<{
                      __typename?: 'RentalUnit';
                      id: string;
                      slug: string;
                      name: string;
                      type: RentalUnitTypeEnum;
                      amountBedrooms?: Maybe<number>;
                      maxOccupancy: number;
                      minOccupancy: number;
                      flexCancellationDays?: Maybe<number>;
                      petsAllowed: number;
                      descriptionParticulars?: Maybe<string>;
                      contentsInsuranceAvailable: boolean;
                      cancellationInsuranceAvailable: boolean;
                      accommodation: {
                          __typename?: 'Accommodation';
                          hasPublicPage: boolean;
                          name: string;
                          id: string;
                          checkInStartTime: string;
                          checkInEndTime: string;
                          checkOutTime: string;
                      };
                  }>;
                  optionalSurcharges: Array<{
                      __typename?: 'BookingCartItemSurcharge';
                      rentalUnitSurchargeId: string;
                      amount: number;
                  }>;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
              }
            | {
                  __typename?: 'Ticket';
                  startDateTime: string;
                  endDateTime: string;
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  ticketStatus: TicketStatus;
                  activity?: Maybe<{
                      __typename?: 'Activity';
                      id: string;
                      name: string;
                      slug: string;
                      descriptionParticulars?: Maybe<string>;
                      bookingType: ActivityBookingTypesEnum;
                      company?: Maybe<{
                          __typename?: 'Company';
                          id: string;
                          normalOperatingHours?: Maybe<{
                              __typename?: 'NormalOperatingHours';
                              monday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                              tuesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                              wednesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                              thursday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                              friday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                              saturday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                              sunday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                          }>;
                          specialOperatingHours: Array<{
                              __typename?: 'SpecialOperatingHours';
                              startDate: string;
                              endDate: string;
                              monday?: Maybe<
                                  Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                              >;
                              tuesday?: Maybe<
                                  Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                              >;
                              wednesday?: Maybe<
                                  Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                              >;
                              thursday?: Maybe<
                                  Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                              >;
                              friday?: Maybe<
                                  Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                              >;
                              saturday?: Maybe<
                                  Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                              >;
                              sunday?: Maybe<
                                  Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                              >;
                          }>;
                      }>;
                  }>;
                  timeslot?: Maybe<{
                      __typename?: 'Timeslot';
                      id: string;
                      startDateTime: string;
                      endDateTime: string;
                      allotment?: Maybe<number>;
                      label?: Maybe<string>;
                      rateGroup?: Maybe<{
                          __typename?: 'ActivityRateGroup';
                          releaseHours?: Maybe<number>;
                          rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                      }>;
                  }>;
                  rateLines: Array<{
                      __typename?: 'TicketRateLine';
                      amount: number;
                      rateId?: Maybe<string>;
                      totalPrice: number;
                      unitPrice: number;
                  }>;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
              }
            | {
                  __typename?: 'UpsellPurchase';
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  amount: number;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
              }
        >;
        customer?: Maybe<{
            __typename?: 'Customer';
            phoneNumber?: Maybe<string>;
            firstName?: Maybe<string>;
            lastName?: Maybe<string>;
            email?: Maybe<string>;
            birthdate?: Maybe<string>;
            address?: Maybe<{
                __typename?: 'Address';
                city?: Maybe<string>;
                countryCode?: Maybe<string>;
                number?: Maybe<string>;
                postalCode?: Maybe<string>;
                street?: Maybe<string>;
            }>;
        }>;
        paymentOptions?: Maybe<{ __typename?: 'PaymentOptions'; totalAmountDue: number; partialPaymentAmount: number }>;
    };
};

export type CheckoutExtrasQueryVariables = Exact<{
    cartId: Scalars['ID'];
}>;

export type CheckoutExtrasQuery = {
    __typename?: 'Query';
    cart?: Maybe<{
        __typename?: 'Cart';
        id: string;
        status: CartStatusEnum;
        availableUpsellOffers: Array<{
            __typename?: 'UpsellOffer';
            id: string;
            name: string;
            unitPrice: number;
            maxAmount: number;
            description?: Maybe<string>;
        }>;
        order: {
            __typename?: 'Order';
            number?: Maybe<string>;
            errors: Array<string>;
            totalPrice: number;
            orderItems: Array<
                | {
                      __typename?: 'Booking';
                      hasCancellationInsurance: boolean;
                      hasContentsInsurance: boolean;
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      status: BookingStatus;
                      amountAdults: number;
                      amountPets: number;
                      amountChildren: number;
                      amountBabies: number;
                      amountYouths: number;
                      arrivalDate: string;
                      departureDate: string;
                      handleDepositPayment: boolean;
                      deposit: number;
                      requiresApproval: boolean;
                      special?: Maybe<{ __typename?: 'Special'; id: string; name: string; description: string }>;
                      rentalUnit?: Maybe<{
                          __typename?: 'RentalUnit';
                          id: string;
                          slug: string;
                          name: string;
                          type: RentalUnitTypeEnum;
                          amountBedrooms?: Maybe<number>;
                          maxOccupancy: number;
                          minOccupancy: number;
                          flexCancellationDays?: Maybe<number>;
                          petsAllowed: number;
                          descriptionParticulars?: Maybe<string>;
                          contentsInsuranceAvailable: boolean;
                          cancellationInsuranceAvailable: boolean;
                          accommodation: {
                              __typename?: 'Accommodation';
                              hasPublicPage: boolean;
                              name: string;
                              id: string;
                              checkInStartTime: string;
                              checkInEndTime: string;
                              checkOutTime: string;
                          };
                      }>;
                      optionalSurcharges: Array<{
                          __typename?: 'BookingCartItemSurcharge';
                          rentalUnitSurchargeId: string;
                          amount: number;
                      }>;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                  }
                | {
                      __typename?: 'Ticket';
                      startDateTime: string;
                      endDateTime: string;
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      ticketStatus: TicketStatus;
                      activity?: Maybe<{
                          __typename?: 'Activity';
                          id: string;
                          name: string;
                          slug: string;
                          descriptionParticulars?: Maybe<string>;
                          bookingType: ActivityBookingTypesEnum;
                          company?: Maybe<{
                              __typename?: 'Company';
                              id: string;
                              normalOperatingHours?: Maybe<{
                                  __typename?: 'NormalOperatingHours';
                                  monday: Array<{
                                      __typename?: 'TimeOpenClosed';
                                      timeOpen: string;
                                      timeClosed: string;
                                  }>;
                                  tuesday: Array<{
                                      __typename?: 'TimeOpenClosed';
                                      timeOpen: string;
                                      timeClosed: string;
                                  }>;
                                  wednesday: Array<{
                                      __typename?: 'TimeOpenClosed';
                                      timeOpen: string;
                                      timeClosed: string;
                                  }>;
                                  thursday: Array<{
                                      __typename?: 'TimeOpenClosed';
                                      timeOpen: string;
                                      timeClosed: string;
                                  }>;
                                  friday: Array<{
                                      __typename?: 'TimeOpenClosed';
                                      timeOpen: string;
                                      timeClosed: string;
                                  }>;
                                  saturday: Array<{
                                      __typename?: 'TimeOpenClosed';
                                      timeOpen: string;
                                      timeClosed: string;
                                  }>;
                                  sunday: Array<{
                                      __typename?: 'TimeOpenClosed';
                                      timeOpen: string;
                                      timeClosed: string;
                                  }>;
                              }>;
                              specialOperatingHours: Array<{
                                  __typename?: 'SpecialOperatingHours';
                                  startDate: string;
                                  endDate: string;
                                  monday?: Maybe<
                                      Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                  >;
                                  tuesday?: Maybe<
                                      Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                  >;
                                  wednesday?: Maybe<
                                      Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                  >;
                                  thursday?: Maybe<
                                      Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                  >;
                                  friday?: Maybe<
                                      Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                  >;
                                  saturday?: Maybe<
                                      Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                  >;
                                  sunday?: Maybe<
                                      Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                  >;
                              }>;
                          }>;
                      }>;
                      timeslot?: Maybe<{
                          __typename?: 'Timeslot';
                          id: string;
                          startDateTime: string;
                          endDateTime: string;
                          allotment?: Maybe<number>;
                          label?: Maybe<string>;
                          rateGroup?: Maybe<{
                              __typename?: 'ActivityRateGroup';
                              releaseHours?: Maybe<number>;
                              rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                          }>;
                      }>;
                      rateLines: Array<{
                          __typename?: 'TicketRateLine';
                          amount: number;
                          rateId?: Maybe<string>;
                          totalPrice: number;
                          unitPrice: number;
                      }>;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                  }
                | {
                      __typename?: 'UpsellPurchase';
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      amount: number;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
                  }
            >;
            paymentOptions?: Maybe<{
                __typename?: 'PaymentOptions';
                totalAmountDue: number;
                partialPaymentAmount: number;
            }>;
        };
    }>;
};

export type SetCartExtrasMutationVariables = Exact<{
    input: UpdateCartInput;
}>;

export type SetCartExtrasMutation = {
    __typename?: 'Mutation';
    updateCart: {
        __typename?: 'UpdateCartPayload';
        cart?: Maybe<{
            __typename?: 'Cart';
            id: string;
            status: CartStatusEnum;
            availableUpsellOffers: Array<{
                __typename?: 'UpsellOffer';
                id: string;
                name: string;
                unitPrice: number;
                maxAmount: number;
                description?: Maybe<string>;
            }>;
            order: {
                __typename?: 'Order';
                number?: Maybe<string>;
                errors: Array<string>;
                totalPrice: number;
                orderItems: Array<
                    | {
                          __typename?: 'Booking';
                          hasCancellationInsurance: boolean;
                          hasContentsInsurance: boolean;
                          totalPrice: number;
                          paymentPrice: number;
                          errors: Array<string>;
                          status: BookingStatus;
                          amountAdults: number;
                          amountPets: number;
                          amountChildren: number;
                          amountBabies: number;
                          amountYouths: number;
                          arrivalDate: string;
                          departureDate: string;
                          handleDepositPayment: boolean;
                          deposit: number;
                          requiresApproval: boolean;
                          special?: Maybe<{ __typename?: 'Special'; id: string; name: string; description: string }>;
                          rentalUnit?: Maybe<{
                              __typename?: 'RentalUnit';
                              id: string;
                              slug: string;
                              name: string;
                              type: RentalUnitTypeEnum;
                              amountBedrooms?: Maybe<number>;
                              maxOccupancy: number;
                              minOccupancy: number;
                              flexCancellationDays?: Maybe<number>;
                              petsAllowed: number;
                              descriptionParticulars?: Maybe<string>;
                              contentsInsuranceAvailable: boolean;
                              cancellationInsuranceAvailable: boolean;
                              accommodation: {
                                  __typename?: 'Accommodation';
                                  hasPublicPage: boolean;
                                  name: string;
                                  id: string;
                                  checkInStartTime: string;
                                  checkInEndTime: string;
                                  checkOutTime: string;
                              };
                          }>;
                          optionalSurcharges: Array<{
                              __typename?: 'BookingCartItemSurcharge';
                              rentalUnitSurchargeId: string;
                              amount: number;
                          }>;
                          priceLines: Array<{
                              __typename?: 'PriceLine';
                              label: string;
                              quantity?: Maybe<string>;
                              totalPrice: number;
                              unitPrice?: Maybe<number>;
                              category?: Maybe<PriceLineCategoryEnum>;
                          }>;
                      }
                    | {
                          __typename?: 'Ticket';
                          startDateTime: string;
                          endDateTime: string;
                          totalPrice: number;
                          paymentPrice: number;
                          errors: Array<string>;
                          ticketStatus: TicketStatus;
                          activity?: Maybe<{
                              __typename?: 'Activity';
                              id: string;
                              name: string;
                              slug: string;
                              descriptionParticulars?: Maybe<string>;
                              bookingType: ActivityBookingTypesEnum;
                              company?: Maybe<{
                                  __typename?: 'Company';
                                  id: string;
                                  normalOperatingHours?: Maybe<{
                                      __typename?: 'NormalOperatingHours';
                                      monday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      tuesday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      wednesday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      thursday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      friday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      saturday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      sunday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                  }>;
                                  specialOperatingHours: Array<{
                                      __typename?: 'SpecialOperatingHours';
                                      startDate: string;
                                      endDate: string;
                                      monday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      tuesday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      wednesday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      thursday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      friday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      saturday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      sunday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                  }>;
                              }>;
                          }>;
                          timeslot?: Maybe<{
                              __typename?: 'Timeslot';
                              id: string;
                              startDateTime: string;
                              endDateTime: string;
                              allotment?: Maybe<number>;
                              label?: Maybe<string>;
                              rateGroup?: Maybe<{
                                  __typename?: 'ActivityRateGroup';
                                  releaseHours?: Maybe<number>;
                                  rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                              }>;
                          }>;
                          rateLines: Array<{
                              __typename?: 'TicketRateLine';
                              amount: number;
                              rateId?: Maybe<string>;
                              totalPrice: number;
                              unitPrice: number;
                          }>;
                          priceLines: Array<{
                              __typename?: 'PriceLine';
                              label: string;
                              quantity?: Maybe<string>;
                              totalPrice: number;
                              unitPrice?: Maybe<number>;
                              category?: Maybe<PriceLineCategoryEnum>;
                          }>;
                      }
                    | {
                          __typename?: 'UpsellPurchase';
                          totalPrice: number;
                          paymentPrice: number;
                          errors: Array<string>;
                          amount: number;
                          priceLines: Array<{
                              __typename?: 'PriceLine';
                              label: string;
                              quantity?: Maybe<string>;
                              totalPrice: number;
                              unitPrice?: Maybe<number>;
                              category?: Maybe<PriceLineCategoryEnum>;
                          }>;
                          offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
                      }
                >;
                paymentOptions?: Maybe<{
                    __typename?: 'PaymentOptions';
                    totalAmountDue: number;
                    partialPaymentAmount: number;
                }>;
            };
        }>;
    };
};

export type UpdateCartMutationVariables = Exact<{
    input: UpdateCartInput;
}>;

export type UpdateCartMutation = {
    __typename?: 'Mutation';
    updateCart: {
        __typename?: 'UpdateCartPayload';
        cart?: Maybe<{
            __typename?: 'Cart';
            id: string;
            status: CartStatusEnum;
            availableUpsellOffers: Array<{
                __typename?: 'UpsellOffer';
                id: string;
                name: string;
                unitPrice: number;
                maxAmount: number;
                description?: Maybe<string>;
            }>;
            order: {
                __typename?: 'Order';
                number?: Maybe<string>;
                errors: Array<string>;
                totalPrice: number;
                orderItems: Array<
                    | {
                          __typename?: 'Booking';
                          hasCancellationInsurance: boolean;
                          hasContentsInsurance: boolean;
                          totalPrice: number;
                          paymentPrice: number;
                          errors: Array<string>;
                          status: BookingStatus;
                          amountAdults: number;
                          amountPets: number;
                          amountChildren: number;
                          amountBabies: number;
                          amountYouths: number;
                          arrivalDate: string;
                          departureDate: string;
                          handleDepositPayment: boolean;
                          deposit: number;
                          requiresApproval: boolean;
                          special?: Maybe<{ __typename?: 'Special'; id: string; name: string; description: string }>;
                          rentalUnit?: Maybe<{
                              __typename?: 'RentalUnit';
                              id: string;
                              slug: string;
                              name: string;
                              type: RentalUnitTypeEnum;
                              amountBedrooms?: Maybe<number>;
                              maxOccupancy: number;
                              minOccupancy: number;
                              flexCancellationDays?: Maybe<number>;
                              petsAllowed: number;
                              descriptionParticulars?: Maybe<string>;
                              contentsInsuranceAvailable: boolean;
                              cancellationInsuranceAvailable: boolean;
                              accommodation: {
                                  __typename?: 'Accommodation';
                                  hasPublicPage: boolean;
                                  name: string;
                                  id: string;
                                  checkInStartTime: string;
                                  checkInEndTime: string;
                                  checkOutTime: string;
                              };
                          }>;
                          optionalSurcharges: Array<{
                              __typename?: 'BookingCartItemSurcharge';
                              rentalUnitSurchargeId: string;
                              amount: number;
                          }>;
                          priceLines: Array<{
                              __typename?: 'PriceLine';
                              label: string;
                              quantity?: Maybe<string>;
                              totalPrice: number;
                              unitPrice?: Maybe<number>;
                              category?: Maybe<PriceLineCategoryEnum>;
                          }>;
                      }
                    | {
                          __typename?: 'Ticket';
                          startDateTime: string;
                          endDateTime: string;
                          totalPrice: number;
                          paymentPrice: number;
                          errors: Array<string>;
                          ticketStatus: TicketStatus;
                          activity?: Maybe<{
                              __typename?: 'Activity';
                              id: string;
                              name: string;
                              slug: string;
                              descriptionParticulars?: Maybe<string>;
                              bookingType: ActivityBookingTypesEnum;
                              company?: Maybe<{
                                  __typename?: 'Company';
                                  id: string;
                                  normalOperatingHours?: Maybe<{
                                      __typename?: 'NormalOperatingHours';
                                      monday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      tuesday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      wednesday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      thursday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      friday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      saturday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                      sunday: Array<{
                                          __typename?: 'TimeOpenClosed';
                                          timeOpen: string;
                                          timeClosed: string;
                                      }>;
                                  }>;
                                  specialOperatingHours: Array<{
                                      __typename?: 'SpecialOperatingHours';
                                      startDate: string;
                                      endDate: string;
                                      monday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      tuesday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      wednesday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      thursday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      friday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      saturday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                      sunday?: Maybe<
                                          Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                                      >;
                                  }>;
                              }>;
                          }>;
                          timeslot?: Maybe<{
                              __typename?: 'Timeslot';
                              id: string;
                              startDateTime: string;
                              endDateTime: string;
                              allotment?: Maybe<number>;
                              label?: Maybe<string>;
                              rateGroup?: Maybe<{
                                  __typename?: 'ActivityRateGroup';
                                  releaseHours?: Maybe<number>;
                                  rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                              }>;
                          }>;
                          rateLines: Array<{
                              __typename?: 'TicketRateLine';
                              amount: number;
                              rateId?: Maybe<string>;
                              totalPrice: number;
                              unitPrice: number;
                          }>;
                          priceLines: Array<{
                              __typename?: 'PriceLine';
                              label: string;
                              quantity?: Maybe<string>;
                              totalPrice: number;
                              unitPrice?: Maybe<number>;
                              category?: Maybe<PriceLineCategoryEnum>;
                          }>;
                      }
                    | {
                          __typename?: 'UpsellPurchase';
                          totalPrice: number;
                          paymentPrice: number;
                          errors: Array<string>;
                          amount: number;
                          priceLines: Array<{
                              __typename?: 'PriceLine';
                              label: string;
                              quantity?: Maybe<string>;
                              totalPrice: number;
                              unitPrice?: Maybe<number>;
                              category?: Maybe<PriceLineCategoryEnum>;
                          }>;
                          offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
                      }
                >;
                paymentOptions?: Maybe<{
                    __typename?: 'PaymentOptions';
                    totalAmountDue: number;
                    partialPaymentAmount: number;
                }>;
            };
        }>;
    };
};

export type CheckoutExtrasFragment = {
    __typename?: 'Cart';
    id: string;
    status: CartStatusEnum;
    availableUpsellOffers: Array<{
        __typename?: 'UpsellOffer';
        id: string;
        name: string;
        unitPrice: number;
        maxAmount: number;
        description?: Maybe<string>;
    }>;
    order: {
        __typename?: 'Order';
        number?: Maybe<string>;
        errors: Array<string>;
        totalPrice: number;
        orderItems: Array<
            | {
                  __typename?: 'Booking';
                  hasCancellationInsurance: boolean;
                  hasContentsInsurance: boolean;
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  status: BookingStatus;
                  amountAdults: number;
                  amountPets: number;
                  amountChildren: number;
                  amountBabies: number;
                  amountYouths: number;
                  arrivalDate: string;
                  departureDate: string;
                  handleDepositPayment: boolean;
                  deposit: number;
                  requiresApproval: boolean;
                  special?: Maybe<{ __typename?: 'Special'; id: string; name: string; description: string }>;
                  rentalUnit?: Maybe<{
                      __typename?: 'RentalUnit';
                      id: string;
                      slug: string;
                      name: string;
                      type: RentalUnitTypeEnum;
                      amountBedrooms?: Maybe<number>;
                      maxOccupancy: number;
                      minOccupancy: number;
                      flexCancellationDays?: Maybe<number>;
                      petsAllowed: number;
                      descriptionParticulars?: Maybe<string>;
                      contentsInsuranceAvailable: boolean;
                      cancellationInsuranceAvailable: boolean;
                      accommodation: {
                          __typename?: 'Accommodation';
                          hasPublicPage: boolean;
                          name: string;
                          id: string;
                          checkInStartTime: string;
                          checkInEndTime: string;
                          checkOutTime: string;
                      };
                  }>;
                  optionalSurcharges: Array<{
                      __typename?: 'BookingCartItemSurcharge';
                      rentalUnitSurchargeId: string;
                      amount: number;
                  }>;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
              }
            | {
                  __typename?: 'Ticket';
                  startDateTime: string;
                  endDateTime: string;
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  ticketStatus: TicketStatus;
                  activity?: Maybe<{
                      __typename?: 'Activity';
                      id: string;
                      name: string;
                      slug: string;
                      descriptionParticulars?: Maybe<string>;
                      bookingType: ActivityBookingTypesEnum;
                      company?: Maybe<{
                          __typename?: 'Company';
                          id: string;
                          normalOperatingHours?: Maybe<{
                              __typename?: 'NormalOperatingHours';
                              monday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                              tuesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                              wednesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                              thursday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                              friday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                              saturday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                              sunday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                          }>;
                          specialOperatingHours: Array<{
                              __typename?: 'SpecialOperatingHours';
                              startDate: string;
                              endDate: string;
                              monday?: Maybe<
                                  Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                              >;
                              tuesday?: Maybe<
                                  Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                              >;
                              wednesday?: Maybe<
                                  Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                              >;
                              thursday?: Maybe<
                                  Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                              >;
                              friday?: Maybe<
                                  Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                              >;
                              saturday?: Maybe<
                                  Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                              >;
                              sunday?: Maybe<
                                  Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>
                              >;
                          }>;
                      }>;
                  }>;
                  timeslot?: Maybe<{
                      __typename?: 'Timeslot';
                      id: string;
                      startDateTime: string;
                      endDateTime: string;
                      allotment?: Maybe<number>;
                      label?: Maybe<string>;
                      rateGroup?: Maybe<{
                          __typename?: 'ActivityRateGroup';
                          releaseHours?: Maybe<number>;
                          rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                      }>;
                  }>;
                  rateLines: Array<{
                      __typename?: 'TicketRateLine';
                      amount: number;
                      rateId?: Maybe<string>;
                      totalPrice: number;
                      unitPrice: number;
                  }>;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
              }
            | {
                  __typename?: 'UpsellPurchase';
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  amount: number;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
              }
        >;
        paymentOptions?: Maybe<{ __typename?: 'PaymentOptions'; totalAmountDue: number; partialPaymentAmount: number }>;
    };
};

export type CheckoutExtrasOrderItem_Booking_Fragment = {
    __typename?: 'Booking';
    hasCancellationInsurance: boolean;
    hasContentsInsurance: boolean;
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    status: BookingStatus;
    amountAdults: number;
    amountPets: number;
    amountChildren: number;
    amountBabies: number;
    amountYouths: number;
    arrivalDate: string;
    departureDate: string;
    handleDepositPayment: boolean;
    deposit: number;
    requiresApproval: boolean;
    special?: Maybe<{ __typename?: 'Special'; id: string; name: string; description: string }>;
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        slug: string;
        name: string;
        type: RentalUnitTypeEnum;
        amountBedrooms?: Maybe<number>;
        maxOccupancy: number;
        minOccupancy: number;
        flexCancellationDays?: Maybe<number>;
        petsAllowed: number;
        descriptionParticulars?: Maybe<string>;
        contentsInsuranceAvailable: boolean;
        cancellationInsuranceAvailable: boolean;
        accommodation: {
            __typename?: 'Accommodation';
            hasPublicPage: boolean;
            name: string;
            id: string;
            checkInStartTime: string;
            checkInEndTime: string;
            checkOutTime: string;
        };
    }>;
    optionalSurcharges: Array<{
        __typename?: 'BookingCartItemSurcharge';
        rentalUnitSurchargeId: string;
        amount: number;
    }>;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
};

export type CheckoutExtrasOrderItem_Ticket_Fragment = {
    __typename?: 'Ticket';
    startDateTime: string;
    endDateTime: string;
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    ticketStatus: TicketStatus;
    activity?: Maybe<{
        __typename?: 'Activity';
        id: string;
        name: string;
        slug: string;
        descriptionParticulars?: Maybe<string>;
        bookingType: ActivityBookingTypesEnum;
        company?: Maybe<{
            __typename?: 'Company';
            id: string;
            normalOperatingHours?: Maybe<{
                __typename?: 'NormalOperatingHours';
                monday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                tuesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                wednesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                thursday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                friday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                saturday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                sunday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
            }>;
            specialOperatingHours: Array<{
                __typename?: 'SpecialOperatingHours';
                startDate: string;
                endDate: string;
                monday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                tuesday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                wednesday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                thursday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                friday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                saturday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                sunday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
            }>;
        }>;
    }>;
    timeslot?: Maybe<{
        __typename?: 'Timeslot';
        id: string;
        startDateTime: string;
        endDateTime: string;
        allotment?: Maybe<number>;
        label?: Maybe<string>;
        rateGroup?: Maybe<{
            __typename?: 'ActivityRateGroup';
            releaseHours?: Maybe<number>;
            rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
        }>;
    }>;
    rateLines: Array<{
        __typename?: 'TicketRateLine';
        amount: number;
        rateId?: Maybe<string>;
        totalPrice: number;
        unitPrice: number;
    }>;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
};

export type CheckoutExtrasOrderItem_UpsellPurchase_Fragment = {
    __typename?: 'UpsellPurchase';
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    amount: number;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
};

export type CheckoutExtrasOrderItemFragment =
    | CheckoutExtrasOrderItem_Booking_Fragment
    | CheckoutExtrasOrderItem_Ticket_Fragment
    | CheckoutExtrasOrderItem_UpsellPurchase_Fragment;

export type CheckoutPaymentQueryVariables = Exact<{
    cartId: Scalars['ID'];
}>;

export type CheckoutPaymentQuery = {
    __typename?: 'Query';
    cart?: Maybe<{
        __typename?: 'Cart';
        id: string;
        status: CartStatusEnum;
        order: {
            __typename?: 'Order';
            number?: Maybe<string>;
            isDeskOrder: boolean;
            errors: Array<string>;
            totalPrice: number;
            agreedToTerms?: Maybe<boolean>;
            newsletterOptin?: Maybe<boolean>;
            orderItems: Array<
                | {
                      __typename?: 'Booking';
                      arrivalDate: string;
                      departureDate: string;
                      hasCancellationInsurance: boolean;
                      hasContentsInsurance: boolean;
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      status: BookingStatus;
                      amountAdults: number;
                      amountPets: number;
                      amountChildren: number;
                      amountBabies: number;
                      amountYouths: number;
                      handleDepositPayment: boolean;
                      deposit: number;
                      requiresApproval: boolean;
                      optionalSurcharges: Array<{
                          __typename?: 'BookingCartItemSurcharge';
                          amount: number;
                          rentalUnitSurchargeId: string;
                      }>;
                      rentalUnit?: Maybe<{
                          __typename?: 'RentalUnit';
                          id: string;
                          name: string;
                          code: string;
                          type: RentalUnitTypeEnum;
                          accommodation: {
                              __typename?: 'Accommodation';
                              id: string;
                              name: string;
                              type: AccommodationTypeEnum;
                              checkInEndTime: string;
                              checkInStartTime: string;
                              checkOutTime: string;
                          };
                      }>;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      special?: Maybe<{ __typename?: 'Special'; id: string }>;
                  }
                | {
                      __typename?: 'Ticket';
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      startDateTime: string;
                      ticketStatus: TicketStatus;
                      timeslot?: Maybe<{
                          __typename?: 'Timeslot';
                          id: string;
                          startDateTime: string;
                          endDateTime: string;
                          allotment?: Maybe<number>;
                          label?: Maybe<string>;
                          rateGroup?: Maybe<{
                              __typename?: 'ActivityRateGroup';
                              releaseHours?: Maybe<number>;
                              rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                          }>;
                      }>;
                      activity?: Maybe<{ __typename?: 'Activity'; id: string; name: string; slug: string }>;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      rateLines: Array<{
                          __typename?: 'TicketRateLine';
                          amount: number;
                          rateId?: Maybe<string>;
                          totalPrice: number;
                          unitPrice: number;
                      }>;
                  }
                | {
                      __typename?: 'UpsellPurchase';
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      amount: number;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
                  }
            >;
            paymentOptions?: Maybe<{
                __typename?: 'PaymentOptions';
                availableMethods: Array<PaymentMethodEnum>;
                fullPaymentDueDate?: Maybe<string>;
                partialPaymentAmount: number;
                totalAmountDue: number;
            }>;
            customer?: Maybe<{
                __typename?: 'Customer';
                phoneNumber?: Maybe<string>;
                firstName?: Maybe<string>;
                lastName?: Maybe<string>;
                email?: Maybe<string>;
                birthdate?: Maybe<string>;
                address?: Maybe<{
                    __typename?: 'Address';
                    city?: Maybe<string>;
                    countryCode?: Maybe<string>;
                    number?: Maybe<string>;
                    postalCode?: Maybe<string>;
                    street?: Maybe<string>;
                }>;
            }>;
        };
    }>;
};

export type CheckoutCartMutationVariables = Exact<{
    input: CheckoutCartInput;
}>;

export type CheckoutCartMutation = {
    __typename?: 'Mutation';
    checkoutCart: {
        __typename?: 'CheckoutCartPayload';
        success?: Maybe<boolean>;
        redirectUrl?: Maybe<string>;
        cart?: Maybe<{
            __typename?: 'Cart';
            id: string;
            status: CartStatusEnum;
            order: {
                __typename?: 'Order';
                number?: Maybe<string>;
                isDeskOrder: boolean;
                errors: Array<string>;
                totalPrice: number;
                agreedToTerms?: Maybe<boolean>;
                newsletterOptin?: Maybe<boolean>;
                orderItems: Array<
                    | {
                          __typename?: 'Booking';
                          arrivalDate: string;
                          departureDate: string;
                          hasCancellationInsurance: boolean;
                          hasContentsInsurance: boolean;
                          totalPrice: number;
                          paymentPrice: number;
                          errors: Array<string>;
                          status: BookingStatus;
                          amountAdults: number;
                          amountPets: number;
                          amountChildren: number;
                          amountBabies: number;
                          amountYouths: number;
                          handleDepositPayment: boolean;
                          deposit: number;
                          requiresApproval: boolean;
                          optionalSurcharges: Array<{
                              __typename?: 'BookingCartItemSurcharge';
                              amount: number;
                              rentalUnitSurchargeId: string;
                          }>;
                          rentalUnit?: Maybe<{
                              __typename?: 'RentalUnit';
                              id: string;
                              name: string;
                              code: string;
                              type: RentalUnitTypeEnum;
                              accommodation: {
                                  __typename?: 'Accommodation';
                                  id: string;
                                  name: string;
                                  type: AccommodationTypeEnum;
                                  checkInEndTime: string;
                                  checkInStartTime: string;
                                  checkOutTime: string;
                              };
                          }>;
                          priceLines: Array<{
                              __typename?: 'PriceLine';
                              label: string;
                              quantity?: Maybe<string>;
                              totalPrice: number;
                              unitPrice?: Maybe<number>;
                              category?: Maybe<PriceLineCategoryEnum>;
                          }>;
                          special?: Maybe<{ __typename?: 'Special'; id: string }>;
                      }
                    | {
                          __typename?: 'Ticket';
                          totalPrice: number;
                          paymentPrice: number;
                          errors: Array<string>;
                          startDateTime: string;
                          ticketStatus: TicketStatus;
                          timeslot?: Maybe<{
                              __typename?: 'Timeslot';
                              id: string;
                              startDateTime: string;
                              endDateTime: string;
                              allotment?: Maybe<number>;
                              label?: Maybe<string>;
                              rateGroup?: Maybe<{
                                  __typename?: 'ActivityRateGroup';
                                  releaseHours?: Maybe<number>;
                                  rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                              }>;
                          }>;
                          activity?: Maybe<{ __typename?: 'Activity'; id: string; name: string; slug: string }>;
                          priceLines: Array<{
                              __typename?: 'PriceLine';
                              label: string;
                              quantity?: Maybe<string>;
                              totalPrice: number;
                              unitPrice?: Maybe<number>;
                              category?: Maybe<PriceLineCategoryEnum>;
                          }>;
                          rateLines: Array<{
                              __typename?: 'TicketRateLine';
                              amount: number;
                              rateId?: Maybe<string>;
                              totalPrice: number;
                              unitPrice: number;
                          }>;
                      }
                    | {
                          __typename?: 'UpsellPurchase';
                          totalPrice: number;
                          paymentPrice: number;
                          errors: Array<string>;
                          amount: number;
                          priceLines: Array<{
                              __typename?: 'PriceLine';
                              label: string;
                              quantity?: Maybe<string>;
                              totalPrice: number;
                              unitPrice?: Maybe<number>;
                              category?: Maybe<PriceLineCategoryEnum>;
                          }>;
                          offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
                      }
                >;
                paymentOptions?: Maybe<{
                    __typename?: 'PaymentOptions';
                    availableMethods: Array<PaymentMethodEnum>;
                    fullPaymentDueDate?: Maybe<string>;
                    partialPaymentAmount: number;
                    totalAmountDue: number;
                }>;
                customer?: Maybe<{
                    __typename?: 'Customer';
                    phoneNumber?: Maybe<string>;
                    firstName?: Maybe<string>;
                    lastName?: Maybe<string>;
                    email?: Maybe<string>;
                    birthdate?: Maybe<string>;
                    address?: Maybe<{
                        __typename?: 'Address';
                        city?: Maybe<string>;
                        countryCode?: Maybe<string>;
                        number?: Maybe<string>;
                        postalCode?: Maybe<string>;
                        street?: Maybe<string>;
                    }>;
                }>;
            };
        }>;
    };
};

export type CheckoutPaymentFragment = {
    __typename?: 'Cart';
    id: string;
    status: CartStatusEnum;
    order: {
        __typename?: 'Order';
        number?: Maybe<string>;
        isDeskOrder: boolean;
        errors: Array<string>;
        totalPrice: number;
        agreedToTerms?: Maybe<boolean>;
        newsletterOptin?: Maybe<boolean>;
        orderItems: Array<
            | {
                  __typename?: 'Booking';
                  arrivalDate: string;
                  departureDate: string;
                  hasCancellationInsurance: boolean;
                  hasContentsInsurance: boolean;
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  status: BookingStatus;
                  amountAdults: number;
                  amountPets: number;
                  amountChildren: number;
                  amountBabies: number;
                  amountYouths: number;
                  handleDepositPayment: boolean;
                  deposit: number;
                  requiresApproval: boolean;
                  optionalSurcharges: Array<{
                      __typename?: 'BookingCartItemSurcharge';
                      amount: number;
                      rentalUnitSurchargeId: string;
                  }>;
                  rentalUnit?: Maybe<{
                      __typename?: 'RentalUnit';
                      id: string;
                      name: string;
                      code: string;
                      type: RentalUnitTypeEnum;
                      accommodation: {
                          __typename?: 'Accommodation';
                          id: string;
                          name: string;
                          type: AccommodationTypeEnum;
                          checkInEndTime: string;
                          checkInStartTime: string;
                          checkOutTime: string;
                      };
                  }>;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  special?: Maybe<{ __typename?: 'Special'; id: string }>;
              }
            | {
                  __typename?: 'Ticket';
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  startDateTime: string;
                  ticketStatus: TicketStatus;
                  timeslot?: Maybe<{
                      __typename?: 'Timeslot';
                      id: string;
                      startDateTime: string;
                      endDateTime: string;
                      allotment?: Maybe<number>;
                      label?: Maybe<string>;
                      rateGroup?: Maybe<{
                          __typename?: 'ActivityRateGroup';
                          releaseHours?: Maybe<number>;
                          rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                      }>;
                  }>;
                  activity?: Maybe<{ __typename?: 'Activity'; id: string; name: string; slug: string }>;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  rateLines: Array<{
                      __typename?: 'TicketRateLine';
                      amount: number;
                      rateId?: Maybe<string>;
                      totalPrice: number;
                      unitPrice: number;
                  }>;
              }
            | {
                  __typename?: 'UpsellPurchase';
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  amount: number;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
              }
        >;
        paymentOptions?: Maybe<{
            __typename?: 'PaymentOptions';
            availableMethods: Array<PaymentMethodEnum>;
            fullPaymentDueDate?: Maybe<string>;
            partialPaymentAmount: number;
            totalAmountDue: number;
        }>;
        customer?: Maybe<{
            __typename?: 'Customer';
            phoneNumber?: Maybe<string>;
            firstName?: Maybe<string>;
            lastName?: Maybe<string>;
            email?: Maybe<string>;
            birthdate?: Maybe<string>;
            address?: Maybe<{
                __typename?: 'Address';
                city?: Maybe<string>;
                countryCode?: Maybe<string>;
                number?: Maybe<string>;
                postalCode?: Maybe<string>;
                street?: Maybe<string>;
            }>;
        }>;
    };
};

export type CheckoutPaymentOrderItem_Booking_Fragment = {
    __typename?: 'Booking';
    hasCancellationInsurance: boolean;
    hasContentsInsurance: boolean;
    arrivalDate: string;
    departureDate: string;
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    status: BookingStatus;
    amountAdults: number;
    amountPets: number;
    amountChildren: number;
    amountBabies: number;
    amountYouths: number;
    handleDepositPayment: boolean;
    deposit: number;
    requiresApproval: boolean;
    optionalSurcharges: Array<{
        __typename?: 'BookingCartItemSurcharge';
        amount: number;
        rentalUnitSurchargeId: string;
    }>;
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        name: string;
        code: string;
        type: RentalUnitTypeEnum;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            name: string;
            type: AccommodationTypeEnum;
            checkInEndTime: string;
            checkInStartTime: string;
            checkOutTime: string;
        };
    }>;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    special?: Maybe<{ __typename?: 'Special'; id: string }>;
};

export type CheckoutPaymentOrderItem_Ticket_Fragment = {
    __typename?: 'Ticket';
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    startDateTime: string;
    ticketStatus: TicketStatus;
    timeslot?: Maybe<{
        __typename?: 'Timeslot';
        id: string;
        startDateTime: string;
        endDateTime: string;
        allotment?: Maybe<number>;
        label?: Maybe<string>;
        rateGroup?: Maybe<{
            __typename?: 'ActivityRateGroup';
            releaseHours?: Maybe<number>;
            rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
        }>;
    }>;
    activity?: Maybe<{ __typename?: 'Activity'; id: string; name: string; slug: string }>;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    rateLines: Array<{
        __typename?: 'TicketRateLine';
        amount: number;
        rateId?: Maybe<string>;
        totalPrice: number;
        unitPrice: number;
    }>;
};

export type CheckoutPaymentOrderItem_UpsellPurchase_Fragment = {
    __typename?: 'UpsellPurchase';
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    amount: number;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
};

export type CheckoutPaymentOrderItemFragment =
    | CheckoutPaymentOrderItem_Booking_Fragment
    | CheckoutPaymentOrderItem_Ticket_Fragment
    | CheckoutPaymentOrderItem_UpsellPurchase_Fragment;

export type CheckoutPaymentBookingFragment = {
    __typename?: 'Booking';
    hasCancellationInsurance: boolean;
    hasContentsInsurance: boolean;
    arrivalDate: string;
    departureDate: string;
    optionalSurcharges: Array<{
        __typename?: 'BookingCartItemSurcharge';
        amount: number;
        rentalUnitSurchargeId: string;
    }>;
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        name: string;
        code: string;
        type: RentalUnitTypeEnum;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            name: string;
            type: AccommodationTypeEnum;
            checkInEndTime: string;
            checkInStartTime: string;
            checkOutTime: string;
        };
    }>;
};

export type CheckoutPaymentOrderQueryVariables = Exact<{
    orderId: Scalars['ID'];
}>;

export type CheckoutPaymentOrderQuery = {
    __typename?: 'Query';
    order?: Maybe<{
        __typename?: 'Order';
        id?: Maybe<string>;
        agreedToTerms?: Maybe<boolean>;
        newsletterOptin?: Maybe<boolean>;
        number?: Maybe<string>;
        errors: Array<string>;
        totalPrice: number;
        payments: Array<{ __typename?: 'Payment'; date: string; amount: number; status: PaymentStatusEnum }>;
        paymentOptions?: Maybe<{
            __typename?: 'PaymentOptions';
            availableMethods: Array<PaymentMethodEnum>;
            fullPaymentDueDate?: Maybe<string>;
            partialPaymentAmount: number;
            totalAmountDue: number;
        }>;
        orderItems: Array<
            | {
                  __typename?: 'Booking';
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  status: BookingStatus;
                  amountAdults: number;
                  amountPets: number;
                  amountChildren: number;
                  amountBabies: number;
                  amountYouths: number;
                  arrivalDate: string;
                  departureDate: string;
                  hasCancellationInsurance: boolean;
                  hasContentsInsurance: boolean;
                  handleDepositPayment: boolean;
                  deposit: number;
                  requiresApproval: boolean;
                  rentalUnit?: Maybe<{
                      __typename?: 'RentalUnit';
                      id: string;
                      code: string;
                      name: string;
                      slug: string;
                      maxOccupancy: number;
                      minOccupancy: number;
                      petsAllowed: number;
                      type: RentalUnitTypeEnum;
                      accommodation: {
                          __typename?: 'Accommodation';
                          id: string;
                          hasPublicPage: boolean;
                          name: string;
                          checkInEndTime: string;
                          checkInStartTime: string;
                          checkOutTime: string;
                          type: AccommodationTypeEnum;
                      };
                      jpegThumbnail: {
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      };
                      webpThumbnail: {
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      };
                  }>;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  special?: Maybe<{ __typename?: 'Special'; id: string }>;
                  optionalSurcharges: Array<{
                      __typename?: 'BookingCartItemSurcharge';
                      rentalUnitSurchargeId: string;
                      amount: number;
                  }>;
              }
            | {
                  __typename?: 'Ticket';
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  startDateTime: string;
                  ticketStatus: TicketStatus;
                  activity?: Maybe<{
                      __typename?: 'Activity';
                      id: string;
                      slug: string;
                      name: string;
                      place?: Maybe<string>;
                      jpegThumbnail?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                      webpThumbnail?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                  }>;
                  timeslot?: Maybe<{
                      __typename?: 'Timeslot';
                      id: string;
                      startDateTime: string;
                      endDateTime: string;
                      allotment?: Maybe<number>;
                      label?: Maybe<string>;
                      rateGroup?: Maybe<{
                          __typename?: 'ActivityRateGroup';
                          releaseHours?: Maybe<number>;
                          rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                      }>;
                  }>;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  rateLines: Array<{
                      __typename?: 'TicketRateLine';
                      amount: number;
                      rateId?: Maybe<string>;
                      totalPrice: number;
                      unitPrice: number;
                  }>;
              }
            | {
                  __typename?: 'UpsellPurchase';
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  amount: number;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
              }
        >;
        customer?: Maybe<{
            __typename?: 'Customer';
            phoneNumber?: Maybe<string>;
            firstName?: Maybe<string>;
            lastName?: Maybe<string>;
            email?: Maybe<string>;
            birthdate?: Maybe<string>;
            address?: Maybe<{
                __typename?: 'Address';
                city?: Maybe<string>;
                countryCode?: Maybe<string>;
                number?: Maybe<string>;
                postalCode?: Maybe<string>;
                street?: Maybe<string>;
            }>;
        }>;
    }>;
};

export type PayOrderMutationVariables = Exact<{
    input: PayOrderInput;
}>;

export type PayOrderMutation = {
    __typename?: 'Mutation';
    payOrder: {
        __typename?: 'PayOrderPayload';
        redirectUrl?: Maybe<string>;
        order?: Maybe<{
            __typename?: 'Order';
            id?: Maybe<string>;
            agreedToTerms?: Maybe<boolean>;
            newsletterOptin?: Maybe<boolean>;
            number?: Maybe<string>;
            errors: Array<string>;
            totalPrice: number;
            payments: Array<{ __typename?: 'Payment'; date: string; amount: number; status: PaymentStatusEnum }>;
            paymentOptions?: Maybe<{
                __typename?: 'PaymentOptions';
                availableMethods: Array<PaymentMethodEnum>;
                fullPaymentDueDate?: Maybe<string>;
                partialPaymentAmount: number;
                totalAmountDue: number;
            }>;
            orderItems: Array<
                | {
                      __typename?: 'Booking';
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      status: BookingStatus;
                      amountAdults: number;
                      amountPets: number;
                      amountChildren: number;
                      amountBabies: number;
                      amountYouths: number;
                      arrivalDate: string;
                      departureDate: string;
                      hasCancellationInsurance: boolean;
                      hasContentsInsurance: boolean;
                      handleDepositPayment: boolean;
                      deposit: number;
                      requiresApproval: boolean;
                      rentalUnit?: Maybe<{
                          __typename?: 'RentalUnit';
                          id: string;
                          code: string;
                          name: string;
                          slug: string;
                          maxOccupancy: number;
                          minOccupancy: number;
                          petsAllowed: number;
                          type: RentalUnitTypeEnum;
                          accommodation: {
                              __typename?: 'Accommodation';
                              id: string;
                              hasPublicPage: boolean;
                              name: string;
                              checkInEndTime: string;
                              checkInStartTime: string;
                              checkOutTime: string;
                              type: AccommodationTypeEnum;
                          };
                          jpegThumbnail: {
                              __typename?: 'GenericImage';
                              transform?: Maybe<{
                                  __typename?: 'ImageTransform';
                                  placeholder?: Maybe<string>;
                                  srcSet: string;
                                  src: string;
                                  ratio: number;
                              }>;
                          };
                          webpThumbnail: {
                              __typename?: 'GenericImage';
                              transform?: Maybe<{
                                  __typename?: 'ImageTransform';
                                  placeholder?: Maybe<string>;
                                  srcSet: string;
                                  src: string;
                                  ratio: number;
                              }>;
                          };
                      }>;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      special?: Maybe<{ __typename?: 'Special'; id: string }>;
                      optionalSurcharges: Array<{
                          __typename?: 'BookingCartItemSurcharge';
                          rentalUnitSurchargeId: string;
                          amount: number;
                      }>;
                  }
                | {
                      __typename?: 'Ticket';
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      startDateTime: string;
                      ticketStatus: TicketStatus;
                      activity?: Maybe<{
                          __typename?: 'Activity';
                          id: string;
                          slug: string;
                          name: string;
                          place?: Maybe<string>;
                          jpegThumbnail?: Maybe<{
                              __typename?: 'GenericImage';
                              transform?: Maybe<{
                                  __typename?: 'ImageTransform';
                                  placeholder?: Maybe<string>;
                                  srcSet: string;
                                  src: string;
                                  ratio: number;
                              }>;
                          }>;
                          webpThumbnail?: Maybe<{
                              __typename?: 'GenericImage';
                              transform?: Maybe<{
                                  __typename?: 'ImageTransform';
                                  placeholder?: Maybe<string>;
                                  srcSet: string;
                                  src: string;
                                  ratio: number;
                              }>;
                          }>;
                      }>;
                      timeslot?: Maybe<{
                          __typename?: 'Timeslot';
                          id: string;
                          startDateTime: string;
                          endDateTime: string;
                          allotment?: Maybe<number>;
                          label?: Maybe<string>;
                          rateGroup?: Maybe<{
                              __typename?: 'ActivityRateGroup';
                              releaseHours?: Maybe<number>;
                              rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                          }>;
                      }>;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      rateLines: Array<{
                          __typename?: 'TicketRateLine';
                          amount: number;
                          rateId?: Maybe<string>;
                          totalPrice: number;
                          unitPrice: number;
                      }>;
                  }
                | {
                      __typename?: 'UpsellPurchase';
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      amount: number;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
                  }
            >;
            customer?: Maybe<{
                __typename?: 'Customer';
                phoneNumber?: Maybe<string>;
                firstName?: Maybe<string>;
                lastName?: Maybe<string>;
                email?: Maybe<string>;
                birthdate?: Maybe<string>;
                address?: Maybe<{
                    __typename?: 'Address';
                    city?: Maybe<string>;
                    countryCode?: Maybe<string>;
                    number?: Maybe<string>;
                    postalCode?: Maybe<string>;
                    street?: Maybe<string>;
                }>;
            }>;
        }>;
    };
};

export type CheckoutPaymentOrderFragment = {
    __typename?: 'Order';
    id?: Maybe<string>;
    agreedToTerms?: Maybe<boolean>;
    newsletterOptin?: Maybe<boolean>;
    number?: Maybe<string>;
    errors: Array<string>;
    totalPrice: number;
    payments: Array<{ __typename?: 'Payment'; date: string; amount: number; status: PaymentStatusEnum }>;
    paymentOptions?: Maybe<{
        __typename?: 'PaymentOptions';
        availableMethods: Array<PaymentMethodEnum>;
        fullPaymentDueDate?: Maybe<string>;
        partialPaymentAmount: number;
        totalAmountDue: number;
    }>;
    orderItems: Array<
        | {
              __typename?: 'Booking';
              totalPrice: number;
              paymentPrice: number;
              errors: Array<string>;
              status: BookingStatus;
              amountAdults: number;
              amountPets: number;
              amountChildren: number;
              amountBabies: number;
              amountYouths: number;
              arrivalDate: string;
              departureDate: string;
              hasCancellationInsurance: boolean;
              hasContentsInsurance: boolean;
              handleDepositPayment: boolean;
              deposit: number;
              requiresApproval: boolean;
              rentalUnit?: Maybe<{
                  __typename?: 'RentalUnit';
                  id: string;
                  code: string;
                  name: string;
                  slug: string;
                  maxOccupancy: number;
                  minOccupancy: number;
                  petsAllowed: number;
                  type: RentalUnitTypeEnum;
                  accommodation: {
                      __typename?: 'Accommodation';
                      id: string;
                      hasPublicPage: boolean;
                      name: string;
                      checkInEndTime: string;
                      checkInStartTime: string;
                      checkOutTime: string;
                      type: AccommodationTypeEnum;
                  };
                  jpegThumbnail: {
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  };
                  webpThumbnail: {
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  };
              }>;
              priceLines: Array<{
                  __typename?: 'PriceLine';
                  label: string;
                  quantity?: Maybe<string>;
                  totalPrice: number;
                  unitPrice?: Maybe<number>;
                  category?: Maybe<PriceLineCategoryEnum>;
              }>;
              special?: Maybe<{ __typename?: 'Special'; id: string }>;
              optionalSurcharges: Array<{
                  __typename?: 'BookingCartItemSurcharge';
                  rentalUnitSurchargeId: string;
                  amount: number;
              }>;
          }
        | {
              __typename?: 'Ticket';
              totalPrice: number;
              paymentPrice: number;
              errors: Array<string>;
              startDateTime: string;
              ticketStatus: TicketStatus;
              activity?: Maybe<{
                  __typename?: 'Activity';
                  id: string;
                  slug: string;
                  name: string;
                  place?: Maybe<string>;
                  jpegThumbnail?: Maybe<{
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  }>;
                  webpThumbnail?: Maybe<{
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  }>;
              }>;
              timeslot?: Maybe<{
                  __typename?: 'Timeslot';
                  id: string;
                  startDateTime: string;
                  endDateTime: string;
                  allotment?: Maybe<number>;
                  label?: Maybe<string>;
                  rateGroup?: Maybe<{
                      __typename?: 'ActivityRateGroup';
                      releaseHours?: Maybe<number>;
                      rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                  }>;
              }>;
              priceLines: Array<{
                  __typename?: 'PriceLine';
                  label: string;
                  quantity?: Maybe<string>;
                  totalPrice: number;
                  unitPrice?: Maybe<number>;
                  category?: Maybe<PriceLineCategoryEnum>;
              }>;
              rateLines: Array<{
                  __typename?: 'TicketRateLine';
                  amount: number;
                  rateId?: Maybe<string>;
                  totalPrice: number;
                  unitPrice: number;
              }>;
          }
        | {
              __typename?: 'UpsellPurchase';
              totalPrice: number;
              paymentPrice: number;
              errors: Array<string>;
              amount: number;
              priceLines: Array<{
                  __typename?: 'PriceLine';
                  label: string;
                  quantity?: Maybe<string>;
                  totalPrice: number;
                  unitPrice?: Maybe<number>;
                  category?: Maybe<PriceLineCategoryEnum>;
              }>;
              offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
          }
    >;
    customer?: Maybe<{
        __typename?: 'Customer';
        phoneNumber?: Maybe<string>;
        firstName?: Maybe<string>;
        lastName?: Maybe<string>;
        email?: Maybe<string>;
        birthdate?: Maybe<string>;
        address?: Maybe<{
            __typename?: 'Address';
            city?: Maybe<string>;
            countryCode?: Maybe<string>;
            number?: Maybe<string>;
            postalCode?: Maybe<string>;
            street?: Maybe<string>;
        }>;
    }>;
};

export type CheckoutRedirectRentalUnitQueryVariables = Exact<{
    slug: Scalars['String'];
}>;

export type CheckoutRedirectRentalUnitQuery = {
    __typename?: 'Query';
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        brand: string;
        cancellationInsuranceAvailable: boolean;
    }>;
};

export type CompanySearchQueryVariables = Exact<{
    params: CompanySearchParamsInput;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sortByDistanceTo?: Maybe<LatLonInput>;
}>;

export type CompanySearchQuery = {
    __typename?: 'Query';
    companies: {
        __typename?: 'CompanySearchResult';
        totalHits: number;
        hits: Array<{
            __typename?: 'Company';
            name: string;
            slug: string;
            id: string;
            place: string;
            targetAudiences: Array<TargetAudienceEnum>;
            videoUrl?: Maybe<string>;
            categories: Array<{ __typename?: 'CompanyCategory'; slug: string }>;
            mainImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            webpMainImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            webpListImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            listImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            normalOperatingHours?: Maybe<{
                __typename?: 'NormalOperatingHours';
                monday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                tuesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                wednesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                thursday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                friday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                saturday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
                sunday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
            }>;
            specialOperatingHours: Array<{
                __typename?: 'SpecialOperatingHours';
                startDate: string;
                endDate: string;
                monday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                tuesday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                wednesday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                thursday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                friday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                saturday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
                sunday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
            }>;
        }>;
        placeCounts: Array<{ __typename?: 'GenericAggregationCount'; value: string; hits: number }>;
        themeCounts: Array<{ __typename?: 'GenericAggregationCount'; value: string; hits: number }>;
        categoryCounts: Array<{ __typename?: 'GenericAggregationCount'; value: string; hits: number }>;
        mainCategoryCounts: Array<{ __typename?: 'GenericAggregationCount'; value: string; hits: number }>;
        targetAudienceCount: Array<{
            __typename?: 'TargetAudienceAggregationCount';
            value: TargetAudienceEnum;
            hits: number;
        }>;
    };
};

export type CompanyHitFragment = {
    __typename?: 'Company';
    name: string;
    slug: string;
    id: string;
    place: string;
    targetAudiences: Array<TargetAudienceEnum>;
    videoUrl?: Maybe<string>;
    categories: Array<{ __typename?: 'CompanyCategory'; slug: string }>;
    mainImage?: Maybe<{
        __typename?: 'GenericImage';
        transform?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    }>;
    webpMainImage?: Maybe<{
        __typename?: 'GenericImage';
        transform?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    }>;
    webpListImage?: Maybe<{
        __typename?: 'GenericImage';
        transform?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    }>;
    listImage?: Maybe<{
        __typename?: 'GenericImage';
        transform?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    }>;
    normalOperatingHours?: Maybe<{
        __typename?: 'NormalOperatingHours';
        monday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
        tuesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
        wednesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
        thursday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
        friday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
        saturday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
        sunday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
    }>;
    specialOperatingHours: Array<{
        __typename?: 'SpecialOperatingHours';
        startDate: string;
        endDate: string;
        monday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
        tuesday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
        wednesday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
        thursday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
        friday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
        saturday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
        sunday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
    }>;
};

export type CompanyFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyFiltersQuery = {
    __typename?: 'Query';
    companyCategories: Array<{
        __typename?: 'CompanyCategory';
        name?: Maybe<string>;
        slug: string;
        position: number;
        children: Array<{ __typename?: 'CompanyCategory'; name?: Maybe<string>; slug: string; position: number }>;
    }>;
    places: Array<{ __typename?: 'Place'; id: string; name: string }>;
};

export type CompanyDetailQueryVariables = Exact<{
    slug: Scalars['String'];
    startDate: Scalars['String'];
    endDate: Scalars['String'];
}>;

export type CompanyDetailQuery = {
    __typename?: 'Query';
    company?: Maybe<{
        __typename?: 'Company';
        id: string;
        videoUrl?: Maybe<string>;
        place: string;
        name: string;
        description?: Maybe<string>;
        socialsFacebookUrl?: Maybe<string>;
        socialsInstagramUrl?: Maybe<string>;
        socialsTwitterUrl?: Maybe<string>;
        email?: Maybe<string>;
        phoneNumber?: Maybe<string>;
        websiteUrl?: Maybe<string>;
        descriptionParticulars?: Maybe<string>;
        jpegHero?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        webpHero?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        jpegThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        webpThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        categories: Array<{
            __typename?: 'CompanyCategory';
            id: string;
            isRoot: boolean;
            name?: Maybe<string>;
            position: number;
            slug: string;
        }>;
        address: {
            __typename?: 'Address';
            city?: Maybe<string>;
            countryCode?: Maybe<string>;
            number?: Maybe<string>;
            postalCode?: Maybe<string>;
            street?: Maybe<string>;
        };
        pngLogo?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        webpLogo?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        coordinates?: Maybe<{ __typename?: 'LatLon'; lat: number; lon: number }>;
        images: Array<{
            __typename?: 'CompanyImage';
            jpegTile?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
            webpTile?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
            jpegGallery?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
            webpGallery?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        activities: Array<{
            __typename?: 'Activity';
            id: string;
            name: string;
            slug: string;
            highlighted: boolean;
            place?: Maybe<string>;
            videoUrl?: Maybe<string>;
            externalBookingUrl?: Maybe<string>;
            bookingType: ActivityBookingTypesEnum;
            minCapacity?: Maybe<number>;
            maxCapacity?: Maybe<number>;
            themes: Array<{ __typename?: 'ActivityTheme'; slug: string; name?: Maybe<string> }>;
            timeslots: Array<{
                __typename?: 'Timeslot';
                id: string;
                allotment?: Maybe<number>;
                startDateTime: string;
                endDateTime: string;
                label?: Maybe<string>;
                rateGroup?: Maybe<{
                    __typename?: 'ActivityRateGroup';
                    releaseHours?: Maybe<number>;
                    rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                }>;
            }>;
            mainImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            webpMainImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            webpListImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            listImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
        }>;
        normalOperatingHours?: Maybe<{
            __typename?: 'NormalOperatingHours';
            monday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
            tuesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
            wednesday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
            thursday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
            friday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
            saturday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
            sunday: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>;
        }>;
        specialOperatingHours: Array<{
            __typename?: 'SpecialOperatingHours';
            startDate: string;
            endDate: string;
            monday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
            tuesday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
            wednesday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
            thursday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
            friday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
            saturday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
            sunday?: Maybe<Array<{ __typename?: 'TimeOpenClosed'; timeOpen: string; timeClosed: string }>>;
        }>;
    }>;
};

export type MyQueryVariables = Exact<{ [key: string]: never }>;

export type MyQuery = {
    __typename?: 'Query';
    viewer?: Maybe<{
        __typename?: 'Customer';
        id?: Maybe<string>;
        firstName?: Maybe<string>;
        lastName?: Maybe<string>;
        birthdate?: Maybe<string>;
        email?: Maybe<string>;
        phoneNumber?: Maybe<string>;
        address?: Maybe<{
            __typename?: 'Address';
            city?: Maybe<string>;
            countryCode?: Maybe<string>;
            number?: Maybe<string>;
            postalCode?: Maybe<string>;
            street?: Maybe<string>;
        }>;
        orders: Array<{
            __typename?: 'Order';
            id?: Maybe<string>;
            number?: Maybe<string>;
            createdDate?: Maybe<string>;
            paymentOptions?: Maybe<{
                __typename?: 'PaymentOptions';
                fullPaymentDueDate?: Maybe<string>;
                totalAmountDue: number;
            }>;
            orderItems: Array<
                | {
                      __typename?: 'Booking';
                      id?: Maybe<string>;
                      downloadVoucherUrl?: Maybe<string>;
                      isCancellable: boolean;
                      isAlterable: boolean;
                      customerComment?: Maybe<string>;
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      status: BookingStatus;
                      amountAdults: number;
                      amountPets: number;
                      amountChildren: number;
                      amountBabies: number;
                      amountYouths: number;
                      arrivalDate: string;
                      departureDate: string;
                      hasCancellationInsurance: boolean;
                      hasContentsInsurance: boolean;
                      handleDepositPayment: boolean;
                      deposit: number;
                      requiresApproval: boolean;
                      review?: Maybe<{
                          __typename?: 'Review';
                          id: string;
                          average: number;
                          comment: string;
                          date: string;
                          ratingHospitality?: Maybe<number>;
                          ratingLayout?: Maybe<number>;
                          ratingLocation?: Maybe<number>;
                          ratingMaintenance?: Maybe<number>;
                          ratingValueForMoney?: Maybe<number>;
                          tips?: Maybe<string>;
                          title: string;
                          travelGroupType?: Maybe<TravelGroupType>;
                          reply?: Maybe<string>;
                      }>;
                      rentalUnit?: Maybe<{
                          __typename?: 'RentalUnit';
                          id: string;
                          flexCancellationDays?: Maybe<number>;
                          code: string;
                          name: string;
                          slug: string;
                          maxOccupancy: number;
                          minOccupancy: number;
                          petsAllowed: number;
                          type: RentalUnitTypeEnum;
                          reviewStats?: Maybe<{
                              __typename?: 'ReviewStats';
                              count: number;
                              average: number;
                              location: number;
                              hospitality: number;
                              layout: number;
                              maintenance: number;
                              valueForMoney: number;
                          }>;
                          accommodation: {
                              __typename?: 'Accommodation';
                              id: string;
                              ownerName?: Maybe<string>;
                              hasPublicPage: boolean;
                              name: string;
                              checkInEndTime: string;
                              checkInStartTime: string;
                              checkOutTime: string;
                              type: AccommodationTypeEnum;
                              jpegOwner?: Maybe<{
                                  __typename?: 'GenericImage';
                                  transform?: Maybe<{
                                      __typename?: 'ImageTransform';
                                      placeholder?: Maybe<string>;
                                      srcSet: string;
                                      src: string;
                                      ratio: number;
                                  }>;
                              }>;
                              webpOwner?: Maybe<{
                                  __typename?: 'GenericImage';
                                  transform?: Maybe<{
                                      __typename?: 'ImageTransform';
                                      placeholder?: Maybe<string>;
                                      srcSet: string;
                                      src: string;
                                      ratio: number;
                                  }>;
                              }>;
                          };
                          jpegThumbnail: {
                              __typename?: 'GenericImage';
                              transform?: Maybe<{
                                  __typename?: 'ImageTransform';
                                  placeholder?: Maybe<string>;
                                  srcSet: string;
                                  src: string;
                                  ratio: number;
                              }>;
                          };
                          webpThumbnail: {
                              __typename?: 'GenericImage';
                              transform?: Maybe<{
                                  __typename?: 'ImageTransform';
                                  placeholder?: Maybe<string>;
                                  srcSet: string;
                                  src: string;
                                  ratio: number;
                              }>;
                          };
                      }>;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      special?: Maybe<{ __typename?: 'Special'; id: string }>;
                      optionalSurcharges: Array<{
                          __typename?: 'BookingCartItemSurcharge';
                          rentalUnitSurchargeId: string;
                          amount: number;
                      }>;
                  }
                | {
                      __typename?: 'Ticket';
                      id?: Maybe<string>;
                      downloadVoucherUrl?: Maybe<string>;
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      startDateTime: string;
                      ticketStatus: TicketStatus;
                      activity?: Maybe<{
                          __typename?: 'Activity';
                          id: string;
                          slug: string;
                          name: string;
                          place?: Maybe<string>;
                          jpegThumbnail?: Maybe<{
                              __typename?: 'GenericImage';
                              transform?: Maybe<{
                                  __typename?: 'ImageTransform';
                                  placeholder?: Maybe<string>;
                                  srcSet: string;
                                  src: string;
                                  ratio: number;
                              }>;
                          }>;
                          webpThumbnail?: Maybe<{
                              __typename?: 'GenericImage';
                              transform?: Maybe<{
                                  __typename?: 'ImageTransform';
                                  placeholder?: Maybe<string>;
                                  srcSet: string;
                                  src: string;
                                  ratio: number;
                              }>;
                          }>;
                      }>;
                      timeslot?: Maybe<{
                          __typename?: 'Timeslot';
                          id: string;
                          startDateTime: string;
                          endDateTime: string;
                          allotment?: Maybe<number>;
                          label?: Maybe<string>;
                          rateGroup?: Maybe<{
                              __typename?: 'ActivityRateGroup';
                              releaseHours?: Maybe<number>;
                              rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                          }>;
                      }>;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      rateLines: Array<{
                          __typename?: 'TicketRateLine';
                          amount: number;
                          rateId?: Maybe<string>;
                          totalPrice: number;
                          unitPrice: number;
                      }>;
                  }
                | {
                      __typename?: 'UpsellPurchase';
                      id?: Maybe<string>;
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      amount: number;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
                  }
            >;
            payments: Array<{ __typename?: 'Payment'; status: PaymentStatusEnum }>;
        }>;
        favorites: Array<
            | {
                  __typename?: 'FavoriteActivity';
                  id: string;
                  activity?: Maybe<{
                      __typename?: 'Activity';
                      slug: string;
                      name: string;
                      id: string;
                      jpegThumbnail?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                      webpThumbnail?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                  }>;
              }
            | {
                  __typename?: 'FavoriteCompany';
                  id: string;
                  company?: Maybe<{
                      __typename?: 'Company';
                      slug: string;
                      name: string;
                      id: string;
                      jpegThumbnail?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                      webpThumbnail?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                  }>;
              }
            | {
                  __typename?: 'FavoriteRentalUnit';
                  id: string;
                  rentalUnit?: Maybe<{
                      __typename?: 'RentalUnit';
                      slug: string;
                      name: string;
                      id: string;
                      jpegThumbnail: {
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      };
                      webpThumbnail: {
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      };
                  }>;
              }
        >;
    }>;
};

export type MyCustomerFragment = {
    __typename?: 'Customer';
    id?: Maybe<string>;
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
    birthdate?: Maybe<string>;
    email?: Maybe<string>;
    phoneNumber?: Maybe<string>;
    address?: Maybe<{
        __typename?: 'Address';
        city?: Maybe<string>;
        countryCode?: Maybe<string>;
        number?: Maybe<string>;
        postalCode?: Maybe<string>;
        street?: Maybe<string>;
    }>;
    orders: Array<{
        __typename?: 'Order';
        id?: Maybe<string>;
        number?: Maybe<string>;
        createdDate?: Maybe<string>;
        paymentOptions?: Maybe<{
            __typename?: 'PaymentOptions';
            fullPaymentDueDate?: Maybe<string>;
            totalAmountDue: number;
        }>;
        orderItems: Array<
            | {
                  __typename?: 'Booking';
                  id?: Maybe<string>;
                  downloadVoucherUrl?: Maybe<string>;
                  isCancellable: boolean;
                  isAlterable: boolean;
                  customerComment?: Maybe<string>;
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  status: BookingStatus;
                  amountAdults: number;
                  amountPets: number;
                  amountChildren: number;
                  amountBabies: number;
                  amountYouths: number;
                  arrivalDate: string;
                  departureDate: string;
                  hasCancellationInsurance: boolean;
                  hasContentsInsurance: boolean;
                  handleDepositPayment: boolean;
                  deposit: number;
                  requiresApproval: boolean;
                  review?: Maybe<{
                      __typename?: 'Review';
                      id: string;
                      average: number;
                      comment: string;
                      date: string;
                      ratingHospitality?: Maybe<number>;
                      ratingLayout?: Maybe<number>;
                      ratingLocation?: Maybe<number>;
                      ratingMaintenance?: Maybe<number>;
                      ratingValueForMoney?: Maybe<number>;
                      tips?: Maybe<string>;
                      title: string;
                      travelGroupType?: Maybe<TravelGroupType>;
                      reply?: Maybe<string>;
                  }>;
                  rentalUnit?: Maybe<{
                      __typename?: 'RentalUnit';
                      id: string;
                      flexCancellationDays?: Maybe<number>;
                      code: string;
                      name: string;
                      slug: string;
                      maxOccupancy: number;
                      minOccupancy: number;
                      petsAllowed: number;
                      type: RentalUnitTypeEnum;
                      reviewStats?: Maybe<{
                          __typename?: 'ReviewStats';
                          count: number;
                          average: number;
                          location: number;
                          hospitality: number;
                          layout: number;
                          maintenance: number;
                          valueForMoney: number;
                      }>;
                      accommodation: {
                          __typename?: 'Accommodation';
                          id: string;
                          ownerName?: Maybe<string>;
                          hasPublicPage: boolean;
                          name: string;
                          checkInEndTime: string;
                          checkInStartTime: string;
                          checkOutTime: string;
                          type: AccommodationTypeEnum;
                          jpegOwner?: Maybe<{
                              __typename?: 'GenericImage';
                              transform?: Maybe<{
                                  __typename?: 'ImageTransform';
                                  placeholder?: Maybe<string>;
                                  srcSet: string;
                                  src: string;
                                  ratio: number;
                              }>;
                          }>;
                          webpOwner?: Maybe<{
                              __typename?: 'GenericImage';
                              transform?: Maybe<{
                                  __typename?: 'ImageTransform';
                                  placeholder?: Maybe<string>;
                                  srcSet: string;
                                  src: string;
                                  ratio: number;
                              }>;
                          }>;
                      };
                      jpegThumbnail: {
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      };
                      webpThumbnail: {
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      };
                  }>;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  special?: Maybe<{ __typename?: 'Special'; id: string }>;
                  optionalSurcharges: Array<{
                      __typename?: 'BookingCartItemSurcharge';
                      rentalUnitSurchargeId: string;
                      amount: number;
                  }>;
              }
            | {
                  __typename?: 'Ticket';
                  id?: Maybe<string>;
                  downloadVoucherUrl?: Maybe<string>;
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  startDateTime: string;
                  ticketStatus: TicketStatus;
                  activity?: Maybe<{
                      __typename?: 'Activity';
                      id: string;
                      slug: string;
                      name: string;
                      place?: Maybe<string>;
                      jpegThumbnail?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                      webpThumbnail?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                  }>;
                  timeslot?: Maybe<{
                      __typename?: 'Timeslot';
                      id: string;
                      startDateTime: string;
                      endDateTime: string;
                      allotment?: Maybe<number>;
                      label?: Maybe<string>;
                      rateGroup?: Maybe<{
                          __typename?: 'ActivityRateGroup';
                          releaseHours?: Maybe<number>;
                          rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                      }>;
                  }>;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  rateLines: Array<{
                      __typename?: 'TicketRateLine';
                      amount: number;
                      rateId?: Maybe<string>;
                      totalPrice: number;
                      unitPrice: number;
                  }>;
              }
            | {
                  __typename?: 'UpsellPurchase';
                  id?: Maybe<string>;
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  amount: number;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
              }
        >;
        payments: Array<{ __typename?: 'Payment'; status: PaymentStatusEnum }>;
    }>;
    favorites: Array<
        | {
              __typename?: 'FavoriteActivity';
              id: string;
              activity?: Maybe<{
                  __typename?: 'Activity';
                  slug: string;
                  name: string;
                  id: string;
                  jpegThumbnail?: Maybe<{
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  }>;
                  webpThumbnail?: Maybe<{
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  }>;
              }>;
          }
        | {
              __typename?: 'FavoriteCompany';
              id: string;
              company?: Maybe<{
                  __typename?: 'Company';
                  slug: string;
                  name: string;
                  id: string;
                  jpegThumbnail?: Maybe<{
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  }>;
                  webpThumbnail?: Maybe<{
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  }>;
              }>;
          }
        | {
              __typename?: 'FavoriteRentalUnit';
              id: string;
              rentalUnit?: Maybe<{
                  __typename?: 'RentalUnit';
                  slug: string;
                  name: string;
                  id: string;
                  jpegThumbnail: {
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  };
                  webpThumbnail: {
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  };
              }>;
          }
    >;
};

export type MyFavorites_FavoriteActivity_Fragment = {
    __typename?: 'FavoriteActivity';
    id: string;
    activity?: Maybe<{
        __typename?: 'Activity';
        slug: string;
        name: string;
        id: string;
        jpegThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        webpThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
    }>;
};

export type MyFavorites_FavoriteCompany_Fragment = {
    __typename?: 'FavoriteCompany';
    id: string;
    company?: Maybe<{
        __typename?: 'Company';
        slug: string;
        name: string;
        id: string;
        jpegThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        webpThumbnail?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
    }>;
};

export type MyFavorites_FavoriteRentalUnit_Fragment = {
    __typename?: 'FavoriteRentalUnit';
    id: string;
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        slug: string;
        name: string;
        id: string;
        jpegThumbnail: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
        webpThumbnail: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
    }>;
};

export type MyFavoritesFragment =
    | MyFavorites_FavoriteActivity_Fragment
    | MyFavorites_FavoriteCompany_Fragment
    | MyFavorites_FavoriteRentalUnit_Fragment;

export type OrderDetailsQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type OrderDetailsQuery = {
    __typename?: 'Query';
    order?: Maybe<{
        __typename?: 'Order';
        id?: Maybe<string>;
        number?: Maybe<string>;
        createdDate?: Maybe<string>;
        downloadOverviewUrl?: Maybe<string>;
        errors: Array<string>;
        totalPrice: number;
        payments: Array<{ __typename?: 'Payment'; date: string; amount: number; status: PaymentStatusEnum }>;
        paymentOptions?: Maybe<{
            __typename?: 'PaymentOptions';
            fullPaymentDueDate?: Maybe<string>;
            partialPaymentAmount: number;
            totalAmountDue: number;
        }>;
        orderItems: Array<
            | {
                  __typename?: 'Booking';
                  id?: Maybe<string>;
                  downloadVoucherUrl?: Maybe<string>;
                  isCancellable: boolean;
                  isAlterable: boolean;
                  customerComment?: Maybe<string>;
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  status: BookingStatus;
                  amountAdults: number;
                  amountPets: number;
                  amountChildren: number;
                  amountBabies: number;
                  amountYouths: number;
                  arrivalDate: string;
                  departureDate: string;
                  hasCancellationInsurance: boolean;
                  hasContentsInsurance: boolean;
                  handleDepositPayment: boolean;
                  deposit: number;
                  requiresApproval: boolean;
                  rentalUnit?: Maybe<{
                      __typename?: 'RentalUnit';
                      id: string;
                      code: string;
                      name: string;
                      slug: string;
                      maxOccupancy: number;
                      minOccupancy: number;
                      petsAllowed: number;
                      type: RentalUnitTypeEnum;
                      flexCancellationDays?: Maybe<number>;
                      accommodation: {
                          __typename?: 'Accommodation';
                          id: string;
                          hasPublicPage: boolean;
                          name: string;
                          checkInEndTime: string;
                          checkInStartTime: string;
                          checkOutTime: string;
                          type: AccommodationTypeEnum;
                          ownerName?: Maybe<string>;
                          jpegOwner?: Maybe<{
                              __typename?: 'GenericImage';
                              transform?: Maybe<{
                                  __typename?: 'ImageTransform';
                                  placeholder?: Maybe<string>;
                                  srcSet: string;
                                  src: string;
                                  ratio: number;
                              }>;
                          }>;
                          webpOwner?: Maybe<{
                              __typename?: 'GenericImage';
                              transform?: Maybe<{
                                  __typename?: 'ImageTransform';
                                  placeholder?: Maybe<string>;
                                  srcSet: string;
                                  src: string;
                                  ratio: number;
                              }>;
                          }>;
                      };
                      jpegThumbnail: {
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      };
                      webpThumbnail: {
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      };
                      reviewStats?: Maybe<{
                          __typename?: 'ReviewStats';
                          count: number;
                          average: number;
                          location: number;
                          hospitality: number;
                          layout: number;
                          maintenance: number;
                          valueForMoney: number;
                      }>;
                  }>;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  review?: Maybe<{
                      __typename?: 'Review';
                      id: string;
                      average: number;
                      comment: string;
                      date: string;
                      ratingHospitality?: Maybe<number>;
                      ratingLayout?: Maybe<number>;
                      ratingLocation?: Maybe<number>;
                      ratingMaintenance?: Maybe<number>;
                      ratingValueForMoney?: Maybe<number>;
                      tips?: Maybe<string>;
                      title: string;
                      travelGroupType?: Maybe<TravelGroupType>;
                      reply?: Maybe<string>;
                  }>;
                  special?: Maybe<{ __typename?: 'Special'; id: string }>;
                  optionalSurcharges: Array<{
                      __typename?: 'BookingCartItemSurcharge';
                      rentalUnitSurchargeId: string;
                      amount: number;
                  }>;
              }
            | {
                  __typename?: 'Ticket';
                  id?: Maybe<string>;
                  downloadVoucherUrl?: Maybe<string>;
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  startDateTime: string;
                  ticketStatus: TicketStatus;
                  activity?: Maybe<{
                      __typename?: 'Activity';
                      id: string;
                      slug: string;
                      name: string;
                      place?: Maybe<string>;
                      jpegThumbnail?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                      webpThumbnail?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                  }>;
                  timeslot?: Maybe<{
                      __typename?: 'Timeslot';
                      id: string;
                      startDateTime: string;
                      endDateTime: string;
                      allotment?: Maybe<number>;
                      label?: Maybe<string>;
                      rateGroup?: Maybe<{
                          __typename?: 'ActivityRateGroup';
                          releaseHours?: Maybe<number>;
                          rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                      }>;
                  }>;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  rateLines: Array<{
                      __typename?: 'TicketRateLine';
                      amount: number;
                      rateId?: Maybe<string>;
                      totalPrice: number;
                      unitPrice: number;
                  }>;
              }
            | {
                  __typename?: 'UpsellPurchase';
                  id?: Maybe<string>;
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  amount: number;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
              }
        >;
    }>;
};

export type OrderDetailsFragment = {
    __typename?: 'Order';
    id?: Maybe<string>;
    number?: Maybe<string>;
    createdDate?: Maybe<string>;
    downloadOverviewUrl?: Maybe<string>;
    errors: Array<string>;
    totalPrice: number;
    payments: Array<{ __typename?: 'Payment'; date: string; amount: number; status: PaymentStatusEnum }>;
    paymentOptions?: Maybe<{
        __typename?: 'PaymentOptions';
        fullPaymentDueDate?: Maybe<string>;
        partialPaymentAmount: number;
        totalAmountDue: number;
    }>;
    orderItems: Array<
        | {
              __typename?: 'Booking';
              id?: Maybe<string>;
              downloadVoucherUrl?: Maybe<string>;
              isCancellable: boolean;
              isAlterable: boolean;
              customerComment?: Maybe<string>;
              totalPrice: number;
              paymentPrice: number;
              errors: Array<string>;
              status: BookingStatus;
              amountAdults: number;
              amountPets: number;
              amountChildren: number;
              amountBabies: number;
              amountYouths: number;
              arrivalDate: string;
              departureDate: string;
              hasCancellationInsurance: boolean;
              hasContentsInsurance: boolean;
              handleDepositPayment: boolean;
              deposit: number;
              requiresApproval: boolean;
              rentalUnit?: Maybe<{
                  __typename?: 'RentalUnit';
                  id: string;
                  code: string;
                  name: string;
                  slug: string;
                  maxOccupancy: number;
                  minOccupancy: number;
                  petsAllowed: number;
                  type: RentalUnitTypeEnum;
                  flexCancellationDays?: Maybe<number>;
                  accommodation: {
                      __typename?: 'Accommodation';
                      id: string;
                      hasPublicPage: boolean;
                      name: string;
                      checkInEndTime: string;
                      checkInStartTime: string;
                      checkOutTime: string;
                      type: AccommodationTypeEnum;
                      ownerName?: Maybe<string>;
                      jpegOwner?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                      webpOwner?: Maybe<{
                          __typename?: 'GenericImage';
                          transform?: Maybe<{
                              __typename?: 'ImageTransform';
                              placeholder?: Maybe<string>;
                              srcSet: string;
                              src: string;
                              ratio: number;
                          }>;
                      }>;
                  };
                  jpegThumbnail: {
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  };
                  webpThumbnail: {
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  };
                  reviewStats?: Maybe<{
                      __typename?: 'ReviewStats';
                      count: number;
                      average: number;
                      location: number;
                      hospitality: number;
                      layout: number;
                      maintenance: number;
                      valueForMoney: number;
                  }>;
              }>;
              priceLines: Array<{
                  __typename?: 'PriceLine';
                  label: string;
                  quantity?: Maybe<string>;
                  totalPrice: number;
                  unitPrice?: Maybe<number>;
                  category?: Maybe<PriceLineCategoryEnum>;
              }>;
              review?: Maybe<{
                  __typename?: 'Review';
                  id: string;
                  average: number;
                  comment: string;
                  date: string;
                  ratingHospitality?: Maybe<number>;
                  ratingLayout?: Maybe<number>;
                  ratingLocation?: Maybe<number>;
                  ratingMaintenance?: Maybe<number>;
                  ratingValueForMoney?: Maybe<number>;
                  tips?: Maybe<string>;
                  title: string;
                  travelGroupType?: Maybe<TravelGroupType>;
                  reply?: Maybe<string>;
              }>;
              special?: Maybe<{ __typename?: 'Special'; id: string }>;
              optionalSurcharges: Array<{
                  __typename?: 'BookingCartItemSurcharge';
                  rentalUnitSurchargeId: string;
                  amount: number;
              }>;
          }
        | {
              __typename?: 'Ticket';
              id?: Maybe<string>;
              downloadVoucherUrl?: Maybe<string>;
              totalPrice: number;
              paymentPrice: number;
              errors: Array<string>;
              startDateTime: string;
              ticketStatus: TicketStatus;
              activity?: Maybe<{
                  __typename?: 'Activity';
                  id: string;
                  slug: string;
                  name: string;
                  place?: Maybe<string>;
                  jpegThumbnail?: Maybe<{
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  }>;
                  webpThumbnail?: Maybe<{
                      __typename?: 'GenericImage';
                      transform?: Maybe<{
                          __typename?: 'ImageTransform';
                          placeholder?: Maybe<string>;
                          srcSet: string;
                          src: string;
                          ratio: number;
                      }>;
                  }>;
              }>;
              timeslot?: Maybe<{
                  __typename?: 'Timeslot';
                  id: string;
                  startDateTime: string;
                  endDateTime: string;
                  allotment?: Maybe<number>;
                  label?: Maybe<string>;
                  rateGroup?: Maybe<{
                      __typename?: 'ActivityRateGroup';
                      releaseHours?: Maybe<number>;
                      rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                  }>;
              }>;
              priceLines: Array<{
                  __typename?: 'PriceLine';
                  label: string;
                  quantity?: Maybe<string>;
                  totalPrice: number;
                  unitPrice?: Maybe<number>;
                  category?: Maybe<PriceLineCategoryEnum>;
              }>;
              rateLines: Array<{
                  __typename?: 'TicketRateLine';
                  amount: number;
                  rateId?: Maybe<string>;
                  totalPrice: number;
                  unitPrice: number;
              }>;
          }
        | {
              __typename?: 'UpsellPurchase';
              id?: Maybe<string>;
              totalPrice: number;
              paymentPrice: number;
              errors: Array<string>;
              amount: number;
              priceLines: Array<{
                  __typename?: 'PriceLine';
                  label: string;
                  quantity?: Maybe<string>;
                  totalPrice: number;
                  unitPrice?: Maybe<number>;
                  category?: Maybe<PriceLineCategoryEnum>;
              }>;
              offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
          }
    >;
};

export type PlannerPageRentalUnitQueryVariables = Exact<{
    slug: Scalars['String'];
}>;

export type PlannerPageRentalUnitQuery = {
    __typename?: 'Query';
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        brand: string;
        maxOccupancy: number;
        petsAllowed: number;
    }>;
};

export type RentalUnitReviewQueryVariables = Exact<{
    unitSlug: Scalars['String'];
}>;

export type RentalUnitReviewQuery = {
    __typename?: 'Query';
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        code: string;
        name: string;
        slug: string;
        maxOccupancy: number;
        minOccupancy: number;
        petsAllowed: number;
        type: RentalUnitTypeEnum;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            hasPublicPage: boolean;
            name: string;
            checkInEndTime: string;
            checkInStartTime: string;
            checkOutTime: string;
            type: AccommodationTypeEnum;
        };
        jpegThumbnail: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
        webpThumbnail: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
    }>;
};

export type RentalUnitReviewFragment = {
    __typename?: 'RentalUnit';
    id: string;
    code: string;
    name: string;
    slug: string;
    maxOccupancy: number;
    minOccupancy: number;
    petsAllowed: number;
    type: RentalUnitTypeEnum;
    accommodation: {
        __typename?: 'Accommodation';
        id: string;
        hasPublicPage: boolean;
        name: string;
        checkInEndTime: string;
        checkInStartTime: string;
        checkOutTime: string;
        type: AccommodationTypeEnum;
    };
    jpegThumbnail: {
        __typename?: 'GenericImage';
        transform?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    };
    webpThumbnail: {
        __typename?: 'GenericImage';
        transform?: Maybe<{
            __typename?: 'ImageTransform';
            placeholder?: Maybe<string>;
            srcSet: string;
            src: string;
            ratio: number;
        }>;
    };
};

export type LeaveUnauthenticatedReviewMutationVariables = Exact<{
    input: LeaveUnauthenticatedReviewInput;
}>;

export type LeaveUnauthenticatedReviewMutation = {
    __typename?: 'Mutation';
    leaveUnauthenticatedReview: {
        __typename?: 'LeaveReviewPayload';
        review?: Maybe<{
            __typename?: 'Review';
            id: string;
            average: number;
            comment: string;
            date: string;
            ratingHospitality?: Maybe<number>;
            ratingLayout?: Maybe<number>;
            ratingLocation?: Maybe<number>;
            ratingMaintenance?: Maybe<number>;
            ratingValueForMoney?: Maybe<number>;
            tips?: Maybe<string>;
            title: string;
            travelGroupType?: Maybe<TravelGroupType>;
            reply?: Maybe<string>;
        }>;
    };
};

export type SearchPageRentalUnitsQueryVariables = Exact<{
    babies: Scalars['Int'];
    filters?: Maybe<Array<PropertyFilterInput> | PropertyFilterInput>;
    persons: Scalars['Int'];
    pets: Scalars['Int'];
    mapArea?: Maybe<BoundingBoxInput>;
    offset?: Maybe<Scalars['Int']>;
    order?: Maybe<SearchRentalUnitsOrderEnum>;
    limit?: Maybe<Scalars['Int']>;
    badgeHandle: Scalars['String'];
    badge2Handle: Scalars['String'];
}>;

export type SearchPageRentalUnitsQuery = {
    __typename?: 'Query';
    searchRentalUnits: {
        __typename?: 'SearchRentalUnitsResult';
        totalHits: number;
        hits: Array<{
            __typename?: 'RentalUnit';
            id: string;
            name: string;
            slug: string;
            type: RentalUnitTypeEnum;
            code: string;
            brand: string;
            amountBedrooms?: Maybe<number>;
            maxOccupancy: number;
            flexCancellationDays?: Maybe<number>;
            tagline: string;
            fullCircleImageUrl?: Maybe<string>;
            videoUrl?: Maybe<string>;
            hasBadge: boolean;
            hasbadge2: boolean;
            reviewStats?: Maybe<{ __typename?: 'ReviewStats'; count: number; average: number }>;
            mainImage?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{ __typename?: 'ImageTransform'; src: string }>;
            }>;
            webpListImage: {
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            };
            listImage: {
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            };
            accommodation: {
                __typename?: 'Accommodation';
                id: string;
                place: string;
                name: string;
                hasPublicPage: boolean;
                collapseInSearch: boolean;
                address: { __typename?: 'Address'; city?: Maybe<string> };
                coordinates: { __typename?: 'LatLon'; lat: number; lon: number };
            };
        }>;
        propertyFilterCounts: Array<{ __typename?: 'PropertyFilterCount'; hits: number; property: string }>;
    };
};

export type SearchTripsQueryVariables = Exact<{
    babies: Scalars['Int'];
    date: Array<Scalars['String']> | Scalars['String'];
    duration: Array<Scalars['Int']> | Scalars['Int'];
    filters?: Maybe<Array<PropertyFilterInput> | PropertyFilterInput>;
    persons: Scalars['Int'];
    pets: Scalars['Int'];
    price?: Maybe<RangeFilterInput>;
    specialId?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
    tripType?: Maybe<Array<TripTypeEnum> | TripTypeEnum>;
    mapArea?: Maybe<BoundingBoxInput>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order?: Maybe<UnitSearchOrderEnum>;
    badgeHandle: Scalars['String'];
    badge2Handle: Scalars['String'];
}>;

export type SearchTripsQuery = {
    __typename?: 'Query';
    searchTrips: {
        __typename?: 'SearchTripsResult';
        hits: Array<{
            __typename?: 'Trip';
            originalPrice?: Maybe<number>;
            date: string;
            duration: number;
            price: number;
            type: TripTypeEnum;
            rentalUnit: {
                __typename?: 'RentalUnit';
                id: string;
                name: string;
                slug: string;
                type: RentalUnitTypeEnum;
                code: string;
                brand: string;
                amountBedrooms?: Maybe<number>;
                maxOccupancy: number;
                flexCancellationDays?: Maybe<number>;
                tagline: string;
                fullCircleImageUrl?: Maybe<string>;
                videoUrl?: Maybe<string>;
                hasBadge: boolean;
                hasbadge2: boolean;
                reviewStats?: Maybe<{ __typename?: 'ReviewStats'; count: number; average: number }>;
                mainImage?: Maybe<{
                    __typename?: 'GenericImage';
                    transform?: Maybe<{ __typename?: 'ImageTransform'; src: string }>;
                }>;
                webpListImage: {
                    __typename?: 'GenericImage';
                    transform?: Maybe<{
                        __typename?: 'ImageTransform';
                        placeholder?: Maybe<string>;
                        srcSet: string;
                        src: string;
                        ratio: number;
                    }>;
                };
                listImage: {
                    __typename?: 'GenericImage';
                    transform?: Maybe<{
                        __typename?: 'ImageTransform';
                        placeholder?: Maybe<string>;
                        srcSet: string;
                        src: string;
                        ratio: number;
                    }>;
                };
                accommodation: {
                    __typename?: 'Accommodation';
                    id: string;
                    place: string;
                    name: string;
                    hasPublicPage: boolean;
                    collapseInSearch: boolean;
                    address: { __typename?: 'Address'; city?: Maybe<string> };
                    coordinates: { __typename?: 'LatLon'; lat: number; lon: number };
                };
            };
            special?: Maybe<{
                __typename?: 'Special';
                id: string;
                name: string;
                description: string;
                landingPageUrl?: Maybe<string>;
            }>;
        }>;
        stats: {
            __typename?: 'SearchTripsStats';
            totalHits: number;
            minPrice?: Maybe<number>;
            maxPrice?: Maybe<number>;
            propertyFilterCounts: Array<{ __typename?: 'PropertyFilterCount'; hits: number; property: string }>;
            tripTypeCounts: Array<{ __typename?: 'TripTypeCount'; tripType: TripTypeEnum; hits: number }>;
            specialCounts: Array<{
                __typename?: 'SpecialCount';
                hits: number;
                special: {
                    __typename?: 'Special';
                    description: string;
                    id: string;
                    landingPageUrl?: Maybe<string>;
                    name: string;
                };
            }>;
        };
    };
};

export type UnitFilterPropertiesQueryVariables = Exact<{ [key: string]: never }>;

export type UnitFilterPropertiesQuery = {
    __typename?: 'Query';
    unitFilterProperties: Array<{
        __typename?: 'UnitFilterPropertyCategory';
        name: string;
        handle: string;
        filterProperties: Array<{ __typename?: 'UnitFilterProperty'; name: string; handle: string }>;
    }>;
};

export type UnitSearchPriceFilterQueryVariables = Exact<{
    params: UnitSearchParamsInput;
}>;

export type UnitSearchPriceFilterQuery = {
    __typename?: 'Query';
    unitSearch: { __typename?: 'UnitSearchResult'; minPrice?: Maybe<number>; maxPrice?: Maybe<number> };
};

export type PropertyFilterCountsFragment = { __typename?: 'PropertyFilterCount'; hits: number; property: string };

export type AddressFragment = {
    __typename?: 'Address';
    city?: Maybe<string>;
    countryCode?: Maybe<string>;
    number?: Maybe<string>;
    postalCode?: Maybe<string>;
    street?: Maybe<string>;
};

export type Booking_Booking_Fragment = {
    __typename?: 'Booking';
    amountAdults: number;
    amountPets: number;
    amountChildren: number;
    amountBabies: number;
    amountYouths: number;
    arrivalDate: string;
    departureDate: string;
    hasCancellationInsurance: boolean;
    hasContentsInsurance: boolean;
    totalPrice: number;
    optionalSurcharges: Array<{
        __typename?: 'BookingCartItemSurcharge';
        rentalUnitSurchargeId: string;
        amount: number;
    }>;
    rentalUnit?: Maybe<{ __typename?: 'RentalUnit'; id: string }>;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
    }>;
};

export type Booking_Ticket_Fragment = {
    __typename?: 'Ticket';
    totalPrice: number;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
    }>;
};

export type Booking_UpsellPurchase_Fragment = {
    __typename?: 'UpsellPurchase';
    totalPrice: number;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
    }>;
};

export type BookingFragment = Booking_Booking_Fragment | Booking_Ticket_Fragment | Booking_UpsellPurchase_Fragment;

export type OrderMetaFragment = {
    __typename?: 'Order';
    number?: Maybe<string>;
    errors: Array<string>;
    totalPrice: number;
    paymentOptions?: Maybe<{ __typename?: 'PaymentOptions'; totalAmountDue: number; partialPaymentAmount: number }>;
};

export type TripPlannerQueryVariables = Exact<{
    slug: Scalars['String'];
    start: Scalars['String'];
    end: Scalars['String'];
    persons: Scalars['Int'];
    pets: Scalars['Int'];
    babies: Scalars['Int'];
}>;

export type TripPlannerQuery = {
    __typename?: 'Query';
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        name: string;
        code: string;
        brand: string;
        trips: Array<{
            __typename?: 'Trip';
            date: string;
            price: number;
            duration: number;
            type: TripTypeEnum;
            originalPrice?: Maybe<number>;
            specialId?: Maybe<string>;
        }>;
    }>;
};

export type PlannerTripFragment = {
    __typename?: 'Trip';
    date: string;
    price: number;
    duration: number;
    type: TripTypeEnum;
    originalPrice?: Maybe<number>;
    specialId?: Maybe<string>;
};

export type GetCartQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetCartQuery = {
    __typename?: 'Query';
    cart?: Maybe<{
        __typename?: 'Cart';
        id: string;
        status: CartStatusEnum;
        availableUpsellOffers: Array<{
            __typename?: 'UpsellOffer';
            id: string;
            name: string;
            unitPrice: number;
            maxAmount: number;
            description?: Maybe<string>;
        }>;
        order: {
            __typename?: 'Order';
            orderItems: Array<
                | {
                      __typename?: 'Booking';
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      status: BookingStatus;
                      amountAdults: number;
                      amountPets: number;
                      amountChildren: number;
                      amountBabies: number;
                      amountYouths: number;
                      arrivalDate: string;
                      departureDate: string;
                      hasCancellationInsurance: boolean;
                      hasContentsInsurance: boolean;
                      handleDepositPayment: boolean;
                      deposit: number;
                      requiresApproval: boolean;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      rentalUnit?: Maybe<{ __typename?: 'RentalUnit'; id: string }>;
                      special?: Maybe<{ __typename?: 'Special'; id: string }>;
                      optionalSurcharges: Array<{
                          __typename?: 'BookingCartItemSurcharge';
                          rentalUnitSurchargeId: string;
                          amount: number;
                      }>;
                  }
                | {
                      __typename?: 'Ticket';
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      startDateTime: string;
                      ticketStatus: TicketStatus;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      activity?: Maybe<{ __typename?: 'Activity'; id: string; slug: string }>;
                      timeslot?: Maybe<{
                          __typename?: 'Timeslot';
                          id: string;
                          allotment?: Maybe<number>;
                          startDateTime: string;
                          endDateTime: string;
                          label?: Maybe<string>;
                          rateGroup?: Maybe<{
                              __typename?: 'ActivityRateGroup';
                              releaseHours?: Maybe<number>;
                              rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                          }>;
                      }>;
                      rateLines: Array<{
                          __typename?: 'TicketRateLine';
                          amount: number;
                          rateId?: Maybe<string>;
                          totalPrice: number;
                          unitPrice: number;
                      }>;
                  }
                | {
                      __typename?: 'UpsellPurchase';
                      totalPrice: number;
                      paymentPrice: number;
                      errors: Array<string>;
                      amount: number;
                      priceLines: Array<{
                          __typename?: 'PriceLine';
                          label: string;
                          quantity?: Maybe<string>;
                          totalPrice: number;
                          unitPrice?: Maybe<number>;
                          category?: Maybe<PriceLineCategoryEnum>;
                      }>;
                      offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
                  }
            >;
        };
    }>;
};

export type CartStatusQueryVariables = Exact<{
    cartId: Scalars['ID'];
}>;

export type CartStatusQuery = {
    __typename?: 'Query';
    cart?: Maybe<{ __typename?: 'Cart'; id: string; status: CartStatusEnum }>;
};

export type SetCartMutationVariables = Exact<{
    input: UpdateCartInput;
}>;

export type SetCartMutation = {
    __typename?: 'Mutation';
    updateCart: {
        __typename?: 'UpdateCartPayload';
        cart?: Maybe<{
            __typename?: 'Cart';
            id: string;
            status: CartStatusEnum;
            availableUpsellOffers: Array<{
                __typename?: 'UpsellOffer';
                id: string;
                name: string;
                unitPrice: number;
                maxAmount: number;
                description?: Maybe<string>;
            }>;
            order: {
                __typename?: 'Order';
                orderItems: Array<
                    | {
                          __typename?: 'Booking';
                          totalPrice: number;
                          paymentPrice: number;
                          errors: Array<string>;
                          status: BookingStatus;
                          amountAdults: number;
                          amountPets: number;
                          amountChildren: number;
                          amountBabies: number;
                          amountYouths: number;
                          arrivalDate: string;
                          departureDate: string;
                          hasCancellationInsurance: boolean;
                          hasContentsInsurance: boolean;
                          handleDepositPayment: boolean;
                          deposit: number;
                          requiresApproval: boolean;
                          priceLines: Array<{
                              __typename?: 'PriceLine';
                              label: string;
                              quantity?: Maybe<string>;
                              totalPrice: number;
                              unitPrice?: Maybe<number>;
                              category?: Maybe<PriceLineCategoryEnum>;
                          }>;
                          rentalUnit?: Maybe<{ __typename?: 'RentalUnit'; id: string }>;
                          special?: Maybe<{ __typename?: 'Special'; id: string }>;
                          optionalSurcharges: Array<{
                              __typename?: 'BookingCartItemSurcharge';
                              rentalUnitSurchargeId: string;
                              amount: number;
                          }>;
                      }
                    | {
                          __typename?: 'Ticket';
                          totalPrice: number;
                          paymentPrice: number;
                          errors: Array<string>;
                          startDateTime: string;
                          ticketStatus: TicketStatus;
                          priceLines: Array<{
                              __typename?: 'PriceLine';
                              label: string;
                              quantity?: Maybe<string>;
                              totalPrice: number;
                              unitPrice?: Maybe<number>;
                              category?: Maybe<PriceLineCategoryEnum>;
                          }>;
                          activity?: Maybe<{ __typename?: 'Activity'; id: string; slug: string }>;
                          timeslot?: Maybe<{
                              __typename?: 'Timeslot';
                              id: string;
                              allotment?: Maybe<number>;
                              startDateTime: string;
                              endDateTime: string;
                              label?: Maybe<string>;
                              rateGroup?: Maybe<{
                                  __typename?: 'ActivityRateGroup';
                                  releaseHours?: Maybe<number>;
                                  rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                              }>;
                          }>;
                          rateLines: Array<{
                              __typename?: 'TicketRateLine';
                              amount: number;
                              rateId?: Maybe<string>;
                              totalPrice: number;
                              unitPrice: number;
                          }>;
                      }
                    | {
                          __typename?: 'UpsellPurchase';
                          totalPrice: number;
                          paymentPrice: number;
                          errors: Array<string>;
                          amount: number;
                          priceLines: Array<{
                              __typename?: 'PriceLine';
                              label: string;
                              quantity?: Maybe<string>;
                              totalPrice: number;
                              unitPrice?: Maybe<number>;
                              category?: Maybe<PriceLineCategoryEnum>;
                          }>;
                          offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
                      }
                >;
            };
        }>;
    };
};

export type GetCartFragment = {
    __typename?: 'Cart';
    id: string;
    status: CartStatusEnum;
    availableUpsellOffers: Array<{
        __typename?: 'UpsellOffer';
        id: string;
        name: string;
        unitPrice: number;
        maxAmount: number;
        description?: Maybe<string>;
    }>;
    order: {
        __typename?: 'Order';
        orderItems: Array<
            | {
                  __typename?: 'Booking';
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  status: BookingStatus;
                  amountAdults: number;
                  amountPets: number;
                  amountChildren: number;
                  amountBabies: number;
                  amountYouths: number;
                  arrivalDate: string;
                  departureDate: string;
                  hasCancellationInsurance: boolean;
                  hasContentsInsurance: boolean;
                  handleDepositPayment: boolean;
                  deposit: number;
                  requiresApproval: boolean;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  rentalUnit?: Maybe<{ __typename?: 'RentalUnit'; id: string }>;
                  special?: Maybe<{ __typename?: 'Special'; id: string }>;
                  optionalSurcharges: Array<{
                      __typename?: 'BookingCartItemSurcharge';
                      rentalUnitSurchargeId: string;
                      amount: number;
                  }>;
              }
            | {
                  __typename?: 'Ticket';
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  startDateTime: string;
                  ticketStatus: TicketStatus;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  activity?: Maybe<{ __typename?: 'Activity'; id: string; slug: string }>;
                  timeslot?: Maybe<{
                      __typename?: 'Timeslot';
                      id: string;
                      allotment?: Maybe<number>;
                      startDateTime: string;
                      endDateTime: string;
                      label?: Maybe<string>;
                      rateGroup?: Maybe<{
                          __typename?: 'ActivityRateGroup';
                          releaseHours?: Maybe<number>;
                          rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
                      }>;
                  }>;
                  rateLines: Array<{
                      __typename?: 'TicketRateLine';
                      amount: number;
                      rateId?: Maybe<string>;
                      totalPrice: number;
                      unitPrice: number;
                  }>;
              }
            | {
                  __typename?: 'UpsellPurchase';
                  totalPrice: number;
                  paymentPrice: number;
                  errors: Array<string>;
                  amount: number;
                  priceLines: Array<{
                      __typename?: 'PriceLine';
                      label: string;
                      quantity?: Maybe<string>;
                      totalPrice: number;
                      unitPrice?: Maybe<number>;
                      category?: Maybe<PriceLineCategoryEnum>;
                  }>;
                  offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
              }
        >;
    };
};

export type CartOrderItem_Booking_Fragment = {
    __typename?: 'Booking';
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    status: BookingStatus;
    amountAdults: number;
    amountPets: number;
    amountChildren: number;
    amountBabies: number;
    amountYouths: number;
    arrivalDate: string;
    departureDate: string;
    hasCancellationInsurance: boolean;
    hasContentsInsurance: boolean;
    handleDepositPayment: boolean;
    deposit: number;
    requiresApproval: boolean;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    rentalUnit?: Maybe<{ __typename?: 'RentalUnit'; id: string }>;
    special?: Maybe<{ __typename?: 'Special'; id: string }>;
    optionalSurcharges: Array<{
        __typename?: 'BookingCartItemSurcharge';
        rentalUnitSurchargeId: string;
        amount: number;
    }>;
};

export type CartOrderItem_Ticket_Fragment = {
    __typename?: 'Ticket';
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    startDateTime: string;
    ticketStatus: TicketStatus;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    activity?: Maybe<{ __typename?: 'Activity'; id: string; slug: string }>;
    timeslot?: Maybe<{
        __typename?: 'Timeslot';
        id: string;
        allotment?: Maybe<number>;
        startDateTime: string;
        endDateTime: string;
        label?: Maybe<string>;
        rateGroup?: Maybe<{
            __typename?: 'ActivityRateGroup';
            releaseHours?: Maybe<number>;
            rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
        }>;
    }>;
    rateLines: Array<{
        __typename?: 'TicketRateLine';
        amount: number;
        rateId?: Maybe<string>;
        totalPrice: number;
        unitPrice: number;
    }>;
};

export type CartOrderItem_UpsellPurchase_Fragment = {
    __typename?: 'UpsellPurchase';
    totalPrice: number;
    paymentPrice: number;
    errors: Array<string>;
    amount: number;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
};

export type CartOrderItemFragment =
    | CartOrderItem_Booking_Fragment
    | CartOrderItem_Ticket_Fragment
    | CartOrderItem_UpsellPurchase_Fragment;

export type CartUpsellFragment = {
    __typename?: 'UpsellPurchase';
    totalPrice: number;
    paymentPrice: number;
    amount: number;
    priceLines: Array<{
        __typename?: 'PriceLine';
        label: string;
        quantity?: Maybe<string>;
        totalPrice: number;
        unitPrice?: Maybe<number>;
        category?: Maybe<PriceLineCategoryEnum>;
    }>;
    offer?: Maybe<{ __typename?: 'UpsellOffer'; id: string }>;
};

export type CartBookingFragment = {
    __typename?: 'Booking';
    status: BookingStatus;
    amountAdults: number;
    amountPets: number;
    amountChildren: number;
    amountBabies: number;
    amountYouths: number;
    arrivalDate: string;
    departureDate: string;
    hasCancellationInsurance: boolean;
    hasContentsInsurance: boolean;
    handleDepositPayment: boolean;
    deposit: number;
    requiresApproval: boolean;
    paymentPrice: number;
    rentalUnit?: Maybe<{ __typename?: 'RentalUnit'; id: string }>;
    special?: Maybe<{ __typename?: 'Special'; id: string }>;
    optionalSurcharges: Array<{
        __typename?: 'BookingCartItemSurcharge';
        rentalUnitSurchargeId: string;
        amount: number;
    }>;
};

export type CartTicketFragment = {
    __typename?: 'Ticket';
    startDateTime: string;
    ticketStatus: TicketStatus;
    activity?: Maybe<{ __typename?: 'Activity'; id: string; slug: string }>;
    timeslot?: Maybe<{
        __typename?: 'Timeslot';
        id: string;
        allotment?: Maybe<number>;
        startDateTime: string;
        endDateTime: string;
        label?: Maybe<string>;
        rateGroup?: Maybe<{
            __typename?: 'ActivityRateGroup';
            releaseHours?: Maybe<number>;
            rates: Array<{ __typename?: 'Rate'; id: string; name: string; price: number }>;
        }>;
    }>;
    rateLines: Array<{
        __typename?: 'TicketRateLine';
        amount: number;
        rateId?: Maybe<string>;
        totalPrice: number;
        unitPrice: number;
    }>;
};

export type UnitDetailsQueryVariables = Exact<{
    slug: Scalars['String'];
    arrivalDate?: Maybe<Scalars['String']>;
    bakedInFilterProperty: Scalars['String'];
}>;

export type UnitDetailsQuery = {
    __typename?: 'Query';
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        firstTripDate?: Maybe<string>;
        flexCancellationDays?: Maybe<number>;
        slug: string;
        name: string;
        brand: string;
        municipalRegistration?: Maybe<string>;
        tagline: string;
        minOccupancy: number;
        maxOccupancy: number;
        petsAllowed: number;
        descriptionLayout: string;
        descriptionParticulars?: Maybe<string>;
        descriptionExtras?: Maybe<string>;
        cancellationInsuranceAvailable: boolean;
        fullCircleImageUrl?: Maybe<string>;
        videoUrl?: Maybe<string>;
        livingArea?: Maybe<number>;
        outdoorArea?: Maybe<number>;
        amountBedrooms?: Maybe<number>;
        amountBathrooms?: Maybe<number>;
        amountToilets?: Maybe<number>;
        code: string;
        type: RentalUnitTypeEnum;
        hasBakedInFilterProperty: boolean;
        jpegThumbnail: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
        webpThumbnail: {
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        };
        jpegHero?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        webpHero?: Maybe<{
            __typename?: 'GenericImage';
            transform?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
        surcharges: Array<{
            __typename?: 'RentalUnitSurcharge';
            id: string;
            name: string;
            calculation: SurchargeCalculationEnum;
            description?: Maybe<string>;
            maxAmount: number;
            minAmount: number;
            unitPrice: number;
        }>;
        reviewStats?: Maybe<{
            __typename?: 'ReviewStats';
            count: number;
            average: number;
            location: number;
            hospitality: number;
            layout: number;
            maintenance: number;
            valueForMoney: number;
        }>;
        attributeCategories: Array<{
            __typename?: 'AttributeCategory';
            name: string;
            handle: string;
            attributes: Array<{
                __typename?: 'Attribute';
                id: string;
                name: string;
                iconUrl?: Maybe<string>;
                handle: string;
            }>;
        }>;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            slug: string;
            descriptionGeneral?: Maybe<string>;
            place: string;
            name: string;
            checkInEndTime: string;
            checkInStartTime: string;
            checkOutTime: string;
            hasPublicPage: boolean;
            ownerIntroduction?: Maybe<string>;
            ownerName?: Maybe<string>;
            ownerTips?: Maybe<string>;
            descriptionLocation: string;
            jpegMain?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            webpMain?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            jpegOwner?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            webpOwner?: Maybe<{
                __typename?: 'GenericImage';
                transform?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
            pointsOfInterest: Array<{ __typename?: 'PointOfInterest'; name: string; distance: number }>;
            coordinates: { __typename?: 'LatLon'; lat: number; lon: number };
            images: Array<{
                __typename?: 'AccommodationImage';
                category: AccommodationImageCategoryEnum;
                jpegTile?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
                webpTile?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
                jpegGallery?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
                webpGallery?: Maybe<{
                    __typename?: 'ImageTransform';
                    placeholder?: Maybe<string>;
                    srcSet: string;
                    src: string;
                    ratio: number;
                }>;
            }>;
        };
        reviews: Array<{
            __typename?: 'Review';
            id: string;
            title: string;
            average: number;
            city?: Maybe<string>;
            date: string;
            comment: string;
            reply?: Maybe<string>;
        }>;
        images: Array<{
            __typename?: 'AccommodationImage';
            category: AccommodationImageCategoryEnum;
            jpegTile?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
            webpTile?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
            jpegGallery?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
            webpGallery?: Maybe<{
                __typename?: 'ImageTransform';
                placeholder?: Maybe<string>;
                srcSet: string;
                src: string;
                ratio: number;
            }>;
        }>;
    }>;
};

export type UnitDetailsSearchQueryVariables = Exact<{
    slug: Scalars['String'];
    params?: Maybe<UnitSearchParamsInput>;
    arrivalDate?: Maybe<Scalars['String']>;
    badgeHandle: Scalars['String'];
    badge2Handle: Scalars['String'];
}>;

export type UnitDetailsSearchQuery = {
    __typename?: 'Query';
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        alternativesInAccommodation?: Maybe<{
            __typename?: 'UnitSearchResult';
            hits: Array<{
                __typename?: 'UnitSearchHit';
                rentalUnit: {
                    __typename?: 'RentalUnit';
                    id: string;
                    name: string;
                    slug: string;
                    type: RentalUnitTypeEnum;
                    code: string;
                    brand: string;
                    amountBedrooms?: Maybe<number>;
                    maxOccupancy: number;
                    flexCancellationDays?: Maybe<number>;
                    tagline: string;
                    fullCircleImageUrl?: Maybe<string>;
                    videoUrl?: Maybe<string>;
                    hasBadge: boolean;
                    hasbadge2: boolean;
                    reviewStats?: Maybe<{ __typename?: 'ReviewStats'; count: number; average: number }>;
                    mainImage?: Maybe<{
                        __typename?: 'GenericImage';
                        transform?: Maybe<{ __typename?: 'ImageTransform'; src: string }>;
                    }>;
                    webpListImage: {
                        __typename?: 'GenericImage';
                        transform?: Maybe<{
                            __typename?: 'ImageTransform';
                            placeholder?: Maybe<string>;
                            srcSet: string;
                            src: string;
                            ratio: number;
                        }>;
                    };
                    listImage: {
                        __typename?: 'GenericImage';
                        transform?: Maybe<{
                            __typename?: 'ImageTransform';
                            placeholder?: Maybe<string>;
                            srcSet: string;
                            src: string;
                            ratio: number;
                        }>;
                    };
                    accommodation: {
                        __typename?: 'Accommodation';
                        id: string;
                        place: string;
                        name: string;
                        hasPublicPage: boolean;
                        collapseInSearch: boolean;
                        address: { __typename?: 'Address'; city?: Maybe<string> };
                        coordinates: { __typename?: 'LatLon'; lat: number; lon: number };
                    };
                };
                trips: Array<{
                    __typename?: 'Trip';
                    originalPrice?: Maybe<number>;
                    date: string;
                    duration: number;
                    price: number;
                    type: TripTypeEnum;
                    special?: Maybe<{
                        __typename?: 'Special';
                        id: string;
                        name: string;
                        description: string;
                        landingPageUrl?: Maybe<string>;
                    }>;
                }>;
            }>;
        }>;
        surcharges: Array<{
            __typename?: 'RentalUnitSurcharge';
            id: string;
            name: string;
            calculation: SurchargeCalculationEnum;
            description?: Maybe<string>;
            maxAmount: number;
            minAmount: number;
            unitPrice: number;
        }>;
    }>;
};

export type UnitDetailInitialTripsQueryVariables = Exact<{
    slug: Scalars['String'];
    start: Scalars['String'];
    end: Scalars['String'];
    persons: Scalars['Int'];
    pets: Scalars['Int'];
    babies: Scalars['Int'];
}>;

export type UnitDetailInitialTripsQuery = {
    __typename?: 'Query';
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        trips: Array<{
            __typename?: 'Trip';
            date: string;
            price: number;
            duration: number;
            type: TripTypeEnum;
            originalPrice?: Maybe<number>;
            specialId?: Maybe<string>;
        }>;
    }>;
};

export type AlternativesInAccommodationQueryVariables = Exact<{
    slug: Scalars['String'];
    params?: Maybe<UnitSearchParamsInput>;
    badgeHandle: Scalars['String'];
    badge2Handle: Scalars['String'];
}>;

export type AlternativesInAccommodationQuery = {
    __typename?: 'Query';
    rentalUnit?: Maybe<{
        __typename?: 'RentalUnit';
        id: string;
        alternativesInAccommodation?: Maybe<{
            __typename?: 'UnitSearchResult';
            hits: Array<{
                __typename?: 'UnitSearchHit';
                rentalUnit: {
                    __typename?: 'RentalUnit';
                    id: string;
                    name: string;
                    slug: string;
                    type: RentalUnitTypeEnum;
                    code: string;
                    brand: string;
                    amountBedrooms?: Maybe<number>;
                    maxOccupancy: number;
                    flexCancellationDays?: Maybe<number>;
                    tagline: string;
                    fullCircleImageUrl?: Maybe<string>;
                    videoUrl?: Maybe<string>;
                    hasBadge: boolean;
                    hasbadge2: boolean;
                    reviewStats?: Maybe<{ __typename?: 'ReviewStats'; count: number; average: number }>;
                    mainImage?: Maybe<{
                        __typename?: 'GenericImage';
                        transform?: Maybe<{ __typename?: 'ImageTransform'; src: string }>;
                    }>;
                    webpListImage: {
                        __typename?: 'GenericImage';
                        transform?: Maybe<{
                            __typename?: 'ImageTransform';
                            placeholder?: Maybe<string>;
                            srcSet: string;
                            src: string;
                            ratio: number;
                        }>;
                    };
                    listImage: {
                        __typename?: 'GenericImage';
                        transform?: Maybe<{
                            __typename?: 'ImageTransform';
                            placeholder?: Maybe<string>;
                            srcSet: string;
                            src: string;
                            ratio: number;
                        }>;
                    };
                    accommodation: {
                        __typename?: 'Accommodation';
                        id: string;
                        place: string;
                        name: string;
                        hasPublicPage: boolean;
                        collapseInSearch: boolean;
                        address: { __typename?: 'Address'; city?: Maybe<string> };
                        coordinates: { __typename?: 'LatLon'; lat: number; lon: number };
                    };
                };
                trips: Array<{
                    __typename?: 'Trip';
                    originalPrice?: Maybe<number>;
                    date: string;
                    duration: number;
                    price: number;
                    type: TripTypeEnum;
                    special?: Maybe<{
                        __typename?: 'Special';
                        id: string;
                        name: string;
                        description: string;
                        landingPageUrl?: Maybe<string>;
                    }>;
                }>;
            }>;
        }>;
    }>;
};

export type SurchargeFragment = {
    __typename?: 'RentalUnitSurcharge';
    id: string;
    name: string;
    calculation: SurchargeCalculationEnum;
    description?: Maybe<string>;
    maxAmount: number;
    minAmount: number;
    unitPrice: number;
};

export type TorSitemapQueryVariables = Exact<{
    params: UnitSearchParamsInput;
}>;

export type TorSitemapQuery = {
    __typename?: 'Query';
    unitSearch: {
        __typename?: 'UnitSearchResult';
        hits: Array<{
            __typename?: 'UnitSearchHit';
            rentalUnit: {
                __typename?: 'RentalUnit';
                slug: string;
                accommodation: {
                    __typename?: 'Accommodation';
                    hasPublicPage: boolean;
                    slug: string;
                    mainImage?: Maybe<{
                        __typename?: 'GenericImage';
                        transform?: Maybe<{ __typename?: 'ImageTransform'; src: string }>;
                    }>;
                };
                listImage: {
                    __typename?: 'GenericImage';
                    transform?: Maybe<{ __typename?: 'ImageTransform'; src: string }>;
                };
            };
        }>;
    };
};

export const TimeOpenClosedFragmentDoc = gql`
    fragment TimeOpenClosed on TimeOpenClosed {
        timeOpen
        timeClosed
    }
`;
export const CompanyOperatingHoursFragmentDoc = gql`
    fragment CompanyOperatingHours on Company {
        normalOperatingHours {
            monday {
                ...TimeOpenClosed
            }
            tuesday {
                ...TimeOpenClosed
            }
            wednesday {
                ...TimeOpenClosed
            }
            thursday {
                ...TimeOpenClosed
            }
            friday {
                ...TimeOpenClosed
            }
            saturday {
                ...TimeOpenClosed
            }
            sunday {
                ...TimeOpenClosed
            }
        }
        specialOperatingHours {
            startDate
            endDate
            monday {
                ...TimeOpenClosed
            }
            tuesday {
                ...TimeOpenClosed
            }
            wednesday {
                ...TimeOpenClosed
            }
            thursday {
                ...TimeOpenClosed
            }
            friday {
                ...TimeOpenClosed
            }
            saturday {
                ...TimeOpenClosed
            }
            sunday {
                ...TimeOpenClosed
            }
        }
    }
    ${TimeOpenClosedFragmentDoc}
`;
export const ActivityPlannerActivityFragmentDoc = gql`
    fragment ActivityPlannerActivity on Activity {
        name
        bookingType
        externalBookingUrl
        company {
            ...CompanyOperatingHours
        }
    }
    ${CompanyOperatingHoursFragmentDoc}
`;
export const CheckoutPaymentsBookingFragmentDoc = gql`
    fragment CheckoutPaymentsBooking on Booking {
        rentalUnit {
            id
            name
            accommodation {
                id
                checkInEndTime
                checkInStartTime
                checkOutTime
            }
        }
    }
`;
export const PaymentFragmentDoc = gql`
    fragment Payment on Payment {
        amount
        date
        status
    }
`;
export const ExtrasFragmentDoc = gql`
    fragment Extras on AttributeCategory {
        name
        handle
        attributes {
            id
            name
            iconUrl
            handle
        }
    }
`;
export const LocationFragmentDoc = gql`
    fragment Location on Accommodation {
        descriptionLocation
        pointsOfInterest {
            name
            distance
        }
        coordinates {
            lat
            lon
        }
    }
`;
export const SpecialCountFragmentDoc = gql`
    fragment SpecialCount on UnitSearchResult {
        specialCounts {
            special {
                description
                id
                landingPageUrl
                name
            }
            hits
        }
    }
`;
export const TripTypeCountFragmentDoc = gql`
    fragment TripTypeCount on UnitSearchResult {
        tripTypeCounts {
            hits
            tripType
        }
    }
`;
export const ListImageFragmentDoc = gql`
    fragment ListImage on ImageTransform {
        placeholder
        srcSet
        src
        ratio
    }
`;
export const SearchHitRentalUnitFragmentDoc = gql`
    fragment SearchHitRentalUnit on RentalUnit {
        id
        name
        slug
        type
        code
        brand
        amountBedrooms
        maxOccupancy
        flexCancellationDays
        hasBadge: hasFilterProperty(handle: $badgeHandle)
        hasbadge2: hasFilterProperty(handle: $badge2Handle)
        reviewStats {
            count
            average
        }
        mainImage {
            transform(config: HERO, format: WEBP) {
                src
            }
        }
        webpListImage: listImage {
            transform(config: TILE, format: WEBP) {
                ...ListImage
            }
        }
        listImage {
            transform(config: TILE, format: JPEG) {
                ...ListImage
            }
        }
        accommodation {
            id
            place
            address {
                city
            }
            name
            hasPublicPage
            collapseInSearch
            coordinates {
                lat
                lon
            }
        }
        tagline
        fullCircleImageUrl
        videoUrl
    }
    ${ListImageFragmentDoc}
`;
export const SearchHitTripFragmentDoc = gql`
    fragment SearchHitTrip on Trip {
        originalPrice
        date
        duration
        price
        type
        special {
            id
            name
            description
            landingPageUrl
        }
    }
`;
export const SearchHitFragmentDoc = gql`
    fragment SearchHit on UnitSearchHit {
        rentalUnit {
            ...SearchHitRentalUnit
        }
        trips {
            ...SearchHitTrip
        }
    }
    ${SearchHitRentalUnitFragmentDoc}
    ${SearchHitTripFragmentDoc}
`;
export const SearchRentalUnitsFragmentDoc = gql`
    fragment SearchRentalUnits on UnitSearchResult {
        totalHits
        hits {
            ...SearchHit
        }
    }
    ${SearchHitFragmentDoc}
`;
export const UnitFilterPropertyCategoryFragmentDoc = gql`
    fragment UnitFilterPropertyCategory on UnitFilterPropertyCategory {
        name
        handle
        filterProperties {
            name
            handle
        }
    }
`;
export const TransformFragmentDoc = gql`
    fragment Transform on ImageTransform {
        placeholder
        srcSet
        src
        ratio
    }
`;
export const UnitAccommodationFragmentDoc = gql`
    fragment UnitAccommodation on Accommodation {
        slug
        jpegMain: mainImage {
            transform(config: HERO, format: JPEG) {
                ...Transform
            }
        }
        webpMain: mainImage {
            transform(config: HERO, format: WEBP) {
                ...Transform
            }
        }
        descriptionGeneral
        name
    }
    ${TransformFragmentDoc}
`;
export const UnitFeaturesFragmentDoc = gql`
    fragment UnitFeatures on RentalUnit {
        livingArea
        outdoorArea
        maxOccupancy
        amountBedrooms
        petsAllowed
        amountBathrooms
        amountToilets
        code
        type
        accommodation {
            place
        }
    }
`;
export const UnitOwnerFragmentDoc = gql`
    fragment UnitOwner on Accommodation {
        jpegOwner: ownerImage {
            transform(config: AVATAR, format: JPEG) {
                ...Transform
            }
        }
        webpOwner: ownerImage {
            transform(config: AVATAR, format: WEBP) {
                ...Transform
            }
        }
        ownerIntroduction
        ownerName
        ownerTips
    }
    ${TransformFragmentDoc}
`;
export const ImagesFragmentDoc = gql`
    fragment Images on AccommodationImage {
        category
        jpegTile: transform(config: TILE, format: JPEG) {
            ...Transform
        }
        webpTile: transform(config: TILE, format: WEBP) {
            ...Transform
        }
        jpegGallery: transform(config: GALLERY, format: JPEG) {
            ...Transform
        }
        webpGallery: transform(config: GALLERY, format: WEBP) {
            ...Transform
        }
    }
    ${TransformFragmentDoc}
`;
export const UnitPhotosFragmentDoc = gql`
    fragment UnitPhotos on RentalUnit {
        images {
            ...Images
        }
    }
    ${ImagesFragmentDoc}
`;
export const AccommodationPhotosFragmentDoc = gql`
    fragment AccommodationPhotos on Accommodation {
        images {
            ...Images
        }
    }
    ${ImagesFragmentDoc}
`;
export const UnitRelevantAlternativesFragmentDoc = gql`
    fragment UnitRelevantAlternatives on UnitSearchResult {
        hits {
            ...SearchHit
        }
    }
    ${SearchHitFragmentDoc}
`;
export const UnitReviewsFragmentDoc = gql`
    fragment UnitReviews on Review {
        id
        title
        average
        city
        date
        comment
        reply
    }
`;
export const ActivityPlannerTimeslotFragmentDoc = gql`
    fragment ActivityPlannerTimeslot on Timeslot {
        id
        allotment
        startDateTime
        endDateTime
        label
        rateGroup {
            releaseHours
            rates {
                id
                name
                price
            }
        }
    }
`;
export const ActivityHitFragmentDoc = gql`
    fragment ActivityHit on Activity {
        id
        name
        slug
        highlighted
        place
        videoUrl
        externalBookingUrl
        bookingType
        themes {
            slug
            name
        }
        minCapacity
        maxCapacity
        timeslots(startDate: $startDate, endDate: $endDate) {
            ...ActivityPlannerTimeslot
        }
        themes {
            slug
        }
        mainImage {
            transform(config: HERO, format: JPEG) {
                ...ListImage
            }
        }
        webpMainImage: mainImage {
            transform(config: HERO, format: WEBP) {
                ...ListImage
            }
        }
        webpListImage: listImage {
            transform(config: HERO, format: WEBP) {
                ...ListImage
            }
        }
        listImage {
            transform(config: HERO, format: JPEG) {
                ...ListImage
            }
        }
    }
    ${ActivityPlannerTimeslotFragmentDoc}
    ${ListImageFragmentDoc}
`;
export const AddressFragmentDoc = gql`
    fragment Address on Address {
        city
        countryCode
        number
        postalCode
        street
    }
`;
export const OrderCustomerInfoFragmentDoc = gql`
    fragment OrderCustomerInfo on Customer {
        email
        phoneNumber
        firstName
        lastName
        address {
            ...Address
        }
    }
    ${AddressFragmentDoc}
`;
export const CheckoutConfirmTicketFragmentDoc = gql`
    fragment CheckoutConfirmTicket on Ticket {
        startDateTime
        endDateTime
        activity {
            id
            name
        }
    }
`;
export const CheckoutConfirmBookingFragmentDoc = gql`
    fragment CheckoutConfirmBooking on Booking {
        rentalUnit {
            id
            code
            type
            name
            accommodation {
                id
                checkInStartTime
                checkInEndTime
            }
        }
    }
`;
export const CartTicketFragmentDoc = gql`
    fragment CartTicket on Ticket {
        ticketStatus: status
        startDateTime
        activity {
            id
            slug
        }
        timeslot {
            ...ActivityPlannerTimeslot
        }
        rateLines {
            amount
            rateId
            totalPrice
            unitPrice
        }
    }
    ${ActivityPlannerTimeslotFragmentDoc}
`;
export const CartBookingFragmentDoc = gql`
    fragment CartBooking on Booking {
        status
        amountAdults
        amountPets
        amountChildren
        amountBabies
        amountYouths
        arrivalDate
        departureDate
        hasCancellationInsurance
        hasContentsInsurance
        handleDepositPayment
        deposit
        requiresApproval
        paymentPrice
        rentalUnit {
            id
        }
        special {
            id
        }
        optionalSurcharges {
            rentalUnitSurchargeId
            amount
        }
    }
`;
export const CartUpsellFragmentDoc = gql`
    fragment CartUpsell on UpsellPurchase {
        priceLines {
            label
            quantity
            totalPrice
            unitPrice
            category
        }
        offer {
            id
        }
        totalPrice
        paymentPrice
        amount
    }
`;
export const CartOrderItemFragmentDoc = gql`
    fragment CartOrderItem on OrderItem {
        priceLines {
            label
            quantity
            totalPrice
            unitPrice
            category
        }
        totalPrice
        paymentPrice
        errors
        ...CartTicket
        ...CartBooking
        ...CartUpsell
    }
    ${CartTicketFragmentDoc}
    ${CartBookingFragmentDoc}
    ${CartUpsellFragmentDoc}
`;
export const CheckoutConfirmOrderItemFragmentDoc = gql`
    fragment CheckoutConfirmOrderItem on OrderItem {
        ...CheckoutConfirmTicket
        ...CheckoutConfirmBooking
        ...CartOrderItem
    }
    ${CheckoutConfirmTicketFragmentDoc}
    ${CheckoutConfirmBookingFragmentDoc}
    ${CartOrderItemFragmentDoc}
`;
export const ActivityCheckoutModalTimeslotFragmentDoc = gql`
    fragment ActivityCheckoutModalTimeslot on Timeslot {
        id
        startDateTime
        endDateTime
        allotment
        rateGroup {
            rates {
                id
                name
                price
            }
        }
    }
`;
export const CheckoutExtrasTicketFragmentDoc = gql`
    fragment CheckoutExtrasTicket on Ticket {
        activity {
            id
            name
            slug
            descriptionParticulars
            bookingType
            company {
                id
                ...CompanyOperatingHours
            }
        }
        startDateTime
        endDateTime
        timeslot {
            ...ActivityCheckoutModalTimeslot
        }
        rateLines {
            amount
        }
    }
    ${CompanyOperatingHoursFragmentDoc}
    ${ActivityCheckoutModalTimeslotFragmentDoc}
`;
export const CheckoutExtrasBookingRentalUnitFragmentDoc = gql`
    fragment CheckoutExtrasBookingRentalUnit on RentalUnit {
        id
        slug
        name
        type
        amountBedrooms
        maxOccupancy
        minOccupancy
        flexCancellationDays
        petsAllowed
        descriptionParticulars
        contentsInsuranceAvailable
        cancellationInsuranceAvailable
        accommodation {
            hasPublicPage
            name
            id
            hasPublicPage
            checkInStartTime
            checkInEndTime
            checkOutTime
        }
    }
`;
export const CheckoutExtrasBookingFragmentDoc = gql`
    fragment CheckoutExtrasBooking on Booking {
        special {
            id
            name
            description
        }
        rentalUnit {
            ...CheckoutExtrasBookingRentalUnit
        }
        hasCancellationInsurance
        hasContentsInsurance
        optionalSurcharges {
            rentalUnitSurchargeId
            amount
        }
    }
    ${CheckoutExtrasBookingRentalUnitFragmentDoc}
`;
export const CheckoutExtrasOrderItemFragmentDoc = gql`
    fragment CheckoutExtrasOrderItem on OrderItem {
        ...CheckoutExtrasTicket
        ...CheckoutExtrasBooking
        ...CartOrderItem
    }
    ${CheckoutExtrasTicketFragmentDoc}
    ${CheckoutExtrasBookingFragmentDoc}
    ${CartOrderItemFragmentDoc}
`;
export const CustomerDetailsFragmentDoc = gql`
    fragment CustomerDetails on Customer {
        phoneNumber
        firstName
        lastName
        email
        birthdate
        address {
            ...Address
        }
    }
    ${AddressFragmentDoc}
`;
export const CheckoutDetailsCustomerInfoFragmentDoc = gql`
    fragment CheckoutDetailsCustomerInfo on Order {
        customer {
            ...CustomerDetails
        }
        agreedToTerms
        newsletterOptin
    }
    ${CustomerDetailsFragmentDoc}
`;
export const OrderMetaFragmentDoc = gql`
    fragment OrderMeta on Order {
        number
        errors
        totalPrice
        paymentOptions {
            totalAmountDue
            partialPaymentAmount
        }
    }
`;
export const CheckoutDetailsFragmentDoc = gql`
    fragment CheckoutDetails on Cart {
        id
        status
        order {
            isDeskOrder
            orderItems {
                ...CheckoutExtrasOrderItem
            }
            ...CheckoutDetailsCustomerInfo
            ...OrderMeta
        }
    }
    ${CheckoutExtrasOrderItemFragmentDoc}
    ${CheckoutDetailsCustomerInfoFragmentDoc}
    ${OrderMetaFragmentDoc}
`;
export const CheckoutExtrasFragmentDoc = gql`
    fragment CheckoutExtras on Cart {
        id
        status
        availableUpsellOffers {
            id
            name
            unitPrice
            maxAmount
            description
        }
        order {
            orderItems {
                ...CheckoutExtrasOrderItem
            }
            ...OrderMeta
        }
    }
    ${CheckoutExtrasOrderItemFragmentDoc}
    ${OrderMetaFragmentDoc}
`;
export const CheckoutPaymentBookingFragmentDoc = gql`
    fragment CheckoutPaymentBooking on Booking {
        hasCancellationInsurance
        hasContentsInsurance
        arrivalDate
        departureDate
        optionalSurcharges {
            amount
            rentalUnitSurchargeId
        }
        rentalUnit {
            id
            name
            code
            type
            accommodation {
                id
                name
                type
                checkInEndTime
                checkInStartTime
                checkOutTime
            }
        }
    }
`;
export const CheckoutPaymentTicketFragmentDoc = gql`
    fragment CheckoutPaymentTicket on Ticket {
        timeslot {
            id
            startDateTime
            endDateTime
            rateGroup {
                rates {
                    id
                    name
                    price
                }
            }
        }
        activity {
            id
            name
        }
    }
`;
export const CheckoutPaymentOrderItemFragmentDoc = gql`
    fragment CheckoutPaymentOrderItem on OrderItem {
        ...CheckoutPaymentBooking
        ...CheckoutPaymentTicket
        ...CartOrderItem
    }
    ${CheckoutPaymentBookingFragmentDoc}
    ${CheckoutPaymentTicketFragmentDoc}
    ${CartOrderItemFragmentDoc}
`;
export const CheckoutPaymentFragmentDoc = gql`
    fragment CheckoutPayment on Cart {
        id
        status
        order {
            number
            isDeskOrder
            orderItems {
                ...CheckoutPaymentOrderItem
                ... on Booking {
                    arrivalDate
                    departureDate
                }
            }
            paymentOptions {
                availableMethods
                fullPaymentDueDate
                partialPaymentAmount
                totalAmountDue
            }
            ...OrderMeta
            ...CheckoutDetailsCustomerInfo
        }
    }
    ${CheckoutPaymentOrderItemFragmentDoc}
    ${OrderMetaFragmentDoc}
    ${CheckoutDetailsCustomerInfoFragmentDoc}
`;
export const OrderSummaryItemBookingFragmentDoc = gql`
    fragment OrderSummaryItemBooking on Booking {
        rentalUnit {
            id
            code
            name
            slug
            maxOccupancy
            minOccupancy
            petsAllowed
            accommodation {
                id
                hasPublicPage
                name
                checkInEndTime
                checkInStartTime
                checkOutTime
                type
            }
            type
            jpegThumbnail: listImage {
                transform(config: TILE, format: JPEG) {
                    ...Transform
                }
            }
            webpThumbnail: listImage {
                transform(config: TILE, format: WEBP) {
                    ...Transform
                }
            }
        }
    }
    ${TransformFragmentDoc}
`;
export const OrderSummaryItemTicketFragmentDoc = gql`
    fragment OrderSummaryItemTicket on Ticket {
        activity {
            id
            slug
            name
            place
            jpegThumbnail: listImage {
                transform(config: TILE, format: JPEG) {
                    ...Transform
                }
            }
            webpThumbnail: listImage {
                transform(config: TILE, format: WEBP) {
                    ...Transform
                }
            }
        }
        timeslot {
            id
            startDateTime
            endDateTime
            rateGroup {
                rates {
                    id
                    name
                    price
                }
            }
        }
    }
    ${TransformFragmentDoc}
`;
export const PriceLineFragmentDoc = gql`
    fragment PriceLine on PriceLine {
        label
        quantity
        totalPrice
        unitPrice
        category
    }
`;
export const OrderSummaryItemUpsellPurchaseFragmentDoc = gql`
    fragment OrderSummaryItemUpsellPurchase on UpsellPurchase {
        priceLines {
            ...PriceLine
        }
        totalPrice
        paymentPrice
        offer {
            id
        }
    }
    ${PriceLineFragmentDoc}
`;
export const OrderSummaryItemFragmentDoc = gql`
    fragment OrderSummaryItem on OrderItem {
        ...OrderSummaryItemBooking
        ...OrderSummaryItemTicket
        ...OrderSummaryItemUpsellPurchase
        ...CartOrderItem
    }
    ${OrderSummaryItemBookingFragmentDoc}
    ${OrderSummaryItemTicketFragmentDoc}
    ${OrderSummaryItemUpsellPurchaseFragmentDoc}
    ${CartOrderItemFragmentDoc}
`;
export const OrderSummaryFragmentDoc = gql`
    fragment OrderSummary on Order {
        orderItems {
            ...OrderSummaryItem
        }
        paymentOptions {
            partialPaymentAmount
        }
        ...OrderMeta
    }
    ${OrderSummaryItemFragmentDoc}
    ${OrderMetaFragmentDoc}
`;
export const CheckoutPaymentOrderFragmentDoc = gql`
    fragment CheckoutPaymentOrder on Order {
        id
        payments {
            date
            amount
            status
        }
        paymentOptions {
            availableMethods
            fullPaymentDueDate
            partialPaymentAmount
            totalAmountDue
        }
        ...OrderSummary
        ...CheckoutDetailsCustomerInfo
    }
    ${OrderSummaryFragmentDoc}
    ${CheckoutDetailsCustomerInfoFragmentDoc}
`;
export const CompanyHitFragmentDoc = gql`
    fragment CompanyHit on Company {
        name
        slug
        id
        place
        targetAudiences
        videoUrl
        categories {
            slug
        }
        mainImage {
            transform(config: HERO, format: JPEG) {
                ...ListImage
            }
        }
        webpMainImage: mainImage {
            transform(config: HERO, format: WEBP) {
                ...ListImage
            }
        }
        webpListImage: listImage {
            transform(config: TILE, format: WEBP) {
                ...ListImage
            }
        }
        listImage {
            transform(config: TILE, format: JPEG) {
                ...ListImage
            }
        }
        normalOperatingHours {
            monday {
                timeOpen
                timeClosed
            }
            tuesday {
                timeOpen
                timeClosed
            }
            wednesday {
                timeOpen
                timeClosed
            }
            thursday {
                timeOpen
                timeClosed
            }
            friday {
                timeOpen
                timeClosed
            }
            saturday {
                timeOpen
                timeClosed
            }
            sunday {
                timeOpen
                timeClosed
            }
        }
        specialOperatingHours {
            startDate
            endDate
            monday {
                timeOpen
                timeClosed
            }
            tuesday {
                timeOpen
                timeClosed
            }
            wednesday {
                timeOpen
                timeClosed
            }
            thursday {
                timeOpen
                timeClosed
            }
            friday {
                timeOpen
                timeClosed
            }
            saturday {
                timeOpen
                timeClosed
            }
            sunday {
                timeOpen
                timeClosed
            }
        }
    }
    ${ListImageFragmentDoc}
`;
export const ReviewFragmentDoc = gql`
    fragment Review on Review {
        id
        average
        comment
        date
        ratingHospitality
        ratingLayout
        ratingLocation
        ratingMaintenance
        ratingValueForMoney
        tips
        title
        travelGroupType
        reply
    }
`;
export const ReviewStatsFragmentDoc = gql`
    fragment ReviewStats on ReviewStats {
        count
        average
        location
        hospitality
        layout
        maintenance
        valueForMoney
    }
`;
export const ReviewModalFragmentDoc = gql`
    fragment ReviewModal on OrderItem {
        ... on Booking {
            id
            review {
                ...Review
            }
            rentalUnit {
                id
                flexCancellationDays
                reviewStats {
                    ...ReviewStats
                }
                accommodation {
                    id
                    ownerName
                    jpegOwner: ownerImage {
                        transform(config: AVATAR, format: JPEG) {
                            ...Transform
                        }
                    }
                    webpOwner: ownerImage {
                        transform(config: AVATAR, format: WEBP) {
                            ...Transform
                        }
                    }
                }
            }
        }
        ...OrderSummaryItem
    }
    ${ReviewFragmentDoc}
    ${ReviewStatsFragmentDoc}
    ${TransformFragmentDoc}
    ${OrderSummaryItemFragmentDoc}
`;
export const OrderBookingFragmentDoc = gql`
    fragment OrderBooking on Booking {
        id
        downloadVoucherUrl
        isCancellable
        isAlterable
        customerComment
        ...ReviewModal
    }
    ${ReviewModalFragmentDoc}
`;
export const OrderTicketFragmentDoc = gql`
    fragment OrderTicket on Ticket {
        id
        downloadVoucherUrl
        ticketStatus: status
    }
`;
export const OrderItemFragmentDoc = gql`
    fragment OrderItem on OrderItem {
        id
        ...OrderBooking
        ...OrderTicket
        ...OrderSummaryItem
    }
    ${OrderBookingFragmentDoc}
    ${OrderTicketFragmentDoc}
    ${OrderSummaryItemFragmentDoc}
`;
export const MyOrderFragmentDoc = gql`
    fragment MyOrder on Order {
        id
        number
        createdDate
        paymentOptions {
            fullPaymentDueDate
            totalAmountDue
        }
        orderItems {
            ...OrderItem
        }
        payments {
            status
        }
    }
    ${OrderItemFragmentDoc}
`;
export const MyFavoritesFragmentDoc = gql`
    fragment MyFavorites on Favorite {
        id
        ... on FavoriteRentalUnit {
            rentalUnit {
                slug
                name
                id
                jpegThumbnail: listImage {
                    transform(config: TILE, format: JPEG) {
                        ...Transform
                    }
                }
                webpThumbnail: listImage {
                    transform(config: TILE, format: WEBP) {
                        ...Transform
                    }
                }
            }
        }
        ... on FavoriteCompany {
            company {
                slug
                name
                id
                jpegThumbnail: listImage {
                    transform(config: TILE, format: JPEG) {
                        ...Transform
                    }
                }
                webpThumbnail: listImage {
                    transform(config: TILE, format: WEBP) {
                        ...Transform
                    }
                }
            }
        }
        ... on FavoriteActivity {
            activity {
                slug
                name
                id
                jpegThumbnail: listImage {
                    transform(config: TILE, format: JPEG) {
                        ...Transform
                    }
                }
                webpThumbnail: listImage {
                    transform(config: TILE, format: WEBP) {
                        ...Transform
                    }
                }
            }
        }
    }
    ${TransformFragmentDoc}
`;
export const MyCustomerFragmentDoc = gql`
    fragment MyCustomer on Customer {
        id
        firstName
        lastName
        birthdate
        email
        address {
            ...Address
        }
        phoneNumber
        orders {
            ...MyOrder
        }
        favorites {
            ...MyFavorites
        }
    }
    ${AddressFragmentDoc}
    ${MyOrderFragmentDoc}
    ${MyFavoritesFragmentDoc}
`;
export const OrderDetailsFragmentDoc = gql`
    fragment OrderDetails on Order {
        id
        number
        createdDate
        downloadOverviewUrl
        payments {
            date
            amount
            status
        }
        paymentOptions {
            fullPaymentDueDate
            partialPaymentAmount
        }
        orderItems {
            ...OrderItem
        }
        ...OrderSummary
    }
    ${OrderItemFragmentDoc}
    ${OrderSummaryFragmentDoc}
`;
export const RentalUnitReviewFragmentDoc = gql`
    fragment RentalUnitReview on RentalUnit {
        id
        code
        name
        slug
        maxOccupancy
        minOccupancy
        petsAllowed
        accommodation {
            id
            hasPublicPage
            name
            checkInEndTime
            checkInStartTime
            checkOutTime
            type
        }
        type
        jpegThumbnail: listImage {
            transform(config: TILE, format: JPEG) {
                ...Transform
            }
        }
        webpThumbnail: listImage {
            transform(config: TILE, format: WEBP) {
                ...Transform
            }
        }
    }
    ${TransformFragmentDoc}
`;
export const PropertyFilterCountsFragmentDoc = gql`
    fragment PropertyFilterCounts on PropertyFilterCount {
        hits
        property
    }
`;
export const BookingFragmentDoc = gql`
    fragment Booking on OrderItem {
        priceLines {
            label
            quantity
            totalPrice
            unitPrice
        }
        totalPrice
        ... on Booking {
            amountAdults
            amountPets
            amountChildren
            amountBabies
            amountYouths
            arrivalDate
            departureDate
            hasCancellationInsurance
            hasContentsInsurance
            optionalSurcharges {
                rentalUnitSurchargeId
                amount
            }
            rentalUnit {
                id
            }
        }
    }
`;
export const PlannerTripFragmentDoc = gql`
    fragment PlannerTrip on Trip {
        date
        price
        duration
        type
        originalPrice
        specialId
    }
`;
export const GetCartFragmentDoc = gql`
    fragment GetCart on Cart {
        id
        status
        availableUpsellOffers {
            id
            name
            unitPrice
            maxAmount
            description
        }
        order {
            orderItems {
                ...CartOrderItem
            }
        }
    }
    ${CartOrderItemFragmentDoc}
`;
export const SurchargeFragmentDoc = gql`
    fragment Surcharge on RentalUnitSurcharge {
        id
        name
        calculation
        description
        maxAmount
        minAmount
        unitPrice
    }
`;
export const ActivityAtlternativesDocument = gql`
    query ActivityAtlternatives(
        $params: ActivitySearchParamsInput!
        $limit: Int
        $startDate: String!
        $endDate: String!
    ) {
        activities(params: $params, limit: $limit) {
            hits {
                ...ActivityHit
            }
        }
    }
    ${ActivityHitFragmentDoc}
`;

/**
 * __useActivityAtlternativesQuery__
 *
 * To run a query within a React component, call `useActivityAtlternativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityAtlternativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityAtlternativesQuery({
 *   variables: {
 *      params: // value for 'params'
 *      limit: // value for 'limit'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useActivityAtlternativesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ActivityAtlternativesQuery, ActivityAtlternativesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ActivityAtlternativesQuery, ActivityAtlternativesQueryVariables>(
        ActivityAtlternativesDocument,
        options
    );
}
export function useActivityAtlternativesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivityAtlternativesQuery, ActivityAtlternativesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ActivityAtlternativesQuery, ActivityAtlternativesQueryVariables>(
        ActivityAtlternativesDocument,
        options
    );
}
export type ActivityAtlternativesQueryHookResult = ReturnType<typeof useActivityAtlternativesQuery>;
export type ActivityAtlternativesLazyQueryHookResult = ReturnType<typeof useActivityAtlternativesLazyQuery>;
export type ActivityAtlternativesQueryResult = Apollo.QueryResult<
    ActivityAtlternativesQuery,
    ActivityAtlternativesQueryVariables
>;
export const ActivityPlannerDocument = gql`
    query ActivityPlanner($slug: String!, $startDate: String!, $endDate: String!) {
        activity(slug: $slug) {
            id
            externalBookingUrl
            bookingType
            timeslots(startDate: $startDate, endDate: $endDate) {
                ...ActivityPlannerTimeslot
            }
        }
    }
    ${ActivityPlannerTimeslotFragmentDoc}
`;

/**
 * __useActivityPlannerQuery__
 *
 * To run a query within a React component, call `useActivityPlannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityPlannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityPlannerQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useActivityPlannerQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ActivityPlannerQuery, ActivityPlannerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ActivityPlannerQuery, ActivityPlannerQueryVariables>(
        ActivityPlannerDocument,
        options
    );
}
export function useActivityPlannerLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivityPlannerQuery, ActivityPlannerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ActivityPlannerQuery, ActivityPlannerQueryVariables>(
        ActivityPlannerDocument,
        options
    );
}
export type ActivityPlannerQueryHookResult = ReturnType<typeof useActivityPlannerQuery>;
export type ActivityPlannerLazyQueryHookResult = ReturnType<typeof useActivityPlannerLazyQuery>;
export type ActivityPlannerQueryResult = Apollo.QueryResult<ActivityPlannerQuery, ActivityPlannerQueryVariables>;
export const SearchSurchargesDocument = gql`
    query SearchSurcharges($slug: String!, $arrivalDate: String) {
        rentalUnit(slug: $slug) {
            id
            surcharges(arrivalDate: $arrivalDate) {
                ...Surcharge
            }
        }
    }
    ${SurchargeFragmentDoc}
`;

/**
 * __useSearchSurchargesQuery__
 *
 * To run a query within a React component, call `useSearchSurchargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSurchargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSurchargesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      arrivalDate: // value for 'arrivalDate'
 *   },
 * });
 */
export function useSearchSurchargesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<SearchSurchargesQuery, SearchSurchargesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<SearchSurchargesQuery, SearchSurchargesQueryVariables>(
        SearchSurchargesDocument,
        options
    );
}
export function useSearchSurchargesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchSurchargesQuery, SearchSurchargesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<SearchSurchargesQuery, SearchSurchargesQueryVariables>(
        SearchSurchargesDocument,
        options
    );
}
export type SearchSurchargesQueryHookResult = ReturnType<typeof useSearchSurchargesQuery>;
export type SearchSurchargesLazyQueryHookResult = ReturnType<typeof useSearchSurchargesLazyQuery>;
export type SearchSurchargesQueryResult = Apollo.QueryResult<SearchSurchargesQuery, SearchSurchargesQueryVariables>;
export const CheckoutOrderPageDocument = gql`
    query CheckoutOrderPage($orderId: ID!) {
        order(id: $orderId) {
            id
            payments {
                ...Payment
            }
            ...OrderSummary
        }
    }
    ${PaymentFragmentDoc}
    ${OrderSummaryFragmentDoc}
`;

/**
 * __useCheckoutOrderPageQuery__
 *
 * To run a query within a React component, call `useCheckoutOrderPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutOrderPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutOrderPageQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCheckoutOrderPageQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CheckoutOrderPageQuery, CheckoutOrderPageQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CheckoutOrderPageQuery, CheckoutOrderPageQueryVariables>(
        CheckoutOrderPageDocument,
        options
    );
}
export function useCheckoutOrderPageLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckoutOrderPageQuery, CheckoutOrderPageQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CheckoutOrderPageQuery, CheckoutOrderPageQueryVariables>(
        CheckoutOrderPageDocument,
        options
    );
}
export type CheckoutOrderPageQueryHookResult = ReturnType<typeof useCheckoutOrderPageQuery>;
export type CheckoutOrderPageLazyQueryHookResult = ReturnType<typeof useCheckoutOrderPageLazyQuery>;
export type CheckoutOrderPageQueryResult = Apollo.QueryResult<CheckoutOrderPageQuery, CheckoutOrderPageQueryVariables>;
export const CheckoutPageDocument = gql`
    query CheckoutPage($id: ID!) {
        cart(id: $id) {
            id
            status
            order {
                ...OrderSummary
            }
            availableUpsellOffers {
                id
                name
                unitPrice
                maxAmount
                description
            }
        }
    }
    ${OrderSummaryFragmentDoc}
`;

/**
 * __useCheckoutPageQuery__
 *
 * To run a query within a React component, call `useCheckoutPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckoutPageQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CheckoutPageQuery, CheckoutPageQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CheckoutPageQuery, CheckoutPageQueryVariables>(CheckoutPageDocument, options);
}
export function useCheckoutPageLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckoutPageQuery, CheckoutPageQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CheckoutPageQuery, CheckoutPageQueryVariables>(CheckoutPageDocument, options);
}
export type CheckoutPageQueryHookResult = ReturnType<typeof useCheckoutPageQuery>;
export type CheckoutPageLazyQueryHookResult = ReturnType<typeof useCheckoutPageLazyQuery>;
export type CheckoutPageQueryResult = Apollo.QueryResult<CheckoutPageQuery, CheckoutPageQueryVariables>;
export const DeskModeDocument = gql`
    query DeskMode($id: ID!) {
        cart(id: $id) {
            id
            order {
                isDeskOrder
            }
        }
    }
`;

/**
 * __useDeskModeQuery__
 *
 * To run a query within a React component, call `useDeskModeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskModeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskModeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeskModeQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<DeskModeQuery, DeskModeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<DeskModeQuery, DeskModeQueryVariables>(DeskModeDocument, options);
}
export function useDeskModeLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeskModeQuery, DeskModeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<DeskModeQuery, DeskModeQueryVariables>(DeskModeDocument, options);
}
export type DeskModeQueryHookResult = ReturnType<typeof useDeskModeQuery>;
export type DeskModeLazyQueryHookResult = ReturnType<typeof useDeskModeLazyQuery>;
export type DeskModeQueryResult = Apollo.QueryResult<DeskModeQuery, DeskModeQueryVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($input: UpdateCustomerInput!) {
        updateCustomer(input: $input) {
            customer {
                id
                ...CustomerDetails
            }
        }
    }
    ${CustomerDetailsFragmentDoc}
`;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
        UpdateCustomerDocument,
        options
    );
}
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
>;
export const FavoriteButtonDocument = gql`
    query FavoriteButton {
        viewer {
            favorites {
                __typename
                id
                targetId
            }
        }
    }
`;

/**
 * __useFavoriteButtonQuery__
 *
 * To run a query within a React component, call `useFavoriteButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoriteButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoriteButtonQuery({
 *   variables: {
 *   },
 * });
 */
export function useFavoriteButtonQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<FavoriteButtonQuery, FavoriteButtonQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<FavoriteButtonQuery, FavoriteButtonQueryVariables>(
        FavoriteButtonDocument,
        options
    );
}
export function useFavoriteButtonLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FavoriteButtonQuery, FavoriteButtonQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<FavoriteButtonQuery, FavoriteButtonQueryVariables>(
        FavoriteButtonDocument,
        options
    );
}
export type FavoriteButtonQueryHookResult = ReturnType<typeof useFavoriteButtonQuery>;
export type FavoriteButtonLazyQueryHookResult = ReturnType<typeof useFavoriteButtonLazyQuery>;
export type FavoriteButtonQueryResult = Apollo.QueryResult<FavoriteButtonQuery, FavoriteButtonQueryVariables>;
export const AddFavoriteActivityDocument = gql`
    mutation addFavoriteActivity($input: AddFavoriteActivityInput!) {
        result: addFavoriteActivity(input: $input) {
            favorite {
                ...MyFavorites
            }
        }
    }
    ${MyFavoritesFragmentDoc}
`;

/**
 * __useAddFavoriteActivityMutation__
 *
 * To run a mutation, you first call `useAddFavoriteActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFavoriteActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFavoriteActivityMutation, { data, loading, error }] = useAddFavoriteActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFavoriteActivityMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        AddFavoriteActivityMutation,
        AddFavoriteActivityMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<AddFavoriteActivityMutation, AddFavoriteActivityMutationVariables>(
        AddFavoriteActivityDocument,
        options
    );
}
export type AddFavoriteActivityMutationHookResult = ReturnType<typeof useAddFavoriteActivityMutation>;
export type AddFavoriteActivityMutationResult = Apollo.MutationResult<AddFavoriteActivityMutation>;
export type AddFavoriteActivityMutationOptions = Apollo.BaseMutationOptions<
    AddFavoriteActivityMutation,
    AddFavoriteActivityMutationVariables
>;
export const AddFavoriteCompanyDocument = gql`
    mutation addFavoriteCompany($input: AddFavoriteCompanyInput!) {
        result: addFavoriteCompany(input: $input) {
            favorite {
                ...MyFavorites
            }
        }
    }
    ${MyFavoritesFragmentDoc}
`;

/**
 * __useAddFavoriteCompanyMutation__
 *
 * To run a mutation, you first call `useAddFavoriteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFavoriteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFavoriteCompanyMutation, { data, loading, error }] = useAddFavoriteCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFavoriteCompanyMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<AddFavoriteCompanyMutation, AddFavoriteCompanyMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<AddFavoriteCompanyMutation, AddFavoriteCompanyMutationVariables>(
        AddFavoriteCompanyDocument,
        options
    );
}
export type AddFavoriteCompanyMutationHookResult = ReturnType<typeof useAddFavoriteCompanyMutation>;
export type AddFavoriteCompanyMutationResult = Apollo.MutationResult<AddFavoriteCompanyMutation>;
export type AddFavoriteCompanyMutationOptions = Apollo.BaseMutationOptions<
    AddFavoriteCompanyMutation,
    AddFavoriteCompanyMutationVariables
>;
export const AddFavoriteRentalUnitDocument = gql`
    mutation addFavoriteRentalUnit($input: AddFavoriteRentalUnitInput!) {
        result: addFavoriteRentalUnit(input: $input) {
            favorite {
                ...MyFavorites
            }
        }
    }
    ${MyFavoritesFragmentDoc}
`;

/**
 * __useAddFavoriteRentalUnitMutation__
 *
 * To run a mutation, you first call `useAddFavoriteRentalUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFavoriteRentalUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFavoriteRentalUnitMutation, { data, loading, error }] = useAddFavoriteRentalUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFavoriteRentalUnitMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        AddFavoriteRentalUnitMutation,
        AddFavoriteRentalUnitMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<AddFavoriteRentalUnitMutation, AddFavoriteRentalUnitMutationVariables>(
        AddFavoriteRentalUnitDocument,
        options
    );
}
export type AddFavoriteRentalUnitMutationHookResult = ReturnType<typeof useAddFavoriteRentalUnitMutation>;
export type AddFavoriteRentalUnitMutationResult = Apollo.MutationResult<AddFavoriteRentalUnitMutation>;
export type AddFavoriteRentalUnitMutationOptions = Apollo.BaseMutationOptions<
    AddFavoriteRentalUnitMutation,
    AddFavoriteRentalUnitMutationVariables
>;
export const RemoveFavoriteDocument = gql`
    mutation removeFavorite($input: RemoveFavoriteInput!) {
        removeFavorite(input: $input) {
            success
        }
    }
`;

/**
 * __useRemoveFavoriteMutation__
 *
 * To run a mutation, you first call `useRemoveFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFavoriteMutation, { data, loading, error }] = useRemoveFavoriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFavoriteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveFavoriteMutation, RemoveFavoriteMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<RemoveFavoriteMutation, RemoveFavoriteMutationVariables>(
        RemoveFavoriteDocument,
        options
    );
}
export type RemoveFavoriteMutationHookResult = ReturnType<typeof useRemoveFavoriteMutation>;
export type RemoveFavoriteMutationResult = Apollo.MutationResult<RemoveFavoriteMutation>;
export type RemoveFavoriteMutationOptions = Apollo.BaseMutationOptions<
    RemoveFavoriteMutation,
    RemoveFavoriteMutationVariables
>;
export const MeDocument = gql`
    query Me {
        viewer {
            id
        }
    }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SendAuthenticationEmailDocument = gql`
    mutation SendAuthenticationEmail($input: SendAuthenticationEmailInput!) {
        sendAuthenticationEmail(input: $input) {
            success
        }
    }
`;

/**
 * __useSendAuthenticationEmailMutation__
 *
 * To run a mutation, you first call `useSendAuthenticationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAuthenticationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAuthenticationEmailMutation, { data, loading, error }] = useSendAuthenticationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendAuthenticationEmailMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SendAuthenticationEmailMutation,
        SendAuthenticationEmailMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<SendAuthenticationEmailMutation, SendAuthenticationEmailMutationVariables>(
        SendAuthenticationEmailDocument,
        options
    );
}
export type SendAuthenticationEmailMutationHookResult = ReturnType<typeof useSendAuthenticationEmailMutation>;
export type SendAuthenticationEmailMutationResult = Apollo.MutationResult<SendAuthenticationEmailMutation>;
export type SendAuthenticationEmailMutationOptions = Apollo.BaseMutationOptions<
    SendAuthenticationEmailMutation,
    SendAuthenticationEmailMutationVariables
>;
export const RegisterDocument = gql`
    mutation Register($input: RegisterInput!) {
        register(input: $input) {
            customer {
                id
            }
        }
    }
`;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterMutation, RegisterMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const LeaveReviewDocument = gql`
    mutation LeaveReview($input: LeaveReviewInput!) {
        leaveReview(input: $input) {
            review {
                ...Review
            }
        }
    }
    ${ReviewFragmentDoc}
`;

/**
 * __useLeaveReviewMutation__
 *
 * To run a mutation, you first call `useLeaveReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveReviewMutation, { data, loading, error }] = useLeaveReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveReviewMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveReviewMutation, LeaveReviewMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<LeaveReviewMutation, LeaveReviewMutationVariables>(
        LeaveReviewDocument,
        options
    );
}
export type LeaveReviewMutationHookResult = ReturnType<typeof useLeaveReviewMutation>;
export type LeaveReviewMutationResult = Apollo.MutationResult<LeaveReviewMutation>;
export type LeaveReviewMutationOptions = Apollo.BaseMutationOptions<LeaveReviewMutation, LeaveReviewMutationVariables>;
export const UnitSearchAlternativeDatesDocument = gql`
    query UnitSearchAlternativeDates($params: UnitAndTripSearchParamsInput!) {
        unitSearchAlternativeDates(params: $params) {
            hits
            date
        }
    }
`;

/**
 * __useUnitSearchAlternativeDatesQuery__
 *
 * To run a query within a React component, call `useUnitSearchAlternativeDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitSearchAlternativeDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitSearchAlternativeDatesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUnitSearchAlternativeDatesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        UnitSearchAlternativeDatesQuery,
        UnitSearchAlternativeDatesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<UnitSearchAlternativeDatesQuery, UnitSearchAlternativeDatesQueryVariables>(
        UnitSearchAlternativeDatesDocument,
        options
    );
}
export function useUnitSearchAlternativeDatesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        UnitSearchAlternativeDatesQuery,
        UnitSearchAlternativeDatesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<UnitSearchAlternativeDatesQuery, UnitSearchAlternativeDatesQueryVariables>(
        UnitSearchAlternativeDatesDocument,
        options
    );
}
export type UnitSearchAlternativeDatesQueryHookResult = ReturnType<typeof useUnitSearchAlternativeDatesQuery>;
export type UnitSearchAlternativeDatesLazyQueryHookResult = ReturnType<typeof useUnitSearchAlternativeDatesLazyQuery>;
export type UnitSearchAlternativeDatesQueryResult = Apollo.QueryResult<
    UnitSearchAlternativeDatesQuery,
    UnitSearchAlternativeDatesQueryVariables
>;
export const SearchRentalUnitsDocument = gql`
    query SearchRentalUnits(
        $slug: String!
        $params: UnitSearchParamsInput
        $limit: Int
        $offset: Int
        $badgeHandle: String!
        $badge2Handle: String!
    ) {
        accommodation(slug: $slug) {
            id
            searchRentalUnits(params: $params, limit: $limit, offset: $offset) {
                ...SearchRentalUnits
            }
        }
    }
    ${SearchRentalUnitsFragmentDoc}
`;

/**
 * __useSearchRentalUnitsQuery__
 *
 * To run a query within a React component, call `useSearchRentalUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchRentalUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchRentalUnitsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      params: // value for 'params'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      badgeHandle: // value for 'badgeHandle'
 *      badge2Handle: // value for 'badge2Handle'
 *   },
 * });
 */
export function useSearchRentalUnitsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<SearchRentalUnitsQuery, SearchRentalUnitsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<SearchRentalUnitsQuery, SearchRentalUnitsQueryVariables>(
        SearchRentalUnitsDocument,
        options
    );
}
export function useSearchRentalUnitsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchRentalUnitsQuery, SearchRentalUnitsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<SearchRentalUnitsQuery, SearchRentalUnitsQueryVariables>(
        SearchRentalUnitsDocument,
        options
    );
}
export type SearchRentalUnitsQueryHookResult = ReturnType<typeof useSearchRentalUnitsQuery>;
export type SearchRentalUnitsLazyQueryHookResult = ReturnType<typeof useSearchRentalUnitsLazyQuery>;
export type SearchRentalUnitsQueryResult = Apollo.QueryResult<SearchRentalUnitsQuery, SearchRentalUnitsQueryVariables>;
export const SpecialDocument = gql`
    query Special($id: ID!) {
        special(id: $id) {
            id
            description
            landingPageUrl
            name
        }
    }
`;

/**
 * __useSpecialQuery__
 *
 * To run a query within a React component, call `useSpecialQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpecialQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SpecialQuery, SpecialQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<SpecialQuery, SpecialQueryVariables>(SpecialDocument, options);
}
export function useSpecialLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SpecialQuery, SpecialQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<SpecialQuery, SpecialQueryVariables>(SpecialDocument, options);
}
export type SpecialQueryHookResult = ReturnType<typeof useSpecialQuery>;
export type SpecialLazyQueryHookResult = ReturnType<typeof useSpecialLazyQuery>;
export type SpecialQueryResult = Apollo.QueryResult<SpecialQuery, SpecialQueryVariables>;
export const UnitRelevantAlternativesDocument = gql`
    query UnitRelevantAlternatives(
        $slug: String!
        $params: UnitSearchParamsInput
        $badgeHandle: String!
        $badge2Handle: String!
    ) {
        rentalUnit(slug: $slug) {
            id
            relevantAlternatives(params: $params) {
                ...UnitRelevantAlternatives
            }
        }
    }
    ${UnitRelevantAlternativesFragmentDoc}
`;

/**
 * __useUnitRelevantAlternativesQuery__
 *
 * To run a query within a React component, call `useUnitRelevantAlternativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitRelevantAlternativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitRelevantAlternativesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      params: // value for 'params'
 *      badgeHandle: // value for 'badgeHandle'
 *      badge2Handle: // value for 'badge2Handle'
 *   },
 * });
 */
export function useUnitRelevantAlternativesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        UnitRelevantAlternativesQuery,
        UnitRelevantAlternativesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<UnitRelevantAlternativesQuery, UnitRelevantAlternativesQueryVariables>(
        UnitRelevantAlternativesDocument,
        options
    );
}
export function useUnitRelevantAlternativesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        UnitRelevantAlternativesQuery,
        UnitRelevantAlternativesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<UnitRelevantAlternativesQuery, UnitRelevantAlternativesQueryVariables>(
        UnitRelevantAlternativesDocument,
        options
    );
}
export type UnitRelevantAlternativesQueryHookResult = ReturnType<typeof useUnitRelevantAlternativesQuery>;
export type UnitRelevantAlternativesLazyQueryHookResult = ReturnType<typeof useUnitRelevantAlternativesLazyQuery>;
export type UnitRelevantAlternativesQueryResult = Apollo.QueryResult<
    UnitRelevantAlternativesQuery,
    UnitRelevantAlternativesQueryVariables
>;
export const ReviewsDocument = gql`
    query Reviews($slug: String!, $limit: Int!, $offset: Int) {
        rentalUnit(slug: $slug) {
            id
            reviews(limit: $limit, offset: $offset) {
                ...UnitReviews
            }
        }
    }
    ${UnitReviewsFragmentDoc}
`;

/**
 * __useReviewsQuery__
 *
 * To run a query within a React component, call `useReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useReviewsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ReviewsQuery, ReviewsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, options);
}
export function useReviewsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewsQuery, ReviewsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, options);
}
export type ReviewsQueryHookResult = ReturnType<typeof useReviewsQuery>;
export type ReviewsLazyQueryHookResult = ReturnType<typeof useReviewsLazyQuery>;
export type ReviewsQueryResult = Apollo.QueryResult<ReviewsQuery, ReviewsQueryVariables>;
export const AccommodationDetailsDocument = gql`
    query AccommodationDetails($slug: String!) {
        accommodation(slug: $slug) {
            id
            slug
            jpegHero: mainImage {
                transform(config: HERO, format: JPEG) {
                    ...Transform
                }
            }
            webpHero: mainImage {
                transform(config: HERO, format: WEBP) {
                    ...Transform
                }
            }
            type
            name
            hasPublicPage
            place
            descriptionGeneral
            descriptionLocation
            images {
                ...Images
            }
            attributeCategories {
                ...Extras
            }
            ...Location
        }
    }
    ${TransformFragmentDoc}
    ${ImagesFragmentDoc}
    ${ExtrasFragmentDoc}
    ${LocationFragmentDoc}
`;

/**
 * __useAccommodationDetailsQuery__
 *
 * To run a query within a React component, call `useAccommodationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccommodationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccommodationDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useAccommodationDetailsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<AccommodationDetailsQuery, AccommodationDetailsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AccommodationDetailsQuery, AccommodationDetailsQueryVariables>(
        AccommodationDetailsDocument,
        options
    );
}
export function useAccommodationDetailsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccommodationDetailsQuery, AccommodationDetailsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<AccommodationDetailsQuery, AccommodationDetailsQueryVariables>(
        AccommodationDetailsDocument,
        options
    );
}
export type AccommodationDetailsQueryHookResult = ReturnType<typeof useAccommodationDetailsQuery>;
export type AccommodationDetailsLazyQueryHookResult = ReturnType<typeof useAccommodationDetailsLazyQuery>;
export type AccommodationDetailsQueryResult = Apollo.QueryResult<
    AccommodationDetailsQuery,
    AccommodationDetailsQueryVariables
>;
export const ActivityDetailDocument = gql`
    query ActivityDetail($slug: String!) {
        activity(slug: $slug) {
            id
            slug
            externalBookingUrl
            externalInfoUrl
            bookingType
            videoUrl
            jpegHero: mainImage {
                transform(config: HERO, format: JPEG) {
                    ...Transform
                }
            }
            webpHero: mainImage {
                transform(config: HERO, format: WEBP) {
                    ...Transform
                }
            }
            jpegThumbnail: listImage {
                transform(config: TILE, format: JPEG) {
                    ...Transform
                }
            }
            webpThumbnail: listImage {
                transform(config: TILE, format: WEBP) {
                    ...Transform
                }
            }
            place
            name
            minCapacity
            maxCapacity
            targetAudiences
            themes {
                id
                name
            }
            description
            coordinates {
                lat
                lon
            }
            descriptionLocation
            descriptionParticulars
            images {
                jpegTile: transform(config: TILE, format: JPEG) {
                    ...Transform
                }
                webpTile: transform(config: TILE, format: WEBP) {
                    ...Transform
                }
                jpegGallery: transform(config: GALLERY, format: JPEG) {
                    ...Transform
                }
                webpGallery: transform(config: GALLERY, format: WEBP) {
                    ...Transform
                }
            }
            company {
                id
                name
                description
                slug
                webpMain: mainImage {
                    transform(config: HERO, format: WEBP) {
                        ...Transform
                    }
                }
                jpegMain: mainImage {
                    transform(config: HERO, format: JPEG) {
                        ...Transform
                    }
                }
                ...CompanyOperatingHours
            }
        }
    }
    ${TransformFragmentDoc}
    ${CompanyOperatingHoursFragmentDoc}
`;

/**
 * __useActivityDetailQuery__
 *
 * To run a query within a React component, call `useActivityDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityDetailQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useActivityDetailQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ActivityDetailQuery, ActivityDetailQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ActivityDetailQuery, ActivityDetailQueryVariables>(
        ActivityDetailDocument,
        options
    );
}
export function useActivityDetailLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivityDetailQuery, ActivityDetailQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ActivityDetailQuery, ActivityDetailQueryVariables>(
        ActivityDetailDocument,
        options
    );
}
export type ActivityDetailQueryHookResult = ReturnType<typeof useActivityDetailQuery>;
export type ActivityDetailLazyQueryHookResult = ReturnType<typeof useActivityDetailLazyQuery>;
export type ActivityDetailQueryResult = Apollo.QueryResult<ActivityDetailQuery, ActivityDetailQueryVariables>;
export const ActivityIframePlannerDocument = gql`
    query ActivityIframePlanner($slug: String!) {
        activity(slug: $slug) {
            id
            bookingType
            externalBookingUrl
            jpegThumbnail: listImage {
                transform(config: TILE, format: JPEG) {
                    ...Transform
                }
            }
            webpThumbnail: listImage {
                transform(config: TILE, format: WEBP) {
                    ...Transform
                }
            }
            name
            descriptionParticulars
        }
    }
    ${TransformFragmentDoc}
`;

/**
 * __useActivityIframePlannerQuery__
 *
 * To run a query within a React component, call `useActivityIframePlannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityIframePlannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityIframePlannerQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useActivityIframePlannerQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ActivityIframePlannerQuery, ActivityIframePlannerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ActivityIframePlannerQuery, ActivityIframePlannerQueryVariables>(
        ActivityIframePlannerDocument,
        options
    );
}
export function useActivityIframePlannerLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivityIframePlannerQuery, ActivityIframePlannerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ActivityIframePlannerQuery, ActivityIframePlannerQueryVariables>(
        ActivityIframePlannerDocument,
        options
    );
}
export type ActivityIframePlannerQueryHookResult = ReturnType<typeof useActivityIframePlannerQuery>;
export type ActivityIframePlannerLazyQueryHookResult = ReturnType<typeof useActivityIframePlannerLazyQuery>;
export type ActivityIframePlannerQueryResult = Apollo.QueryResult<
    ActivityIframePlannerQuery,
    ActivityIframePlannerQueryVariables
>;
export const ActivitySearchDocument = gql`
    query ActivitySearch(
        $params: ActivitySearchParamsInput!
        $limit: Int
        $offset: Int
        $startDate: String!
        $endDate: String!
    ) {
        activities(params: $params, limit: $limit, offset: $offset) {
            ... on ActivitySearchResult {
                totalHits
                hits {
                    ...ActivityHit
                }
                placeCounts {
                    value
                    hits
                }
                themeCounts {
                    value
                    hits
                }
                targetAudienceCount {
                    value
                    hits
                }
            }
        }
    }
    ${ActivityHitFragmentDoc}
`;

/**
 * __useActivitySearchQuery__
 *
 * To run a query within a React component, call `useActivitySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivitySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitySearchQuery({
 *   variables: {
 *      params: // value for 'params'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useActivitySearchQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ActivitySearchQuery, ActivitySearchQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ActivitySearchQuery, ActivitySearchQueryVariables>(
        ActivitySearchDocument,
        options
    );
}
export function useActivitySearchLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivitySearchQuery, ActivitySearchQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ActivitySearchQuery, ActivitySearchQueryVariables>(
        ActivitySearchDocument,
        options
    );
}
export type ActivitySearchQueryHookResult = ReturnType<typeof useActivitySearchQuery>;
export type ActivitySearchLazyQueryHookResult = ReturnType<typeof useActivitySearchLazyQuery>;
export type ActivitySearchQueryResult = Apollo.QueryResult<ActivitySearchQuery, ActivitySearchQueryVariables>;
export const AgendaFiltersDocument = gql`
    query AgendaFilters {
        activityThemes {
            slug
            name
            position
        }
        places {
            id
            position
            name
        }
    }
`;

/**
 * __useAgendaFiltersQuery__
 *
 * To run a query within a React component, call `useAgendaFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgendaFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgendaFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAgendaFiltersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<AgendaFiltersQuery, AgendaFiltersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AgendaFiltersQuery, AgendaFiltersQueryVariables>(AgendaFiltersDocument, options);
}
export function useAgendaFiltersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AgendaFiltersQuery, AgendaFiltersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<AgendaFiltersQuery, AgendaFiltersQueryVariables>(
        AgendaFiltersDocument,
        options
    );
}
export type AgendaFiltersQueryHookResult = ReturnType<typeof useAgendaFiltersQuery>;
export type AgendaFiltersLazyQueryHookResult = ReturnType<typeof useAgendaFiltersLazyQuery>;
export type AgendaFiltersQueryResult = Apollo.QueryResult<AgendaFiltersQuery, AgendaFiltersQueryVariables>;
export const BookingAlterationPreviewDocument = gql`
    query BookingAlterationPreview($input: AlterBookingInput!) {
        bookingAlterationPreview(input: $input) {
            bookingPreview {
                ...OrderBooking
            }
            newOrderAmountDue
        }
    }
    ${OrderBookingFragmentDoc}
`;

/**
 * __useBookingAlterationPreviewQuery__
 *
 * To run a query within a React component, call `useBookingAlterationPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingAlterationPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingAlterationPreviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookingAlterationPreviewQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        BookingAlterationPreviewQuery,
        BookingAlterationPreviewQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BookingAlterationPreviewQuery, BookingAlterationPreviewQueryVariables>(
        BookingAlterationPreviewDocument,
        options
    );
}
export function useBookingAlterationPreviewLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BookingAlterationPreviewQuery,
        BookingAlterationPreviewQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<BookingAlterationPreviewQuery, BookingAlterationPreviewQueryVariables>(
        BookingAlterationPreviewDocument,
        options
    );
}
export type BookingAlterationPreviewQueryHookResult = ReturnType<typeof useBookingAlterationPreviewQuery>;
export type BookingAlterationPreviewLazyQueryHookResult = ReturnType<typeof useBookingAlterationPreviewLazyQuery>;
export type BookingAlterationPreviewQueryResult = Apollo.QueryResult<
    BookingAlterationPreviewQuery,
    BookingAlterationPreviewQueryVariables
>;
export const AlterBookingDocument = gql`
    mutation AlterBooking($input: AlterBookingInput!) {
        alterBooking(input: $input) {
            booking {
                ...OrderBooking
            }
        }
    }
    ${OrderBookingFragmentDoc}
`;

/**
 * __useAlterBookingMutation__
 *
 * To run a mutation, you first call `useAlterBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterBookingMutation, { data, loading, error }] = useAlterBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlterBookingMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<AlterBookingMutation, AlterBookingMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<AlterBookingMutation, AlterBookingMutationVariables>(
        AlterBookingDocument,
        options
    );
}
export type AlterBookingMutationHookResult = ReturnType<typeof useAlterBookingMutation>;
export type AlterBookingMutationResult = Apollo.MutationResult<AlterBookingMutation>;
export type AlterBookingMutationOptions = Apollo.BaseMutationOptions<
    AlterBookingMutation,
    AlterBookingMutationVariables
>;
export const BookingCancellationPreviewDocument = gql`
    query BookingCancellationPreview($input: CancelBookingInput!) {
        bookingCancellationPreview(input: $input) {
            bookingPreview {
                ...OrderBooking
            }
            newOrderAmountDue
        }
    }
    ${OrderBookingFragmentDoc}
`;

/**
 * __useBookingCancellationPreviewQuery__
 *
 * To run a query within a React component, call `useBookingCancellationPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingCancellationPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingCancellationPreviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookingCancellationPreviewQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        BookingCancellationPreviewQuery,
        BookingCancellationPreviewQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BookingCancellationPreviewQuery, BookingCancellationPreviewQueryVariables>(
        BookingCancellationPreviewDocument,
        options
    );
}
export function useBookingCancellationPreviewLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BookingCancellationPreviewQuery,
        BookingCancellationPreviewQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<BookingCancellationPreviewQuery, BookingCancellationPreviewQueryVariables>(
        BookingCancellationPreviewDocument,
        options
    );
}
export type BookingCancellationPreviewQueryHookResult = ReturnType<typeof useBookingCancellationPreviewQuery>;
export type BookingCancellationPreviewLazyQueryHookResult = ReturnType<typeof useBookingCancellationPreviewLazyQuery>;
export type BookingCancellationPreviewQueryResult = Apollo.QueryResult<
    BookingCancellationPreviewQuery,
    BookingCancellationPreviewQueryVariables
>;
export const CancelBookingDocument = gql`
    mutation CancelBooking($input: CancelBookingInput!) {
        cancelBooking(input: $input) {
            booking {
                ...OrderBooking
            }
        }
    }
    ${OrderBookingFragmentDoc}
`;

/**
 * __useCancelBookingMutation__
 *
 * To run a mutation, you first call `useCancelBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBookingMutation, { data, loading, error }] = useCancelBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelBookingMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<CancelBookingMutation, CancelBookingMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CancelBookingMutation, CancelBookingMutationVariables>(
        CancelBookingDocument,
        options
    );
}
export type CancelBookingMutationHookResult = ReturnType<typeof useCancelBookingMutation>;
export type CancelBookingMutationResult = Apollo.MutationResult<CancelBookingMutation>;
export type CancelBookingMutationOptions = Apollo.BaseMutationOptions<
    CancelBookingMutation,
    CancelBookingMutationVariables
>;
export const CheckoutConfirmDocument = gql`
    query CheckoutConfirm($cartId: ID!) {
        cart(id: $cartId) {
            id
            status
            order {
                customer {
                    ...OrderCustomerInfo
                }
                orderItems {
                    ...CheckoutConfirmOrderItem
                }
                number
                ...OrderMeta
            }
        }
    }
    ${OrderCustomerInfoFragmentDoc}
    ${CheckoutConfirmOrderItemFragmentDoc}
    ${OrderMetaFragmentDoc}
`;

/**
 * __useCheckoutConfirmQuery__
 *
 * To run a query within a React component, call `useCheckoutConfirmQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutConfirmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutConfirmQuery({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useCheckoutConfirmQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CheckoutConfirmQuery, CheckoutConfirmQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CheckoutConfirmQuery, CheckoutConfirmQueryVariables>(
        CheckoutConfirmDocument,
        options
    );
}
export function useCheckoutConfirmLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckoutConfirmQuery, CheckoutConfirmQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CheckoutConfirmQuery, CheckoutConfirmQueryVariables>(
        CheckoutConfirmDocument,
        options
    );
}
export type CheckoutConfirmQueryHookResult = ReturnType<typeof useCheckoutConfirmQuery>;
export type CheckoutConfirmLazyQueryHookResult = ReturnType<typeof useCheckoutConfirmLazyQuery>;
export type CheckoutConfirmQueryResult = Apollo.QueryResult<CheckoutConfirmQuery, CheckoutConfirmQueryVariables>;
export const CheckoutConfirmOrderDocument = gql`
    query CheckoutConfirmOrder($orderId: ID!) {
        order(id: $orderId) {
            id
            customer {
                ...OrderCustomerInfo
            }
            payments {
                ...Payment
            }
            ...OrderSummary
        }
    }
    ${OrderCustomerInfoFragmentDoc}
    ${PaymentFragmentDoc}
    ${OrderSummaryFragmentDoc}
`;

/**
 * __useCheckoutConfirmOrderQuery__
 *
 * To run a query within a React component, call `useCheckoutConfirmOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutConfirmOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutConfirmOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCheckoutConfirmOrderQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CheckoutConfirmOrderQuery, CheckoutConfirmOrderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CheckoutConfirmOrderQuery, CheckoutConfirmOrderQueryVariables>(
        CheckoutConfirmOrderDocument,
        options
    );
}
export function useCheckoutConfirmOrderLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckoutConfirmOrderQuery, CheckoutConfirmOrderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CheckoutConfirmOrderQuery, CheckoutConfirmOrderQueryVariables>(
        CheckoutConfirmOrderDocument,
        options
    );
}
export type CheckoutConfirmOrderQueryHookResult = ReturnType<typeof useCheckoutConfirmOrderQuery>;
export type CheckoutConfirmOrderLazyQueryHookResult = ReturnType<typeof useCheckoutConfirmOrderLazyQuery>;
export type CheckoutConfirmOrderQueryResult = Apollo.QueryResult<
    CheckoutConfirmOrderQuery,
    CheckoutConfirmOrderQueryVariables
>;
export const CheckoutDetailsDocument = gql`
    query CheckoutDetails($cartId: ID!) {
        cart(id: $cartId) {
            ...CheckoutDetails
        }
    }
    ${CheckoutDetailsFragmentDoc}
`;

/**
 * __useCheckoutDetailsQuery__
 *
 * To run a query within a React component, call `useCheckoutDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutDetailsQuery({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useCheckoutDetailsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CheckoutDetailsQuery, CheckoutDetailsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CheckoutDetailsQuery, CheckoutDetailsQueryVariables>(
        CheckoutDetailsDocument,
        options
    );
}
export function useCheckoutDetailsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckoutDetailsQuery, CheckoutDetailsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CheckoutDetailsQuery, CheckoutDetailsQueryVariables>(
        CheckoutDetailsDocument,
        options
    );
}
export type CheckoutDetailsQueryHookResult = ReturnType<typeof useCheckoutDetailsQuery>;
export type CheckoutDetailsLazyQueryHookResult = ReturnType<typeof useCheckoutDetailsLazyQuery>;
export type CheckoutDetailsQueryResult = Apollo.QueryResult<CheckoutDetailsQuery, CheckoutDetailsQueryVariables>;
export const CheckoutDetailsMeDocument = gql`
    query CheckoutDetailsMe {
        viewer {
            id
            ...CustomerDetails
        }
    }
    ${CustomerDetailsFragmentDoc}
`;

/**
 * __useCheckoutDetailsMeQuery__
 *
 * To run a query within a React component, call `useCheckoutDetailsMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutDetailsMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutDetailsMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckoutDetailsMeQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<CheckoutDetailsMeQuery, CheckoutDetailsMeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CheckoutDetailsMeQuery, CheckoutDetailsMeQueryVariables>(
        CheckoutDetailsMeDocument,
        options
    );
}
export function useCheckoutDetailsMeLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckoutDetailsMeQuery, CheckoutDetailsMeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CheckoutDetailsMeQuery, CheckoutDetailsMeQueryVariables>(
        CheckoutDetailsMeDocument,
        options
    );
}
export type CheckoutDetailsMeQueryHookResult = ReturnType<typeof useCheckoutDetailsMeQuery>;
export type CheckoutDetailsMeLazyQueryHookResult = ReturnType<typeof useCheckoutDetailsMeLazyQuery>;
export type CheckoutDetailsMeQueryResult = Apollo.QueryResult<CheckoutDetailsMeQuery, CheckoutDetailsMeQueryVariables>;
export const UpdateCartFromDetailsDocument = gql`
    mutation UpdateCartFromDetails($input: UpdateCartInput!) {
        updateCart(input: $input) {
            checkoutAllowed
            cart {
                ...CheckoutDetails
            }
        }
    }
    ${CheckoutDetailsFragmentDoc}
`;

/**
 * __useUpdateCartFromDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateCartFromDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartFromDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartFromDetailsMutation, { data, loading, error }] = useUpdateCartFromDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartFromDetailsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateCartFromDetailsMutation,
        UpdateCartFromDetailsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpdateCartFromDetailsMutation, UpdateCartFromDetailsMutationVariables>(
        UpdateCartFromDetailsDocument,
        options
    );
}
export type UpdateCartFromDetailsMutationHookResult = ReturnType<typeof useUpdateCartFromDetailsMutation>;
export type UpdateCartFromDetailsMutationResult = Apollo.MutationResult<UpdateCartFromDetailsMutation>;
export type UpdateCartFromDetailsMutationOptions = Apollo.BaseMutationOptions<
    UpdateCartFromDetailsMutation,
    UpdateCartFromDetailsMutationVariables
>;
export const CheckoutExtrasDocument = gql`
    query CheckoutExtras($cartId: ID!) {
        cart(id: $cartId) {
            id
            ...CheckoutExtras
        }
    }
    ${CheckoutExtrasFragmentDoc}
`;

/**
 * __useCheckoutExtrasQuery__
 *
 * To run a query within a React component, call `useCheckoutExtrasQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutExtrasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutExtrasQuery({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useCheckoutExtrasQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CheckoutExtrasQuery, CheckoutExtrasQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CheckoutExtrasQuery, CheckoutExtrasQueryVariables>(
        CheckoutExtrasDocument,
        options
    );
}
export function useCheckoutExtrasLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckoutExtrasQuery, CheckoutExtrasQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CheckoutExtrasQuery, CheckoutExtrasQueryVariables>(
        CheckoutExtrasDocument,
        options
    );
}
export type CheckoutExtrasQueryHookResult = ReturnType<typeof useCheckoutExtrasQuery>;
export type CheckoutExtrasLazyQueryHookResult = ReturnType<typeof useCheckoutExtrasLazyQuery>;
export type CheckoutExtrasQueryResult = Apollo.QueryResult<CheckoutExtrasQuery, CheckoutExtrasQueryVariables>;
export const SetCartExtrasDocument = gql`
    mutation SetCartExtras($input: UpdateCartInput!) {
        updateCart(input: $input) {
            cart {
                ...CheckoutExtras
            }
        }
    }
    ${CheckoutExtrasFragmentDoc}
`;

/**
 * __useSetCartExtrasMutation__
 *
 * To run a mutation, you first call `useSetCartExtrasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCartExtrasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCartExtrasMutation, { data, loading, error }] = useSetCartExtrasMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCartExtrasMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<SetCartExtrasMutation, SetCartExtrasMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<SetCartExtrasMutation, SetCartExtrasMutationVariables>(
        SetCartExtrasDocument,
        options
    );
}
export type SetCartExtrasMutationHookResult = ReturnType<typeof useSetCartExtrasMutation>;
export type SetCartExtrasMutationResult = Apollo.MutationResult<SetCartExtrasMutation>;
export type SetCartExtrasMutationOptions = Apollo.BaseMutationOptions<
    SetCartExtrasMutation,
    SetCartExtrasMutationVariables
>;
export const UpdateCartDocument = gql`
    mutation UpdateCart($input: UpdateCartInput!) {
        updateCart(input: $input) {
            cart {
                ...CheckoutExtras
            }
        }
    }
    ${CheckoutExtrasFragmentDoc}
`;

/**
 * __useUpdateCartMutation__
 *
 * To run a mutation, you first call `useUpdateCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartMutation, { data, loading, error }] = useUpdateCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCartMutation, UpdateCartMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpdateCartMutation, UpdateCartMutationVariables>(UpdateCartDocument, options);
}
export type UpdateCartMutationHookResult = ReturnType<typeof useUpdateCartMutation>;
export type UpdateCartMutationResult = Apollo.MutationResult<UpdateCartMutation>;
export type UpdateCartMutationOptions = Apollo.BaseMutationOptions<UpdateCartMutation, UpdateCartMutationVariables>;
export const CheckoutPaymentDocument = gql`
    query CheckoutPayment($cartId: ID!) {
        cart(id: $cartId) {
            ...CheckoutPayment
        }
    }
    ${CheckoutPaymentFragmentDoc}
`;

/**
 * __useCheckoutPaymentQuery__
 *
 * To run a query within a React component, call `useCheckoutPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutPaymentQuery({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useCheckoutPaymentQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CheckoutPaymentQuery, CheckoutPaymentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CheckoutPaymentQuery, CheckoutPaymentQueryVariables>(
        CheckoutPaymentDocument,
        options
    );
}
export function useCheckoutPaymentLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckoutPaymentQuery, CheckoutPaymentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CheckoutPaymentQuery, CheckoutPaymentQueryVariables>(
        CheckoutPaymentDocument,
        options
    );
}
export type CheckoutPaymentQueryHookResult = ReturnType<typeof useCheckoutPaymentQuery>;
export type CheckoutPaymentLazyQueryHookResult = ReturnType<typeof useCheckoutPaymentLazyQuery>;
export type CheckoutPaymentQueryResult = Apollo.QueryResult<CheckoutPaymentQuery, CheckoutPaymentQueryVariables>;
export const CheckoutCartDocument = gql`
    mutation CheckoutCart($input: CheckoutCartInput!) {
        checkoutCart(input: $input) {
            success
            redirectUrl
            cart {
                ...CheckoutPayment
            }
            redirectUrl
        }
    }
    ${CheckoutPaymentFragmentDoc}
`;

/**
 * __useCheckoutCartMutation__
 *
 * To run a mutation, you first call `useCheckoutCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutCartMutation, { data, loading, error }] = useCheckoutCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckoutCartMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<CheckoutCartMutation, CheckoutCartMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CheckoutCartMutation, CheckoutCartMutationVariables>(
        CheckoutCartDocument,
        options
    );
}
export type CheckoutCartMutationHookResult = ReturnType<typeof useCheckoutCartMutation>;
export type CheckoutCartMutationResult = Apollo.MutationResult<CheckoutCartMutation>;
export type CheckoutCartMutationOptions = Apollo.BaseMutationOptions<
    CheckoutCartMutation,
    CheckoutCartMutationVariables
>;
export const CheckoutPaymentOrderDocument = gql`
    query CheckoutPaymentOrder($orderId: ID!) {
        order(id: $orderId) {
            ...CheckoutPaymentOrder
        }
    }
    ${CheckoutPaymentOrderFragmentDoc}
`;

/**
 * __useCheckoutPaymentOrderQuery__
 *
 * To run a query within a React component, call `useCheckoutPaymentOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutPaymentOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutPaymentOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCheckoutPaymentOrderQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CheckoutPaymentOrderQuery, CheckoutPaymentOrderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CheckoutPaymentOrderQuery, CheckoutPaymentOrderQueryVariables>(
        CheckoutPaymentOrderDocument,
        options
    );
}
export function useCheckoutPaymentOrderLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckoutPaymentOrderQuery, CheckoutPaymentOrderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CheckoutPaymentOrderQuery, CheckoutPaymentOrderQueryVariables>(
        CheckoutPaymentOrderDocument,
        options
    );
}
export type CheckoutPaymentOrderQueryHookResult = ReturnType<typeof useCheckoutPaymentOrderQuery>;
export type CheckoutPaymentOrderLazyQueryHookResult = ReturnType<typeof useCheckoutPaymentOrderLazyQuery>;
export type CheckoutPaymentOrderQueryResult = Apollo.QueryResult<
    CheckoutPaymentOrderQuery,
    CheckoutPaymentOrderQueryVariables
>;
export const PayOrderDocument = gql`
    mutation PayOrder($input: PayOrderInput!) {
        payOrder(input: $input) {
            order {
                ...CheckoutPaymentOrder
            }
            redirectUrl
        }
    }
    ${CheckoutPaymentOrderFragmentDoc}
`;

/**
 * __usePayOrderMutation__
 *
 * To run a mutation, you first call `usePayOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payOrderMutation, { data, loading, error }] = usePayOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayOrderMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<PayOrderMutation, PayOrderMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<PayOrderMutation, PayOrderMutationVariables>(PayOrderDocument, options);
}
export type PayOrderMutationHookResult = ReturnType<typeof usePayOrderMutation>;
export type PayOrderMutationResult = Apollo.MutationResult<PayOrderMutation>;
export type PayOrderMutationOptions = Apollo.BaseMutationOptions<PayOrderMutation, PayOrderMutationVariables>;
export const CheckoutRedirectRentalUnitDocument = gql`
    query CheckoutRedirectRentalUnit($slug: String!) {
        rentalUnit(slug: $slug) {
            id
            brand
            cancellationInsuranceAvailable
        }
    }
`;

/**
 * __useCheckoutRedirectRentalUnitQuery__
 *
 * To run a query within a React component, call `useCheckoutRedirectRentalUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutRedirectRentalUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutRedirectRentalUnitQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCheckoutRedirectRentalUnitQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        CheckoutRedirectRentalUnitQuery,
        CheckoutRedirectRentalUnitQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CheckoutRedirectRentalUnitQuery, CheckoutRedirectRentalUnitQueryVariables>(
        CheckoutRedirectRentalUnitDocument,
        options
    );
}
export function useCheckoutRedirectRentalUnitLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        CheckoutRedirectRentalUnitQuery,
        CheckoutRedirectRentalUnitQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CheckoutRedirectRentalUnitQuery, CheckoutRedirectRentalUnitQueryVariables>(
        CheckoutRedirectRentalUnitDocument,
        options
    );
}
export type CheckoutRedirectRentalUnitQueryHookResult = ReturnType<typeof useCheckoutRedirectRentalUnitQuery>;
export type CheckoutRedirectRentalUnitLazyQueryHookResult = ReturnType<typeof useCheckoutRedirectRentalUnitLazyQuery>;
export type CheckoutRedirectRentalUnitQueryResult = Apollo.QueryResult<
    CheckoutRedirectRentalUnitQuery,
    CheckoutRedirectRentalUnitQueryVariables
>;
export const CompanySearchDocument = gql`
    query CompanySearch($params: CompanySearchParamsInput!, $limit: Int, $offset: Int, $sortByDistanceTo: LatLonInput) {
        companies(params: $params, limit: $limit, offset: $offset, sortByDistanceTo: $sortByDistanceTo) {
            ... on CompanySearchResult {
                totalHits
                hits {
                    ...CompanyHit
                }
                placeCounts {
                    value
                    hits
                }
                themeCounts {
                    value
                    hits
                }
                categoryCounts {
                    value
                    hits
                }
                mainCategoryCounts {
                    value
                    hits
                }
                targetAudienceCount {
                    value
                    hits
                }
            }
        }
    }
    ${CompanyHitFragmentDoc}
`;

/**
 * __useCompanySearchQuery__
 *
 * To run a query within a React component, call `useCompanySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySearchQuery({
 *   variables: {
 *      params: // value for 'params'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortByDistanceTo: // value for 'sortByDistanceTo'
 *   },
 * });
 */
export function useCompanySearchQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CompanySearchQuery, CompanySearchQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CompanySearchQuery, CompanySearchQueryVariables>(CompanySearchDocument, options);
}
export function useCompanySearchLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanySearchQuery, CompanySearchQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CompanySearchQuery, CompanySearchQueryVariables>(
        CompanySearchDocument,
        options
    );
}
export type CompanySearchQueryHookResult = ReturnType<typeof useCompanySearchQuery>;
export type CompanySearchLazyQueryHookResult = ReturnType<typeof useCompanySearchLazyQuery>;
export type CompanySearchQueryResult = Apollo.QueryResult<CompanySearchQuery, CompanySearchQueryVariables>;
export const CompanyFiltersDocument = gql`
    query CompanyFilters {
        companyCategories {
            name
            slug
            position
            children {
                name
                slug
                position
            }
        }
        places {
            id
            name
        }
    }
`;

/**
 * __useCompanyFiltersQuery__
 *
 * To run a query within a React component, call `useCompanyFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyFiltersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyFiltersQuery, CompanyFiltersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CompanyFiltersQuery, CompanyFiltersQueryVariables>(
        CompanyFiltersDocument,
        options
    );
}
export function useCompanyFiltersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyFiltersQuery, CompanyFiltersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CompanyFiltersQuery, CompanyFiltersQueryVariables>(
        CompanyFiltersDocument,
        options
    );
}
export type CompanyFiltersQueryHookResult = ReturnType<typeof useCompanyFiltersQuery>;
export type CompanyFiltersLazyQueryHookResult = ReturnType<typeof useCompanyFiltersLazyQuery>;
export type CompanyFiltersQueryResult = Apollo.QueryResult<CompanyFiltersQuery, CompanyFiltersQueryVariables>;
export const CompanyDetailDocument = gql`
    query CompanyDetail($slug: String!, $startDate: String!, $endDate: String!) {
        company(slug: $slug) {
            id
            videoUrl
            jpegHero: mainImage {
                transform(config: HERO, format: JPEG) {
                    ...Transform
                }
            }
            webpHero: mainImage {
                transform(config: HERO, format: WEBP) {
                    ...Transform
                }
            }
            jpegThumbnail: listImage {
                transform(config: TILE, format: JPEG) {
                    ...Transform
                }
            }
            webpThumbnail: listImage {
                transform(config: TILE, format: WEBP) {
                    ...Transform
                }
            }
            place
            name
            description
            categories {
                id
                isRoot
                name
                position
                slug
            }
            socialsFacebookUrl
            socialsInstagramUrl
            socialsTwitterUrl
            email
            phoneNumber
            websiteUrl
            address {
                ...Address
            }
            pngLogo: logoImage {
                transform(config: LOGO, format: PNG) {
                    ...Transform
                }
            }
            webpLogo: logoImage {
                transform(config: LOGO, format: WEBP) {
                    ...Transform
                }
            }
            coordinates {
                lat
                lon
            }
            ...CompanyOperatingHours
            descriptionParticulars
            images {
                jpegTile: transform(config: TILE, format: JPEG) {
                    ...Transform
                }
                webpTile: transform(config: TILE, format: WEBP) {
                    ...Transform
                }
                jpegGallery: transform(config: GALLERY, format: JPEG) {
                    ...Transform
                }
                webpGallery: transform(config: GALLERY, format: WEBP) {
                    ...Transform
                }
            }
            activities {
                ...ActivityHit
            }
        }
    }
    ${TransformFragmentDoc}
    ${AddressFragmentDoc}
    ${CompanyOperatingHoursFragmentDoc}
    ${ActivityHitFragmentDoc}
`;

/**
 * __useCompanyDetailQuery__
 *
 * To run a query within a React component, call `useCompanyDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyDetailQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCompanyDetailQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CompanyDetailQuery, CompanyDetailQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CompanyDetailQuery, CompanyDetailQueryVariables>(CompanyDetailDocument, options);
}
export function useCompanyDetailLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyDetailQuery, CompanyDetailQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CompanyDetailQuery, CompanyDetailQueryVariables>(
        CompanyDetailDocument,
        options
    );
}
export type CompanyDetailQueryHookResult = ReturnType<typeof useCompanyDetailQuery>;
export type CompanyDetailLazyQueryHookResult = ReturnType<typeof useCompanyDetailLazyQuery>;
export type CompanyDetailQueryResult = Apollo.QueryResult<CompanyDetailQuery, CompanyDetailQueryVariables>;
export const MyDocument = gql`
    query My {
        viewer {
            ...MyCustomer
        }
    }
    ${MyCustomerFragmentDoc}
`;

/**
 * __useMyQuery__
 *
 * To run a query within a React component, call `useMyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyQuery, MyQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<MyQuery, MyQueryVariables>(MyDocument, options);
}
export function useMyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyQuery, MyQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<MyQuery, MyQueryVariables>(MyDocument, options);
}
export type MyQueryHookResult = ReturnType<typeof useMyQuery>;
export type MyLazyQueryHookResult = ReturnType<typeof useMyLazyQuery>;
export type MyQueryResult = Apollo.QueryResult<MyQuery, MyQueryVariables>;
export const OrderDetailsDocument = gql`
    query OrderDetails($id: ID!) {
        order(id: $id) {
            ...OrderDetails
        }
    }
    ${OrderDetailsFragmentDoc}
`;

/**
 * __useOrderDetailsQuery__
 *
 * To run a query within a React component, call `useOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderDetailsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<OrderDetailsQuery, OrderDetailsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<OrderDetailsQuery, OrderDetailsQueryVariables>(OrderDetailsDocument, options);
}
export function useOrderDetailsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderDetailsQuery, OrderDetailsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<OrderDetailsQuery, OrderDetailsQueryVariables>(OrderDetailsDocument, options);
}
export type OrderDetailsQueryHookResult = ReturnType<typeof useOrderDetailsQuery>;
export type OrderDetailsLazyQueryHookResult = ReturnType<typeof useOrderDetailsLazyQuery>;
export type OrderDetailsQueryResult = Apollo.QueryResult<OrderDetailsQuery, OrderDetailsQueryVariables>;
export const PlannerPageRentalUnitDocument = gql`
    query PlannerPageRentalUnit($slug: String!) {
        rentalUnit(slug: $slug) {
            id
            brand
            maxOccupancy
            petsAllowed
        }
    }
`;

/**
 * __usePlannerPageRentalUnitQuery__
 *
 * To run a query within a React component, call `usePlannerPageRentalUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlannerPageRentalUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlannerPageRentalUnitQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePlannerPageRentalUnitQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<PlannerPageRentalUnitQuery, PlannerPageRentalUnitQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PlannerPageRentalUnitQuery, PlannerPageRentalUnitQueryVariables>(
        PlannerPageRentalUnitDocument,
        options
    );
}
export function usePlannerPageRentalUnitLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlannerPageRentalUnitQuery, PlannerPageRentalUnitQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PlannerPageRentalUnitQuery, PlannerPageRentalUnitQueryVariables>(
        PlannerPageRentalUnitDocument,
        options
    );
}
export type PlannerPageRentalUnitQueryHookResult = ReturnType<typeof usePlannerPageRentalUnitQuery>;
export type PlannerPageRentalUnitLazyQueryHookResult = ReturnType<typeof usePlannerPageRentalUnitLazyQuery>;
export type PlannerPageRentalUnitQueryResult = Apollo.QueryResult<
    PlannerPageRentalUnitQuery,
    PlannerPageRentalUnitQueryVariables
>;
export const RentalUnitReviewDocument = gql`
    query RentalUnitReview($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...RentalUnitReview
        }
    }
    ${RentalUnitReviewFragmentDoc}
`;

/**
 * __useRentalUnitReviewQuery__
 *
 * To run a query within a React component, call `useRentalUnitReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useRentalUnitReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRentalUnitReviewQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useRentalUnitReviewQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<RentalUnitReviewQuery, RentalUnitReviewQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<RentalUnitReviewQuery, RentalUnitReviewQueryVariables>(
        RentalUnitReviewDocument,
        options
    );
}
export function useRentalUnitReviewLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RentalUnitReviewQuery, RentalUnitReviewQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<RentalUnitReviewQuery, RentalUnitReviewQueryVariables>(
        RentalUnitReviewDocument,
        options
    );
}
export type RentalUnitReviewQueryHookResult = ReturnType<typeof useRentalUnitReviewQuery>;
export type RentalUnitReviewLazyQueryHookResult = ReturnType<typeof useRentalUnitReviewLazyQuery>;
export type RentalUnitReviewQueryResult = Apollo.QueryResult<RentalUnitReviewQuery, RentalUnitReviewQueryVariables>;
export const LeaveUnauthenticatedReviewDocument = gql`
    mutation LeaveUnauthenticatedReview($input: LeaveUnauthenticatedReviewInput!) {
        leaveUnauthenticatedReview(input: $input) {
            review {
                ...Review
            }
        }
    }
    ${ReviewFragmentDoc}
`;

/**
 * __useLeaveUnauthenticatedReviewMutation__
 *
 * To run a mutation, you first call `useLeaveUnauthenticatedReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveUnauthenticatedReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveUnauthenticatedReviewMutation, { data, loading, error }] = useLeaveUnauthenticatedReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveUnauthenticatedReviewMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        LeaveUnauthenticatedReviewMutation,
        LeaveUnauthenticatedReviewMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        LeaveUnauthenticatedReviewMutation,
        LeaveUnauthenticatedReviewMutationVariables
    >(LeaveUnauthenticatedReviewDocument, options);
}
export type LeaveUnauthenticatedReviewMutationHookResult = ReturnType<typeof useLeaveUnauthenticatedReviewMutation>;
export type LeaveUnauthenticatedReviewMutationResult = Apollo.MutationResult<LeaveUnauthenticatedReviewMutation>;
export type LeaveUnauthenticatedReviewMutationOptions = Apollo.BaseMutationOptions<
    LeaveUnauthenticatedReviewMutation,
    LeaveUnauthenticatedReviewMutationVariables
>;
export const SearchPageRentalUnitsDocument = gql`
    query SearchPageRentalUnits(
        $babies: Int!
        $filters: [PropertyFilterInput!]
        $persons: Int!
        $pets: Int!
        $mapArea: BoundingBoxInput
        $offset: Int
        $order: SearchRentalUnitsOrderEnum
        $limit: Int
        $badgeHandle: String!
        $badge2Handle: String!
    ) {
        searchRentalUnits(
            babies: $babies
            filters: $filters
            persons: $persons
            pets: $pets
            mapArea: $mapArea
            limit: $limit
            offset: $offset
            order: $order
        ) {
            hits {
                ...SearchHitRentalUnit
            }
            propertyFilterCounts {
                ...PropertyFilterCounts
            }
            totalHits
        }
    }
    ${SearchHitRentalUnitFragmentDoc}
    ${PropertyFilterCountsFragmentDoc}
`;

/**
 * __useSearchPageRentalUnitsQuery__
 *
 * To run a query within a React component, call `useSearchPageRentalUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPageRentalUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPageRentalUnitsQuery({
 *   variables: {
 *      babies: // value for 'babies'
 *      filters: // value for 'filters'
 *      persons: // value for 'persons'
 *      pets: // value for 'pets'
 *      mapArea: // value for 'mapArea'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *      limit: // value for 'limit'
 *      badgeHandle: // value for 'badgeHandle'
 *      badge2Handle: // value for 'badge2Handle'
 *   },
 * });
 */
export function useSearchPageRentalUnitsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<SearchPageRentalUnitsQuery, SearchPageRentalUnitsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<SearchPageRentalUnitsQuery, SearchPageRentalUnitsQueryVariables>(
        SearchPageRentalUnitsDocument,
        options
    );
}
export function useSearchPageRentalUnitsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchPageRentalUnitsQuery, SearchPageRentalUnitsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<SearchPageRentalUnitsQuery, SearchPageRentalUnitsQueryVariables>(
        SearchPageRentalUnitsDocument,
        options
    );
}
export type SearchPageRentalUnitsQueryHookResult = ReturnType<typeof useSearchPageRentalUnitsQuery>;
export type SearchPageRentalUnitsLazyQueryHookResult = ReturnType<typeof useSearchPageRentalUnitsLazyQuery>;
export type SearchPageRentalUnitsQueryResult = Apollo.QueryResult<
    SearchPageRentalUnitsQuery,
    SearchPageRentalUnitsQueryVariables
>;
export const SearchTripsDocument = gql`
    query SearchTrips(
        $babies: Int!
        $date: [String!]!
        $duration: [Int!]!
        $filters: [PropertyFilterInput!]
        $persons: Int!
        $pets: Int!
        $price: RangeFilterInput
        $specialId: [ID!]
        $tripType: [TripTypeEnum!]
        $mapArea: BoundingBoxInput
        $limit: Int
        $offset: Int
        $order: UnitSearchOrderEnum
        $badgeHandle: String!
        $badge2Handle: String!
    ) {
        searchTrips(
            babies: $babies
            date: $date
            duration: $duration
            filters: $filters
            persons: $persons
            pets: $pets
            price: $price
            specialId: $specialId
            tripType: $tripType
            mapArea: $mapArea
        ) {
            hits(limit: $limit, offset: $offset, order: $order) {
                ...SearchHitTrip
                rentalUnit {
                    ...SearchHitRentalUnit
                }
            }
            stats {
                totalHits
                propertyFilterCounts {
                    ...PropertyFilterCounts
                }
                minPrice
                maxPrice
                tripTypeCounts {
                    tripType
                    hits
                }
                specialCounts {
                    special {
                        description
                        id
                        landingPageUrl
                        name
                    }
                    hits
                }
            }
        }
    }
    ${SearchHitTripFragmentDoc}
    ${SearchHitRentalUnitFragmentDoc}
    ${PropertyFilterCountsFragmentDoc}
`;

/**
 * __useSearchTripsQuery__
 *
 * To run a query within a React component, call `useSearchTripsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTripsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTripsQuery({
 *   variables: {
 *      babies: // value for 'babies'
 *      date: // value for 'date'
 *      duration: // value for 'duration'
 *      filters: // value for 'filters'
 *      persons: // value for 'persons'
 *      pets: // value for 'pets'
 *      price: // value for 'price'
 *      specialId: // value for 'specialId'
 *      tripType: // value for 'tripType'
 *      mapArea: // value for 'mapArea'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *      badgeHandle: // value for 'badgeHandle'
 *      badge2Handle: // value for 'badge2Handle'
 *   },
 * });
 */
export function useSearchTripsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<SearchTripsQuery, SearchTripsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<SearchTripsQuery, SearchTripsQueryVariables>(SearchTripsDocument, options);
}
export function useSearchTripsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchTripsQuery, SearchTripsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<SearchTripsQuery, SearchTripsQueryVariables>(SearchTripsDocument, options);
}
export type SearchTripsQueryHookResult = ReturnType<typeof useSearchTripsQuery>;
export type SearchTripsLazyQueryHookResult = ReturnType<typeof useSearchTripsLazyQuery>;
export type SearchTripsQueryResult = Apollo.QueryResult<SearchTripsQuery, SearchTripsQueryVariables>;
export const UnitFilterPropertiesDocument = gql`
    query UnitFilterProperties {
        unitFilterProperties {
            name
            handle
            filterProperties {
                name
                handle
            }
        }
    }
`;

/**
 * __useUnitFilterPropertiesQuery__
 *
 * To run a query within a React component, call `useUnitFilterPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitFilterPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitFilterPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnitFilterPropertiesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<UnitFilterPropertiesQuery, UnitFilterPropertiesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<UnitFilterPropertiesQuery, UnitFilterPropertiesQueryVariables>(
        UnitFilterPropertiesDocument,
        options
    );
}
export function useUnitFilterPropertiesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnitFilterPropertiesQuery, UnitFilterPropertiesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<UnitFilterPropertiesQuery, UnitFilterPropertiesQueryVariables>(
        UnitFilterPropertiesDocument,
        options
    );
}
export type UnitFilterPropertiesQueryHookResult = ReturnType<typeof useUnitFilterPropertiesQuery>;
export type UnitFilterPropertiesLazyQueryHookResult = ReturnType<typeof useUnitFilterPropertiesLazyQuery>;
export type UnitFilterPropertiesQueryResult = Apollo.QueryResult<
    UnitFilterPropertiesQuery,
    UnitFilterPropertiesQueryVariables
>;
export const UnitSearchPriceFilterDocument = gql`
    query UnitSearchPriceFilter($params: UnitSearchParamsInput!) {
        unitSearch(params: $params) {
            minPrice
            maxPrice
        }
    }
`;

/**
 * __useUnitSearchPriceFilterQuery__
 *
 * To run a query within a React component, call `useUnitSearchPriceFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitSearchPriceFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitSearchPriceFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUnitSearchPriceFilterQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<UnitSearchPriceFilterQuery, UnitSearchPriceFilterQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<UnitSearchPriceFilterQuery, UnitSearchPriceFilterQueryVariables>(
        UnitSearchPriceFilterDocument,
        options
    );
}
export function useUnitSearchPriceFilterLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnitSearchPriceFilterQuery, UnitSearchPriceFilterQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<UnitSearchPriceFilterQuery, UnitSearchPriceFilterQueryVariables>(
        UnitSearchPriceFilterDocument,
        options
    );
}
export type UnitSearchPriceFilterQueryHookResult = ReturnType<typeof useUnitSearchPriceFilterQuery>;
export type UnitSearchPriceFilterLazyQueryHookResult = ReturnType<typeof useUnitSearchPriceFilterLazyQuery>;
export type UnitSearchPriceFilterQueryResult = Apollo.QueryResult<
    UnitSearchPriceFilterQuery,
    UnitSearchPriceFilterQueryVariables
>;
export const TripPlannerDocument = gql`
    query TripPlanner($slug: String!, $start: String!, $end: String!, $persons: Int!, $pets: Int!, $babies: Int!) {
        rentalUnit(slug: $slug) {
            id
            name
            code
            brand
            trips(pets: $pets, persons: $persons, babies: $babies, startDate: $start, endDate: $end) {
                ...PlannerTrip
            }
        }
    }
    ${PlannerTripFragmentDoc}
`;

/**
 * __useTripPlannerQuery__
 *
 * To run a query within a React component, call `useTripPlannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripPlannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripPlannerQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      persons: // value for 'persons'
 *      pets: // value for 'pets'
 *      babies: // value for 'babies'
 *   },
 * });
 */
export function useTripPlannerQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<TripPlannerQuery, TripPlannerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TripPlannerQuery, TripPlannerQueryVariables>(TripPlannerDocument, options);
}
export function useTripPlannerLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TripPlannerQuery, TripPlannerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TripPlannerQuery, TripPlannerQueryVariables>(TripPlannerDocument, options);
}
export type TripPlannerQueryHookResult = ReturnType<typeof useTripPlannerQuery>;
export type TripPlannerLazyQueryHookResult = ReturnType<typeof useTripPlannerLazyQuery>;
export type TripPlannerQueryResult = Apollo.QueryResult<TripPlannerQuery, TripPlannerQueryVariables>;
export const GetCartDocument = gql`
    query GetCart($id: ID!) {
        cart(id: $id) {
            ...GetCart
        }
    }
    ${GetCartFragmentDoc}
`;

/**
 * __useGetCartQuery__
 *
 * To run a query within a React component, call `useGetCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCartQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCartQuery, GetCartQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetCartQuery, GetCartQueryVariables>(GetCartDocument, options);
}
export function useGetCartLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCartQuery, GetCartQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetCartQuery, GetCartQueryVariables>(GetCartDocument, options);
}
export type GetCartQueryHookResult = ReturnType<typeof useGetCartQuery>;
export type GetCartLazyQueryHookResult = ReturnType<typeof useGetCartLazyQuery>;
export type GetCartQueryResult = Apollo.QueryResult<GetCartQuery, GetCartQueryVariables>;
export const CartStatusDocument = gql`
    query CartStatus($cartId: ID!) {
        cart(id: $cartId) {
            id
            status
        }
    }
`;

/**
 * __useCartStatusQuery__
 *
 * To run a query within a React component, call `useCartStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartStatusQuery({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useCartStatusQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CartStatusQuery, CartStatusQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CartStatusQuery, CartStatusQueryVariables>(CartStatusDocument, options);
}
export function useCartStatusLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CartStatusQuery, CartStatusQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CartStatusQuery, CartStatusQueryVariables>(CartStatusDocument, options);
}
export type CartStatusQueryHookResult = ReturnType<typeof useCartStatusQuery>;
export type CartStatusLazyQueryHookResult = ReturnType<typeof useCartStatusLazyQuery>;
export type CartStatusQueryResult = Apollo.QueryResult<CartStatusQuery, CartStatusQueryVariables>;
export const SetCartDocument = gql`
    mutation SetCart($input: UpdateCartInput!) {
        updateCart(input: $input) {
            cart {
                ...GetCart
            }
        }
    }
    ${GetCartFragmentDoc}
`;

/**
 * __useSetCartMutation__
 *
 * To run a mutation, you first call `useSetCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCartMutation, { data, loading, error }] = useSetCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCartMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<SetCartMutation, SetCartMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<SetCartMutation, SetCartMutationVariables>(SetCartDocument, options);
}
export type SetCartMutationHookResult = ReturnType<typeof useSetCartMutation>;
export type SetCartMutationResult = Apollo.MutationResult<SetCartMutation>;
export type SetCartMutationOptions = Apollo.BaseMutationOptions<SetCartMutation, SetCartMutationVariables>;
export const UnitDetailsDocument = gql`
    query UnitDetails($slug: String!, $arrivalDate: String, $bakedInFilterProperty: String!) {
        rentalUnit(slug: $slug) {
            id
            firstTripDate
            flexCancellationDays
            slug
            name
            brand
            jpegThumbnail: listImage {
                transform(config: TILE, format: JPEG) {
                    ...Transform
                }
            }
            webpThumbnail: listImage {
                transform(config: TILE, format: WEBP) {
                    ...Transform
                }
            }
            jpegHero: mainImage {
                transform(config: HERO, format: JPEG) {
                    ...Transform
                }
            }
            webpHero: mainImage {
                transform(config: HERO, format: WEBP) {
                    ...Transform
                }
            }
            municipalRegistration
            tagline
            minOccupancy
            maxOccupancy
            petsAllowed
            descriptionLayout
            descriptionParticulars
            descriptionExtras
            cancellationInsuranceAvailable
            fullCircleImageUrl
            videoUrl
            hasBakedInFilterProperty: hasFilterProperty(handle: $bakedInFilterProperty)
            surcharges(arrivalDate: $arrivalDate) {
                ...Surcharge
            }
            reviewStats {
                ...ReviewStats
            }
            ...UnitFeatures
            ...UnitPhotos
            attributeCategories {
                ...Extras
            }
            accommodation {
                id
                slug
                ...UnitAccommodation
                descriptionGeneral
                ...UnitOwner
                ...Location
                ...AccommodationPhotos
                place
                name
                checkInEndTime
                checkInStartTime
                checkOutTime
                hasPublicPage
            }
            reviews(limit: 3, offset: 0) {
                ...UnitReviews
            }
        }
    }
    ${TransformFragmentDoc}
    ${SurchargeFragmentDoc}
    ${ReviewStatsFragmentDoc}
    ${UnitFeaturesFragmentDoc}
    ${UnitPhotosFragmentDoc}
    ${ExtrasFragmentDoc}
    ${UnitAccommodationFragmentDoc}
    ${UnitOwnerFragmentDoc}
    ${LocationFragmentDoc}
    ${AccommodationPhotosFragmentDoc}
    ${UnitReviewsFragmentDoc}
`;

/**
 * __useUnitDetailsQuery__
 *
 * To run a query within a React component, call `useUnitDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      arrivalDate: // value for 'arrivalDate'
 *      bakedInFilterProperty: // value for 'bakedInFilterProperty'
 *   },
 * });
 */
export function useUnitDetailsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<UnitDetailsQuery, UnitDetailsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<UnitDetailsQuery, UnitDetailsQueryVariables>(UnitDetailsDocument, options);
}
export function useUnitDetailsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnitDetailsQuery, UnitDetailsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<UnitDetailsQuery, UnitDetailsQueryVariables>(UnitDetailsDocument, options);
}
export type UnitDetailsQueryHookResult = ReturnType<typeof useUnitDetailsQuery>;
export type UnitDetailsLazyQueryHookResult = ReturnType<typeof useUnitDetailsLazyQuery>;
export type UnitDetailsQueryResult = Apollo.QueryResult<UnitDetailsQuery, UnitDetailsQueryVariables>;
export const UnitDetailsSearchDocument = gql`
    query UnitDetailsSearch(
        $slug: String!
        $params: UnitSearchParamsInput
        $arrivalDate: String
        $badgeHandle: String!
        $badge2Handle: String!
    ) {
        rentalUnit(slug: $slug) {
            id
            alternativesInAccommodation(params: $params) {
                hits {
                    ...SearchHit
                }
            }
            surcharges(arrivalDate: $arrivalDate) {
                ...Surcharge
            }
        }
    }
    ${SearchHitFragmentDoc}
    ${SurchargeFragmentDoc}
`;

/**
 * __useUnitDetailsSearchQuery__
 *
 * To run a query within a React component, call `useUnitDetailsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitDetailsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitDetailsSearchQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      params: // value for 'params'
 *      arrivalDate: // value for 'arrivalDate'
 *      badgeHandle: // value for 'badgeHandle'
 *      badge2Handle: // value for 'badge2Handle'
 *   },
 * });
 */
export function useUnitDetailsSearchQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<UnitDetailsSearchQuery, UnitDetailsSearchQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<UnitDetailsSearchQuery, UnitDetailsSearchQueryVariables>(
        UnitDetailsSearchDocument,
        options
    );
}
export function useUnitDetailsSearchLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnitDetailsSearchQuery, UnitDetailsSearchQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<UnitDetailsSearchQuery, UnitDetailsSearchQueryVariables>(
        UnitDetailsSearchDocument,
        options
    );
}
export type UnitDetailsSearchQueryHookResult = ReturnType<typeof useUnitDetailsSearchQuery>;
export type UnitDetailsSearchLazyQueryHookResult = ReturnType<typeof useUnitDetailsSearchLazyQuery>;
export type UnitDetailsSearchQueryResult = Apollo.QueryResult<UnitDetailsSearchQuery, UnitDetailsSearchQueryVariables>;
export const UnitDetailInitialTripsDocument = gql`
    query UnitDetailInitialTrips(
        $slug: String!
        $start: String!
        $end: String!
        $persons: Int!
        $pets: Int!
        $babies: Int!
    ) {
        rentalUnit(slug: $slug) {
            id
            trips(pets: $pets, persons: $persons, babies: $babies, startDate: $start, endDate: $end) {
                ...PlannerTrip
            }
        }
    }
    ${PlannerTripFragmentDoc}
`;

/**
 * __useUnitDetailInitialTripsQuery__
 *
 * To run a query within a React component, call `useUnitDetailInitialTripsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitDetailInitialTripsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitDetailInitialTripsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      persons: // value for 'persons'
 *      pets: // value for 'pets'
 *      babies: // value for 'babies'
 *   },
 * });
 */
export function useUnitDetailInitialTripsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<UnitDetailInitialTripsQuery, UnitDetailInitialTripsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<UnitDetailInitialTripsQuery, UnitDetailInitialTripsQueryVariables>(
        UnitDetailInitialTripsDocument,
        options
    );
}
export function useUnitDetailInitialTripsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        UnitDetailInitialTripsQuery,
        UnitDetailInitialTripsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<UnitDetailInitialTripsQuery, UnitDetailInitialTripsQueryVariables>(
        UnitDetailInitialTripsDocument,
        options
    );
}
export type UnitDetailInitialTripsQueryHookResult = ReturnType<typeof useUnitDetailInitialTripsQuery>;
export type UnitDetailInitialTripsLazyQueryHookResult = ReturnType<typeof useUnitDetailInitialTripsLazyQuery>;
export type UnitDetailInitialTripsQueryResult = Apollo.QueryResult<
    UnitDetailInitialTripsQuery,
    UnitDetailInitialTripsQueryVariables
>;
export const AlternativesInAccommodationDocument = gql`
    query AlternativesInAccommodation(
        $slug: String!
        $params: UnitSearchParamsInput
        $badgeHandle: String!
        $badge2Handle: String!
    ) {
        rentalUnit(slug: $slug) {
            id
            alternativesInAccommodation(params: $params) {
                hits {
                    ...SearchHit
                }
            }
        }
    }
    ${SearchHitFragmentDoc}
`;

/**
 * __useAlternativesInAccommodationQuery__
 *
 * To run a query within a React component, call `useAlternativesInAccommodationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlternativesInAccommodationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlternativesInAccommodationQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      params: // value for 'params'
 *      badgeHandle: // value for 'badgeHandle'
 *      badge2Handle: // value for 'badge2Handle'
 *   },
 * });
 */
export function useAlternativesInAccommodationQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        AlternativesInAccommodationQuery,
        AlternativesInAccommodationQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AlternativesInAccommodationQuery, AlternativesInAccommodationQueryVariables>(
        AlternativesInAccommodationDocument,
        options
    );
}
export function useAlternativesInAccommodationLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        AlternativesInAccommodationQuery,
        AlternativesInAccommodationQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<AlternativesInAccommodationQuery, AlternativesInAccommodationQueryVariables>(
        AlternativesInAccommodationDocument,
        options
    );
}
export type AlternativesInAccommodationQueryHookResult = ReturnType<typeof useAlternativesInAccommodationQuery>;
export type AlternativesInAccommodationLazyQueryHookResult = ReturnType<typeof useAlternativesInAccommodationLazyQuery>;
export type AlternativesInAccommodationQueryResult = Apollo.QueryResult<
    AlternativesInAccommodationQuery,
    AlternativesInAccommodationQueryVariables
>;
export const TorSitemapDocument = gql`
    query TorSitemap($params: UnitSearchParamsInput!) {
        unitSearch(params: $params, limit: 9999999) {
            hits {
                rentalUnit {
                    accommodation {
                        hasPublicPage
                        slug
                        mainImage {
                            transform(format: JPEG, config: HERO) {
                                src
                            }
                        }
                    }
                    listImage {
                        transform(format: JPEG, config: TILE) {
                            src
                        }
                    }
                    slug
                }
            }
        }
    }
`;

/**
 * __useTorSitemapQuery__
 *
 * To run a query within a React component, call `useTorSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useTorSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTorSitemapQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useTorSitemapQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<TorSitemapQuery, TorSitemapQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TorSitemapQuery, TorSitemapQueryVariables>(TorSitemapDocument, options);
}
export function useTorSitemapLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TorSitemapQuery, TorSitemapQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TorSitemapQuery, TorSitemapQueryVariables>(TorSitemapDocument, options);
}
export type TorSitemapQueryHookResult = ReturnType<typeof useTorSitemapQuery>;
export type TorSitemapLazyQueryHookResult = ReturnType<typeof useTorSitemapLazyQuery>;
export type TorSitemapQueryResult = Apollo.QueryResult<TorSitemapQuery, TorSitemapQueryVariables>;
