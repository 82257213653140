import { PaymentMethodEnum, SearchRentalUnitsOrderEnum, UnitSearchOrderEnum } from '../generated/graphql';
import { BrandConfig } from './brandConfig';
import { rentalUnitTypes } from './filterTypes';
import indexImage from '../../public/static/img/campercontactBg.jpeg';
import theme from '../themes/campercontact';

const campercontactConfig: BrandConfig = {
    name: 'campercontact',
    locales: ['nl', 'de', 'en'],
    theme,
    gtmID: 'GTM-MJ7LG44',
    hasReviews: false,
    initialNewsletterChecked: true,
    font: {
        hrefs: [
            '/static/font/ShandonSlab-thin.ttf',
            '/static/font/ShandonSlab-thin-italic.ttf',
            '/static/font/ShandonSlab-Light.ttf',
            '/static/font/ShandonSlab-LightItalic.ttf',
            '/static/font/ShandonSlab-Regular.ttf',
            '/static/font/ShandonSlab-Italic.ttf',
            '/static/font/ShandonSlab-Medium.ttf',
            '/static/font/ShandonSlab-MediumItalic.ttf',
            '/static/font/ShandonSlab-Bold.ttf',
            '/static/font/ShandonSlab-BoldItalic.ttf',
            '/static/font/ShandonSlab-ExtraBold.ttf',
            '/static/font/ShandonSlab-ExtraBoldItalic.ttf',
            '/static/font/ShandonSlab-Black.ttf',
            '/static/font/ShandonSlab-BlackItalic.ttf',
            '/static/font/RedHatDisplay-Light.ttf',
            '/static/font/RedHatDisplay-LightItalic.ttf',
            '/static/font/RedHatDisplay-Regular.ttf',
            '/static/font/RedHatDisplay-Italic.ttf',
            '/static/font/RedHatDisplay-Medium.ttf',
            '/static/font/RedHatDisplay-MediumItalic.ttf',
            '/static/font/RedHatDisplay-Bold.ttf',
            '/static/font/RedHatDisplay-BoldItalic.ttf',
            '/static/font/RedHatDisplay-ExtraBold.ttf',
            '/static/font/RedHatDisplay-ExtraBoldItalic.ttf',
            '/static/font/RedHatDisplay-Black.ttf',
            '/static/font/RedHatDisplay-BlackItalic.ttf',
        ],
        css: `
        // Shandon Slab

        @font-face {
            font-family: 'Shandon Slab';
            src: url('/static/font/ShandonSlab-thin.ttf') format('truetype');
            font-weight: 100;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Shandon Slab';
            src: url('/static/font/ShandonSlab-thin-italic.ttf') format('truetype');
            font-weight: 100;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: 'Shandon Slab';
            src: url('/static/font/ShandonSlab-Light.ttf') format('truetype');
            font-weight: 300;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Shandon Slab';
            src: url('/static/font/ShandonSlab-LightItalic.ttf') format('truetype');
            font-weight: 300;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: 'Shandon Slab';
            src: url('/static/font/ShandonSlab-Regular.ttf') format('truetype');
            font-weight: 400;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Shandon Slab';
            src: url('/static/font/ShandonSlab-Italic.ttf') format('truetype');
            font-weight: 400;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: 'Shandon Slab';
            src: url('/static/font/ShandonSlab-Medium.ttf') format('truetype');
            font-weight: 500;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Shandon Slab';
            src: url('/static/font/ShandonSlab-MediumItalic.ttf') format('truetype');
            font-weight: 500;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: 'Shandon Slab';
            src: url('/static/font/ShandonSlab-Bold.ttf') format('truetype');
            font-weight: 700;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Shandon Slab';
            src: url('/static/font/ShandonSlab-BoldItalic.ttf') format('truetype');
            font-weight: 700;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: 'Shandon Slab';
            src: url('/static/font/ShandonSlab-ExtraBold.ttf') format('truetype');
            font-weight: 800;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Shandon Slab';
            src: url('/static/font/ShandonSlab-ExtraBoldItalic.ttf') format('truetype');
            font-weight: 800;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: 'Shandon Slab';
            src: url('/static/font/ShandonSlab-Black.ttf') format('truetype');
            font-weight: 900;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Shandon Slab';
            src: url('/static/font/ShandonSlab-BlackItalic.ttf') format('truetype');
            font-weight: 900;
            font-style: italic;
            font-display: swap;
        }

        // Red Hat Display

        @font-face {
            font-family: 'Red Hat Display';
            src: url('/static/font/RedHatDisplay-Light.ttf') format('truetype');
            font-weight: 300;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Red Hat Display';
            src: url('/static/font/RedHatDisplay-LightItalic.ttf') format('truetype');
            font-weight: 300;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: 'Red Hat Display';
            src: url('/static/font/RedHatDisplay-Regular.ttf') format('truetype');
            font-weight: 400;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Red Hat Display';
            src: url('/static/font/RedHatDisplay-Italic.ttf') format('truetype');
            font-weight: 400;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: 'Red Hat Display';
            src: url('/static/font/RedHatDisplay-Medium.ttf') format('truetype');
            font-weight: 500;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Red Hat Display';
            src: url('/static/font/RedHatDisplay-MediumItalic.ttf') format('truetype');
            font-weight: 500;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: 'Red Hat Display';
            src: url('/static/font/RedHatDisplay-Bold.ttf') format('truetype');
            font-weight: 700;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Red Hat Display';
            src: url('/static/font/RedHatDisplay-BoldItalic.ttf') format('truetype');
            font-weight: 700;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: 'Red Hat Display';
            src: url('/static/font/RedHatDisplay-ExtraBold.ttf') format('truetype');
            font-weight: 800;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Red Hat Display';
            src: url('/static/font/RedHatDisplay-ExtraBoldItalic.ttf') format('truetype');
            font-weight: 800;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: 'Red Hat Display';
            src: url('/static/font/RedHatDisplay-Black.ttf') format('truetype');
            font-weight: 900;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Red Hat Display';
            src: url('/static/font/RedHatDisplay-BlackItalic.ttf') format('truetype');
            font-weight: 900;
            font-style: italic;
            font-display: swap;
        }

    `,
    },
    contact: {},
    indexImage,
    accommodationTypes: [
        {
            message: 'all',
            value: 'all',
        },
        {
            message: 'camping',
            value: `${rentalUnitTypes.CAMPING_PITCH}`,
        },
        {
            message: 'camperPitch',
            value: `${rentalUnitTypes.CAMPER_SPOT}`,
        },
    ],
    groupTypes: ['adults', 'youth', 'children', 'babies', 'pets'],
    unitSearchOrder: {
        popularity: SearchRentalUnitsOrderEnum.RandomBoosted,
        ratingDesc: SearchRentalUnitsOrderEnum.ReviewRatingDesc,
    },
    tripsSearchOrder: {
        popularity: UnitSearchOrderEnum.RandomBoosted,
        ratingDesc: UnitSearchOrderEnum.ReviewRatingDesc,
        priceAsc: UnitSearchOrderEnum.PriceAsc,
        priceDesc: UnitSearchOrderEnum.PriceDesc,
    },

    // Map zoom level
    mapZoom: 10.5,

    // Map min zoom level
    mapMinZoom: {
        desktop: 10.5,
        mobile: 8.5,
    },

    coordinates: {
        latitude: 52.3892,
        longitude: 4.8906,
    },

    seo: {
        siteName: 'Campercontact',
    },
    minBookingAge: 18,
    cancellationInsuranceDefault: true,
    complaintFormUrl: {
        nl: 'https://www.campercontact.com/nl/content/contact',
        en: 'https://www.campercontact.com/en/content/contact',
        de: 'https://www.campercontact.com/de/content/kontakt',
    },

    checkout: {
        initialPaymentMethod: {
            nl: PaymentMethodEnum.Ideal,
            en: PaymentMethodEnum.CreditCard,
            de: PaymentMethodEnum.Paypal,
        },
        showBookingConfirmExtras: false,
    },
    termsAndConditions: {
        nl: `https://www.campercontact.com/nl/content/algemene-boekingsvoorwaarden`,
        en: `https://www.campercontact.com/en/content/general-bookingconditions`,
        de: `https://www.campercontact.com/de/content/allgemeine-buchungsbedingungen-von-campercontact`,
    },
    enableFavorites: true,
};

export default campercontactConfig;
